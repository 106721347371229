// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Map } from "immutable";
import HeaderUnsupported from "../elements/HeaderUnsupported";
import Card from "../elements/Card";
import MediaList from "../elements/MediaList";
import imgFirefox from "../../images/firefox.png";
import imgChrome from "../../images/chrome.png";
import imgOpera from "../../images/opera.png";
import imgAlert from "../../images/alert.png";
import imgGPlay from "../../images/img-gplay.png";
import imgAppStore from "../../images/img-appstore.png";

import { t } from "../../helpers/global";

type Props = {
  translations: Map<string, any>
};

class UnsupportedBrowser extends React.Component<Props> {
  render() {
    const { translations } = this.props;

    return (
      <Fragment>
        <HeaderUnsupported
          company="Astronaut Technologies"
          type="default"
          className="center bg-header-gray"
        />
        <div className="wrapper-failed">
          <div className="unsupported-content">
            <div>
              <div className="col-md-12">
                <img src={imgAlert} className="img-responsive" alt="" />
              </div>
              <div className="col-md-12 m-t-30">
                <h1 className="bold">Sorry!</h1>
              </div>
              <div className="col-md-12">
                <h1 className="bold">
                  We don't support the browser you're using.
                </h1>
              </div>
              <div className="col-md-12 m-t-25">
                <h6 className="grey-color-font">
                  We recommended you to use the latest version of Chrome,
                  Firefox, or Opera.
                </h6>
              </div>
              <div className="col-md-12 m-t-20">
                <h6 className="grey-color-font">
                  Alternatively, you can also download our app to do the Q&A
                  Session.
                </h6>
              </div>
              <div className="col-md-12 m-t-20">
                <div className="inline">
                  <div className="flex-container">
                    <a
                      href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="m-r-8 m-t-8"
                    >
                      <img src={imgGPlay} className="img-responsive" alt="" />
                    </a>
                    <a
                      href="https://itunes.apple.com/id/app/astronaut-always-be-interviewing/id1098124247?mt=8"
                      className="m-r-10"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="m-r-8 m-t-8"
                    >
                      <img
                        src={imgAppStore}
                        className="img-responsive"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    translations: state.translations
  };
};

export default connect(
  mapStateToProps,
  null
)(UnsupportedBrowser);
