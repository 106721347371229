// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Map } from "immutable";
import bindAll from "lodash/bindAll";
import pluralize from "pluralize";
import Header from "../../lib/elements/Header";
import CardSingleContent from "../../lib/elements/CardSingleContent";
import CardWithLabel from "../../lib/elements/CardWithLabel";
import InstructionDesk from "../../lib/elements/InstructionDesk";
import Button from "../../lib/elements/Button";
import Time from "../../lib/elements/Time";
import Icon from "../../lib/elements/Icon";
import ModalDialog from "../../lib/elements/ModalDialog";
import imgInstruction1 from "../../images/instruction-mcq-1.svg";
import imgInstruction2 from "../../images/instruction-mcq-2.svg";
import imgInstruction3 from "../../images/instruction-mcq-3.svg";
import { sendAstronautQnASessionEvent } from "../../actions/embedded-interview";

import Card from "../../lib/elements/Card";
import Checkbox from "../../lib/elements/Checkbox";

import { globalGenerateUnitOfTimeHelper } from "../../helpers/global";

import { t } from "../../helpers/global";

import {
  mcqsStartRequest,
  mcqsStartWelcomeVideoRequest
} from "../../actions/mcqs";

type Props = {
  job: Map<string, any>,
  company: Map<string, any>,
  candidate: Map<string, any>,
  mcqsStartRequest: Function,
  mcqsStartWelcomeVideoRequest: Function,
  translations: Map<string, any>,
  sessionCode: string,
  sessionsFilterData: Map<string, any>
};

type State = {
  modal: boolean,
  isConsent: boolean
};

class McqGDPRPage extends React.Component<Props, State> {
  constructor(props: Object): void {
    super(props);

    this.state = {
      modal: false,
      isConsent: false
    };

    bindAll(this, [
      "handleConsentClick",
      "handleContinueClick",
      "handleModalToggle"
    ]);
  }

  handleConsentClick(e) {
    this.setState({ isConsent: !this.state.isConsent });
  }

  handleContinueClick(e: Object): void {
    e.preventDefault();

    const {
      sessionCode,
      sessionsFilterData,
      mcqsStartRequest,
      mcqsStartWelcomeVideoRequest,
      sendAstronautQnASessionEvent
    } = this.props;

    sendAstronautQnASessionEvent("GDPR Complied");

    if (sessionsFilterData.get("welcome_video_url") == "") {
      mcqsStartRequest(sessionCode);
    } else {
      mcqsStartWelcomeVideoRequest(sessionCode);
    }
  }

  handleModalToggle(e: Object): void {
    e.preventDefault();

    this.setState({ modal: !this.state.modal });
  }

  render() {
    const {
      job,
      company,
      candidate,
      sessionsFilterData,
      translations
    } = this.props;

    const { isConsent, modal } = this.state;

    const duration: Map<string, any> = globalGenerateUnitOfTimeHelper(
      sessionsFilterData.get("duration_left")
    );
    const questionIdsSize: number = sessionsFilterData.get("question_ids").size;

    return (
      <Fragment>
        <Header
          company={company.get("title")}
          job={job.get("title")}
          position={job.get("jobType")}
          location={job.get("locations")}
          type="primary"
        />
        <div className="container">
          <h4 className="m-t-20 m-b-10">
            <b>{t(translations, "GDPR_TITLE")}</b>
          </h4>
          <Card theme="basic">
            <div className="card-body p-0">
              <div className="row">
                <div className="">
                  <h6
                    align="left"
                    dangerouslySetInnerHTML={{
                      __html: sessionsFilterData.get("gdpr_text")
                    }}
                  />
                </div>
              </div>
              <Checkbox
                onClick={this.handleConsentClick}
                checked={isConsent}
                label={sessionsFilterData.get("gdpr_aggrement_text")}
                className="m-t-20"
              />
            </div>
          </Card>
        </div>
        <div className="row justify-content-center">
          <Button
            className="m-t-40"
            onClick={this.handleContinueClick}
            disabled={!isConsent}
          >
            {t(translations, "LABEL_CONTINUE")}
          </Button>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations,
    job: state.job,
    company: state.company,
    candidate: state.candidate,
    sessionCode: ownProps.match.params.sessionCode,
    sessionsFilterData: state.sessionsFilterData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    mcqsStartRequest: sessionCode => dispatch(mcqsStartRequest(sessionCode)),
    mcqsStartWelcomeVideoRequest: sessionCode =>
      dispatch(mcqsStartWelcomeVideoRequest(sessionCode)),
    sendAstronautQnASessionEvent: astronautEvent =>
      dispatch(sendAstronautQnASessionEvent(astronautEvent))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(McqGDPRPage)
);
