// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Map } from "immutable";
import pluralize from "pluralize";
import { t } from "../../helpers/global";

type Props = {
  data: Map<string, any>,
  translations: Map<string, any>,
  className: string,
  children?: any
};

class Time extends React.Component<Props> {
  render() {
    const {
      data,
      className,
      children,
      translations,
      withoutSeconds
    } = this.props;

    let elemHour: ?Object;
    let elemMinute: ?Object;
    let elemSecond: ?Object;

    if (data.get("hour")) {
      elemHour = (
        <Fragment>
          <p className="heading1 m-r-8">{data.get("hour")}</p>
          <p className="heading3 uppercase normal m-r-12">
            {t(translations, "TIME_LABEL_HOUR", {
              ":pluralize_hour": pluralize("Hour", data.get("hour"))
            })}
          </p>
        </Fragment>
      );
    }

    if (data.get("minutes") > 0) {
      elemMinute = (
        <Fragment>
          <p className="heading1 m-r-8">{data.get("minutes")}</p>
          <p className="heading3 uppercase normal m-r-12">
            {t(translations, "TIME_LABEL_MINUTE", {
              ":pluralize_min": pluralize("Minute", data.get("minutes"))
            })}
          </p>
        </Fragment>
      );
    } else if (data.get("minutes") == 0) {
      if (withoutSeconds) {
        elemMinute = (
          <Fragment>
            <p className="heading1 m-r-8">{0}</p>
            <p className="heading3 uppercase normal m-r-12">
              {t(translations, "TIME_LABEL_MINUTE", {
                ":pluralize_min": pluralize("Minute", 0)
              })}
            </p>
          </Fragment>
        );
      }
    }
    if (!withoutSeconds) {
      if (data.get("seconds")) {
        elemSecond = (
          <Fragment>
            <p className="heading1 m-r-8">{data.get("seconds")}</p>
            <p className="heading3 uppercase normal m-r-12">
              {t(translations, "TIME_LABEL_SECOND", {
                ":pluralize_sec": pluralize("Second", data.get("seconds"))
              })}
            </p>
          </Fragment>
        );
      }
    }

    return (
      <div className={`d-flex align-items-center ${className}`}>
        {children}
        <div className="d-flex align-items-center m-0">
          {elemHour}
          {elemMinute}
          {elemSecond}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations
  };
};

export default connect(
  mapStateToProps,
  null
)(Time);
