// @flow
import React from "react";
import Card from "./Card";
import Content from "./Content";
import ContentSoundCheck from "./ContentSoundCheck";
import Button from "../elements/Button";
import Loader from "../elements/Loader";
import MicrophoneTester from "../../lib/elements/MicrophoneTester";
import VideoSample from "../../images/video-sample.mp4";
import moment from "moment";
import "moment/locale/id";

type Props = {
  contentTitle: string,
  contentImage: string,
  contentImageAlt: string,
  contentText: string,
  className: string,
  buttonName: string,
  loaderName?: string,
  loaderClassName?: string,
  onClick: Function,
  isLoading: boolean
};

moment().locale("id");

function timeStamp(date) {
  var stillUtc = moment.utc(date).toDate();
  var local = moment(stillUtc)
    .local()
    .format("YYYY-MM-DD HH:mm:ss");
  return moment(local).fromNow();
}

class CardTopicUlasan extends React.Component<Props> {
  static defaultProps = {
    contentTitle: "",
    contentText: "",
    contentImage: "",
    contentImageAlt: "",
    className: "",
    buttonName: "",
    onClick: () => {},
    isLoading: false
  };

  render() {
    const {
      contentTitle,
      contentImage,
      contentImageAlt,
      contentText,
      className,
      buttonName,
      onClick,
      loaderName,
      loaderClassName,
      isLoading,
      reviewsData,
      reviewsPagination,
      reviewsRating,
      open_pagination,
      open_topic_page
    } = this.props;

    let render_review = [];
    let render_open_all;
    if (reviewsData.size == 0) {
      render_review = (
        <div className="list-rating no-data">
          {/* <div className="flex-container flex-between">
                                    <div>
                                      <div className="bold">{review.get("fullname")}</div>
                                      <div>2 bulan lalu</div>
                                    </div>
                                    <div className="rating-score">
                                      <span>{review.get("rating")}</span>/10
                                    </div>
                                  </div> */}
          <div className="m-t-10">
            Belum ada ulasan. Jadilah yang pertama memberikan ulasan
          </div>
        </div>
      );
    } else {
      reviewsData.map((review, index) => {
        render_review.push(
          <div key={index} className="list-rating">
            <div className="flex-container flex-between">
              <div>
                <div className="bold">
                  {" "}
                  {review.get("is_hide_review_name") == 1
                    ? review.get("fullname").replace(/(?!^)[\S](?!$)/g, "*")
                    : review.get("fullname")}
                </div>
                <div>
                  {timeStamp(review.get("candidate_finish_interview_time"))}
                </div>
              </div>
              <div className="rating-score">
                <span>{review.get("rating")}</span>/10
              </div>
            </div>
            <div className="m-t-10">{review.get("feedback")}</div>
          </div>
        );
      });
    }

    if (reviewsPagination.get("next_page_url")) {
      render_open_all = (
        <div className="btn-lihat-banyak" onClick={open_pagination}>
          Lihat lebih banyak <i className="fa fa-chevron-down" />
        </div>
      );
    }
    if (reviewsRating.get("is_loading")) {
      render_open_all = (
        <div className="btn-lihat-banyak">
          <i className="fa fa-spinner fa-spin" />
        </div>
      );
    }

    return (
      <Card theme="desc" className={className}>
        <div className="card-body p-0">
          <div className="flex-container flex-between vertical-item--center">
            <h4 className="heading3 bold inline-content">Ulasan</h4>
            <div className="btn-all-topic" onClick={open_topic_page}>
              <p className="heading4 inline m-b-0">
                Kembali Ke Halaman Kursus{" "}
              </p>
              <i className="fa fa-angle-right"></i>
            </div>
          </div>
          <div className="div-ulasan">
            <div className="div-rating div-rating-desktop-hide">
              <div className="kotak-rating horizontal-item--center">
                {reviewsRating.get("average_rating")}
              </div>
              <div className="m-t-5">
                {reviewsPagination.get("total")} Ulasan
              </div>
            </div>
            <div className="div-rating-mobile div-rating-mobile-hide">
              <div className="kotak-rating horizontal-item--center">
                {reviewsRating.get("average_rating")}
              </div>
              <div className="m-l-16 vertical-item--center">
                ({reviewsPagination.get("total")} Ulasan)
              </div>
            </div>
            <div
              className={`div-rating-list ${
                reviewsData.size == 0 ? "no-data" : ""
              }`}
            >
              {render_review}

              <div className="lihat-banyak-container">{render_open_all}</div>
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

export default CardTopicUlasan;
