import _axios from "../dependencies/_axios";
import Promise from "bluebird";
import { push } from "react-router-redux";
import authToken from "../dependencies/authStore";
import { change, startAsyncValidation, stopAsyncValidation } from "redux-form";

export function getRegisterData(jobCode) {
  return dispatch => {
    return new Promise((res, rej) => {
      _axios({
        url: "/api/ecourse/initial-data-v2",
        method: "post",
        data: {
          ecourse_code: jobCode,
          isTranslations: false
        }
      })
        .then(response => {})
        .catch(error => {
          console.log(error);
        });
    });
  };
}

export function ecourseGetJobDetail(jobCode) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: "ECOURSE_GET_DETAIL_JOB",
        jobCode,
        resolve,
        reject
      });
    });
  };
}

export function ecourseRegistrationSuccess(jobCode, values, resolve, reject) {
  return {
    type: "ECOURSE_REGISTRATION_SUCCESS",
    jobCode,
    values,
    resolve,
    reject
  };
}

export function ecourseRegistration(jobCode, values) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(ecourseRegistrationSuccess(jobCode, values, resolve, reject));
    });
  };
}

export function redirectCourse(link) {
  console.log("asup");
  return dispatch => {
    return new Promise((resolve, reject) => {
      resolve(dispatch(push(link)));
    });
  };
}

export function login(values, jobCode) {
  return dispatch => {
    return new Promise((res, rej) => {
      _axios({
        url: "/api/ecourse/login",
        method: "post",
        data: values
      })
        .then(response => {
          if (response.data.status_code == 200) {
            // save token
            console.log("save token");
            console.log(response.data.user.token);
            authToken.set(response.data.user.token);

            // redirect
            // res(dispatch(push("/" + jobCode)));
            window.location = "/" + jobCode;
          } else {
            console.log("not redirected");
            console.log(response);
            rej(`error`);
          }
        })
        .catch(error => {
          console.log(error);
          rej(`error`);
        });
    });
  };
}

export function auth(token, jobCode, ecourseId) {
  return dispatch => {
    return new Promise((res, rej) => {
      _axios({
        url: "/api/ecourse/authentication",
        method: "post",
        headers: {
          token: token
        },
        data: {
          ecourse_code: jobCode,
          ecourse_detail_id: ecourseId
        }
      })
        .then(response => {
          let data = response.data;
          if (data.status_code == 200) {
            let payment = data.payment;
            if (null == payment) {
              dispatch(authStatus(data.message, res, rej));
              res(dispatch(authSuccess(false, res, rej)));
            } else {
              res(dispatch(authSuccess(true, res, rej)));
              // res(dispatch(push("/code/" + data.interview_code)));
            }
          }
        })
        .catch(e => {
          console.log(e);
        });
    });
  };
}

export function authSuccess(payment, resolve, reject) {
  return {
    type: "ECOURSE_AUTH",
    payment,
    resolve,
    reject
  };
}

export function authStatus(status, resolve, reject) {
  return {
    type: "ECOURSE_AUTH_STATUS",
    status,
    resolve,
    reject
  };
}

export function ecourseValidateEmailOnUserRegisterWizardFormRequest(
  email,
  jobCode
) {
  return {
    type: "ECOURSE_VALIDATE_EMAIL_ON_USER_REGISTER_WIZARD_FORM_REQUEST",
    email,
    jobCode
  };
}

export function ecourseLogin(values) {
  return {
    type: "ECOURSE_LOGIN_ACCESS",
    values
  };
}

export function ecourseResetPassword(
  email: string,
  ecourse_code: string,
  course_title: string
) {
  return dispatch => {
    return new Promise((res, rej) => {
      _axios({
        url: "/api/ecourse/reset-password",
        method: "post",
        data: {
          email,
          ecourse_code,
          course_title
        }
      }).then(response => {
        res(dispatch(push(`/requested/${ecourse_code}`)));
      });
    });
  };
}

export function getReset(token) {
  return dispatch => {
    return new Promise((res, rej) => {
      _axios({
        url: "/api/ecourse/check-reset",
        method: "post",
        data: {
          token
        }
      });
    });
  };
}

export function changePassword(token, password, ecourse_code) {
  return dispatch => {
    return new Promise((res, rej) => {
      _axios({
        url: "/api/ecourse/change-password",
        method: "post",
        data: {
          token,
          password
        }
      }).then(response => {
        res(dispatch(push(`/pass-success/${ecourse_code}`)));
      });
    });
  };
}
