// @flow

import React, { Fragment } from 'react'
import RelativeTime from './RelativeTime'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import { t } from '../../helpers/global'

type Props = {
  total: string,
  className: string,
  translations: Map<string, any>
};

class PrepTime extends React.Component<Props> {

  static defaultProps = {
    total: "7 min",
    className: '',
  }

  render() {
    const {
      total,
      className,
      translations
    } = this.props

    return (
      <Fragment>
        <p className={className}>
          {t(translations,"LABEL_PREP_TIME")} <RelativeTime total={total} />
        </p>
      </Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations,
  }
}

export default connect(mapStateToProps, null)(PrepTime)
