import _axios from '../dependencies/_axios'

export function cvsStatusApi(companyId, jobId, candidateId, sessionCode) {
  return _axios({
    url: '/api/v2/cv/status',
    method: 'post',
    data: {
      company_id: companyId,
      job_id: jobId,
      candidate_id: candidateId,
      interview_code: sessionCode
    }
  })
}

export function cvsStartApi(companyId, jobId, candidateId, sessionCode) {
  return _axios({
    url: '/api/v2/cv/start',
    method: 'post',
    data: {
      company_id: companyId,
      job_id: jobId,
      candidate_id: candidateId,
      interview_code: sessionCode
    }
  })
}
