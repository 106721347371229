// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Map } from "immutable";
import bindAll from "lodash/bindAll";
import pluralize from "pluralize";
import Header from "../../lib/elements/Header";
import CardSingleContent from "../../lib/elements/CardSingleContent";
import CardWithLabel from "../../lib/elements/CardWithLabel";
import InstructionDesk from "../../lib/elements/InstructionDesk";
import Button from "../../lib/elements/Button";
import Time from "../../lib/elements/Time";
import Icon from "../../lib/elements/Icon";
import ModalDialog from "../../lib/elements/ModalDialog";
import imgInstruction1 from "../../images/instruction-ftq-1.png";
import imgInstruction2 from "../../images/instruction-ftq-2.png";
import imgInstruction3 from "../../images/instruction-ftq-3.png";

import { globalGenerateUnitOfTimeHelper } from "../../helpers/global";

import { t } from "../../helpers/global";

import { ftqStartRequest, ftqGDPRRequest } from "../../actions/ftq";

type Props = {
  job: Map<string, any>,
  company: Map<string, any>,
  candidate: Map<string, any>,
  ftqStartWelcomeVideoRequest: Function,
  ftqGDPRRequest: Function,
  translations: Map<string, any>,
  sessionCode: string,
  sessionsFilterData: Map<string, any>
};

type State = {
  modal: boolean
};

class FtqIndexWelcome extends React.Component<Props, State> {
  constructor(props: Object): void {
    super(props);

    this.state = {
      modal: false,
      isModalOpen: false
    };

    bindAll(this, ["handleContinueClick", "handleModalToggle", "getCookie"]);
  }
  componentDidMount() {
    const { sessionsFilterData } = this.props;
    window.scrollTo(0, 0);
    var isWatched = this.getCookie("isWatched");
    if (isWatched || sessionsFilterData.get("is_access_profile") == 1) {
      this.setState({ isModalOpen: false });
    }
  }
  handleContinueClick(e: Object): void {
    e.preventDefault();

    const {
      sessionCode,
      ftqStartRequest,
      ftqGDPRRequest,
      sessionsFilterData
    } = this.props;

    ftqStartRequest(sessionCode);
  }

  handleModalToggle(e: Object): void {
    e.preventDefault();

    this.setState({ modal: !this.state.modal });
  }

  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  render() {
    const {
      job,
      company,
      candidate,
      sessionsFilterData,
      translations,
      sessionsUiData
    } = this.props;

    const { modal } = this.state;
    const { isModalOpen } = this.state;

    const duration: Map<string, any> = globalGenerateUnitOfTimeHelper(
      sessionsFilterData.get("duration_left")
    );
    const questionIdsSize: number = sessionsFilterData.get("question_ids").size;
    return (
      <Fragment>
        <Header
          company={company.get("title")}
          job={job.get("title")}
          position={job.get("jobType")}
          location={job.get("locations")}
          type="primary"
          redirectCode={sessionsFilterData.get("ecourse_code")}
          learner={candidate}
        />
        <div className="container">
          <section>
            <h2 className="heading4 normal m-t-24">
              {t(translations, "LABEL_WELCOME")},{" "}
              <b className="bold">
                {" "}
                {sessionsFilterData.get("is_access_profile") == 1
                  ? "Friends"
                  : candidate.get("fullname")}
              </b>
              ! {t(translations, "LABEL_WHAT_TO_EXPECT")}:
            </h2>
            <CardSingleContent
              className="m-t-32"
              number={questionIdsSize}
              text={t(translations, "LABEL_FREETEXT")}
            >
              <Icon
                type="icon"
                name="icon-ftq"
                size="extralarge"
                align="bottom"
                className="m-r-8"
              />
            </CardSingleContent>
            <CardWithLabel
              label={t(translations, "INSTRUCTION_TIME_TO_COMPLETE")}
            >
              <Time data={duration} className="m-b-0 bold">
                <Icon
                  type="icon"
                  name="icon-timer"
                  size="extralarge"
                  align="middle"
                  className="m-r-8"
                />
              </Time>
            </CardWithLabel>
          </section>
          <section>
            <h2 className="heading4 uppercase normal m-t-32">
              <b>{t(translations, "HOW_IT_WORKS_HOW_LABEL")}</b>{" "}
              {t(translations, "HOW_IT_WORKS_WORKS_LABEL")}
            </h2>
            <div className="row no-gutters m-t-20">
              <InstructionDesk
                image={imgInstruction1}
                text={t(translations, "HOW_IT_WORKS_FTQ_TIMER")}
                className="col-sm-4 bg-white vertical-item--center"
              />
              <InstructionDesk
                image={imgInstruction2}
                text={t(translations, "HOW_IT_WORKS_FTQ_TOOLS")}
                className="col-sm-4 bg-white vertical-item--center"
              />
              <InstructionDesk
                image={imgInstruction3}
                text={t(translations, "HOW_IT_WORKS_FTQ_INSTRUCTION")}
                className="col-sm-4 bg-white vertical-item--center"
              />
            </div>
          </section>
          <section>
            <center>
              <Button
                className="m-t-40 m-b-40"
                onClick={this.handleModalToggle}
              >
                {t(translations, "INSTRUCTION_START_SESSION")}
              </Button>
            </center>
          </section>
        </div>
        <ModalDialog
          isOpen={modal}
          toggle={this.handleModalToggle}
          title={t(translations, "INSTRUCTION_START_SESSION") + "?"}
          text={t(translations, "POPUP_CONTENT_START_SESSION_MCQ")}
        >
          <Button
            type="button"
            theme="secondary"
            size="small"
            className="m-r-20"
            onClick={this.handleModalToggle}
          >
            {t(translations, "LABEL_CANCEL")}
          </Button>
          <Button
            type="button"
            theme="primary"
            size="small"
            onClick={this.handleContinueClick}
            buttonLoading={sessionsUiData.get("loading")}
            disabled={sessionsUiData.get("disabled_button")}
          >
            {t(translations, "LABEL_LETS_GO")}
          </Button>
        </ModalDialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations,
    job: state.job,
    company: state.company,
    candidate: state.candidate,
    sessionCode: ownProps.match.params.sessionCode,
    sessionsFilterData: state.sessionsFilterData,
    sessionsUiData: state.sessionsUiData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ftqStartRequest: sessionCode => dispatch(ftqStartRequest(sessionCode)),
    ftqGDPRRequest: sessionCode => dispatch(ftqGDPRRequest(sessionCode))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FtqIndexWelcome)
);
