// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import bindAll from "lodash/bindAll";
import { Map } from "immutable";
import OptionNote from "../../lib/elements/OptionNote";
import ListAnswers from "../../lib/elements/ListAnswers";
import Button from "../../lib/elements/Button";
import Swal from "sweetalert2";
import ModalDialog from "../../lib/elements/ModalDialog";
import { sectionsAnswerTextQuestionRequest } from "../../actions/sections";
import {
  ftqAnswerQuestionRequest,
  ftqSubmitQuestionRequest
} from "../../actions/ftq";
import {
  questionsAddAnswersTextRequest,
  questionsAddMaxLengthRequest
} from "../../actions/questions";

import { t } from "../../helpers/global";

type Props = {
  translations: Map<string, any>,
  candidate: Map<string, any>,
  interviewsAddUiTestDurationClass: Function,
  sectionsAnswerTextQuestionRequest: Function,
  ftqAnswerQuestionRequest: Function,
  sessionsFilterData: Map<string, any>,
  sessionCode: string,
  question: Map<string, any>,
  questionsFormData: Map<string, any>,
  questionsAddAnswersTextRequest: Function,
  questionsTestAnswer: Function,
  questionsAddMaxLengthRequest: Function,
  questionsTestAnswerFinish: Function,
  section: Map<string, any>
};

class FtqBody extends React.Component<Props> {
  constructor(props: Object): void {
    super(props);
    this.state = {
      freetext_modal: false,
      limWords: null,
      secondValue: "",
      wordCount: "",
      max_value: 0
    };
    bindAll(this, [
      "alphabetAtIndex",
      "renderNonSectionSubmitButton",
      "handleSubmitClick",
      "resizeTextArea",
      "handleGetValue",
      "handleSubmitFreetext",
      "handleModalToggleFreetext",
      "handleFocus",
      "handleBlur",
      "handleOnKeyDown"
    ]);
  }

  handleFocus(e) {
    document.onkeypress = null;
  }

  handleBlur(e) {
    if (window.isKeyPress) {
      document.onkeypress = window.isKeyPress;
    }
  }

  handleOnKeyDown(e) {
    const {
      questionsAddAnswersTextRequest,
      question,
      sectionsAnswerTextQuestionRequest,
      sessionCode,
      questionsAddMaxLengthRequest,
      questionsFormData,
      sessionsFilterData,
      section,
      questionId,
      ftqAnswerQuestionRequestDirect
    } = this.props;
    // if (window.startRequestFtq) {
    //   clearTimeout(window.startRequestFtq);
    // }

    let value = e.target.value;
    value = value.substring(0, value.length - 1);
    const realCount =
      e.target.value === "" || e.target.value.match(/\S+/g) == null
        ? 0
        : e.target.value.match(/\S+/g).length;

    const key = e.keyCode || e.charCode;
    // console.log(key)
    // console.log(value)
    // console.log(wordCount)
    if ((key == 8 || key == 46) && realCount > question.get("max_length")) {
      const wordCount =
        value === "" || value.match(/\S+/g) == null
          ? 0
          : value.match(/\S+/g).length;

      this.resizeTextArea(this.getTextArea);
      questionsAddAnswersTextRequest(value);
      // if (section.size != 0) {
      //   window.startRequestFtq = setTimeout(() => {
      //     sectionsAnswerTextQuestionRequest(value, questionId, sessionCode);
      //   }, 1000);
      // } else {
      //   window.startRequestFtq = setTimeout(() => {
      //     ftqAnswerQuestionRequestDirect(sessionCode);
      //   }, 1000);
      // }

      var max_length = question.get("max_length") - wordCount;
      questionsAddMaxLengthRequest(max_length);
      this.setState({
        max_value: wordCount
      });
    }
  }

  handleModalToggleFreetext(e) {
    e.preventDefault();

    this.setState({ freetext_modal: !this.state.freetext_modal });
  }

  alphabetAtIndex(index: number): string {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    return alphabet.charAt(index);
  }

  handleSubmitClick(e: Object): void {
    e.preventDefault();

    const {
      sessionCode,
      ftqAnswerQuestionRequest,
      questionsAddAnswersTextRequest,
      questionsFormData,
      question
    } = this.props;

    const text_answer: String = questionsFormData.get("text_answer");
    const countText: Number =
      text_answer == "" ? 0 : text_answer.match(/\S+/g).length;
    if (countText < question.get("min_length")) {
      this.setState({ freetext_modal: true });
    } else {
      ftqAnswerQuestionRequest(sessionCode);
      setTimeout(function() {
        questionsAddAnswersTextRequest("");
      }, 1000);
    }
  }

  resizeTextArea(text) {
    // console.log(text)
    var x = window.scrollX,
      y = window.scrollY;
    text.style.height = "auto";
    text.style.height = text.scrollHeight + "px";
    text.scrollTop = text.scrollHeight;
    text.focus();
    window.scrollTo(x, y);
  }

  /* 0-timeout to get the already changed text */
  delayedResize(text) {
    window.setTimeout(this.resize(text), 0);
  }

  componentDidMount() {
    this.resizeTextArea(this.getTextArea);
  }

  componentDidUpdate(nextProps) {
    const text_answer: String = this.props.questionId;
    const next_text_answer: String = nextProps.questionId;
    if (text_answer != next_text_answer) {
      this.resizeTextArea(this.getTextArea);
    }
  }

  handleGetValue(e) {
    const {
      questionsAddAnswersTextRequest,
      question,
      sectionsAnswerTextQuestionRequest,
      sessionCode,
      questionsAddMaxLengthRequest,
      questionsFormData,
      sessionsFilterData
    } = this.props;
    e.preventDefault();
    let value = e.target.value;
    const wordCount =
      e.target.value === "" || e.target.value.match(/\S+/g) == null
        ? 0
        : e.target.value.match(/\S+/g).length;
    if (wordCount > question.get("max_length")) {
      // // Split the string on first 200 words and rejoin on spaces
      // var lastIndex = e.target.value.lastIndexOf(" ");
      // var count_cpace = e.target.value.
      // var new_text  = e.target.value.substring(0, lastIndex);
      // // Add a space at the end to keep new typing making new words
      // questionsAddAnswersTextRequest(new_text);
    } else {
      this.resizeTextArea(this.getTextArea);
      questionsAddAnswersTextRequest(e.target.value);
      // var max_length = question.get("max_length") - wordCount;
      // questionsAddMaxLengthRequest(max_length);
      // this.setState({
      //   max_value: wordCount
      // });
    }
  }

  handleSubmitFreetext(e) {
    const {
      ftqAnswerQuestionRequest,
      sessionCode,
      questionsAddAnswersTextRequest,
      question
    } = this.props;
    ftqAnswerQuestionRequest(sessionCode);
    setTimeout(function() {
      questionsAddAnswersTextRequest("");
    }, 1000);
    this.setState({ freetext_modal: false });
  }
  renderNonSectionSubmitButton() {
    const {
      questionsFormData,
      sessionsFilterData,
      translations,
      question
    } = this.props;

    let nonSectionSubmitButton: Object;

    if (sessionsFilterData.get("type") === "close test") {
      nonSectionSubmitButton = (
        <center>
          <Button
            theme="primary"
            className="m-b-40"
            onClick={this.handleSubmitClick}
          >
            {t(translations, "LABEL_SUBMIT")}
          </Button>
        </center>
      );
    }

    return nonSectionSubmitButton;
  }

  render(): Object {
    const { question, questionsFormData, translations } = this.props;
    var countText: Number;
    const text_answer: String = questionsFormData.get("text_answer");
    if (text_answer.trim().length == 0) {
      countText = 0;
    } else {
      countText = text_answer == "" ? 0 : text_answer.match(/\S+/g).length;
    }

    return (
      <Fragment>
        <div className="div-text-maxwords" />
        <div className="div-form-textarea">
          <textarea
            ref={elem => (this.getTextArea = elem)}
            className="ftq-textarea"
            id="ftqForm"
            placeholder={t(translations, "LABEL_ENTER_YOUR_ANSWER")}
            onChange={this.handleGetValue}
            onKeyDown={this.handleOnKeyDown}
            value={questionsFormData.get("text_answer")}
          />
        </div>
        <div className="div-text-countwords" id="valueWords">
          {countText} / {question.get("max_length")}{" "}
          {t(translations, "LABEL_WORDS")} {t(translations, "LABEL_AT_LEAST")}{" "}
          {question.get("min_length")}
          {t(translations, "LABEL_AT_LEAST_END")}
        </div>
        {this.renderNonSectionSubmitButton()}
        <ModalDialog
          isOpen={this.state.freetext_modal}
          toggle={this.handleModalToggleFreetext}
          title={t(translations, "LABEL_NOT_LONG_ENOUGH")}
          text={t(translations, "LABEL_NOT_MET_MINIMUM")}
        >
          <Button
            type="button"
            theme="secondary"
            size="small"
            className="m-r-20"
            onClick={this.handleModalToggleFreetext}
          >
            {t(translations, "LABEL_GO_BACK")}
          </Button>
          <Button
            type="button"
            theme="primary"
            size="small"
            onClick={this.handleSubmitFreetext}
          >
            {t(translations, "LABEL_SUBMIT")}
          </Button>
        </ModalDialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations,
    candidate: state.candidate,
    sessionsFilterData: state.sessionsFilterData,
    sessionCode: ownProps.match.params.sessionCode,
    question: state.question,
    questionsFormData: state.questionsFormData,
    questionId: ownProps.match.params.questionId,
    section: state.section
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sectionsAnswerTextQuestionRequest: (text, sessionCode) =>
      dispatch(sectionsAnswerTextQuestionRequest(text, sessionCode)),
    questionsAddMaxLengthRequest: max_length =>
      dispatch(questionsAddMaxLengthRequest(max_length)),
    ftqAnswerQuestionRequest: sessionCode =>
      dispatch(ftqAnswerQuestionRequest(sessionCode)),

    ftqSubmitQuestionRequest: sessionCode =>
      dispatch(ftqSubmitQuestionRequest(sessionCode)),
    questionsAddAnswersTextRequest: text =>
      dispatch(questionsAddAnswersTextRequest(text))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FtqBody)
);
