import hark from "hark";
import { Map } from "immutable";
import Promise from "bluebird";
import * as Logger from "astrnt-web-logger";

window.recordedSoundChecks = [];

export function soundInitRecords(ref, callbackStopRecord) {
  // console.log("masuk");
  try {
    window.recordedSoundChecks = [];

    // setAudioPlayerRecordedListener();

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(stream => setMediaRecorderAndPlayer(stream, callbackStopRecord))
      .catch(error => {
        let params = {
          event: "Video Recording", // string
          message: "error" + error,
          status: "offline" // string
        };

        Logger.recordEvent(params);

        throw error;
      });
    // .then(stream => setSpeechEvents(stream, ref));
  } catch (error) {
    console.log(error);
  }
}

function setAudioPlayerRecordedListener() {
  // $FlowFixMe
  document.getElementById("js-sound-check-control").addEventListener(
    "error",
    ev => {
      console.error("MediaRecording.recordedMedia.error()");

      // $FlowFixMe
      console.error(
        "Your browser cannot play\n\n" +
          document.getElementById("js-sound-check-recorder").src +
          "\n\n media clip. event: " +
          JSON.stringify(ev)
      );

      throw ev;
    },
    true
  );
}

function setSpeechEvents(
  stream,
  ref,
  callbackIsLowSound = null,
  callbackIsNoSound = null
) {
  let countLowDb: number = 0;

  if (callbackIsLowSound !== null) {
    window.checkLowSoundSpeechEvents = hark(stream, {
      threshold: -50,
      interval: 500
    });

    window.checkLowSoundSpeechEvents.on("volume_change", db => {
      if (db < -50 && db > -100) {
        countLowDb++;
      }

      if (countLowDb >= 10) {
        countLowDb = 0;

        callbackIsLowSound(true);
      }
    });
  }

  if (callbackIsNoSound !== null) {
    window.checkNoSoundSpeechEvents = hark(stream, {
      threshold: -50,
      interval: 500
    });

    window.checkNoSoundSpeechEvents.on("volume_change", db => {
      window.arrNoDb.push(db);

      if (window.arrNoDb.length === 20) {
        const accArrNoDb: number =
          window.arrNoDb.reduce((x, y) => x + y, 0) / 20;

        if (accArrNoDb <= -75) {
          callbackIsNoSound();
        }

        window.arrNoDb = [];
      }
    });
  }

  window.speechEvents = hark(stream, {
    threshold: -50
  });

  window.speechEvents.on("speaking", () => {
    // setRefStyleHeight(ref, 100);

    if (callbackIsLowSound !== null) {
      countLowDb = 0;

      callbackIsLowSound(false);
    }
  });

  //   window.speechEvents.on("stopped_speaking", () => setRefStyleHeight(ref, 0));
}

function decideMediaRecorderOptions() {
  let options = {};

  // $FlowFixMe
  if (MediaRecorder.isTypeSupported("audio/webm")) {
    options = {
      type: "audio",
      timeSlice: 1000,
      mimeType: "audio/webm"
    };
  } else if (MediaRecorder.isTypeSupported("audio/webm;codecs=opus")) {
    options = {
      type: "audio",
      timeSlice: 1000,
      mimeType: "audio/webm;codecs=opus"
    };
  }

  return options;
}

function setMediaRecorderAndPlayer(stream, callbackStopRecord): ?Promise {
  try {
    return new Promise(resolve => {
      // $FlowFixMe
      const interviewSoundPlayerElem: Object = document.getElementById(
        "js-sound-check"
      );

      if (interviewSoundPlayerElem) {
        interviewSoundPlayerElem.srcObject = stream;
      }

      const options: Object = decideMediaRecorderOptions();
      // $FlowFixMe
      const soundRecorder: Object = new MediaRecorder(stream, options);

      soundRecorder.onstop = callbackStopRecord;
      soundRecorder.ondataavailable = handleDataAvailableRecord;

      window.soundRecorder = soundRecorder;

      resolve(stream);
    });
  } catch (error) {
    let params = {
      event: "Audio Recording", // string
      message: "Audio Recording error " + error,
      status: "offline" // string
    };

    Logger.recordEvent(params);

    console.error("Exception while creating MediaRecorder: " + error);
  }
}

function handleDataAvailableRecord(event) {
  if (event.data && event.data.size > 0) {
    // console.log("record");
    window.recordedSoundChecks.push(event.data);
  }
}
