// @flow

import React from 'react'
import { Modal, ModalBody } from 'reactstrap'

type Props = {
  title: string,
  text: string,
  isOpen: boolean,
  toggle: Function,
  style: ?Object,
  children?: any,
};

class ModalDialog extends React.Component<Props> {
  static defaultProps = {
    title: '',
    text: '',
    style: null,
    children: null,
    isOpen: false,
    toggle: () => {}
  }

  render() {
    const {
      title,
      text,
      children,
      style,
      isOpen,
      toggle
    } = this.props

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        style={style}>
        <ModalBody>
          <h2 className="heading2 m-b-12">{title}</h2>
          <div dangerouslySetInnerHTML={{__html: text}} />
          <div className="d-flex justify-content-center m-t-24">
            {children}
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

export default ModalDialog
