// @flow

import React from "react";
import Card from "./Card";
import bindAll from "lodash/bindAll";
import axios from "axios";
import { formatBytes } from "../../helpers/global";

type Props = {
  label: string,
  children?: any,
  icon?: any,
  supportMaterial: Array,
  sectionId: Number,
  companyId: Number,
  zipUrl: string
};

class CardMaterialDownload extends React.Component<Props> {
  static defaultProps = {
    label: "",
    children: null,
    icon: null
  };

  renderMaterialFilePreview(item, key) {
    return (
      <div className="ada-file flex-container flex-between align-items-center">
        <span>{item.get("title")}</span>
        <span className="titik-titik"></span>
        <span>
          {formatBytes(item.get("size"))}
          <a href={item.get("url")} download target="_blank">
            <i className="icon icon-i-download-material"></i>
          </a>
        </span>
      </div>
    );
  }

  render() {
    const { children, label, icon, supportMaterial, sectionId } = this.props;

    return (
      <Card theme="material" className="card">
        <div>
          <h5 className="heading4 bold uppercase m-0">Materi Pendukung</h5>
        </div>
        <div className="div-support-materials">
          <div className="empty-file hide">Your files will appear here</div>
          {supportMaterial.map((item, key) =>
            this.renderMaterialFilePreview(item, key)
          )}
          {supportMaterial.size > 1 && (
            <div className="download-file">
              <a
                className="btn btn-transparent"
                href={this.props.zipUrl}
                download
                target="_blank"
              >
                <i className="fa fa-download"></i>Download all files
              </a>
            </div>
          )}
        </div>
      </Card>
    );
  }
}

export default CardMaterialDownload;
