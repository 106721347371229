import { Map, fromJS } from 'immutable'

export function company(state = Map({}), action) {
	switch (action.type) {
		case 'COMPANIES_GET_DETAIL_SUCCESS':
			return fromJS(action.company)
		default:
			return state
	}
}
