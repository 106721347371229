// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { List, Map } from "immutable";
import bindAll from "lodash/bindAll";
import store_shopee from "../../dependencies/forShopeeStorage";
import Indicator from "../../lib/elements/Indicator";
import Duration from "../../lib/elements/Duration";
import Icon from "../../lib/elements/Icon";
import QuestionContent from "../../lib/elements/QuestionContent";
import ModalDialog from "../../lib/elements/ModalDialog";
import Button from "../../lib/elements/Button";
import AudioElement from "../../lib/elements/AudioElement";
import VideoElement from "../../lib/elements/VideoElement";
import { withRouter } from "react-router";

import { questionMediaAttempt } from "../../actions/questions";

import {
  globalBuildDurationDisplayHelper,
  sToTime
} from "../../helpers/global";

import { t } from "../../helpers/global";

type Props = {
  question: Map<string, any>,
  sessionsFilterData: Map<string, any>,
  translations: Map<string, any>,
  section: Map<string, any>,
  sections: List<Map<string, any>>
};

class McqHeader extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.state = {
      have_opened: 0,
      is_play: false,
      percent_audio: 0,
      max_duration: "",
      current_duration: "",
      muted: false
    };

    bindAll(this, [
      "renderIndicatorComponent",
      "handleModalToggleMcq",
      "handleDurationChange",
      "handlePlayClick",
      "handleVolumeClick",
      "handleResetPlayButton",
      "handlePlayVideoClick",
      "handleVolumeVideoClick"
    ]);
  }

  handleResetPlayButton() {
    this.setState({ is_play: false });
  }

  handleModalToggleMcq() {
    const { sessionCode } = this.props;
    this.setState({ have_opened: 1 });

    store_shopee.setModalOpenedOnce("was_open", sessionCode);
  }
  componentDidMount() {
    const { sessionCode, question } = this.props;
    if (store_shopee.getModalOpenedOnce(sessionCode) === "was_open") {
      this.setState({ have_opened: 1 });
    }
    if (
      question.get("audio_url") != "null" ||
      question.get("audio_url") != ""
    ) {
      document.getElementById("js-sound-player-section").src = question.get(
        "audio_url"
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { question } = this.props;
    if (
      typeof question.get("audio_url") != "null" ||
      question.get("audio_url") != ""
    ) {
      if (prevProps.question.get("audio_url") != question.get("audio_url")) {
        document.getElementById("js-sound-player-section").src = question.get(
          "audio_url"
        );

        this.setState({
          is_play: false,
          percent_audio: 0,
          max_duration: "",
          current_duration: "",
          muted: false
        });
      }
    } else {
      document.getElementById("js-sound-player-section").src = "";
    }

    if (
      typeof question.get("video_url") != "null" ||
      question.get("video_url") != ""
    ) {
      if (prevProps.question.get("video_url") != question.get("video_url")) {
        document.getElementById("js-video-player-section").src = question.get(
          "video_url"
        );

        this.setState({
          is_play: false,
          percent_audio: 0,
          max_duration: "",
          current_duration: "",
          muted: false
        });
      }
    } else {
      document.getElementById("js-video-player-section").src = "";
    }
  }

  handleDurationChange(e) {
    // console.log(e.target)
    const current_time = e.target.currentTime;
    const duration = e.target.duration;
    const percent = (current_time * 100) / duration;
    let currentTime = sToTime(current_time);

    let __duration = sToTime(duration);

    this.setState({ max_duration: __duration }, () => {
      this.setState({ current_duration: currentTime }, () => {
        this.setState({ percent_audio: percent }, () => {
          if (this.state.current_duration == this.state.max_duration) {
            this.handleResetPlayButton();
          }
        });
      });
    });
  }

  handlePlayVideoClick() {
    const {
      sessionsFilterData,
      questionMediaAttempt,
      candidate,
      question,
      sessionCode
    } = this.props;
    let audio_url = document.getElementById("js-video-player-section");
    if (question.get("media_attempt_left") == 0) {
      return;
    }
    if (this.state.is_play == false) {
      this.setState({ is_play: true }, () => {
        if (this.state.is_play == true) {
          questionMediaAttempt(
            candidate.get("id"),
            sessionCode,
            question,
            sessionsFilterData.get("token")
          ).then(() => {
            audio_url.play();
          });
        }
        //  else if (this.state.is_play == false) {
        //   audio_url.pause();
        // }
      });
    }
  }

  handleVolumeVideoClick() {
    this.setState({ muted: !this.state.muted });
    // console.log(this.state.muted)
    let audio_url = document.getElementById("js-video-player-section");
    audio_url.muted = !this.state.muted;
  }

  handlePlayClick() {
    const {
      sessionsFilterData,
      questionMediaAttempt,
      candidate,
      question,
      sessionCode
    } = this.props;
    let audio_url = document.getElementById("js-sound-player-section");
    if (question.get("media_attempt_left") == 0) {
      return;
    }
    if (this.state.is_play == false) {
      this.setState({ is_play: true }, () => {
        if (this.state.is_play == true) {
          questionMediaAttempt(
            candidate.get("id"),
            sessionCode,
            question,
            sessionsFilterData.get("token")
          ).then(() => {
            audio_url.play();
          });
        }
        //  else if (this.state.is_play == false) {
        //   audio_url.pause();
        // }
      });
    }
  }

  handleVolumeClick() {
    this.setState({ muted: !this.state.muted });
    // console.log(this.state.muted)
    let audio_url = document.getElementById("js-sound-player-section");
    audio_url.muted = !this.state.muted;
  }

  renderIndicatorComponent() {
    const {
      question,
      sessionsFilterData,
      section,
      sections,
      translations,
      time_indicator_stop,
      sessionCode
    } = this.props;

    let indicatorTheme: string = "success";
    let id: number;
    let ids: List<number>;
    let questionDuration: number;
    let questionDurationDisplay: string;
    let questionDurationDisplay_temp: string;

    let indicatorTypeString: string;
    let time_left_ten_min: false;

    if (sessionsFilterData.get("type") === "close test") {
      indicatorTypeString = t(translations, "LABEL_QUESTION");
      id = question.get("id");
      ids = sessionsFilterData.get("question_ids");
      questionDuration = question.get("question_duration_left");
    } else {
      indicatorTypeString = t(translations, "SECTION_LABEL");
      id = section.get("id");
      ids = sections.map(_section => _section.get("id"));
      questionDuration = section.get("duration");
    }
    // console.log(time_indicator_stop);
    // console.log(questionDuration);
    if (time_indicator_stop == true) {
      questionDuration = questionDuration - 600;
      if (this.state.have_opened == 1) {
        questionDuration = questionDuration + 600;
      }
    }
    // console.log(questionDuration);
    questionDurationDisplay = globalBuildDurationDisplayHelper(
      questionDuration
    );

    questionDurationDisplay_temp = globalBuildDurationDisplayHelper(
      questionDuration + 600
    );

    if (questionDuration <= 5) {
      indicatorTheme = "danger";
    }

    if (time_indicator_stop == true) {
      // 10 menit shopee ada diparent nya mcqshow

      if (questionDuration <= 0) {
        if (this.state.have_opened == 0) {
          store_shopee.set("open");
          if (store_shopee.getModalOpenedOnce(sessionCode) === "was_open") {
          } else {
            time_left_ten_min = true;
          }
        } else {
          time_left_ten_min = false;
        }
      }
    }

    return (
      <Fragment>
        <ModalDialog
          isOpen={time_left_ten_min}
          title={`Time’s Up!`}
          text={`We are giving you an extra 10 minutes to answer our questions. Don't miss this opportunity to let us know more about you!`}
        >
          <Button
            type="button"
            theme="secondary"
            size="small"
            className="m-r-20"
            onClick={this.handleModalToggleMcq}
          >
            OK
          </Button>
        </ModalDialog>

        <Indicator layout="between" theme={indicatorTheme}>
          <div>
            <b>{`${indicatorTypeString} ${ids.indexOf(id) + 1}`} </b>
            {t(translations, "LABEL_OF")} {ids.size}
            {time_left_ten_min}
          </div>
          {/* <Duration
            data={
              time_left_ten_min == true
                ? questionDurationDisplay_temp
                : questionDurationDisplay
            }
            className="bold"
          >
            <Icon
              type="icon"
              name="icon-clock"
              align="bottom"
              className="m-r-8"
            />
          </Duration> */}
        </Indicator>
      </Fragment>
    );
  }

  render() {
    const {
      question,
      not_all_pages,
      first_question,
      id,
      time_indicator_stop
    } = this.props;
    //not_all_pages then its shopee quickspeed
    let headers;
    // console.log(question.get("video_url"))
    let childComponent;
    childComponent = (
      <Fragment>
        <AudioElement
          isQuestion={true}
          hidden={
            question.get("audio_url") === null ||
            question.get("audio_url") == "null" ||
            question.get("audio_url") == ""
              ? "hide"
              : ""
          }
          style={{ padding: "30px 0" }}
          muted={this.state.muted}
          chances={question.get("media_attempt_left")}
          current_duration={this.state.current_duration}
          max_duration={this.state.max_duration}
          progress={this.state.percent_audio}
          is_play={this.state.is_play}
          handlePlayClick={this.handlePlayClick}
          handleVolumeClick={this.handleVolumeClick}
        />

        <VideoElement
          isQuestion={true}
          hidden={
            question.get("video_url") === null ||
            question.get("video_url") == "null" ||
            question.get("video_url") == ""
              ? "hide"
              : ""
          }
          style={{ padding: "0" }}
          chances={question.get("media_attempt_left")}
          onTimeUpdate={this.handleDurationChange}
          muted={this.state.muted}
          current_duration={this.state.current_duration}
          max_duration={this.state.max_duration}
          progress={this.state.percent_audio}
          is_play={this.state.is_play}
          handlePlayClick={this.handlePlayVideoClick}
          handleVolumeClick={this.handleVolumeVideoClick}
          url={question.get("video_url")}
          id="js-video-player-section"
        />
      </Fragment>
    );

    if (not_all_pages == false) {
      headers = (
        <Fragment>
          <audio
            onTimeUpdate={this.handleDurationChange}
            ref={el => (this.audioPlayer = el)}
            id="js-sound-player-section"
            controls="controls"
            type="audio/webm"
            className="hide"
          />
          {this.renderIndicatorComponent()}
          <QuestionContent
            childrenAudio={childComponent}
            id={id}
            not_all_pages={not_all_pages}
            text={first_question.get("title")}
            image={first_question.get("image_url")}
          />
        </Fragment>
      );
    } else {
      headers = (
        <Fragment>
          <audio
            onTimeUpdate={this.handleDurationChange}
            ref={el => (this.audioPlayer = el)}
            id="js-sound-player-section"
            controls="controls"
            type="audio/webm"
            className="hide"
          />
          {this.renderIndicatorComponent()}
          <QuestionContent
            childrenAudio={childComponent}
            not_all_pages={not_all_pages}
            text={question.get("title")}
            image={question.get("image_url")}
          />
        </Fragment>
      );
    }
    return <Fragment> {headers} </Fragment>;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations,
    section: state.section,
    sections: state.sections,
    question: state.question,
    sessionsFilterData: state.sessionsFilterData,
    sessionCode: ownProps.match.params.sessionCode,
    candidate: state.candidate
  };
};

const mapDispatchToProps = dispatch => {
  return {
    questionMediaAttempt: (candidateId, sessionCode, questionId, token) =>
      dispatch(
        questionMediaAttempt(candidateId, sessionCode, questionId, token)
      )
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(McqHeader)
);
