import { List, Map, fromJS } from "immutable";

const initFormData = fromJS({
  answered_ids: [],
  text_answer: "",
  max_length: 0,
  is_next: true
});

const optMcq = fromJS({
  is_next: true
});

export function question(state = Map({}), action) {
  switch (action.type) {
    case "QUESTIONS_GET_DETAIL_SUCCESS":
      return fromJS(action.question);
    case "QUESTIONS_REDUCE_DURATION_LEFT_SUCCESS":
      return state.set("question_duration_left", action.duration_left);
    case "QUESTIONS_ADD_USED_ATTEMPT":
      return state.set("used_attempt", state.get("used_attempt") + 1);
    default:
      return state;
  }
}

export function questionsFormData(state = initFormData, action) {
  switch (action.type) {
    case "QUESTIONS_ADD_FORM_ANSWERED_IDS_SUCCESS":
      return state.set("answered_ids", List(action.answered_ids));
    case "QUESTIONS_ADD_FORM_ANSWERED_TEXT_SUCCESS":
      return state.set("text_answer", action.text_answer);
    case "QUESTIONS_ADD_FORM_MAX_LENGTH_SUCCESS":
      return state.set("max_length", action.max_length);
    case "QUESTIONS_CLEAR_FORM_DATA_SUCCESS":
      return fromJS({
        answered_ids: [],
        text_answer: ""
      });
    default:
      return state;
  }
}

export function questionsOptionMcq(state = optMcq, action) {
  switch (action.type) {
    case "QUESTIONS_IS_NEXT_SUCCESS":
      return state.set("is_next", action.is_next);
    case "QUESTIONS_CLEAR_FORM_DATA_SUCCESS":
      return fromJS({
        is_next: true
      });
    default:
      return state;
  }
}
