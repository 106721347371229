import { List, fromJS, Map } from "immutable";

export function ecourseDetail(state = Map({}), action) {
  switch (action.type) {
    case "ECOURSE_DETAIL_DATA_SUCCESS":
      return fromJS(action.ecourse_detail);
    default:
      return state;
  }
}

export function ecourseJobDetail(state = Map({}), action) {
  switch (action.type) {
    case "ECOURSE_FETCH_JOB_DATA_SUCCESS":
      return fromJS(action.ecourse_job);
    default:
      return state;
  }
}

export function ecourseTeachers(state = Map({}), action) {
  switch (action.type) {
    case "ECOURSE_TEACHERS_DATA_SUCCESS":
      return fromJS(action.ecourse_teachers);
    default:
      return state;
  }
}
