import _axios from '../dependencies/_axios'

export function getCandidateUnsubscribeStatus(sessionCode) {
  return _axios({
    url: '/api/web/interview/code/'+sessionCode+'/unsubscribe',
    method: 'get'
  })
}

export function unsubscribeCandidate(sessionCode, candidateId, inviteId, message) {
  return _axios({
    url: '/api/web/interview/code/'+sessionCode+'/unsubscribe',
    method: 'post',
    data: {
      candidate_id: candidateId,
      invite_id : inviteId,
      message : message
    }
  })
}

export function resubscribeCandidate(sessionCode, inviteId, candidateId) {
  return _axios({
    url: '/api/web/interview/code/' + sessionCode + '/resubscribe',
    method: 'post',
    data: {
      candidate_id: candidateId,
      invite_id: inviteId,
    }
  })
}

export function finishOnBoarding(sessionCode) {
  return _axios({
    url: '/api/web/interview/code/'+ sessionCode +'/onboarding',
    method: 'post'
  })
}
