import { store } from "../index";

export function frontEndRunTime(duration_left, countFunction, stopInterval) {
  //type duration_left is ms
  var counted_time = duration_left;
  // console.log(stopInterval)
  if (stopInterval === true) {
    // console.log("masuk true");

    if (typeof window.startIntervalRuntime === "number") {
      clearInterval(window.startIntervalRuntime);
    }
  } else {
    // console.log('asup')

    clearInterval(window.startIntervalRuntime);
    window.startIntervalRuntime = setInterval(() => {
      //property action
      counted_time = counted_time - 250;

      store.dispatch(countFunction(counted_time));
    }, 250);
  }
}
