import Swal from "sweetalert2";

export function checkWindow(arrayErrorMsg) {
  //Private variables
  var _LOCALSTORAGE_KEY = "WINDOW_VALIDATION";
  var _LOCALSTORAGE_ATTEMP = "ATTEMPT_VALIDATION";
  var RECHECK_WINDOW_DELAY_MS = 100;
  var _initialized = false;
  var _isMainWindow = false;
  var _unloaded = false;
  var _windowArray;
  var _windowId;
  var _isNewWindowPromotedToMain = false;
  var _onWindowUpdated;

  function WindowStateManager(isNewWindowPromotedToMain, onWindowUpdated) {
    //this.resetWindows();
    _onWindowUpdated = onWindowUpdated;
    _isNewWindowPromotedToMain = isNewWindowPromotedToMain;
    _windowId = Date.now().toString();

    bindUnload();

    determineWindowState.call(this);

    _initialized = true;

    _onWindowUpdated.call(this);
  }

  //Determine the state of the window
  //If its a main or child window
  function determineWindowState() {
    var self = this;
    var _previousState = _isMainWindow;

    _windowArray = localStorage.getItem(_LOCALSTORAGE_KEY);

    if (_windowArray === null || _windowArray === "NaN") {
      _windowArray = [];
    } else {
      _windowArray = JSON.parse(_windowArray);
    }

    if (_initialized) {
      //Determine if this window should be promoted
      if (
        _windowArray.length <= 1 ||
        (_isNewWindowPromotedToMain
          ? _windowArray[_windowArray.length - 1]
          : _windowArray[0]) === _windowId
      ) {
        _isMainWindow = true;
      } else {
        _isMainWindow = false;
      }
    } else {
      if (_windowArray.length === 0) {
        _isMainWindow = true;
        _windowArray[0] = _windowId;
        localStorage.setItem(_LOCALSTORAGE_KEY, JSON.stringify(_windowArray));
      } else {
        _isMainWindow = false;
        _windowArray.push(_windowId);
        localStorage.setItem(_LOCALSTORAGE_KEY, JSON.stringify(_windowArray));
      }
    }

    //If the window state has been updated invoke callback
    if (_previousState !== _isMainWindow) {
      _onWindowUpdated.call(this);
    }

    //Perform a recheck of the window on a delay
    setTimeout(function() {
      determineWindowState.call(self);
    }, RECHECK_WINDOW_DELAY_MS);
  }

  //Remove the window from the global count
  function removeWindow() {
    var __windowArray = JSON.parse(localStorage.getItem(_LOCALSTORAGE_KEY));
    for (var i = 0, length = __windowArray.length; i < length; i++) {
      if (__windowArray[i] === _windowId) {
        __windowArray.splice(i, 1);
        break;
      }
    }
    //Update the local storage with the new array
    localStorage.setItem(_LOCALSTORAGE_KEY, JSON.stringify(_windowArray));
  }

  //Bind unloading events
  function bindUnload() {
    localStorage.setItem(_LOCALSTORAGE_ATTEMP, 0);
    window.addEventListener("beforeunload", function() {
      if (!_unloaded) {
        removeWindow();
      }
    });
    window.addEventListener("unload", function() {
      if (!_unloaded) {
        removeWindow();
      }
    });
  }

  WindowStateManager.prototype.isMainWindow = function() {
    return _isMainWindow;
  };

  WindowStateManager.prototype.resetWindows = function() {
    localStorage.removeItem(_LOCALSTORAGE_KEY);
  };

  WindowStateManager = WindowStateManager;

  var WindowStateManager = new WindowStateManager(true, windowUpdated);

  window.timeout = null;

  function windowUpdated() {
    var value = localStorage.getItem(_LOCALSTORAGE_ATTEMP);
    // console.log(value);
    if (value >= 1) {
      var __checked = this.isMainWindow() ? "main" : "child";

      if (window.timeout) {
        clearTimeout(window.timeout);
      }

      if (__checked == "child") {
        window.timeout = setTimeout(function() {
          Swal({
            type: "error",
            title: "Multi Tab",
            text: "Tolong gunakan satu tab saja."
          }).then(function() {
            window.location.reload(true);
          });
        }, 2000);
      } else {
        Swal.close();
      }
    }
    value++;
    localStorage.setItem(_LOCALSTORAGE_ATTEMP, value);

    //   if(typeof document.getElementsByClassName('modal')[0] != 'undefined'){
    //     if(!this.isMainWindow()){
    //       document.getElementsByClassName('modal')[0].parentElement.parentElement.remove()
    //     }
    //   }
  }
}
