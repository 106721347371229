// @flow

import React from 'react'
import Icon from './Icon'
import classnames from 'classnames'

type Props = {
  label: string,
  className: string,
}

class Loader extends React.Component<Props> {

  static defaultProps = {
    label: 'loading',
    className: '',
  }

  render() {
    const {
      label,
      className
    } = this.props

    const classProps = classnames(
      'loading',
      'text-purple',
      className
    )

    return (
      <div className={classProps}>
        <Icon name="fa fa-spinner" animate="fa-spin" className="m-r-4" />
        {label}
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </div>
    )
  }
}

export default Loader
