// @flow

import React from 'react'

type Props = {
  imageUrl: string,
  imageAlt: string,
  title: string,
  text: string,
  linkText: string,
};

class MediaList extends React.Component<Props> {

  static defaultProps = {
    imageUrl: '',
    imageAlt: '',
    title: '',
    text: '',
    linkText: '',
  }

	render() {
    const {
      imageUrl,
      imageAlt,
      title,
      linkText,
      text
    } = this.props

		return(
      <li className="media align-items-center">
        { imageUrl ? <img src={imageUrl} alt={imageAlt} className="m-r-20" /> : null }
        <div className="media-body">
          <h3 className="heading4 bold">{title}</h3>
          <a href={linkText}>{text}</a>
        </div>
      </li>
		)
	}
}

export default MediaList
