import { Map, List, fromJS } from "immutable";

export function sections(state = List([]), action) {
  switch (action.type) {
    case "SECTIONS_FETCH_DATA_SUCCESS":
      return fromJS(action.sections);
    default:
      return state;
  }
}

export function section(state = Map({}), action) {
  switch (action.type) {
    case "SECTIONS_GET_DETAIL_SUCCESS":
      return fromJS(action.section);
    case "SECTIONS_REDUCE_DURATION_SUCCESS":
      return state.set("duration", action.duration);
    // case 'SECTIONS_ADD_UNANSWERED_QUESTION_IDS_SUCCESS':
    //   return state.set('unanswered_question_ids', action.unanswered_question_ids)
    case "SECTIONS_REDUCE_UNANSWERED_QUESTION_IDS_SUCCESS":
      return state.set(
        "unanswered_question_ids",
        action.unanswered_question_ids
      );
    case "SECTIONS_REDUCE_PREPARATION_SUCCESS":
      return state.set("preparation", action.preparation);
    default:
      return state;
  }
}
