// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "react-router-redux";
import { Map, List } from "immutable";
import pluralize from "pluralize";
import bindAll from "lodash/bindAll";
import Header from "../../lib/elements/Header";
import Button from "../../lib/elements/Button";
import ModalDialog from "../../lib/elements/ModalDialog";
import CardWelcomeVideo from "../../lib/elements/CardWelcomeVideo";
import * as Logger from "astrnt-web-logger";
import ModalWelcomeVideo from "../partials/ModalWelcomeVideo";
import { sendAstronautQnASessionEvent } from "../../actions/embedded-interview";
import store_shopee from "../../dependencies/forShopeeStorage";
import authToken from "../../dependencies/authStore";
import CardTopicTitle from "../../lib/elements/CardTopicTitle";
import CardTopicDesc from "../../lib/elements/CardTopicDesc";
import CardTopicList from "../../lib/elements/CardTopicList";
import CardTopicUlasan from "../../lib/elements/CardTopicUlasan";
import NumberFormat from "react-number-format";

import LogoAstrntWhite from "../../images/logo-white.png";

import { t } from "../../helpers/global";
import { sessionsPaymentCourse } from "../../actions/sessions";
import {
  sectionsStartSessionRequest,
  sectionsStartSessionWelcomeVideoRequest,
  sectionsCheckGDPRRequest,
  sectionDetail
} from "../../actions/sections";

import { interviewsStartSessionPracticeRequest } from "../../actions/interviews";
import CardPrice from "../../lib/elements/CardPrice";
import { auth } from "../../actions/ecourse";
import Swal from "sweetalert2";
import $ from "jquery";

type Props = {
  job: Map<string, any>,
  company: Map<string, any>,
  candidate: Map<string, any>,
  translations: Map<string, any>,
  interviewsStartSessionPracticeRequest: Function,
  sections: List<Map<string, any>>,
  sectionsStartSessionRequest: Function,
  sectionsStartSessionWelcomeVideoRequest: Function,
  sectionsCheckGDPRRequest: Function,
  sessionCode: string,
  sessionsFilterData: Map<string, any>,
  sessionsStateInformation: Map<string, any>
};

type State = {
  modal: boolean
};

const PAYMENT_PENDING = "pending";

class SectionIndex extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      token: null,
      isProcess: false,
      isPendingAfterPayment: false
    };

    bindAll(this, [
      "handleModalToggle",
      "handleLetsGoClick",
      "handlePracticeRecordingClick",
      "setDurationString",
      "setDurationStringWithSec",
      "setSectionTypeString",
      "handleClickWelcomeVideo",
      "handleTopicClick",
      "handleOpenAll",
      "handlePayment",
      "handleNextButton"
    ]);
  }

  handlePayment(e) {
    const { sessionCode, sessionsPaymentCourse, ecourseDetail } = this.props;
    authToken.get().then(token => {
      sessionsPaymentCourse(sessionCode, ecourseDetail.get("id"), token);
    });
  }

  handleOpenAll() {
    const {
      redirect,
      ecourseCode,
      sessionCode,
      sessionsFilterData
    } = this.props;

    if (sessionsFilterData.get("is_course_auth")) {
      redirect(`/code/${sessionCode}/reviews`);
    } else {
      redirect(`/${ecourseCode}/reviews`);
    }
  }

  handleTopicClick(e, index) {
    const {
      sectionDetail,
      sessionCode,
      sectionsStartSessionRequest,
      sectionsStartSessionWelcomeVideoRequest,
      sections,
      sessionsFilterData
    } = this.props;

    if (!sessionsFilterData.get("is_course_auth")) {
      return;
    }
    sectionDetail(sections.get(index)).then(() => {
      if (sessionsFilterData.get("welcome_video_url") == "") {
        sectionsStartSessionRequest(sessionCode);
      } else {
        sectionsStartSessionWelcomeVideoRequest(sessionCode);
      }
    });
  }

  componentDidMount() {
    const { sessionCode } = this.props;
    let params = {
      event: "Section Welcome", // string
      message: "Enter Section Welcome",
      status: "offline"
    };
    window.scrollTo(0, 0);
    Logger.recordEvent(params);
    //handle untuk pembukaan sesi pertama harus di set close untuk shopee

    authToken.get().then(token => {
      this.setState({ token: token });
    });

    store_shopee.set("close");
    store_shopee.setModalOpenedOnce("not_open", sessionCode);

    if (
      PAYMENT_PENDING ==
      this.props.sessionsFilterData.get("ecourse_payment_status")
    ) {
      this.setState({ isPendingAfterPayment: PAYMENT_PENDING });
    }
  }
  setDurationString(duration): string {
    const { translations } = this.props;
    const minutes: number = Math.floor(duration / 60);

    return `${minutes} ${t(translations, "TIME_LABEL_MIN")}`;
  }

  setDurationStringWithSec(duration): string {
    const { translations } = this.props;
    const minutes: number = Math.floor(duration / 60);
    var mind = duration % (60 * 60);
    var secd = mind % 60;
    const seconds: number = Math.ceil(secd);

    if (duration < 60) {
      return `${duration} ${t(translations, "TIME_LABEL_SEC")}`;
    } else if (seconds == 0) {
      return `${minutes} ${t(translations, "TIME_LABEL_MIN")}`;
    } else {
      return `${minutes} ${t(translations, "TIME_LABEL_MIN")} ${seconds} ${t(
        translations,
        "TIME_LABEL_SEC"
      )}`;
    }
  }

  setSectionTypeString(sectionType, questionSize): string {
    const { translations } = this.props;

    let sectionTypeString: string;
    if (sectionType === "mcq") {
      sectionTypeString = t(translations, "QUESTION_TYPE_MCQ_PLURAL", {
        ":pluralize_question": pluralize("Question", questionSize),
        ":n_question": questionSize
      });
    } else if (sectionType === "freetext") {
      sectionTypeString = t(translations, "QUESTION_TYPE_FTQ_PLURAL", {
        ":pluralize_question": pluralize("Question", questionSize),
        ":n_question": questionSize
      });
    } else {
      sectionTypeString = t(translations, "QUESTION_TYPE_VIDEO_PLURAL", {
        ":pluralize_question": pluralize("Question", questionSize),
        ":n_question": questionSize
      });
    }

    return sectionTypeString;
  }

  handleClickWelcomeVideo() {
    this.ModalWelcomeVideo.handleOpenModal(false);
  }

  handleLetsGoClick(e) {
    e.preventDefault();

    let params = {
      event: "Section Welcome", // string
      message: "Lets Go Button Clicked",
      status: "offline"
    };
    Logger.recordEvent(params);

    const {
      sessionCode,
      sectionsStartSessionRequest,
      sectionsStartSessionWelcomeVideoRequest,
      sectionsCheckGDPRRequest,
      sessionsFilterData,
      sendAstronautQnASessionEvent,
      sections,
      sectionDetail
    } = this.props;

    if (undefined == sessionCode) {
      return false;
    }

    let unanswered = sections.some((section, index) => {
      if (section.get("unanswered_question_ids")) {
        if (section.get("unanswered_question_ids").size != 0) {
          sectionDetail(sections.get(index)).then(() => {
            if (sessionsFilterData.get("welcome_video_url") == "") {
              sectionsStartSessionRequest(sessionCode);
            } else {
              sectionsStartSessionWelcomeVideoRequest(sessionCode);
            }
          });
          return true;
        }
      }
    });

    if (!unanswered) {
      if (sessionsFilterData.get("welcome_video_url") == "") {
        sectionsStartSessionRequest(sessionCode);
      } else {
        sectionsStartSessionWelcomeVideoRequest(sessionCode);
      }
    }

    sendAstronautQnASessionEvent("Start QnA Session");
    // if (sessionsFilterData.get("gdpr_complied") == "0") {
    //   sectionsCheckGDPRRequest(sessionCode);
    // } else
  }

  handleModalToggle(e) {
    e.preventDefault();

    this.setState({ modal: !this.state.modal });
  }

  handlePracticeRecordingClick(e) {
    e.preventDefault();

    const { sessionCode, interviewsStartSessionPracticeRequest } = this.props;

    interviewsStartSessionPracticeRequest(sessionCode);
  }

  handleNextButton() {
    const {
      redirect,
      ecourseCode,
      ecourseDetail,
      doAuth,
      sessionsPaymentCourse
    } = this.props;
    const { token } = this.state;
    const $this = this;

    if (this.state.isProcess) {
      console.log("please waiitt..");
      return false;
    }
    this.setState({ isProcess: true });

    // check
    if (null === token || "" === token) {
      // register/login
      redirect("/login" + this.props.location.pathname);
      window.location.reload();
    } else {
      // check auth
      doAuth(token, ecourseCode, ecourseDetail.get("job_id")).then(() => {
        let isAuth = this.props.sessionsFilterData.get("is_course_auth");
        // let isPending = this.props.sessionsFilterData.get("is_course_pending");
        let paymentStatus = this.props.sessionsFilterData.get(
          "ecourse_payment_status"
        );

        if (isAuth) {
          // study
          console.log("buru mulai study");
        } else if (PAYMENT_PENDING == paymentStatus) {
          Swal({
            type: "warning",
            title: "<h4><b>PEMBAYARAN BELUM TERKONFIRMASI</b></h4>",
            text:
              "silakan selesaikan pembayaran sebelumnya dengan mengikuti instruksi yang telah Kami kirimkan ke email Kamu.",
            showCancelButton: true,
            confirmButtonText: "Kembali",
            cancelButtonText: "Ubah Metode Pembayaran",
            buttonsStyling: false,
            confirmButtonClass: "button button__primary btn-swal-pembayaran",
            cancelButtonClass:
              "button button__secondary top-margin btn-swal-pembayaran",
            onBeforeOpen: function(elm) {
              $(elm)
                .find(".swal2-actions")
                .toggleClass("swal2-actions vertical-buttons");
              $(elm)
                .find(".swal2-content")
                .toggleClass("swal2-content font-15");
            }
          }).then(function(e) {
            if (e.dismiss == "cancel") {
              sessionsPaymentCourse(
                ecourseCode,
                ecourseDetail.get("job_id"),
                token
              ).then(() => {
                $this.setState({ isProcess: false });
                $this.handleAfterPayment();
              });
              return true;
            } else {
              $this.setState({ isProcess: false });
              return true;
            }
          });
        } else {
          // payment
          sessionsPaymentCourse(
            ecourseCode,
            ecourseDetail.get("job_id"),
            token
          ).then(() => {
            this.setState({ isProcess: false });
            this.handleAfterPayment();
          });
        }
      });
    }
  }

  handleAfterPayment() {
    let isPending =
      this.props.sessionsFilterData.get("ecourse_payment_status") ==
      PAYMENT_PENDING;
    this.setState({ isPendingAfterPayment: isPending });

    console.log("oiii");

    if (isPending) {
      const text1 =
        "Kami telah mengirimkan email yang berisi instruksi untuk menyelesaikan pembayaran.";
      const text2 =
        "Setelah pembayaran terkonfirmasi, silakan kembali ke halaman ini untuk mengakses material pembelajaran.";

      Swal({
        type: "warning",
        title: "<h4><b>CEK EMAIL KAMU</b></h4>",
        html: "<p>" + text1 + "</p><p>" + text2 + "</p>",
        confirmButtonText: "OK",
        buttonsStyling: false,
        confirmButtonClass: "button button__primary",
        onBeforeOpen: function(elm) {
          $(elm)
            .find(".swal2-actions")
            .toggleClass("swal2-actions vertical-buttons");
          $(elm)
            .find(".swal2-content")
            .toggleClass("swal2-content font-15");
        }
      });
    }
  }

  render() {
    const {
      job,
      company,
      candidate,
      sections,
      sessionsFilterData,
      translations,
      sessionsStateInformation,
      sessionsUiData,
      interviewPracticeSound,
      reviewsData,
      reviewsRating,
      reviewsPagination,
      ecourseDetail,
      ecourseTeachers
    } = this.props;

    const { token } = this.state;
    const is_valid = interviewPracticeSound.get("valid");
    let renderButtonStart;

    if (sessionsFilterData.get("is_course_auth")) {
      renderButtonStart = (
        <Button
          // onClick={this.handleModalToggle}
          onClick={this.handleLetsGoClick}
          className={`btn-start-session`}
          buttonLoading={sessionsUiData.get("loading")}
        >
          Mulai Belajar
        </Button>
      );
    } else if ("" !== token && null !== token) {
      renderButtonStart = (
        <Button
          className={`btn-start-session beli`}
          onClick={this.handleNextButton}
          buttonLoading={this.state.isProcess}
        >
          {ecourseDetail.get("price") > 0 ? (
            this.state.isPendingAfterPayment ? (
              <span>Menunggu Pembayaran</span>
            ) : (
              <Fragment>
                Beli -{" "}
                <NumberFormat
                  value={ecourseDetail.get("price")}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  displayType={"text"}
                  prefix={"Rp"}
                />
              </Fragment>
            )
          ) : (
            <span>Gratis</span>
          )}
        </Button>
      );
    } else {
      renderButtonStart = (
        <Button className={`btn-start-session`} onClick={this.handleNextButton}>
          MASUK
        </Button>
      );
    }

    const now_section = sections.get(
      sessionsStateInformation.get("section_index")
    );
    // const duration: Map<string, any> =

    let modal;

    modal = <ModalWelcomeVideo onRef={ref => (this.ModalWelcomeVideo = ref)} />;

    const video = sessionsFilterData.get("welcome_video_url");
    var renderVideo = "";
    if (video != "") {
      renderVideo = (
        <div
          className="card-click"
          onClick={this.handleClickWelcomeVideo}
          style={{ cursor: "pointer" }}
        >
          <CardWelcomeVideo className="m-t-16 test" />
        </div>
      );
    }
    return (
      <Fragment>
        <Header
          provider={company.get("title")}
          course={job.get("title")}
          position={job.get("jobType")}
          location={job.get("locations")}
          redirectCode={sessionsFilterData.get("ecourse_code")}
          learner={candidate}
          token={token}
          type="primary"
          logoCourse={company.get("logoUrl")}
        />
        <div className="container">
          <section>
            {sessionsFilterData.get("is_access_profile") == 0 ? (
              <h2 className="heading4 normal m-t-24">
                {t(translations, "LABEL_WELCOME")},{" "}
                <b className="bold">{" " + candidate.get("fullname")}</b>!
              </h2>
            ) : (
              ""
            )}
            {renderVideo}
            {/*<CardTopicAll />*/}
            {/*<CardTopicInstruksi />*/}
            <CardTopicTitle
              provider={company}
              ecourseDetail={ecourseDetail}
              course={job}
              ecourseTeachers={ecourseTeachers}
            />
            <CardTopicDesc description={job.get("description")} />
            <CardTopicList
              total_duration={sessionsFilterData.get("duration_left")}
              topics={sections}
              handleTopicClick={this.handleTopicClick}
            />
            <CardTopicUlasan
              reviewsData={reviewsData}
              reviewsPagination={reviewsPagination}
              reviewsRating={reviewsRating}
              open_all={this.handleOpenAll}
            />
            {sessionsFilterData.get("is_course_auth") ||
            (token !== "" && token !== null) ? (
              ""
            ) : (
              <CardPrice price={ecourseDetail.get("price")} />
            )}
            {/*<CardSingleContent
              className="m-t-16 m-t-small"
              number="3"
              text="Pertanyaan Pilihan Ganda"
            >
              <Icon
                type="icon"
                name="icon-test"
                size="extralarge"
                align="bottom"
                className="m-r-8"
              />
            </CardSingleContent>*/}
          </section>
          <section>
            <center className={`${!is_valid ? "m-t-40" : "m-t-40"} m-b-40`}>
              {renderButtonStart}
            </center>
          </section>
        </div>
        <footer className="footer-astrnt horizontal-item--center">
          Powered by <img src={LogoAstrntWhite} /> All Rights Reserved.
        </footer>
        <ModalDialog
          isOpen={this.state.modal}
          toggle={this.handleModalToggle}
          title={`${t(translations, "INSTRUCTION_START_SESSION")}?`}
          text={t(translations, "POPUP_CONTENT_START_SESSION")}
        >
          <Button
            type="button"
            theme="secondary"
            size="small"
            className="m-r-20"
            onClick={this.handleModalToggle}
          >
            {t(translations, "LABEL_CANCEL")}
          </Button>
          <Button
            type="button"
            theme="primary"
            size="small"
            onClick={this.handleLetsGoClick}
            buttonLoading={sessionsUiData.get("loading")}
            disabled={sessionsUiData.get("disabled_button")}
          >
            {t(translations, "LABEL_LETS_GO")}
          </Button>
        </ModalDialog>
        {modal}
        {/* <ModalDialog isOpen={true}>
          <Button className='vertical-buttons'>OK</Button>
          <Button className='top-margin'>OK</Button>
        </ModalDialog> */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations,
    job: state.job,
    company: state.company,
    candidate: state.candidate,
    interviewPracticeSound: state.interviewPracticeSound,
    sections: state.sections,
    sessionCode: ownProps.match.params.sessionCode,
    sessionsFilterData: state.sessionsFilterData,
    sessionsStateInformation: state.sessionsStateInformation,
    sessionsUiData: state.sessionsUiData,
    reviewsData: state.reviewsData,
    reviewsRating: state.reviewsRating,
    reviewsPagination: state.reviewsPagination,
    ecourseDetail: state.ecourseDetail,
    // jobCode: ownProps.location.pathname.split("/")[1],
    ecourseCode: ownProps.match.params.ecourseCode,
    ecourseTeachers: state.ecourseTeachers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sectionsCheckGDPRRequest: sessionCode =>
      dispatch(sectionsCheckGDPRRequest(sessionCode)),
    sectionsStartSessionRequest: sessionCode =>
      dispatch(sectionsStartSessionRequest(sessionCode)),
    sectionsStartSessionWelcomeVideoRequest: sessionCode =>
      dispatch(sectionsStartSessionWelcomeVideoRequest(sessionCode)),
    interviewsStartSessionPracticeRequest: sessionCode =>
      dispatch(interviewsStartSessionPracticeRequest(sessionCode)),
    sendAstronautQnASessionEvent: astronautEvent =>
      dispatch(sendAstronautQnASessionEvent(astronautEvent)),
    sectionDetail: section => dispatch(sectionDetail(section)),
    redirect: link => dispatch(push(link)),
    sessionsPaymentCourse: (interview_code, id, token) =>
      dispatch(sessionsPaymentCourse(interview_code, id, token)),
    doAuth: (token, ecourseCode, ecourseId) =>
      dispatch(auth(token, ecourseCode, ecourseId))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SectionIndex)
);
