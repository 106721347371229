import _axios from "../dependencies/_axios";

export function sessionsFetchInitialDataApi(sessionCode, resubscribe = true) {
  // Januar. use ecourse_code and interview_code until requeirement aggrement
  let dataSend = resubscribe
    ? {
        ecourse_code: sessionCode,
        // interview_code: sessionCode,
        isTranslations: true
      }
    : {
        ecourse_code: sessionCode,
        // interview_code: sessionCode,
        isTranslations: true,
        is_resubscribe: false
      };
  return _axios({
    url: "/api/ecourse/initial-data",
    method: "post",
    headers: {
      token: ""
    },
    data: dataSend
  });
}

export function sessionsFetchInitialDataApiV2(
  sessionCode,
  token = null,
  resubscribe = true
) {
  // Januar. use ecourse_code and interview_code until requeirement aggrement
  let dataSend = resubscribe
    ? {
        ecourse_code: sessionCode,
        // interview_code: sessionCode,
        isTranslations: true
      }
    : {
        ecourse_code: sessionCode,
        // interview_code: sessionCode,
        isTranslations: true,
        is_resubscribe: false
      };
  return _axios({
    url: "/api/ecourse/initial-data-v2",
    method: "post",
    headers: {
      token: token
    },
    data: dataSend
  });
}

export function sessionsTokenAuthApi(ecourseCode, ecourse_id, token) {
  return _axios({
    url: "/api/ecourse/authentication",
    method: "post",
    headers: {
      token: token
    },
    data: {
      ecourse_code: ecourseCode,
      ecourse_detail_id: ecourse_id
    }
  });
}

export function sessionsSetGDPRComplied(sessionCode) {
  return _axios({
    url: "/api/v2/user/gdpr_complied",
    method: "post",
    data: {
      interview_code: sessionCode
    }
  });
}

export function sessionsStartApi(sessionCode, token) {
  return _axios({
    url: "/api/v2/interview/start",
    method: "post",
    headers: {
      token
    },
    data: {
      interview_code: sessionCode
    }
  });
}

export function sessionsUpdateElapsedTimeApi(refId, type, sessionCode, token) {
  return _axios({
    url: "/api/v2/interview/update/elapsedTime",
    method: "post",
    headers: {
      token
    },
    data: {
      ref_id: refId,
      type,
      interview_code: sessionCode
    }
  });
}

export function sessionsFinishApi(sessionCode, token) {
  return _axios({
    url: "/api/v2/interview/finish",
    method: "post",
    headers: {
      token
    },
    data: {
      interview_code: sessionCode
    }
  });
}

export function sessionsUpdateIsWatchedApi(sessionCode, invite_id) {
  return _axios({
    url: `/api/web/interview/code/${sessionCode}/iswatched`,
    method: "post",
    data: {
      invite_id: invite_id
    }
  });
}

export function sessionsGetVideoProcessingProgressDataApi(sessionCode, token) {
  return _axios({
    url: "/api/v2/get/finished/processed/video",
    method: "post",
    headers: {
      token
    },
    data: {
      interview_code: sessionCode
    }
  });
}

export function sessionsFetchUserDataApi(token, sessionCode) {
  return _axios({
    url: "/api/ecourse/user_detail",
    method: "post",
    headers: {
      token: token
    },
    data: {
      ecourse_code: sessionCode,
      token: token
    }
  });
}
