// @flow
import React from 'react'
import classnames from 'classnames'

const checkboxSize = {
  small: '',
  medium: 'md',
}

type Props = {
  label: string | number,
  className: string,
  value: string | number,
  onClick: Function,
  disabled: boolean,
  size: string,
  checked: boolean
};

class Checkbox extends React.Component<Props> {
  static defaultProps = {
    label: 'label name',
    className: '',
    value: '',
    onClick: () => {},
    checked: false,
    disabled: false,
    size: 'medium',
  }

  render() {
    const {
      size,
      value,
      label,
      className,
      onClick,
      checked,
      disabled
    } = this.props

    const classLabel: string = classnames(
      {
        'disabled' : disabled
      }
    )
    const classSize : string = classnames(
      checkboxSize[size],
      {
        'checked' : checked
      }
    )

    return (
      <label className={`${classLabel} ${className}`}>
        <button
          className={`btn checkbox ${classSize}`}
          data-checked={checked ? '1' : '0'}
          onClick={onClick}
          value={value}
          checked={checked}
          disabled={disabled}>
        </button>
          {label}
      </label>
    )
  }
}

export default Checkbox
