import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import CardBasicLayout from '../../lib/elements/CardBasicLayout'
import Header from '../../lib/elements/Header'
import imgBroken from '../../images/broken.png'

type Props = {
  job: Map<string, any>,
  company: Map<string, any>
};

class BrokenLink extends React.Component<Props> {
  render() {
    const {
      job,
      company
    } = this.props

    return(
      <Fragment>
        <Header
          company={company.get('title')}
          job={job.get('title')}
          position={job.get('jobType')}
          location={job.get('locations')} />
        <div className="container">
          <CardBasicLayout
            contentTitle="Q&A Session not found"
            contentImage={imgBroken}
            contentImageAlt="Invalid link"
            contentText={`
              We don't recognize the code on your URL. Make sure you entered the right URL. Tip: it's case sensitive!
              <br /><br />
              If you think this is an error, please contact our support at
              <a
                href="mailto:help@astrnt.co"
                target="_blank"
                rel="noopener noreferrer"
                className="card-link">
                help@astrnt.co
              </a>
              and include your URL.
              `}
            />
        </div>
			</Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    job: state.job,
    company: state.company
  }
}

export default connect(mapStateToProps, null)(BrokenLink)
