// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Map } from "immutable";
import bindAll from "lodash/bindAll";
import pluralize from "pluralize";
import Header from "../../lib/elements/Header";
import CardSingleContent from "../../lib/elements/CardSingleContent";
import CardWithLabel from "../../lib/elements/CardWithLabel";
import CardSoundCheck from "../../lib/elements/CardSoundCheck";
import InstructionDesk from "../../lib/elements/InstructionDesk";
import Button from "../../lib/elements/Button";
import Time from "../../lib/elements/Time";
import Icon from "../../lib/elements/Icon";
import ModalDialog from "../../lib/elements/ModalDialog";
import imgInstruction1 from "../../images/instruction-interview-1.svg";
import imgInstruction2 from "../../images/instruction-interview-2.svg";
import imgInstruction3 from "../../images/instruction-interview-3.svg";
import { sendAstronautQnASessionEvent } from "../../actions/embedded-interview";

import { globalGenerateUnitOfTimeHelper } from "../../helpers/global";

import { t } from "../../helpers/global";
import CardWelcomeVideo from "../../lib/elements/CardWelcomeVideo";

import ModalWelcomeVideo from "../partials/ModalWelcomeVideo";

import {
  interviewsCheckGDPRRequest,
  interviewsStartSessionRequest,
  interviewsStartWelcomeVideoRequest,
  interviewsStartSessionPracticeRequest
} from "../../actions/interviews";

type Props = {
  history: Object,
  job: Map<string, any>,
  company: Map<string, any>,
  candidate: Map<string, any>,
  translations: Map<string, any>,
  interviewsCheckGDPRRequest: Function,
  interviewsStartSessionRequest: Function,
  interviewsStartWelcomeVideoRequest: Function,
  interviewsStartSessionPracticeRequest: Function,
  sessionCode: string,
  sessionsFilterData: Map<string, any>,
  sessionsStateInformation: Map<string, any>
};

type State = {
  modal: boolean
};

class InterviewIndex extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      modal: false
    };

    bindAll(this, [
      "handleContinueClick",
      "handleModalToggle",
      "handlePracticeRecordingClick",
      "handleClickWelcomeVideo"
    ]);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleContinueClick(e) {
    e.preventDefault();

    const {
      sessionCode,
      interviewsCheckGDPRRequest,
      interviewsStartSessionRequest,
      interviewsStartWelcomeVideoRequest,
      sessionsFilterData,
      sendAstronautQnASessionEvent
    } = this.props;

    sendAstronautQnASessionEvent("Start Interview Session");

    if (sessionsFilterData.get("gdpr_complied") == "0") {
      interviewsCheckGDPRRequest(sessionCode);
    } else if (sessionsFilterData.get("welcome_video_url") == "") {
      interviewsStartSessionRequest(sessionCode);
    } else {
      interviewsStartWelcomeVideoRequest(sessionCode);
    }
  }

  handleClickWelcomeVideo() {
    this.ModalWelcomeVideo.handleOpenModal(false);
  }
  handleModalToggle(e) {
    e.preventDefault();

    this.setState({ modal: !this.state.modal });
  }

  handlePracticeRecordingClick(e) {
    e.preventDefault();

    const { sessionCode, interviewsStartSessionPracticeRequest } = this.props;

    interviewsStartSessionPracticeRequest(sessionCode);
  }

  render() {
    const {
      job,
      company,
      candidate,
      sessionsFilterData,
      translations,
      sessionsStateInformation,
      sessionsUiData,
      interviewPracticeSound
    } = this.props;

    const { modal } = this.state;
    const is_valid = interviewPracticeSound.get("valid");
    const duration: Map<string, any> = globalGenerateUnitOfTimeHelper(
      sessionsFilterData.get("duration_left")
    );
    const questionIdsSize: number = sessionsFilterData.get("question_ids").size;
    let modalWelcome;
    if (sessionsStateInformation.get("is_onboarding")) {
      modalWelcome = (
        <ModalWelcomeVideo onRef={ref => (this.ModalWelcomeVideo = ref)} />
      );
    } else {
      modalWelcome = "";
    }
    if (
      sessionsFilterData.get("interview_settings_skipped_introduction_page") ==
        1 &&
      sessionsFilterData.get("interview_settings_skipped_welcome_video_page") ==
        0
    ) {
      modalWelcome = (
        <ModalWelcomeVideo onRef={ref => (this.ModalWelcomeVideo = ref)} />
      );
    }
    const video = sessionsFilterData.get("welcome_video_url");
    var renderVideo = "";
    if (video != "") {
      renderVideo = (
        <div
          className="card-click"
          onClick={this.handleClickWelcomeVideo}
          style={{ cursor: "pointer" }}
        >
          <CardWelcomeVideo className="m-t-16 test m-t-small" />
        </div>
      );
    }
    return (
      <Fragment>
        <Header
          company={company.get("title")}
          job={job.get("title")}
          position={job.get("jobType")}
          location={job.get("locations")}
          type="primary"
        />
        <div className="container">
          <section>
            <h2 className="heading4 normal m-t-24 m-t-small">
              {t(translations, "LABEL_WELCOME")},{" "}
              <b className="bold">
                {sessionsFilterData.get("is_access_profile") == 1
                  ? "Friend"
                  : candidate.get("fullname")}
              </b>
              ! {t(translations, "LABEL_WHAT_TO_EXPECT")}:
            </h2>
            {renderVideo}
            <CardSingleContent
              className="m-t-16 m-t-small"
              number={questionIdsSize}
              text={t(translations, "QUESTION_TYPE_VIDEO_PLURAL_WITHOUT_N", {
                ":pluralize_question": pluralize("question", questionIdsSize)
              })}
            >
              <Icon
                type="icon"
                name="icon-test"
                size="extralarge"
                align="bottom"
                className="m-r-8"
              />
            </CardSingleContent>
            <CardWithLabel
              label={t(translations, "INSTRUCTION_TIME_TO_COMPLETE")}
            >
              <Time data={duration} className="m-b-0 bold">
                <Icon
                  type="icon"
                  name="icon-timer"
                  size="extralarge"
                  align="middle"
                  className="m-r-8"
                />
              </Time>
            </CardWithLabel>
            <CardSoundCheck
              className="m-t-16 m-t-small"
              number={questionIdsSize}
              text={t(translations, "QUESTION_TYPE_VIDEO_PLURAL_WITHOUT_N", {
                ":pluralize_question": pluralize("question", questionIdsSize)
              })}
            >
              <Icon
                type="icon"
                name="icon-test"
                size="extralarge"
                align="bottom"
                className="m-r-8"
              />
            </CardSoundCheck>
          </section>
          <section>
            <h2 className="heading4 normal m-t-32 m-t-small">
              <b>{t(translations, "HOW_IT_WORKS_HOW_LABEL")}</b>{" "}
              {t(translations, "HOW_IT_WORKS_WORKS_LABEL")}
            </h2>
            <div className="row no-gutters m-t-20 m-t-small">
              <InstructionDesk
                image={imgInstruction1}
                text={t(translations, "HOW_IT_WORKS_VIDEO_PLACE")}
                className="col-sm-4 bg-white"
              />
              <InstructionDesk
                image={imgInstruction2}
                text={t(translations, "HOW_IT_WORKS_VIDEO_CAPTURE")}
                className="col-sm-4 bg-white"
              />
              <InstructionDesk
                image={imgInstruction3}
                text={t(translations, "HOW_IT_WORKS_VIDEO_REFRESH")}
                className="col-sm-4 bg-white"
              />
            </div>
          </section>
          <section>
            <center className={`m-t-20 ${!is_valid ? "" : "hide"}`}>
              <p className="p-test-mic">
                {t(translations, "LABEL_TEST_THE_MIC")}
              </p>
            </center>
            <center className={`${!is_valid ? "m-t-20" : "m-t-40"} m-b-40`}>
              <Button
                disabled={!is_valid}
                className={`m-r-24 btn-practice-record ${
                  !is_valid ? "disabled" : ""
                }`}
                theme="secondary"
                onClick={this.handlePracticeRecordingClick}
              >
                {t(translations, "INSTRUCTION_PRACTICE")}
              </Button>
              <Button
                disabled={!is_valid}
                className={`btn-start-session ${!is_valid ? "disabled" : ""}`}
                onClick={this.handleModalToggle}
              >
                {t(translations, "INSTRUCTION_START_SESSION")}
              </Button>
            </center>
          </section>
        </div>
        <ModalDialog
          isOpen={modal}
          toggle={this.handleModalToggle}
          title={t(translations, "INSTRUCTION_START_SESSION") + "?"}
          text={t(translations, "POPUP_CONTENT_START_SESSION")}
        >
          <Button
            type="button"
            theme="secondary"
            size="small"
            className="m-r-20"
            onClick={this.handleModalToggle}
          >
            {t(translations, "LABEL_CANCEL")}
          </Button>
          <Button
            type="button"
            theme="primary"
            size="small"
            onClick={this.handleContinueClick}
            buttonLoading={sessionsUiData.get("loading")}
            disabled={sessionsUiData.get("disabled_button")}
          >
            {t(translations, "LABEL_LETS_GO")}
          </Button>
        </ModalDialog>
        {modalWelcome}
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations,
    job: state.job,
    company: state.company,
    candidate: state.candidate,
    sessionCode: ownProps.match.params.sessionCode,
    sessionsFilterData: state.sessionsFilterData,
    sessionsUiData: state.sessionsUiData,
    sessionsStateInformation: state.sessionsStateInformation,
    interviewPracticeSound: state.interviewPracticeSound
  };
};

const mapDispatchToProps = dispatch => {
  return {
    interviewsCheckGDPRRequest: sessionCode =>
      dispatch(interviewsCheckGDPRRequest(sessionCode)),
    interviewsStartSessionRequest: sessionCode =>
      dispatch(interviewsStartSessionRequest(sessionCode)),
    interviewsStartWelcomeVideoRequest: sessionCode =>
      dispatch(interviewsStartWelcomeVideoRequest(sessionCode)),
    interviewsStartSessionPracticeRequest: sessionCode =>
      dispatch(interviewsStartSessionPracticeRequest(sessionCode)),
    sendAstronautQnASessionEvent: astronautEvent =>
      dispatch(sendAstronautQnASessionEvent(astronautEvent))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(InterviewIndex)
);
