import _axios from "../dependencies/_axios";

export function questionsTestAnswerRequest(sessionCode) {
  return {
    type: "QUESTIONS_TEST_ANSWER_REQUEST",
    sessionCode
  };
}

export function questionsGetDetailOnStartedTestRequest(sessionCode) {
  return {
    type: "QUESTIONS_GET_DETAIL_ON_STARTED_TEST_REQUEST",
    sessionCode
  };
}

export function questionsGetPrevOrNextIdRequest(isNext, sessionCode) {
  return {
    type: "QUESTIONS_GET_PREV_OR_NEXT_ID_REQUEST",
    isNext,
    sessionCode
  };
}

export function questionsGetDetailOnSectionRequest(id, sessionCode) {
  return {
    type: "QUESTIONS_GET_DETAIL_ON_SECTION_REQUEST",
    id,
    sessionCode
  };
}

export function questionsAddAnswersRequest(id) {
  return {
    type: "QUESTIONS_ADD_ANSWERS_REQUEST",
    id
  };
}

export function questionsAddAnswersRequestOnAllPages(id, question) {
  return {
    type: "QUESTIONS_ADD_ANSWERS_REQUEST_ON_ALL_PAGES",
    id,
    question
  };
}

export function questionsAddAnswersTextRequest(text) {
  return {
    type: "QUESTIONS_ADD_ANSWERS_TEXT_REQUEST",
    text
  };
}

export function questionsAddMaxLengthRequest(max_length) {
  return {
    type: "QUESTIONS_ADD_MAX_LENGTH_REQUEST",
    max_length
  };
}

export function questionMediaAttempt(
  candidateId,
  sessionCode,
  question,
  token
) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const data = new FormData();

      data.append("candidate_id", candidateId || "");
      data.append("question_id", question.get("id") || "");
      data.append("interview_code", sessionCode || "");

      _axios({
        url: "/api/v2/media/attempt",
        method: "post",
        timeout: 200000,
        headers: {
          token,
          "Content-Type": "multipart/form-data"
        },
        data
      }).then(response => {
        var questionJS = question.toJS();
        // console.log(response.data.attempt_left)
        questionJS.media_attempt_left = response.data.attempt_left;
        // console.log(questionJS)
        dispatch({
          type: "QUESTIONS_GET_DETAIL_SUCCESS",
          question: questionJS
        });
        resolve();
      });
    });
  };
}

export function questionsVideoUpload(
  companyId,
  jobId,
  candidateId,
  questionId,
  sessionCode,
  token,
  front_end_run_time
) {
  return dispatch => {
    const interviewVideo = new Blob(window.recordedBlobs, {
      type: "video/webm"
    });
    //if size 150 kb of video defined to be corrupt
    // console.log(interviewVideo)
    console.log("video size : " + interviewVideo.size + " bytes");
    let error = "corrupt";
    if (interviewVideo.size < 150000) {
      throw error;
    }
    const data = new FormData();

    data.append("company_id", companyId || "");
    data.append("job_id", jobId || "");
    data.append("candidate_id", candidateId || "");
    data.append("question_id", questionId || "");
    data.append("interview_code", sessionCode || "");
    data.append("interview_video", interviewVideo || []);
    data.append("front_end_run_time", front_end_run_time || 0);

    return _axios({
      url: "/api/v2/video/upload",
      method: "post",
      timeout: 200000,
      headers: {
        token,
        "Content-Type": "multipart/form-data"
      },
      data,
      onUploadProgress: progressEvent => {
        const uploadVideoPercentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        dispatch({
          type: "INTERVIEWS_ADD_UI_UPLOAD_VIDEO_PERCENTAGE_SUCCESS",
          upload_video_percentage: uploadVideoPercentage
        });
      }
    });
  };
}
