import { Map, fromJS } from 'immutable'
import titleCase from 'voca/title_case'
import replace from 'voca/replace'

export function job(state = Map({}), action) {
	switch (action.type) {
		case 'JOBS_GET_DETAIL_SUCCESS':
			return fromJS(action.job)
        .set(
          'jobType',
          titleCase(replace(action.job.jobType, '_', ' '))
        )
		default:
			return state
	}
}
