import React, { Fragment } from "react";
import {
  Field,
  reduxForm,
  change,
  formValueSelector,
  stopAsyncValidation,
  touch
} from "redux-form";
import { connect } from "react-redux";
import Header from "../../lib/elements/Header";
import bindAll from "lodash/bindAll";
import { getReset, changePassword } from "../../actions/ecourse";
import validate from "./validateReg";

const normalizeEmail = (value, previousValue) => {
  return value.trim();
};

class NewPass extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      hasUpper: false,
      hasLower: false,
      hasNumber: false,
      hasEightChar: false
    };
    bindAll(this, [
      "handleSaveData",
      "renderError",
      "inputFocus",
      "inputBlur",
      "checkPasswordPolicy",
      "handleChange"
    ]);
  }

  componentDidMount() {
    const { token, getReset } = this.props;
    getReset(token).then(() => {
      console.log("wuhuu");
    });
  }

  handleSaveData(values) {
    const { token, changePassword, ecourseCode } = this.props;
    changePassword(token, values.password, ecourseCode);
  }

  handleChange(e) {
    if (e.target.name == "password") {
      this.checkPasswordPolicy(e.target.value);
    }
  }

  checkPasswordPolicy(val) {
    let hasUpper = /^.*(?=.*[A-Z]).*$/.test(val);
    let hasLower = /^.*(?=.*[a-z]).*$/.test(val);
    let hasNumber = /^.*(?=.*\d).*$/.test(val);
    let hasEightChar = val.length >= 8 ? true : false;

    this.setState({
      hasLower: hasLower,
      hasUpper: hasUpper,
      hasNumber: hasNumber,
      hasEightChar: hasEightChar
    });
  }

  renderError({ meta: { touched, error } }) {
    // const {
    // 	translationjob
    // } = this.props
    // const translations : Map<string, any> = fromJS(translationjob.translation)
    return touched && error ? (
      <div>
        <span className="error normal" id="js-err-msg">
          {error}
        </span>
      </div>
    ) : (
      false
    );
  }

  inputFocus(e: object): void {
    // const {publicJobCustom}= this.props
    e.target.style.boxShadow = `0 0 10px ${"red"}`;
    e.target.style.border = `1px solid ${"red"}`;
    e.target.style.borderRadius = `5px`;
  }

  inputBlur(e: object): void {
    e.target.style.border = `1px solid #999999`;
    e.target.style.borderRadius = ``;
    e.target.style.boxShadow = ``;
  }

  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      ecourseJobDetail
    } = this.props;
    let courseName, logoUrl, companyName;
    if (ecourseJobDetail.size > 0) {
      let provider = ecourseJobDetail.get("provider");
      let course = ecourseJobDetail.get("course");

      companyName = provider.get("title");
      courseName = course.get("title");
      logoUrl = provider.get("logoUrl");
    }

    return (
      <Fragment>
        <Header
          provider={companyName}
          course={courseName}
          position={"Pendaftaran"}
          location={"bandung"}
          logoCourse={logoUrl}
          type="register"
        />
        <div className="container max-width-500 m-t-50">
          <section id="section-registration" className="registration">
            <div className="div-apply-now">
              <p
                className={"text-center uppercase"}
                style={{ color: "#000000" }}
              >
                {"Ubah Kata Sandi"}
              </p>

              <hr style={{ borderColor: "#000000" }} />
            </div>
            <form
              onSubmit={handleSubmit(this.handleSaveData)}
              id="form-registration"
              className="vertical-form"
            >
              <div className="form-group  input-tool">
                <label>{"Kata Sandi Baru"}</label>
                <span className="pass-req-mobile">
                  Minimal 8 karakter, terdiri dari angka, huruf kapital dan
                  huruf kecil.
                </span>
                <Field
                  name="password"
                  type="password"
                  onFocus={this.inputFocus}
                  onBlur={this.inputBlur}
                  placeholder={"Kata Sandi Baru"}
                  component="input"
                  onChange={this.handleChange}
                />
                <span class="tooltiptext">
                  Kata sandi harus memiliki panjang minimal 8 karakter, terdiri
                  dari angka, huruf kapital dan huruf kecil.
                  <span
                    class={`pass-req ${
                      this.state.hasEightChar ? "active" : ""
                    }`}
                  >
                    <i class="far fa-check-circle"></i>minimal 8 karakter
                  </span>
                  <span
                    class={`pass-req ${this.state.hasNumber ? "active" : ""}`}
                  >
                    <i class="far fa-check-circle"></i>berisi angka
                  </span>
                  <span
                    class={`pass-req ${this.state.hasUpper ? "active" : ""}`}
                  >
                    <i class="far fa-check-circle"></i>berisi huruf kapital
                  </span>
                  <span
                    class={`pass-req ${this.state.hasLower ? "active" : ""}`}
                  >
                    <i class="far fa-check-circle"></i>berisi huruf kecil
                  </span>
                </span>
                <Field name="password" component={this.renderError} />
              </div>
              <div className="form-group">
                <label>{"Ulangi Kata Sandi"}</label>
                <Field
                  name="password2"
                  type="password"
                  onFocus={this.inputFocus}
                  onBlur={this.inputBlur}
                  placeholder={"Ulangi Kata Sandi"}
                  component="input"
                />
                <Field name="password2" component={this.renderError} />
              </div>
              {/*
                    <div className="form-group hidden">
                        <div className="col-md-10 p-0"><label>What of experience do you expect to gain at <a href="https://astrnt.co" target="_blank"><b style={{color:customColor}}>Astronaut</b></a>?</label></div>
                        <div className="col-md-2 text-right p-0"><label>{countText}<font className="normal">/1000</font></label></div>
                        <textarea
                            name="experience"
                            onFocus={this.inputFocus}
                            onBlur={this.inputBlur}
                            value={formValues.experience}
                            onChange={this.handleChangeTextArea}
                            placeholder="What of experience do you expect to gain at Astronaut?"
                            className="form-control h-100"
                        />

                        <Field
                            name="experience"
                            component="textarea"
                            placeholder="What of experience do you expect to gain at Astronaut?"
                            className="form-control h-100 hidden" />
                        <Field name="experience" component={this.renderError}/>
                    </div> */}

              <center className="m-t-30">
                {/* <p className="label-click-continue" dangerouslySetInnerHTML={{__html: t(translations, 'LABEL_CONTINUE_DESC')}}/> */}
                <button
                  id="btn-submit"
                  type="submit"
                  className={`button button__primary button--medium button-submit-course uppercase is-on`}
                  onClick={() => {
                    return;
                  }}
                  disabled={false}
                >
                  Ubah
                </button>
              </center>
            </form>
          </section>
        </div>
      </Fragment>
    );
  }
}

NewPass = reduxForm({
  form: "NewPass",
  enableReinitialize: true,
  validate
})(NewPass);

const initialValues = {
  password: ""
};
const selector = formValueSelector("NewPass");
const mapStateToProps = (state, ownProps) => {
  return {
    initialValues,
    formValues: selector(state, "email"),
    token: ownProps.match.params.key,
    ecourseCode: ownProps.match.params.id,
    ecourseJobDetail: state.ecourseJobDetail
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeFieldValue: (field, value) =>
      dispatch(change("NewPass", field, value, true, true)),
    touchForm: field => dispatch(touch("NewPass", field)),
    stopAsyncValidationForm: object =>
      dispatch(stopAsyncValidation("NewPass", object)),
    getReset: token => dispatch(getReset(token)),
    changePassword: (token, password, ecourseCode) =>
      dispatch(changePassword(token, password, ecourseCode))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPass);
