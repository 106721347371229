// @flow

import React, { Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Map, List } from "immutable";
import bindAll from "lodash/bindAll";
import QuestionPosition from "../../lib/elements/QuestionPosition";
import Button from "../../lib/elements/Button";
import ModalDialog from "../../lib/elements/ModalDialog";

import * as Logger from "astrnt-web-logger";
import {
  questionsGetPrevOrNextIdRequest,
  questionsAddAnswersTextRequest
} from "../../actions/questions";
import {
  sectionsStartNextMcqSessionRequest,
  sectionsAnswerTextQuestionRequest,
  sectionsStartNextFtqSessionRequest,
  sectionsAnswerQuestionSendRequest
} from "../../actions/sections";

import { t } from "../../helpers/global";
import { sendAstronautQnASessionEvent } from "../../actions/embedded-interview";
import { sessionsDisabledButton } from "../../actions/sessions";
type Props = {
  sessionCode: string,
  translations: Map<string, any>,
  questionId: string | number,
  questionsGetPrevOrNextIdRequest: Function,
  questionsAddAnswersTextRequest: Function,
  section: Map<string, any>,
  question: Map<string, any>,
  sectionsStartNextMcqSessionRequest: Function,
  sectionsStartNextFtqSessionRequest: Function,
  sectionsAnswerTextQuestionRequest: Function,
  questionsFormData: Map<string, any>
};

type State = {
  modal: boolean
};

class SectionQuestionFooter extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      freetext_modal: false,
      next: true
    };

    bindAll(this, [
      "isFirstQuestion",
      "isLastQuestion",
      "handlePreviousClick",
      "handleNextClick",
      "handleNextSectionClick",
      "handleModalToggle",
      "handleModalToggleFreetext",
      "handleSubmitFreetext"
    ]);
  }

  handlePreviousClick(e) {
    e.preventDefault();

    const {
      sessionCode,
      questionsGetPrevOrNextIdRequest,
      question,
      section,
      questionsFormData,
      questionId,
      sectionsAnswerTextQuestionRequest,
      sectionsAnswerQuestionSendRequest
    } = this.props;
    this.setState({ next: false });
    //floww dirubah takut mcq rusak
    // questionsGetPrevOrNextIdRequest(false, sessionCode);

    if (section.get("sub_type") == "freetext") {
      const text_answer: String = questionsFormData.get("text_answer");
      const countText: Number =
        text_answer == "" ? 0 : text_answer.split(" ").length;
      if (countText < question.get("min_length")) {
        this.setState({ freetext_modal: true });
      } else {
        sectionsAnswerTextQuestionRequest(
          questionsFormData.get("text_answer"),
          questionId,
          sessionCode
        ).then(() => {
          questionsGetPrevOrNextIdRequest(false, sessionCode);
        });

        setTimeout(function() {
          var url = window.location.href.replace(/\/$/, "");
          var lastSeg = url.substr(url.lastIndexOf("/") + 1);
          section.get("unanswered_question_ids").find(function(val) {
            if (lastSeg == val) {
              questionsAddAnswersTextRequest("");
            }
          });
        }, 2500);
      }
    } else {
      sectionsAnswerQuestionSendRequest(sessionCode).then(() => {
        questionsGetPrevOrNextIdRequest(false, sessionCode);
      });
    }
  }

  handleNextClick(e) {
    e.preventDefault();

    const {
      sessionCode,
      questionsGetPrevOrNextIdRequest,
      sectionsStartNextMcqSessionRequest,
      sectionsStartNextFtqSessionRequest,
      section,
      question,
      questionsFormData,
      questionsAddAnswersTextRequest,
      sectionsAnswerTextQuestionRequest,
      sectionsAnswerQuestionSendRequest,
      questionId,
      sessionsDisabledButton
    } = this.props;
    this.setState({ next: true });
    const sectionNumber: number = section.get("index") + 1;
    if (this.isLastQuestion()) {
      let params = {
        event: "Section Info", // string
        message: "Section Ended on (" + sectionNumber + ")",
        status: "offline" // string
      };

      Logger.recordEvent(params);
      if (this.props.section.get("unanswered_question_ids").size) {
        if (section.get("sub_type") == "freetext") {
          const text_answer: String = questionsFormData.get("text_answer");
          const countText: Number =
            text_answer == "" ? 0 : text_answer.split(" ").length;

          if (countText == 0) {
            this.setState({ modal: true });
          } else if (countText < question.get("min_length")) {
            this.setState({ freetext_modal: true });
          } else {
            sectionsAnswerTextQuestionRequest(
              questionsFormData.get("text_answer"),
              questionId,
              sessionCode
            ).then(() => {
              sectionsStartNextFtqSessionRequest(sessionCode);
            });

            setTimeout(function() {
              var url = window.location.href.replace(/\/$/, "");
              var lastSeg = url.substr(url.lastIndexOf("/") + 1);
              section.get("unanswered_question_ids").find(function(val) {
                if (lastSeg == val) {
                  questionsAddAnswersTextRequest("");
                }
              });
            }, 2500);
          }
        } else {
          sectionsAnswerQuestionSendRequest(sessionCode).then(() => {
            this.setState({ modal: true });
          });
        }
      } else {
        if (section.get("sub_type") == "freetext") {
          // console.log(questionId);
          sectionsAnswerTextQuestionRequest(
            questionsFormData.get("text_answer"),
            questionId,
            sessionCode
          ).then(() => {
            sectionsStartNextFtqSessionRequest(sessionCode);
          });
        } else {
          sessionsDisabledButton(true).then(() => {
            sectionsAnswerQuestionSendRequest(sessionCode).then(() => {
              sectionsStartNextMcqSessionRequest(sessionCode);
            });
          });
        }
      }
    } else {
      if (section.get("sub_type") == "freetext") {
        const text_answer: String = questionsFormData.get("text_answer");
        const countText: Number =
          text_answer == "" ? 0 : text_answer.split(" ").length;
        if (countText < question.get("min_length")) {
          this.setState({ freetext_modal: true });
        } else {
          sectionsAnswerTextQuestionRequest(
            questionsFormData.get("text_answer"),
            questionId,
            sessionCode
          ).then(() => {
            questionsGetPrevOrNextIdRequest(true, sessionCode);
          });
          setTimeout(function() {
            var url = window.location.href.replace(/\/$/, "");
            var lastSeg = url.substr(url.lastIndexOf("/") + 1);
            section.get("unanswered_question_ids").find(function(val) {
              if (lastSeg == val) {
                questionsAddAnswersTextRequest("");
              }
            });
          }, 2500);
        }
      } else {
        sectionsAnswerQuestionSendRequest(sessionCode).then(() => {
          questionsGetPrevOrNextIdRequest(true, sessionCode);
        });
      }
    }
  }
  handleSubmitFreetext(e) {
    const {
      sessionCode,
      questionsGetPrevOrNextIdRequest,
      questionsAddAnswersTextRequest,
      sectionsAnswerTextQuestionRequest,
      sectionsStartNextFtqSessionRequest,
      question,
      questionsFormData,
      section,
      questionId
    } = this.props;
    sectionsAnswerTextQuestionRequest(
      questionsFormData.get("text_answer"),
      questionId,
      sessionCode
    );
    const isnext = this.state.next;
    if (this.isLastQuestion()) {
      if (isnext == true) {
        sectionsStartNextFtqSessionRequest(sessionCode);
      } else {
        questionsGetPrevOrNextIdRequest(isnext, sessionCode);
      }
    } else {
      questionsGetPrevOrNextIdRequest(isnext, sessionCode);
    }

    setTimeout(function() {
      var url = window.location.href.replace(/\/$/, "");
      var lastSeg = url.substr(url.lastIndexOf("/") + 1);
      section.get("unanswered_question_ids").find(function(val) {
        if (lastSeg == val) {
          questionsAddAnswersTextRequest("");
        }
      });
    }, 1000);
    this.setState({ freetext_modal: false });
  }

  handleNextSectionClick(e) {
    e.preventDefault();

    const {
      section,
      sessionCode,
      sectionsStartNextMcqSessionRequest,
      sectionsStartNextFtqSessionRequest,
      sendAstronautQnASessionEvent,
      sectionsAnswerTextQuestionRequest,
      questionId,
      questionsFormData
    } = this.props;

    sendAstronautQnASessionEvent("Next Section");

    if (section.get("sub_type") == "freetext") {
      sectionsStartNextFtqSessionRequest(sessionCode);
    } else {
      sectionsStartNextMcqSessionRequest(sessionCode);
    }
  }
  handleModalToggleFreetext(e) {
    e.preventDefault();

    this.setState({ freetext_modal: !this.state.freetext_modal });
  }
  handleModalToggle(e) {
    e.preventDefault();

    this.setState({ modal: !this.state.modal });
  }

  isLastQuestion() {
    const { section, questionId } = this.props;

    const questionIds: List<number> = section.get("question_ids");
    const questionIndex: number = questionIds.indexOf(parseInt(questionId, 10));

    return questionIndex === questionIds.size - 1;
  }

  isFirstQuestion() {
    const { section, questionId } = this.props;

    const questionIds: List<number> = section.get("question_ids");
    const questionIndex: number = questionIds.indexOf(parseInt(questionId, 10));

    return questionIndex === 0;
  }

  render() {
    const {
      section,
      questionId,
      questionsFormData,
      translations,
      sessionsUiData
    } = this.props;
    const unansweredQuestionIds: List<number> = section.get(
      "unanswered_question_ids"
    );
    // console.log(sessionsUiData.get('disabled_button'))
    let duration_handleButton = section.get("duration");
    // console.log(section.get(
    //   "unanswered_question_ids"
    // ).toJS())

    // if (section.get("sub_type") == "freetext") {
    //     var countText: Number;
    //     const text_answer: String = questionsFormData.get("text_answer");
    //     if (text_answer.trim().length == 0) {
    //       countText = 0;
    //     } else {
    //       countText = text_answer == "" ? 0 : text_answer.match(/\S+/g).length;
    //     }
    // }

    let renderButton;
    if (section.get("sub_type") == "freetext") {
      var countText: Number;
      const text_answer: String = questionsFormData.get("text_answer");
      if (text_answer.trim().length == 0) {
        countText = 0;
      } else {
        countText = text_answer == "" ? 0 : text_answer.match(/\S+/g).length;
      }

      if (countText > 0) {
        if (
          section.get("unanswered_question_ids").size == 1 &&
          section.get("unanswered_question_ids").toJS()[0] == questionId
        ) {
          renderButton = (
            <Button theme="text" onClick={this.handleNextClick}>
              <font className="bold underline">
                {" "}
                {t(translations, "LABEL_END_SECTION")}{" "}
                <i className="icon-next" />{" "}
              </font>
            </Button>
          );
        } else {
          renderButton = (
            <Button
              theme="text"
              onClick={this.handleNextClick}
              disabled={!unansweredQuestionIds.isEmpty()}
            >
              <font className="bold underline">
                {" "}
                {t(translations, "LABEL_END_SECTION")}{" "}
                <i className="icon-next" />{" "}
              </font>
            </Button>
          );
        }
      } else {
        renderButton = (
          <Button theme="text" onClick={this.handleNextClick} disabled={true}>
            <font className="bold underline">
              {" "}
              {t(
                translations,
                "LABEL_END_SECTION"
              )} <i className="icon-next" />{" "}
            </font>
          </Button>
        );
      }
    } else {
      //kalo duration 0
      let bool_button = false;
      if (duration_handleButton <= 1) {
        bool_button = true;
      } else {
        if (!unansweredQuestionIds.isEmpty()) {
          bool_button = sessionsUiData.get("disabled_button");
        } else {
          bool_button = sessionsUiData.get("disabled_button");
        }
      }

      // console.log(bool_button)
      renderButton = (
        <Button
          theme="text"
          onClick={this.handleNextClick}
          disabled={bool_button}
        >
          <font className="bold underline">
            {" "}
            {t(
              translations,
              "LABEL_END_SECTION"
            )} <i className="icon-next" />{" "}
          </font>
        </Button>
      );
    }

    const questionIds: List<number> = section.get("question_ids");

    return (
      <Fragment>
        <div className="wrapper-fixed-footer text-white">
          <div className="container d-flex justify-content-between">
            <div className="align-self-center">
              <Button
                theme="text"
                disabled={this.isFirstQuestion()}
                onClick={this.handlePreviousClick}
              >
                <i className="icon-previous" /> {t(translations, "LABEL_PREV")}
              </Button>
            </div>
            <div className="m-r-12 m-l-12">
              <QuestionPosition
                current={questionIds.indexOf(parseInt(questionId, 10)) + 1}
                end={questionIds.size}
                size="medium"
              />
            </div>
            <div className="align-self-center">
              {section.get("is_shopee") == 1 ? (
                [
                  this.isLastQuestion() ? (
                    <Button
                      theme="text"
                      disabled={!unansweredQuestionIds.isEmpty()}
                      onClick={this.handleNextClick}
                    >
                      <font className="bold underline">
                        {this.isLastQuestion()
                          ? t(translations, "LABEL_END_SECTION")
                          : t(translations, "LABEL_NEXT")}{" "}
                      </font>
                      <i className="icon-next" />
                    </Button>
                  ) : (
                    <Button theme="text" onClick={this.handleNextClick}>
                      <font className="bold underline">
                        {this.isLastQuestion()
                          ? t(translations, "LABEL_END_SECTION")
                          : t(translations, "LABEL_NEXT")}{" "}
                      </font>
                      <i className="icon-next" />
                    </Button>
                  )
                ]
              ) : (
                <Fragment>
                  {this.isLastQuestion() ? (
                    renderButton
                  ) : (
                    <Button theme="text" onClick={this.handleNextClick}>
                      <font className="bold underline">
                        {" "}
                        {t(translations, "LABEL_NEXT")}{" "}
                      </font>
                      <i className="icon-next" />{" "}
                    </Button>
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </div>
        <ModalDialog
          isOpen={this.state.freetext_modal}
          toggle={this.handleModalToggleFreetext}
          title={t(translations, "LABEL_NOT_LONG_ENOUGH")}
          text={t(translations, "LABEL_NOT_MET_MINIMUM_SECTION")}
        >
          <Button
            type="button"
            theme="secondary"
            size="small"
            className="m-r-20"
            onClick={this.handleModalToggleFreetext}
          >
            Go Back
          </Button>
          <Button
            type="button"
            theme="primary"
            size="small"
            onClick={this.handleSubmitFreetext}
          >
            Submit
          </Button>
        </ModalDialog>
        <ModalDialog
          isOpen={this.state.modal}
          toggle={this.handleModalToggle}
          title={t(translations, "POPUP_TITLE_END_SECTION")}
          text={t(translations, "POPUP_CONTENT_END_SECTION")}
        >
          <Button
            type="button"
            theme="secondary"
            size="small"
            className="m-r-20"
            onClick={this.handleNextSectionClick}
          >
            {t(translations, "LABEL_END_SECTION")}
          </Button>
          <Button
            type="button"
            theme="primary"
            size="small"
            onClick={this.handleModalToggle}
          >
            {t(translations, "LABEL_KEEP_ANSWER")}
          </Button>
        </ModalDialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations,
    section: state.section,
    sections: state.sections,
    sessionCode: ownProps.match.params.sessionCode,
    questionId: ownProps.match.params.questionId,
    questionsFormData: state.questionsFormData,
    question: state.question,
    sessionsUiData: state.sessionsUiData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    questionsGetPrevOrNextIdRequest: (isNext, sessionCode) =>
      dispatch(questionsGetPrevOrNextIdRequest(isNext, sessionCode)),
    sectionsAnswerTextQuestionRequest: (text, question_id, sessionCode) =>
      dispatch(
        sectionsAnswerTextQuestionRequest(text, question_id, sessionCode)
      ),
    sectionsStartNextMcqSessionRequest: sessionCode =>
      dispatch(sectionsStartNextMcqSessionRequest(sessionCode)),
    sectionsStartNextFtqSessionRequest: sessionCode =>
      dispatch(sectionsStartNextFtqSessionRequest(sessionCode)),
    questionsAddAnswersTextRequest: text =>
      dispatch(questionsAddAnswersTextRequest(text)),
    sendAstronautQnASessionEvent: astronautEvent =>
      dispatch(sendAstronautQnASessionEvent(astronautEvent)),
    sectionsAnswerQuestionSendRequest: sessionCode =>
      dispatch(sectionsAnswerQuestionSendRequest(sessionCode)),
    sessionsDisabledButton: bool => dispatch(sessionsDisabledButton(bool))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SectionQuestionFooter)
);
