// @flow

import { put, call, select, take, all, fork, cancel } from "redux-saga/effects";
import { push } from "react-router-redux";
import { Map, fromJS } from "immutable";
import Swal from "sweetalert2";
import { countdown, countdownFinish, countdownPractice } from "./global";
import { sessionsFinish, sessionsFinishCustom } from "./sessions";
import { delay } from "redux-saga";
import * as Logger from "astrnt-web-logger";
import { sessionRunTimeWatcher } from "../actions/sessions";
import { getsessionDurationCount } from "../selectors";
import { frontEndRunTime } from "../dependencies/frontEndRuntime";
import {
  questionsFinish,
  questionsGetDetail,
  questionsGetNextId
} from "./questions";

import { sessionsStartApi, sessionsSetGDPRComplied } from "../apis/sessions";

import {
  questionsAttemptApi,
  questionsResetAttemptApi
} from "../apis/questions";

import { questionsVideoUpload } from "../actions/questions";

import { sendAstronautQnASessionEvent } from "../actions/embedded-interview";

import { t } from "../helpers/global";

import {
  getJob,
  getCompany,
  getQuestion,
  getCandidate,
  getTranslations,
  getInterviewsFilterData,
  getSection,
  getSessionsFilterData,
  getSessionsStateInformation,
  getinterviewPracticeSound
} from "../selectors";

function* initRecording(
  sessionCode: string,
  haltStart: boolean
): Generator<*, *, *> {
  const question: Map<string, any> = yield select(getQuestion);
  const translations: Map<string, any> = yield select(getTranslations);
  const interviewsFilterData: Map<string, any> = yield select(
    getInterviewsFilterData
  );

  if (haltStart === false) {
    yield all([
      call(setStateForRecordingUi, {
        questionTitle: question.get("title"),
        recordingTitle: interviewsFilterData.get("is_practice")
          ? t(translations, "INTERVIEW_DATA_PRACTICE_QUESTION")
          : t(translations, "INTERVIEW_DATA_QUESTION"),
        recordingDotType: "default"
      }),
      put({
        type: "INTERVIEWS_REDUCE_COUNTDOWN_DURATION_REQUEST_TO_THREE",
        sessionCode
      }),
      put({
        type: "INTERVIEWS_REDUCE_COUNTDOWN_DURATION_REQUEST",
        sessionCode,
        haltStart
      }),
      put({
        type: "INTERVIEWS_ADD_FILTER_IS_COUNTING_SUCCESS",
        is_counting: true
      })
    ]);
  } else {
    var question_takes_count;
    if (interviewsFilterData.get("question_takes_count") === 0) {
      question_takes_count = 0;
    } else {
      question_takes_count =
        interviewsFilterData.get("question_takes_count") - 1;
    }
    yield all([
      call(setStateForRecordingUi, {
        questionTitle: question.get("title"),
        recordingTitle: interviewsFilterData.get("is_practice")
          ? t(translations, "INTERVIEW_DATA_PRACTICE_QUESTION")
          : t(translations, "INTERVIEW_DATA_QUESTION"),
        recordingDotType: "default"
      }),
      put({
        type: "INTERVIEWS_ADD_FILTER_QUESTION_TAKES_COUNT_SUCCESS",
        question_takes_count: question_takes_count
      }),
      put({
        type: "INTERVIEWS_ADD_FILTER_QUESTION_USED_ATTEMPT_SUCCESS",
        question_used_attempt:
          interviewsFilterData.get("question_used_attempt") + 1
      }),
      put({
        type: "INTERVIEWS_REDUCE_COUNTDOWN_DURATION_REQUEST",
        sessionCode,
        haltStart
      }),
      put({
        type: "INTERVIEWS_ADD_FILTER_IS_COUNTING_SUCCESS",
        is_counting: true
      })
    ]);
  }
}

function* setStateForRecordingUi({
  questionTitle,
  recordingTitle,
  recordingDotType
}: Object = {}): Generator<*, *, *> {
  if (questionTitle) {
    yield put({
      type: "INTERVIEWS_ADD_UI_QUESTION_TITLE_SUCCESS",
      question_title: questionTitle
    });
  }

  if (recordingTitle) {
    yield put({
      type: "INTERVIEWS_ADD_UI_RECORDING_TITLE_SUCCESS",
      recording_title: recordingTitle
    });
  }

  if (recordingDotType) {
    yield put({
      type: "INTERVIEWS_ADD_UI_RECORDING_DOT_TYPE_SUCCESS",
      recording_dot_type: recordingDotType
    });
  }
}

export function* initInterview(): Generator<*, *, *> {
  const question: Map<string, any> = yield select(getQuestion);
  const translations: Map<string, any> = yield select(getTranslations);
  const interviewsFilterData: Map<string, any> = yield select(
    getInterviewsFilterData
  );

  yield put({ type: "QUESTIONS_ADD_USED_ATTEMPT" });

  var question_takes_count;
  if (question.get("takesCount") === 0) {
    question_takes_count = 0;
  } else {
    question_takes_count =
      question.get("takesCount") - question.get("used_attempt");
  }
  yield all([
    call(setStateForRecordingUi, {
      questionTitle: t(translations, "INTERVIEW_DATA_CLICK", {
        ":prep_time": question.get("prepTime")
      }),
      recordingTitle: interviewsFilterData.get("is_practice")
        ? t(translations, "INTERVIEW_DATA_PRACTICE")
        : t(translations, "INTERVIEW_DATA_INSTRUCTION"),
      recordingDotType: "default"
    }),
    put({
      type: "INTERVIEWS_ADD_FILTER_COUNTDOWN_DURATION_SUCCESS",
      countdown_duration: question.get("prepTime")
    }),
    put({
      type: "INTERVIEWS_ADD_FILTER_QUESTION_DURATION_SUCCESS",
      question_duration: question.get("maxTime")
    }),
    put({
      type: "INTERVIEWS_ADD_FILTER_IS_FINISHED_SUCCESS",
      is_finished: false
    }),
    put({
      type: "INTERVIEWS_ADD_FILTER_QUESTION_TAKES_COUNT_SUCCESS",
      question_takes_count: question_takes_count
    }),
    put({
      type: "INTERVIEWS_ADD_FILTER_IS_MODAL_OPEN_SUCCESS",
      is_modal_open: false
    }),
    put({
      type: "INTERVIEWS_ADD_UI_UPLOAD_STATUS_SUCCESS",
      upload_status: "default"
    }),
    put({
      type: "INTERVIEWS_ADD_UI_UPLOAD_VIDEO_PERCENTAGE_SUCCESS",
      upload_video_percentage: 0
    })
  ]);
}

export function* interviewsStartSession({
  sessionCode
}: Object): Generator<*, *, *> {
  const translations: Map<string, any> = yield select(getTranslations);
  try {
    const sessionsFilterData: Map<string, any> = yield select(
      getSessionsFilterData
    );

    yield all([
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
        loading: true
      }),
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
        disabled_button: true
      })
    ]);

    yield call(
      sessionsSetGDPRComplied,
      sessionCode,
      sessionsFilterData.get("token")
    );

    const response: Object = yield call(
      sessionsStartApi,
      sessionCode,
      sessionsFilterData.get("token")
    );

    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data.message != "not authorized"
    ) {
      const sessionsStateInformation: Map<string, any> = yield select(
        getSessionsStateInformation
      );
      const questionId: number = sessionsFilterData
        .get("question_ids")
        .get(sessionsStateInformation.get("interviewIndex"));

      const question: Map<string, any> = yield call(
        questionsGetDetail,
        questionId,
        sessionCode
      );

      yield all([
        put({
          type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
          loading: false
        }),
        put({
          type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
          disabled_button: false
        })
      ]);
      yield put({
        type: "INTERVIEWS_ADD_FILTER_IS_PRACTICE_SUCCESS",
        is_practice: false
      });

      yield call(initInterview);

      if (question.get("takesCount") === 1) {
        yield put({
          type: "INTERVIEWS_ADD_FILTER_IS_TEST_SOUND_SUCCESS",
          is_test_sound: true
        });
      }

      yield put(
        push(
          `/code/${sessionCode}/interview-questions/${questionId}/instruction`
        )
        // push(`/code/${sessionCode}/video/processing/progress`)
      );
    } else {
      throw response;
    }
  } catch (error) {
    yield all([
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
        loading: false
      }),
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
        disabled_button: false
      })
    ]);
    console.error(error);
    // console.log(error.data)
    if (typeof error.data == "undefined") {
      console.log(error);
    } else {
      if (error.data.message == "not authorized") {
        Swal({
          type: "error",
          title: t(translations, "MULTIPLE_DEVICES_TITLE"),
          text: t(translations, "MULTIPLE_DEVICES_CONTENT")
        }).then(function() {
          window.location.reload(true);
        });

        // console.log(error);
      } else {
        console.log(error);
      }
    }
  }
}

export function* interviewsStartWelcomeVideo({
  sessionCode
}: Object): Generator<*, *, *> {
  try {
    const sessionsFilterData: Map<string, any> = yield select(
      getSessionsFilterData
    );

    yield all([
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
        loading: true
      }),
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
        disabled_button: true
      })
    ]);

    yield call(
      sessionsSetGDPRComplied,
      sessionCode,
      sessionsFilterData.get("token")
    );

    yield all([
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
        loading: false
      }),
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
        disabled_button: false
      })
    ]);

    // yield put(push(`/code/${sessionCode}/interview-index-welcome`));
    yield call(interviewsStartSession, { sessionCode });
  } catch (error) {
    yield all([
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
        loading: false
      }),
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
        disabled_button: false
      })
    ]);
    console.error(error);
  }
}

export function* interviewsCheckGDPR({
  sessionCode
}: Object): Generator<*, *, *> {
  try {
    const sessionsFilterData: Map<string, any> = yield select(
      getSessionsFilterData
    );

    const response: Object = yield call(
      getSessionsFilterData,
      sessionCode,
      sessionsFilterData.get("token"),
      sessionsFilterData.get("gdpr_complied"),
      sessionsFilterData.get("gdpr_text")
    );

    yield put(push(`/code/${sessionCode}/check-gdpr-interview`));
  } catch (error) {
    console.error(error);
  }
}

export function* interviewsStartNextQuestion({
  sessionCode
}: Object): Generator<*, *, *> {
  try {
    const questionId: number = yield call(questionsGetNextId);
    const translations: Map<string, any> = yield select(getTranslations);
    const sessionsFilterData: Map<string, any> = yield select(
      getSessionsFilterData
    );

    if (questionId) {
      const question: Map<string, any> = yield call(
        questionsGetDetail,
        questionId,
        sessionCode
      );

      yield put({
        type: "INTERVIEWS_ADD_FILTER_IS_PRACTICE_SUCCESS",
        is_practice: false
      });

      yield call(initInterview);

      if (question.get("takesCount") === 1) {
        yield put({
          type: "INTERVIEWS_ADD_FILTER_IS_TEST_SOUND_SUCCESS",
          is_test_sound: true
        });
      }

      yield put(
        push(
          `/code/${sessionCode}/interview-questions/${questionId}/instruction`
        )
        // push(`/code/${sessionCode}/video/processing/progress`)
      );
    } else {
      if (
        sessionsFilterData.get("interview_settings_custom_pop_up") == "impress"
      ) {
        yield call(sessionsFinishCustom, sessionCode);
      } else {
        yield call(
          sessionsFinish,
          sessionCode,
          t(translations, "INTERVIEW_DATA_THATS"),
          t(translations, "INTERVIEW_DATA_YOU_HAVE"),
          sessionsFilterData.get(
            "interview_settings_display_video_processing_process"
          )
        );
      }
    }
  } catch (error) {
    console.error(error);
  }
}

export function* interviewsStartSessionPractice({
  sessionCode
}: Object): Generator<*, *, *> {
  const translations: Map<string, any> = yield select(getTranslations);

  const question: Map<string, any> = fromJS({
    id: 0,
    title: t(translations, "INTERVIEW_DATA_WHAT_ARE"),
    takesCount: 3,
    prepTime: 10,
    maxTime: 45,
    image_url: "",
    answers: [],
    type_child: "",
    type_parent: "video_question",
    question_duration_left: 0,
    test_duration_left: 0,
    section_duration_left: 0,
    used_attempt: 0
  });

  yield put({
    type: "QUESTIONS_GET_DETAIL_SUCCESS",
    question
  });

  yield put({
    type: "INTERVIEWS_ADD_FILTER_IS_PRACTICE_SUCCESS",
    is_practice: true
  });

  yield call(initInterview);

  yield put(push(`/code/${sessionCode}/interview-practice`));
}

export function* interviewsStartRecording({
  sessionCode,
  haltStart
}: Object): Generator<*, *, *> {
  let params = {
    event: "Video Record", // string
    message: "Start Interview Record",
    status: "offline" // string
  };
  Logger.recordEvent(params);

  yield put.resolve(sendAstronautQnASessionEvent("Start Recording"));

  yield put({
    type: "SESSIONS_ADD_UI_MODAL_LOADER_DATA_SUCCESS",
    modal_loader_data: {
      isOpen: true
    }
  });
  const translations: Map<string, any> = yield select(getTranslations);
  try {
    const candidate: Map<string, any> = yield select(getCandidate);
    const question: Map<string, any> = yield select(getQuestion);
    const section: Map<string, any> = yield select(getSection);
    const sessionsFilterData: Map<string, any> = yield select(
      getSessionsFilterData
    );

    if (haltStart === true) {
      const response: Object = yield call(
        questionsAttemptApi,
        candidate.get("id"),
        question.get("id"),
        sessionCode,
        sessionsFilterData.get("token"),
        section.get("id")
      );

      yield delay(800);

      if (
        response.status >= 200 &&
        response.status < 300 &&
        response.data.message != "not authorized"
      ) {
        yield call(initRecording, sessionCode, haltStart);
        yield put({
          type: "SESSIONS_ADD_UI_MODAL_LOADER_DATA_SUCCESS",
          modal_loader_data: { isOpen: false }
        });
      } else {
        throw response;
      }
    } else {
      yield put({
        type: "SESSIONS_ADD_UI_MODAL_LOADER_DATA_SUCCESS",
        modal_loader_data: { isOpen: false }
      });

      yield call(initRecording, sessionCode, haltStart);
      // console.log("must not call attempt again");
    }
  } catch (error) {
    yield put({
      type: "SESSIONS_ADD_UI_MODAL_LOADER_DATA_SUCCESS",
      modal_loader_data: { isOpen: false }
    });

    // console.log(error.data)
    if (typeof error.data == "undefined") {
      console.log(error);
    } else {
      if (error.data.message == "not authorized") {
        yield put({
          type: "SESSIONS_ADD_UI_MODAL_LOADER_DATA_SUCCESS",
          modal_loader_data: { isOpen: false }
        });
        Swal({
          type: "error",
          title: t(translations, "MULTIPLE_DEVICES_TITLE"),
          text: t(translations, "MULTIPLE_DEVICES_CONTENT")
        }).then(function() {
          window.location.reload(true);
        });

        let params = {
          event: "Video Record", // string
          message:
            "systems detect this Q&A session is being accessed on another device",
          status: "offline" // string
        };
        Logger.recordEvent(params);

        console.log(error);
      } else {
        yield put({
          type: "SESSIONS_ADD_UI_MODAL_LOADER_DATA_SUCCESS",
          modal_loader_data: { isOpen: false }
        });

        Swal({
          type: "error",
          title: "Whoops!",
          text:
            "It seems you have an unstable internet connection.Please try to click the Record button again to start recording."
        });

        let params = {
          event: "Video Record", // string
          message: "Start Interview Record Failed because " + error,
          status: "offline" // string
        };
        Logger.recordEvent(params);
      }
    }

    console.error(error);
  }
}

export function* interviewsStartRecordingPractice({
  sessionCode,
  haltStart
}: Object): Generator<*, *, *> {
  let params = {
    event: "Video Record", // string
    message: "Start Practice",
    status: "offline" // string
  };
  Logger.recordEvent(params);

  yield call(initRecording, sessionCode, haltStart);
}

export function* interviewsRetake({ sessionCode }: Object): Generator<*, *, *> {
  yield call(initInterview);
}

export function* interviewsReduceCountdownDurationtothree({
  sessionCode
}: Object): Generator<*, *, *> {
  const interviewsFilterData: Map<string, any> = yield select(
    getInterviewsFilterData
  );
  const countdownDuration: number = interviewsFilterData.get(
    "countdown_duration"
  );

  const channel = yield call(countdown, 4);
  var secondsZero;
  try {
    while (true) {
      const seconds = yield take(channel);

      if (seconds === 0) {
        secondsZero = true;
        window.arrEbdb = [[]];
      }

      yield put({
        type: "INTERVIEWS_REDUCE_COUNTDOWN_DURATION_SUCCESS",
        countdown_duration: seconds
      });
    }
  } finally {
    if (secondsZero === true) {
      window.mediaRecorder.start();

      const translations: Map<string, any> = yield select(getTranslations);

      yield all([
        put({
          type: "INTERVIEWS_REDUCE_QUESTION_DURATION_REQUEST",
          sessionCode
        }),
        call(setStateForRecordingUi, {
          recordingTitle: interviewsFilterData.get("is_practice")
            ? t(translations, "INTERVIEW_DATA_PRACTICE_RECORDING")
            : t(translations, "INTERVIEW_DATA_RECORDING"),
          recordingDotType: "active"
        }),
        put({
          type: "INTERVIEWS_ADD_FILTER_IS_COUNTING_SUCCESS",
          is_counting: false
        })
      ]);

      channel.close();
    }
  }
}

export function* interviewsReduceCountdownDuration({
  sessionCode,
  haltStart
}: Object): Generator<*, *, *> {
  const interviewsFilterData: Map<string, any> = yield select(
    getInterviewsFilterData
  );
  const countdownDuration: number = interviewsFilterData.get(
    "countdown_duration"
  );

  const channel = yield call(countdown, countdownDuration);
  if (haltStart === false) {
    channel.close();
    return;
  }
  var secondsZero;
  try {
    while (true) {
      const seconds = yield take(channel);
      if (seconds === 0) {
        secondsZero = true;
        window.arrEbdb = [[]];
      }
      yield put({
        type: "INTERVIEWS_REDUCE_COUNTDOWN_DURATION_SUCCESS",
        countdown_duration: seconds
      });
    }
  } finally {
    if (haltStart === true && secondsZero === true) {
      window.mediaRecorder.start();

      const translations: Map<string, any> = yield select(getTranslations);

      yield all([
        put({
          type: "INTERVIEWS_REDUCE_QUESTION_DURATION_REQUEST",
          sessionCode
        }),
        call(setStateForRecordingUi, {
          recordingTitle: interviewsFilterData.get("is_practice")
            ? t(translations, "INTERVIEW_DATA_PRACTICE_RECORDING")
            : t(translations, "INTERVIEW_DATA_RECORDING"),
          recordingDotType: "active"
        }),
        put({
          type: "INTERVIEWS_ADD_FILTER_IS_COUNTING_SUCCESS",
          is_counting: false
        })
      ]);

      channel.close();
    } else {
      channel.close();
      return;
    }
  }
}

export function* interviewsReduceQuestionDuration(
  sessionCode: string
): Generator<*, *, *> {
  const interviewsFilterData: Map<string, any> = yield select(
    getInterviewsFilterData
  );

  const channel = yield call(
    countdown,
    interviewsFilterData.get("question_duration")
  );

  try {
    while (true) {
      const seconds = yield take(channel);

      yield put({
        type: "INTERVIEWS_REDUCE_QUESTION_DURATION_SUCCESS",
        question_duration: seconds
      });
    }
  } finally {
    window.mediaRecorder.stop();

    // $FlowFixMe
    document.getElementById("js-interview-video-player").pause();

    yield all([
      put({
        type: "INTERVIEWS_ADD_FILTER_IS_FINISHED_SUCCESS",
        is_finished: true
      }),
      call(setStateForRecordingUi, {
        recordingTitle: "PREVIEW",
        recordingDotType: "default"
      })
    ]);

    channel.close();
  }
}

export function* interviewsStartUpload({
  sessionCode
}: Object): Generator<*, *, *> {
  const translations: Map<string, any> = yield select(getTranslations);
  try {
    yield all([
      put({
        type: "INTERVIEWS_ADD_UI_UPLOAD_STATUS_SUCCESS",
        upload_status: ""
      }),
      put({
        type: "INTERVIEWS_ADD_FILTER_IS_MODAL_OPEN_SUCCESS",
        is_modal_open: true
      }),
      put({
        type: "INTERVIEWS_ADD_UI_UPLOAD_TITLE_SUCCESS",
        upload_title: t(translations, "INTERVIEW_DATA_UPLOADING")
      })
    ]);

    const company: Map<string, any> = yield select(getCompany);
    const job: Map<string, any> = yield select(getJob);
    const candidate: Map<string, any> = yield select(getCandidate);
    const question: Map<string, any> = yield select(getQuestion);
    const sessionsFilterData: Map<string, any> = yield select(
      getSessionsFilterData
    );
    const sessionDurationCount: Map<string, any> = yield select(
      getsessionDurationCount
    );

    let params = {
      event: "Video Record", // string
      message:
        "Uploading Video for question id = " + question.get("id") + " . . . ",
      status: "offline" // string
    };
    Logger.recordEvent(params);

    frontEndRunTime(
      sessionDurationCount.get("front_end_run_time"),
      sessionRunTimeWatcher,
      true
    );
    const duration_used =
      sessionDurationCount.get("duration_left") -
      sessionDurationCount.get("front_end_run_time");

    yield put({ type: "SECTIONS_REDUCE_DURATION_CANCEL" });

    yield put.resolve(sendAstronautQnASessionEvent("Video Upload"));
    yield put.resolve(
      questionsVideoUpload(
        company.get("id"),
        job.get("id"),
        candidate.get("id"),
        question.get("id"),
        sessionCode,
        sessionsFilterData.get("token"),
        duration_used
      )
    );

    frontEndRunTime(
      sessionDurationCount.get("front_end_run_time"),
      sessionRunTimeWatcher,
      false
    );

    yield put({
      type: "SECTIONS_REDUCE_DURATION_REQUEST",
      sessionCode: sessionCode
    });

    yield all([
      put({
        type: "INTERVIEWS_ADD_UI_UPLOAD_STATUS_SUCCESS",
        upload_status: "success"
      }),
      put({
        type: "INTERVIEWS_ADD_UI_UPLOAD_TITLE_SUCCESS",
        upload_title: t(translations, "INTERVIEW_DATA_YOUR_ANSWER_SUCCESS")
      }),
      call(questionsFinish, question.get("id"), sessionCode)
    ]);

    yield put.resolve(sendAstronautQnASessionEvent("Video Uploaded"));

    params = {
      event: "Video Record", // string
      message:
        "Video Uploaded to /api/v2/video/upload for question id : " +
        question.get("id"),
      status: "offline" // string
    };
    Logger.recordEvent(params);
  } catch (error) {
    let params = {
      event: "Video Record", // string
      message: "Error On Uploading " + error, // string
      status: "offline"
    };

    yield put({
      type: "SECTIONS_REDUCE_DURATION_REQUEST",
      sessionCode: sessionCode
    });

    if (error == "corrupt") {
      Logger.recordEvent(params);

      yield put.resolve(sendAstronautQnASessionEvent("Video Upload Failed"));

      yield all([
        put({
          type: "INTERVIEWS_ADD_UI_UPLOAD_STATUS_SUCCESS",
          upload_status: "failed"
        }),
        put({
          type: "INTERVIEWS_ADD_UI_UPLOAD_TITLE_SUCCESS",
          upload_title: t(translations, "INTERVIEW_DATA_YOUR_ANSWER_CORRUPT")
        })
      ]);
    } else {
      Logger.recordEvent(params);

      yield put.resolve(sendAstronautQnASessionEvent("Video Upload Failed"));

      yield all([
        put({
          type: "INTERVIEWS_ADD_UI_UPLOAD_STATUS_SUCCESS",
          upload_status: "failed"
        }),
        put({
          type: "INTERVIEWS_ADD_UI_UPLOAD_TITLE_SUCCESS",
          upload_title: t(translations, "INTERVIEW_DATA_YOUR_ANSWER_FAILED")
        })
      ]);
    }
  }
}

export function* interviewsResetQuestionAttempt({
  sessionCode
}: Object): Generator<*, *, *> {
  try {
    let params = {
      event: "Video Record", // string
      message: "Reset Question Attempt ",
      status: "offline" // string
    };
    Logger.recordEvent(params);

    const candidate: Map<string, any> = yield select(getCandidate);
    const question: Map<string, any> = yield select(getQuestion);
    const sessionsFilterData: Map<string, any> = yield select(
      getSessionsFilterData
    );

    const response: Object = yield call(
      questionsResetAttemptApi,
      sessionsFilterData.get("invite_id"),
      candidate.get("id"),
      question.get("id"),
      sessionCode,
      true
    );

    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: "INTERVIEWS_ADD_FILTER_IS_NO_SOUND_SUCCESS",
        is_no_sound: true
      });

      yield put({ type: "INTERVIEWS_REDUCE_QUESTION_DURATION_CANCEL" });
    } else {
      throw response;
    }
  } catch (error) {
    let params = {
      event: "Video Record", // string
      message: "Reset Question Attempt Fail Because " + error, // string
      status: "offline"
    };
    Logger.recordEvent(params);

    console.error(error);
  }
}

export function* interviewsReduceQuestionDurationWatcher({
  sessionCode
}: Object): Generator<*, *, *> {
  const backgroundSyncTask = yield fork(
    interviewsReduceQuestionDuration,
    sessionCode
  );

  yield take("INTERVIEWS_REDUCE_QUESTION_DURATION_CANCEL");

  yield cancel(backgroundSyncTask);
}

export function* interviewsStartPracticeSound({
  start
}: Object): Generator<*, *, *> {
  try {
    window.soundRecorder.start();
    yield put({
      type: "INTERVIEWS_PRACTICE_SOUND_START_SUCCESS",
      is_record: start
    });
    const interviewPracticeSound: Map<string, any> = yield select(
      getinterviewPracticeSound
    );

    const channel = yield call(
      countdownPractice,
      5,
      interviewPracticeSound.get("is_record")
    );
    var secondsZero;
    try {
      while (true) {
        const seconds = yield take(channel);
        yield put({
          type: "INTERVIEWS_PRACTICE_SOUND_DURATION_SUCCESS",
          duration: seconds
        });
      }
    } finally {
      // console.log("masuk finally");
      window.soundRecorder.stop();
      // document.getElementById("js-sound-check-recorder").pause();
      yield put({
        type: "INTERVIEWS_PRACTICE_SOUND_IS_DONE_SUCCESS",
        is_done: true
      });
      yield put({
        type: "INTERVIEWS_PRACTICE_SOUND_START_SUCCESS",
        is_record: false
      });
      yield put({
        type: "INTERVIEWS_PRACTICE_SOUND_DURATION_SUCCESS",
        duration: 0
      });
      channel.close();
    }
  } catch (error) {
    console.log(error);
  }
}
