import { all, takeLatest } from "redux-saga/effects";
import { sendAstronautQnASessionEvent } from "./embedded-interview";

import {
  mcqsStart,
  mcqsGDPR,
  mcqsAnswerQuestion,
  mcqsStartWelcomeVideo
} from "./mcqs";

import {
  ftqStart,
  ftqGDPR,
  ftqAnswerQuestion,
  ftqStartWelcomeVideo,
  ftqSubmitQuestion
} from "./ftq";

import { candidateUpdate } from "./candidates";

import {
  sectionsStartSession,
  sectionsStartSessionWelcomeVideo,
  sectionsGDPR,
  sectionsStartInterview,
  sectionsStartMcq,
  sectionsStartFtq,
  sectionsAnswerQuestion,
  sectionsAnswerTextQuestion,
  sectionsReduceDurationWatcher,
  sectionsReducePreparationWatcher,
  sectionsDecideReduceTimer,
  sectionsStartNextInterviewQuestion,
  sectionsStartNextMcqSession,
  sectionsStartNextFtqSession,
  sectionsAnswerQuestionSend,
  sectionsAnswerQuestionOnShowAll
} from "./sections";

import {
  interviewsStartSession,
  interviewsStartWelcomeVideo,
  interviewsCheckGDPR,
  interviewsStartRecording,
  interviewsStartSessionPractice,
  interviewsReduceCountdownDurationtothree,
  interviewsStartRecordingPractice,
  interviewsStartUpload,
  interviewsStartNextQuestion,
  interviewsRetake,
  interviewsResetQuestionAttempt,
  interviewsReduceCountdownDuration,
  interviewsReduceQuestionDurationWatcher,
  interviewsStartPracticeSound
} from "./interviews";

import {
  sessionsFetchInitialData,
  sessionsReduceDurationLeft,
  sessionsFinishCustom,
  sessionsAddIsWatchedOnState
} from "./sessions";

import {
  ecourseGetJobDetail,
  ecourseRegistration,
  ecourseAuth,
  ecourseAuthStatus,
  ecourseValidateEmailOnUserRegisterWizardForm,
  ecourseLogin
} from "./ecourse";

import {
  // Angga added middleware questionsReduceDurationWatcher that will handle for this questiondurationleft
  // questionsReduceDurationLeft,
  questionsGetDetailOnStartedTest,
  questionsAddAnswers,
  questionsAddAnswersOnAllPages,
  questionsAddTextAnswers,
  questionsAddMaxLength,
  questionsGetPrevOrNextId,
  questionsGetDetailOnSection,
  questionsSetMaxLength,
  questionsReduceDurationWatcher
} from "./questions";

export default function* root() {
  yield all([
    takeLatest("MCQS_START_REQUEST", mcqsStart),
    takeLatest("MCQS_START_WELCOME_VIDEO", mcqsStartWelcomeVideo),
    takeLatest("MCQS_GDPR_REQUEST", mcqsGDPR),
    takeLatest("MCQS_ANSWER_QUESTION_REQUEST", mcqsAnswerQuestion),
    takeLatest("FTQ_START_REQUEST", ftqStart),
    takeLatest("FTQ_START_WELCOME_VIDEO", ftqStartWelcomeVideo),
    takeLatest("FTQ_GDPR_REQUEST", ftqGDPR),
    takeLatest("FTQ_ANSWER_QUESTION_REQUEST", ftqAnswerQuestion),
    takeLatest("CANDIDATES_UPDATE_REQUEST", candidateUpdate),
    takeLatest("FTQ_SUBMIT_ANSWER_QUESTION_REQUEST", ftqSubmitQuestion),
    takeLatest("SECTIONS_START_SESSION_REQUEST", sectionsStartSession),
    takeLatest(
      "SECTIONS_START_SESSION_WELCOME_VIDEO_REQUEST",
      sectionsStartSessionWelcomeVideo
    ),
    takeLatest("SECTIONS_CHECK_GDPR_REQUEST", sectionsGDPR),
    takeLatest("SECTIONS_START_INTERVIEW_REQUEST", sectionsStartInterview),
    takeLatest("SECTIONS_START_MCQ_REQUEST", sectionsStartMcq),
    takeLatest("SECTIONS_START_FTQ_REQUEST", sectionsStartFtq),
    takeLatest("SECTIONS_ANSWER_QUESTION_REQUEST", sectionsAnswerQuestion),
    takeLatest(
      "SECTIONS_ANSWER_QUESTION_ON_SHOW_ALL_REQUEST",
      sectionsAnswerQuestionOnShowAll
    ),
    takeLatest(
      "SECTIONS_ANSWER_QUESTION_SEND_REQUEST",
      sectionsAnswerQuestionSend
    ),
    takeLatest(
      "SECTIONS_ANSWER_TEXT_QUESTION_REQUEST",
      sectionsAnswerTextQuestion
    ),
    takeLatest(
      "SECTIONS_REDUCE_DURATION_REQUEST",
      sectionsReduceDurationWatcher
    ),
    takeLatest(
      "SECTIONS_REDUCE_PREPARATION_REQUEST",
      sectionsReducePreparationWatcher
    ),
    takeLatest(
      "SECTIONS_DECIDE_REDUCE_TIMER_REQUEST",
      sectionsDecideReduceTimer
    ),
    takeLatest(
      "SECTIONS_START_NEXT_INTERVIEW_QUESTION_REQUEST",
      sectionsStartNextInterviewQuestion
    ),
    takeLatest(
      "SECTIONS_START_NEXT_MCQ_SESSION_REQUEST",
      sectionsStartNextMcqSession
    ),
    takeLatest(
      "SECTIONS_START_NEXT_FTQ_SESSION_REQUEST",
      sectionsStartNextFtqSession
    ),
    takeLatest("INTERVIEWS_START_SESSION_REQUEST", interviewsStartSession),
    takeLatest(
      "INTERVIEWS_START_WELCOME_VIDEO_REQUEST",
      interviewsStartWelcomeVideo
    ),
    takeLatest("INTERVIEWS_CHECK_GDPR_REQUEST", interviewsCheckGDPR),
    takeLatest("INTERVIEWS_START_RECORDING_REQUEST", interviewsStartRecording),
    takeLatest(
      "INTERVIEWS_START_SESSION_PRACTICE_REQUEST",
      interviewsStartSessionPractice
    ),
    takeLatest(
      "INTERVIEWS_START_RECORDING_PRACTICE_REQUEST",
      interviewsStartRecordingPractice
    ),
    takeLatest("INTERVIEWS_START_UPLOAD_REQUEST", interviewsStartUpload),
    takeLatest(
      "INTERVIEWS_START_NEXT_QUESTION_REQUEST",
      interviewsStartNextQuestion
    ),
    takeLatest("INTERVIEWS_RETAKE_REQUEST", interviewsRetake),
    takeLatest(
      "INTERVIEWS_RESET_QUESTION_ATTEMPT_REQUEST",
      interviewsResetQuestionAttempt
    ),
    takeLatest(
      "INTERVIEWS_REDUCE_COUNTDOWN_DURATION_REQUEST",
      interviewsReduceCountdownDuration
    ),
    takeLatest(
      "INTERVIEWS_REDUCE_QUESTION_DURATION_REQUEST",
      interviewsReduceQuestionDurationWatcher
    ),
    takeLatest(
      "INTERVIEWS_REDUCE_COUNTDOWN_DURATION_REQUEST_TO_THREE",
      interviewsReduceCountdownDurationtothree
    ),
    takeLatest(
      "INTERVIEWS_START_PRACTICE_RECORDING_SOUND",
      interviewsStartPracticeSound
    ),
    takeLatest("SESSIONS_FETCH_INITIAL_DATA_REQUEST", sessionsFetchInitialData),
    takeLatest(
      "SESSIONS_REDUCE_DURATION_LEFT_REQUEST",
      sessionsReduceDurationLeft
    ),
    takeLatest(
      "SESSIONS_ADD_IS_WATCHED_ON_STATE_INFORMATION",
      sessionsAddIsWatchedOnState
    ),
    takeLatest(
      "QUESTIONS_GET_DETAIL_ON_STARTED_TEST_REQUEST",
      questionsGetDetailOnStartedTest
    ),
    takeLatest(
      "QUESTIONS_REDUCE_DURATION_LEFT_REQUEST",
      questionsReduceDurationWatcher
    ),
    takeLatest("QUESTIONS_ADD_ANSWERS_REQUEST", questionsAddAnswers),
    takeLatest(
      "QUESTIONS_ADD_ANSWERS_REQUEST_ON_ALL_PAGES",
      questionsAddAnswersOnAllPages
    ),
    takeLatest("QUESTIONS_ADD_ANSWERS_TEXT_REQUEST", questionsAddTextAnswers),
    takeLatest("QUESTIONS_ADD_MAX_LENGTH_REQUEST", questionsAddMaxLength),
    takeLatest(
      "QUESTIONS_GET_PREV_OR_NEXT_ID_REQUEST",
      questionsGetPrevOrNextId
    ),
    takeLatest(
      "QUESTIONS_GET_DETAIL_ON_SECTION_REQUEST",
      questionsGetDetailOnSection
    ),
    // takeLatest(
    //   "QUESTIONS_REDUCE_DURATION_REQUEST",
    //   questionsReduceDurationWatcher
    // ),
    takeLatest("SEND_EVENT_EMBEDDED_INTERVIEW", sendAstronautQnASessionEvent),
    takeLatest("INTERVIEWS_FINISH_BY_TRIGGER", sessionsFinishCustom),
    takeLatest("ECOURSE_GET_DETAIL_JOB", ecourseGetJobDetail),
    takeLatest("ECOURSE_REGISTRATION_SUCCESS", ecourseRegistration),
    takeLatest("ECOURSE_AUTH", ecourseAuth),
    takeLatest("ECOURSE_AUTH_STATUS", ecourseAuthStatus),
    takeLatest(
      "ECOURSE_VALIDATE_EMAIL_ON_USER_REGISTER_WIZARD_FORM_REQUEST",
      ecourseValidateEmailOnUserRegisterWizardForm
    ),
    takeLatest("ECOURSE_LOGIN_ACCESS", ecourseLogin)
  ]);
}
