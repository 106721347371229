import React, { Fragment } from "react";
import {
  Field,
  reduxForm,
  change,
  formValueSelector,
  stopAsyncValidation,
  touch
} from "redux-form";
import { withRouter } from "react-router";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import Header from "../../lib/elements/Header";
import ModalDialog from "../../lib/elements/ModalDialog";
import Button from "../../lib/elements/Button";
import IconCeklis from "../../images/icon-ceklis-ungu.png";
import bindAll from "lodash/bindAll";
import {
  ecourseGetJobDetail,
  ecourseRegistration,
  ecourseValidateEmailOnUserRegisterWizardFormRequest
} from "../../actions/ecourse";
import { sessionsFetchInitialDataRequest } from "../../actions/sessions";
import { List, Map, fromJS } from "immutable";
import validate from "./validateReg";
import Swal from "sweetalert2";
import $ from "jquery";

const normalizeEmail = (value, previousValue) => {
  return value.trim();
};

class RegisterPage extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      isConsent: false,
      isProcess: false,
      isBlocking: false,
      isModal: false,
      hasUpper: false,
      hasLower: false,
      hasNumber: false,
      hasEightChar: false
    };
    bindAll(this, [
      "handleSaveData",
      "renderError",
      "renderField",
      "renderEmailError",
      "inputFocus",
      "inputBlur",
      "handleConsentClick",
      "handleRedirect",
      "handleChange",
      "onUnload",
      "handleChangeEmail",
      "letsGo",
      "checkPasswordPolicy"
    ]);
  }

  handleChangeEmail(e) {
    const {
      ecourseValidateEmailOnUserRegisterWizardFormRequest,
      jobCode
    } = this.props;
    this.handleChange(e);
    ecourseValidateEmailOnUserRegisterWizardFormRequest(
      e.target.value.trim(),
      jobCode
    );
  }

  onUnload(e) {
    e.preventDefault();
    e.returnValue = "";
  }

  componentDidUpdate() {
    const { isBlocking } = this.state;
    if (isBlocking) {
      window.addEventListener("beforeunload", this.onUnload);
    } else {
      window.removeEventListener("beforeunload", this.onUnload);
    }
  }

  handleChange(e) {
    if (e.target.name == "password") {
      this.checkPasswordPolicy(e.target.value);
    }

    var isBlocking = false;
    $("form input").each(function() {
      if (this.value !== "") {
        isBlocking = true;
        return false;
      }
    });
    this.setState({ isBlocking: isBlocking });
  }

  handleRedirect() {
    const { redirect, jobCode } = this.props;
    // redirect(`/code/${`rmnwiDhF`}/not-interested`)
    redirect(`/login/${jobCode}`);
  }

  handleConsentClick(e) {
    if (e.target.name == "gdpr") {
      this.setState({ isConsent: !this.state.isConsent });
    }
  }

  handleSaveData(values) {
    const {
      ecourseRegistration,
      ecourseJobDetail,
      jobCode,
      questionId,
      subFeature,
      redirect,
      sessionsFetchInitialDataRequest
    } = this.props;

    this.setState({ isProcess: true });

    var ecourseDetail = ecourseJobDetail.toJS();
    const __values: Map<string, any> = fromJS(values);

    const _values: Map<string, any> = __values.merge({
      ecourse_id: ecourseDetail.course.job_id
    });

    ecourseRegistration(ecourseDetail.course.job_id, _values.toJS()).then(
      () => {
        // this.setState({ isModal: true });
        window.removeEventListener("beforeunload", this.onUnload);

        Swal({
          type: "success",
          confirmButtonText: "MASUK",
          confirmButtonColor: "#5B63DB",
          allowOutsideClick: false,
          confirmButtonClass: "button",
          buttonsStyling: false,
          title: "BERHASIL!",
          text: "Kamu telah teregistrasi kursus " + ecourseDetail.course.title
        }).then(function(willReload) {
          if (willReload.value) {
            window.location = `/${jobCode}`;
            // sessionsFetchInitialDataRequest(
            //   jobCode,
            //   questionId,
            //   subFeature,
            //   "",
            //   true
            // ).then(() => {
            //   redirect(`/${jobCode}`);
            // });
          }
        });
      }
    );
  }

  letsGo() {
    const {
      jobCode,
      redirect,
      sessionsFetchInitialDataRequest,
      questionId,
      subFeature
    } = this.props;
    sessionsFetchInitialDataRequest(
      jobCode,
      questionId,
      subFeature,
      "",
      true
    ).then(() => {
      redirect(`/${jobCode}`);
    });
  }

  checkPasswordPolicy(val) {
    let hasUpper = /^.*(?=.*[A-Z]).*$/.test(val);
    let hasLower = /^.*(?=.*[a-z]).*$/.test(val);
    let hasNumber = /^.*(?=.*\d).*$/.test(val);
    let hasEightChar = val.length >= 8 ? true : false;

    this.setState({
      hasLower: hasLower,
      hasUpper: hasUpper,
      hasNumber: hasNumber,
      hasEightChar: hasEightChar
    });
  }

  renderError({ meta: { touched, error } }) {
    // const {
    // 	translationjob
    // } = this.props
    // const translations : Map<string, any> = fromJS(translationjob.translation)
    return touched && error ? (
      <div className="error">
        <span className="error normal" id="js-err-msg">
          {error}
        </span>
      </div>
    ) : (
      false
    );
  }

  renderEmailError({ isValidatingEmail, meta: { touched, error } }) {
    // const {
    // 	translationjob
    // } = this.props
    // const translations : Map<string, any> = fromJS(translationjob.translation)

    return (
      <Fragment>
        {touched && error && (
          <div className="error">
            <div dangerouslySetInnerHTML={{ __html: error }}></div>
          </div>
        )}
        {isValidatingEmail && (
          <div className="help-block">Checking your email...</div>
        )}
      </Fragment>
    );
  }

  renderField({ input, placeholder, type, meta: { touched, error } }) {
    return (
      <input
        {...input}
        type={type}
        placeholder={placeholder}
        className={touched && error ? "error" : ""}
      />
    );
  }

  inputFocus(e: object): void {
    // const {publicJobCustom}= this.props
    e.target.style.boxShadow = `0 0 10px ${"#554AE4"}`;
    e.target.style.border = `1px solid ${"#554AE4"}`;
    e.target.style.borderRadius = `5px`;
  }

  inputBlur(e: object): void {
    e.target.style.border = `1px solid #999999`;
    e.target.style.borderRadius = ``;
    e.target.style.boxShadow = ``;
  }

  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      ecourseJobDetail,
      asyncValidating
    } = this.props;

    const { isConsent, isProcess } = this.state;
    const { company } = this.props;

    var ecourseDetail = ecourseJobDetail.toJS();
    let data_provider;
    let data_course;
    let provider = "";
    let course = "";
    let logoUrl = "";
    let jobId = "";
    let registerPage = <div></div>;
    if (ecourseJobDetail.size > 0) {
      data_provider = ecourseDetail.provider;
      data_course = ecourseDetail.course;
      provider = data_provider.title;
      course = data_course.title;
      logoUrl = data_provider.logoUrl;

      registerPage = (
        <Fragment>
          <Header
            provider={provider}
            course={course}
            position={"Pendaftaran"}
            location={"bandung"}
            logoCourse={logoUrl}
            type="register"
          />
          <div className="container max-width-550 m-t-50">
            <section id="section-registration" className="registration">
              <div className="flex-container flex-between vertical-item--center">
                <p className="label-ask">Sudah punya akun Kognisi?</p>
                <div
                  onClick={this.handleRedirect}
                  className="a-action heading4"
                >
                  Login
                </div>
              </div>
              <div className="div-separator-or m-t-30">
                <span className="span-separator-or">atau daftar akun baru</span>
              </div>
              <div className="div-apply-now m-t-40">
                <p
                  className={"text-center uppercase"}
                  style={{ color: "#000000" }}
                >
                  {"Form Pendaftaran"}
                </p>

                <hr style={{ borderColor: "#000000" }} />
              </div>
              <form
                onSubmit={handleSubmit(this.handleSaveData)}
                id="form-registration"
                className="vertical-form"
              >
                <div className="form-group">
                  <Field name="company_id" type="hidden" component="input" />
                  <Field name="job_id" type="hidden" component="input" />
                  <Field
                    name="interview_code"
                    type="hidden"
                    component="input"
                  />
                  <label>
                    {"Nama Lengkap"}
                    <span className="required-star">*</span>
                  </label>
                  <Field
                    name="fullname"
                    type="text"
                    component={this.renderField}
                    onChange={this.handleChange}
                    placeholder={"Nama Lengkap"}
                  />
                  <Field name="fullname" component={this.renderError} />
                </div>
                <div className="form-group">
                  <label>
                    {"Nama Panggilan"}
                    <span className="required-star">*</span>
                  </label>
                  <Field
                    name="name"
                    type="text"
                    component={this.renderField}
                    onChange={this.handleChange}
                    placeholder={"Nama Panggilan"}
                  />
                  <Field name="name" component={this.renderError} />
                </div>
                <div className="form-group">
                  <label>
                    {"Alamat Email"}
                    <span className="required-star">*</span>
                  </label>
                  <Field
                    name="email"
                    type="text"
                    component={this.renderField}
                    onChange={this.handleChangeEmail}
                    placeholder={"Alamat Email"}
                    normalize={normalizeEmail}
                  />
                  <Field
                    props={{
                      isValidatingEmail: asyncValidating
                    }}
                    name="email"
                    component={this.renderEmailError}
                  />
                </div>
                <div className="form-group">
                  <label>
                    {"Konfirmasi Alamat Email"}
                    <span className="required-star">*</span>
                  </label>
                  <Field
                    name="email2"
                    type="text"
                    component={this.renderField}
                    onChange={this.handleChange}
                    placeholder={"Konfirmasi Alamat Email"}
                    normalize={normalizeEmail}
                  />
                  <Field name="email2" component={this.renderError} />
                </div>
                <div className="form-group input-tool">
                  <label>
                    {"Kata Sandi"}
                    <span className="required-star">*</span>
                  </label>
                  <span className="pass-req-mobile">
                    Minimal 8 karakter, terdiri dari angka, huruf kapital dan
                    huruf kecil.
                  </span>
                  <Field
                    name="password"
                    type="password"
                    onBlur={this.inputBlur}
                    placeholder={"Kata Sandi"}
                    component={this.renderField}
                    onChange={this.handleChange}
                  />
                  <span className="tooltiptext">
                    Kata sandi harus memiliki panjang minimal 8 karakter,
                    terdiri dari angka, huruf kapital dan huruf kecil.
                    <span
                      className={`pass-req ${
                        this.state.hasEightChar ? "active" : ""
                      }`}
                    >
                      <i className="far fa-check-circle"></i>minimal 8 karakter
                    </span>
                    <span
                      className={`pass-req ${
                        this.state.hasNumber ? "active" : ""
                      }`}
                    >
                      <i className="far fa-check-circle"></i>berisi angka
                    </span>
                    <span
                      className={`pass-req ${
                        this.state.hasUpper ? "active" : ""
                      }`}
                    >
                      <i className="far fa-check-circle"></i>berisi huruf
                      kapital
                    </span>
                    <span
                      className={`pass-req ${
                        this.state.hasLower ? "active" : ""
                      }`}
                    >
                      <i className="far fa-check-circle"></i>berisi huruf kecil
                    </span>
                  </span>
                  <Field name="password" component={this.renderError} />
                </div>
                <div className="form-group">
                  <label>
                    {"Konfirmasi Kata Sandi"}
                    <span className="required-star">*</span>
                  </label>
                  <Field
                    name="password2"
                    type="password"
                    onBlur={this.inputBlur}
                    placeholder={"Konfirmasi Kata Sandi"}
                    component={this.renderField}
                  />
                  <Field name="password2" component={this.renderError} />
                </div>
                <div className="form-group">
                  <label>
                    {"Nomor Telepon"}
                    <span className="required-star">*</span>
                  </label>
                  <Field
                    name="phone"
                    type="text"
                    component={this.renderField}
                    onBlur={this.inputBlur}
                    placeholder={"Nomor Telepon"}
                  />
                  <Field name="phone" component={this.renderError} />
                </div>
                <div className="form-group">
                  <label>
                    {"Jenis Kelamin"}
                    <span className="required-star">*</span>
                  </label>

                  <div className="form-group" style={{ marginBottom: "5px" }}>
                    <Field
                      name="sex"
                      type="radio"
                      onChange={this.handleChange}
                      placeholder={"Jenis Kelamin"}
                      component="input"
                      value="L"
                    />
                    <span className="m-l-15 label-radio">{`Pria`}</span>
                  </div>

                  <div className="form-group sm m-t-5">
                    <Field
                      name="sex"
                      type="radio"
                      onChange={this.handleChange}
                      placeholder={"Jenis Kelamin"}
                      component="input"
                      value="P"
                    />
                    <span className="m-l-15 label-radio">{`Wanita`}</span>
                  </div>
                  <Field name="sex" component={this.renderError} />
                </div>

                <div className="form-group">
                  <label>
                    {"Organisasi/Institusi"}
                    <span className="required-star">*</span>
                  </label>
                  <Field
                    name="organization"
                    type="text"
                    component={this.renderField}
                    onChange={this.handleChange}
                    placeholder={"Organisasi/Institusi"}
                    component="input"
                  />
                  <Field name="organization" component={this.renderError} />
                </div>

                <div className="form-group">
                  <label>
                    {"Bidang Pekerjaan"}
                    <span className="required-star">*</span>
                  </label>
                  <Field
                    name="field_work"
                    type="text"
                    component={this.renderField}
                    onChange={this.handleChange}
                    placeholder={"Bidang Pekerjaan"}
                    component="input"
                  />
                  <Field name="field_work" component={this.renderError} />
                </div>
                {/*
                    <div className="form-group hidden">
                        <div className="col-md-10 p-0"><label>What of experience do you expect to gain at <a href="https://astrnt.co" target="_blank"><b style={{color:customColor}}>Astronaut</b></a>?</label></div>
                        <div className="col-md-2 text-right p-0"><label>{countText}<font className="normal">/1000</font></label></div>
                        <textarea
                            name="experience"
                            onFocus={this.inputFocus}
                            onBlur={this.inputBlur}
                            value={formValues.experience}
                            onChange={this.handleChangeTextArea}
                            placeholder="What of experience do you expect to gain at Astronaut?"
                            className="form-control h-100"
                        />

                        <Field
                            name="experience"
                            component="textarea"
                            placeholder="What of experience do you expect to gain at Astronaut?"
                            className="form-control h-100 hidden" />
                        <Field name="experience" component={this.renderError}/>
                    </div> */}

                <div
                  className={`form-group main-gdpr-fluid ${
                    isConsent ? "is-on" : ""
                  }`}
                >
                  <label className="label-gdpr-text">
                    {
                      "Saya mengetahui bahwa kemungkinan saya akan menerima pemberitahuan atau permintaan untuk memberikan umpan balik mengenai produk dari Astronaut dan juga saya mengerti bahwa saya dapat meminta untuk tidak memilih komunikasi ini kapan saja."
                    }
                  </label>
                  <label className="inline-content normal checkbox-container m-b-15">
                    <Field
                      name="gdpr"
                      type="checkbox"
                      onClick={this.handleConsentClick}
                      component="input"
                      checked={isConsent}
                      className="btn-checkbox m-r-10"
                    />
                    {
                      "Saya berusia lebih dari 16 tahun & menyetujui pernyataan di atas."
                    }
                    <span className="checkmark"></span>
                  </label>
                  {/* {publicJobDetail.compliance?
                        <label className="inline-content normal checkbox-container" style={{whiteSpace: 'pre-wrap'}}>
                            <Field
                                    name="compliance"
                                    type="checkbox"
                                    onClick={()=>{return}}
                                    component="input"
                                    checked={true}
                                    className="btn-checkbox m-r-10" />{'ini gdpr harus diisi dari mana isi nya bodo amat'}<span className="checkmark"></span>

                        </label>
                        :
                        null} */}
                </div>

                <center className="m-t-30">
                  {/* <p className="label-click-continue" dangerouslySetInnerHTML={{__html: t(translations, 'LABEL_CONTINUE_DESC')}}/> */}
                  <Button
                    id="btn-submit"
                    type="submit"
                    className={`button button__primary button--medium button-submit-course uppercase ${
                      isConsent ? "is-on" : ""
                    }`}
                    disabled={!isConsent || isProcess}
                    buttonLoading={isProcess}
                  >
                    Daftar
                  </Button>
                </center>
              </form>
            </section>
          </div>

          {/* <ModalDialog
            isOpen={!this.state.isModal}
            title={`BERHASIL!`}
            text={`Kamu telah teregistrasi kursus`}
          >
            <Button
              size="small"
              className={`btn-start-session`}
              onClick={this.letsGo}
            >
              MASUK
            </Button>
          </ModalDialog> */}
        </Fragment>
      );
    }

    return registerPage;
  }
}

RegisterPage = reduxForm({
  form: "RegisterPage",
  enableReinitialize: true,
  validate
})(RegisterPage);

const initialValues = {
  fullname: "",
  sex: "L"
};
const selector = formValueSelector("RegisterPage");
const mapStateToProps = (state, ownProps) => {
  return {
    initialValues,
    company: state.company,
    formValues: selector(state, "fullname"),
    jobCode: ownProps.location.pathname.split("/")[2],
    ecourseJobDetail: state.ecourseJobDetail,
    questionId: ownProps.location.pathname.split("/")[4],
    subFeature: ownProps.location.pathname.split("/")[3],
    location_url: ownProps.location.search
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeFieldValue: (field, value) =>
      dispatch(change("RegisterPage", field, value, true, true)),
    touchForm: field => dispatch(touch("RegisterPage", field)),
    stopAsyncValidationForm: object =>
      dispatch(stopAsyncValidation("RegisterPage", object)),
    ecourseGetJobDetail: jobCode => dispatch(ecourseGetJobDetail(jobCode)),
    ecourseRegistration: (jobCode, values) =>
      dispatch(ecourseRegistration(jobCode, values)),
    redirect: link => dispatch(push(link)),
    sessionsFetchInitialDataRequest: (
      sessionCode,
      questionId,
      subFeature,
      token,
      isJob
    ) =>
      dispatch(
        sessionsFetchInitialDataRequest(
          sessionCode,
          questionId,
          subFeature,
          token,
          isJob
        )
      ),
    ecourseValidateEmailOnUserRegisterWizardFormRequest: (email, jobCode) =>
      dispatch(
        ecourseValidateEmailOnUserRegisterWizardFormRequest(email, jobCode)
      )
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisterPage)
);
