// @flow

import React from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import { t } from '../../helpers/global'

import blockedChrome from '../../images/blocked-chrome.gif'

type Props = {
  translations: Map<string, any>
};

class AllowDeviceChrome extends React.Component<Props> {
  render() {
    const { translations } = this.props

    return(
      <div className="interview__header">
        <div className="interview__error">
          <div className="m-t-40 m-b-15">{t(translations, 'ERROR_PAGE_WE_HAVE_PROBLEM')}</div>
          <div className="m-b-10">
            <p>{t(translations, 'ERROR_PAGE_WE_WANT_TO_PROCEED')} <br /> {t(translations, 'ERROR_PAGE_YOUR_CAMERA')}</p>
          </div>
          <div className="m-t-30 m-b-20">
            <img
              src={blockedChrome}
              className="img-responsive"
              alt="" />
          </div>
          <div>
            <p>{t(translations, 'ERROR_PAGE_TO_FIX_CHROME')}<br />
            {t(translations, 'ERROR_PAGE_THEN_SELECT')} <b>"Always allow candidate.astrnt.co to access camera and microphone"</b></p>
            <p className="m-t-25">{t(translations, 'ERROR_PAGE_LETS_DOIT')}</p>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations
  }
}

export default connect(mapStateToProps, null)(AllowDeviceChrome)
