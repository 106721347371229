// @flow

import React, { Fragment } from "react";
import ImageWithZoom from "../../lib/elements/ImageWithZoom";
import ImageWithModal from "../../lib/elements/ImageWithModal";

type Props = {
  image: string,
  alt: string,
  text: string,
  className: string
};

class QuestionContentMcqAll extends React.Component<Props> {
  static defaultProps = {
    image: "",
    text: "",
    alt: "",
    className: ""
  };

  render() {
    const { image, text, alt, className, indexquestion, id } = this.props;
    var txtquestion = { __html: text.replace(/(?:\r\n|\r|\n)/g, "<br/>") };
    return (
      <div className={`p-16 border-top-question-mcq-all ${className}`}>
        {image ? (
          <ImageWithModal
            imageUrl={image}
            alt={alt}
            className="img__question__ftq"
            classQuestion="img-question"
          />
        ) : null}

        <p id={id} className="heading5 table-cell">
          {`${indexquestion}.`}{" "}
        </p>
        <p
          className="heading5 table-cell p-l-5"
          dangerouslySetInnerHTML={txtquestion}
        />
      </div>
    );
  }
}

export default QuestionContentMcqAll;
