// @flow

import React from 'react'
import Dots from './Dots'

type Props = {
  label: string,
  type: string,
};

class DotsWithLabel extends React.Component<Props> {
  static defaultProps = {
    type: 'default',
    label: ''
  }

  render() {
    const {
      label,
      type
    } = this.props

    return (
      <p className="heading6 uppercase"><Dots type={type} className="m-r-8" />{label}</p>
    )

  }
}

export default DotsWithLabel
