export function ftqStartRequest(sessionCode) {
  return {
    type: "FTQ_START_REQUEST",
    sessionCode
  };
}

export function ftqStartWelcomeVideoRequest(sessionCode) {
  return {
    type: "FTQ_START_WELCOME_VIDEO",
    sessionCode
  };
}

export function ftqAnswerQuestionRequest(sessionCode) {
  return {
    type: "FTQ_ANSWER_QUESTION_REQUEST",
    sessionCode
  };
}

export function ftqSubmitQuestionRequest(sessionCode) {
  return {
    type: "FTQ_SUBMIT_ANSWER_QUESTION_REQUEST",
    sessionCode
  };
}

export function ftqGDPRRequest(sessionCode) {
  return {
    type: "FTQ_GDPR_REQUEST",
    sessionCode
  };
}
