import React, { Fragment } from "react";
import {
  Field,
  reduxForm,
  change,
  formValueSelector,
  stopAsyncValidation,
  touch
} from "redux-form";
import { connect } from "react-redux";
import Header from "../../lib/elements/Header";
import bindAll from "lodash/bindAll";
import {
  ecourseLogin,
  redirectCourse,
  login,
  ecourseResetPassword
} from "../../actions/ecourse";

import validate from "./validateLogin";

const normalizeEmail = (value, previousValue) => {
  return value.trim();
};

class ResetPage extends React.Component<Props> {
  constructor(props) {
    super(props);

    bindAll(this, ["handleSaveData", "renderError", "inputFocus", "inputBlur"]);
  }

  handleSaveData(values) {
    const {
      ecourseResetPassword,
      jobCode,
      redirect,
      ecourseJobDetail
    } = this.props;

    if (ecourseJobDetail.size > 0) {
      var ecourseDetail = ecourseJobDetail.toJS();
      var data_course = ecourseDetail.course;
      ecourseResetPassword(values.email, jobCode, data_course.title);
    }
  }

  renderError({ meta: { touched, error } }) {
    // const {
    // 	translationjob
    // } = this.props
    // const translations : Map<string, any> = fromJS(translationjob.translation)
    return touched && error ? (
      <div>
        <span className="error normal" id="js-err-msg">
          {error}
        </span>
      </div>
    ) : (
      false
    );
  }

  inputFocus(e: object): void {
    // const {publicJobCustom}= this.props
    e.target.style.boxShadow = `0 0 10px ${"red"}`;
    e.target.style.border = `1px solid ${"red"}`;
    e.target.style.borderRadius = `5px`;
  }

  inputBlur(e: object): void {
    e.target.style.border = `1px solid #999999`;
    e.target.style.borderRadius = ``;
    e.target.style.boxShadow = ``;
  }

  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      ecourseJobDetail
    } = this.props;
    var ecourseDetail = ecourseJobDetail.toJS();

    let data_provider;
    let data_course;
    let provider = "";
    let course = "";
    let logoUrl = "";
    let jobId = "";
    let resetPage = <div></div>;

    if (ecourseJobDetail.size > 0) {
      data_provider = ecourseDetail.provider;
      data_course = ecourseDetail.course;
      provider = data_provider.title;
      course = data_course.title;
      logoUrl = data_provider.logoUrl;

      resetPage = (
        <Fragment>
          <Header
            provider={provider}
            course={course}
            position={"Pendaftaran"}
            location={"bandung"}
            logoCourse={logoUrl}
            type="register"
          />
          <div className="container max-width-550 m-t-50">
            <section id="section-registration" className="registration">
              <div className="div-apply-now">
                <p
                  className={"text-center uppercase"}
                  style={{ color: "#000000" }}
                >
                  {"Permintaan penggantian Kata Sandi"}
                </p>

                <hr style={{ borderColor: "#000000" }} />
              </div>
              <div className={"text-center course-reset-text m-b-30"}>
                {
                  "Masukkan email yang telah terdaftar. Kami akan mengirimkan email yang berisi instruksi untuk melakukan penggantian kata sandi."
                }
              </div>
              <form
                onSubmit={handleSubmit(this.handleSaveData)}
                id="form-registration"
                className="vertical-form"
              >
                <div className="form-group">
                  <label>{"Email"}</label>
                  <Field
                    name="email"
                    type="text"
                    onFocus={this.inputFocus}
                    onBlur={this.inputBlur}
                    placeholder={"Email"}
                    component="input"
                    normalize={normalizeEmail}
                  />
                  <Field name="email" component={this.renderError} />
                </div>
                {/*
                                <div className="form-group hidden">
                                    <div className="col-md-10 p-0"><label>What of experience do you expect to gain at <a href="https://astrnt.co" target="_blank"><b style={{color:customColor}}>Astronaut</b></a>?</label></div>
                                    <div className="col-md-2 text-right p-0"><label>{countText}<font className="normal">/1000</font></label></div>
                                    <textarea
                                        name="experience"
                                        onFocus={this.inputFocus}
                                        onBlur={this.inputBlur}
                                        value={formValues.experience}
                                        onChange={this.handleChangeTextArea}
                                        placeholder="What of experience do you expect to gain at Astronaut?"
                                        className="form-control h-100"
                                    />

                                    <Field
                                        name="experience"
                                        component="textarea"
                                        placeholder="What of experience do you expect to gain at Astronaut?"
                                        className="form-control h-100 hidden" />
                                    <Field name="experience" component={this.renderError}/>
                                </div> */}

                <center className="m-t-30">
                  {/* <p className="label-click-continue" dangerouslySetInnerHTML={{__html: t(translations, 'LABEL_CONTINUE_DESC')}}/> */}
                  <button
                    id="btn-submit"
                    type="submit"
                    className={`button button-primary button--medium button-submit-course uppercase is-on`}
                    disabled={false}
                  >
                    Kirim Email
                  </button>
                </center>
              </form>
            </section>
          </div>
        </Fragment>
      );
    }

    return resetPage;
  }
}

ResetPage = reduxForm({
  form: "ResetPage",
  enableReinitialize: true,
  validate
})(ResetPage);

const initialValues = {
  password: ""
};
const selector = formValueSelector("ResetPage");
const mapStateToProps = (state, ownProps) => {
  return {
    initialValues,
    formValues: selector(state, "email"),
    ecourseJobDetail: state.ecourseJobDetail,
    jobCode: ownProps.match.params.id
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeFieldValue: (field, value) =>
      dispatch(change("ResetPage", field, value, true, true)),
    touchForm: field => dispatch(touch("ResetPage", field)),
    stopAsyncValidationForm: object =>
      dispatch(stopAsyncValidation("ResetPage", object)),
    ecourseResetPassword: (email, jobCode, courseTitle) =>
      dispatch(ecourseResetPassword(email, jobCode, courseTitle)),
    redirect: link => dispatch(redirectCourse(link))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPage);
