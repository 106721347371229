// @flow

import React, { Fragment } from 'react'

type Props = {
  total: string | number,
  className: string,
};

class RelativeTime extends React.Component<Props> {

  static defaultProps = {
    total: '2 min',
    className: '',
  }

  render() {
    const {
      total,
      className
    } = this.props

    return (
      <Fragment>
        <span className={className}>
          {total}
        </span>
      </Fragment>
    )
  }
}

export default RelativeTime
