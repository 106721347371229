import $ from "jquery";
import configurations from "./misc/configurations";

const validate = values => {
  const errors = {};

  if (!values.email || $.trim(values.email) === "") {
    errors.email = `Email tidak boleh kosong`;
  }

  if (!values.password || $.trim(values.password) === "") {
    errors.password = `Password tidak boleh kosong`;
  }

  if (values.email && !configurations.emailRegex.test(values.email)) {
    errors.email = "Alamat email tidak valid";
  }

  return errors;
};

export default validate;
