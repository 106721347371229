// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Map } from "immutable";
import bindAll from "lodash/bindAll";
import classnames from "classnames";
import logoWhite from "../../images/logo-white.png";
import logoKognisi from "../../images/logo-kognisi-final.png";
import cogButton from "../../images/cog.svg";
import initializers from "../../dependencies/initializers";
import { t } from "../../helpers/global";
import authToken from "../../dependencies/authStore";

const headerType = {
  deafult: "default",
  primary: "primary",
  secondary: "secondary",
  form_profile: "form_profile",
  register: "register"
};

type Props = {
  company: string,
  job: string,
  position: string,
  location: string,
  className: string,
  type: string,
  translations: Map<string, any>
};

class Header extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {};
    bindAll(this, ["logout"]);
  }

  static defaultProps = {
    company: "",
    job: "",
    position: "",
    location: "",
    className: "",
    type: "default"
  };

  logout() {
    authToken.remove();
    window.location = "/" + this.props.redirectCode;
  }

  render() {
    const {
      className,
      provider,
      course,
      position,
      location,
      type,
      fullDataCompany,
      translations,
      logoCourse,
      learner,
      token
    } = this.props;

    const classProps = classnames("header__session", className);
    var host;
    if (initializers.api_host == "https://beta.astrnt.co") {
      host = 746;
    } else if (initializers.api_host == "https://app.astrnt.co") {
      host = 1526;
    } else {
      host = 261;
    }

    let learnerName, learnerEmail, partnersUrl;
    if (learner) {
      learnerName = learner.get("fullname");
      learnerEmail = learner.get("email");
      partnersUrl = learner.get("partners_url");
    }

    let headerComponent: Object = (
      <header className={classProps}>
        <img
          src={logoWhite}
          alt="Astronaut Technologies Q&amp;A"
          className="navbar-brand img-responsive p-0"
        />
      </header>
    );

    if (headerType[type] === "primary") {
      let hideIcon = token === null || token === "" ? "hide" : "";
      let loginStatus = token === null || token === "" ? "" : "width-info";

      headerComponent = (
        <Fragment>
          <header
            className={`large ${classProps} horizontal-item--between header-desktop-hide`}
          >
            <img src={logoKognisi} alt="Kognisi" className="navbar-brand p-0" />
            <div className="vertical-item--center">
              <div className="inline text-right">
                <h1 className="heading4 bold">{provider}</h1>
                <div className="vertical-item--center">
                  <h2 className="heading4">{course}</h2>
                </div>
              </div>
              <div className={`inline text-right m-l-40 ${hideIcon}`}>
                <div className="dropdown">
                  <a data-toggle="dropdown">
                    <img src={cogButton} />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right text-right">
                    <span className="dropdown-item bold" href="#">
                      {learnerName}
                    </span>
                    <span className="dropdown-item" href="#">
                      {learnerEmail}
                    </span>
                    <hr style={{ width: "90%" }} />
                    {partnersUrl ? (
                      ""
                    ) : (
                      <a
                        className="dropdown-item bold"
                        href="#"
                        onClick={this.logout}
                        style={{ fontSize: "14px" }}
                      >
                        Keluar
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </header>
          <header className={`small ${classProps} header-mobile-hide`}>
            <img src={logoKognisi} alt="Kognisi" className="navbar-brand p-0" />
            <div className="vertical-item--center header-info-logout">
              <div className={`inline text-right ${loginStatus}`}>
                <h1 className="heading6 bold">{provider}</h1>
                <div className="vertical-item--center">
                  <h2 className="heading6" style={{ fontSize: "12px" }}>
                    {course}
                  </h2>
                </div>
              </div>
              <div className={`inline text-right width-dropdown ${hideIcon}`}>
                <div className="dropdown">
                  <a data-toggle="dropdown">
                    <img src={cogButton} />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right text-right">
                    <span className="dropdown-item bold" href="#">
                      {learnerName}
                    </span>
                    <span className="dropdown-item" href="#">
                      {learnerEmail}
                    </span>
                    <hr style={{ width: "90%" }} />
                    <a
                      className="dropdown-item bold"
                      href="#"
                      onClick={this.logout}
                      style={{ fontSize: "14px" }}
                    >
                      Keluar
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </Fragment>
      );
    } else if (headerType[type] === "secondary") {
      headerComponent = (
        <header className={classProps}>
          <h1 className="heading3">
            {t(translations, "PAGE_TITLE", {
              ":company_name": provider
            })}
          </h1>
        </header>
      );
    } else if (headerType[type] === "form_profile") {
      headerComponent = (
        <header className={classProps}>
          <h1
            className="heading2"
            style={{ fontWeight: "normal", fontSize: "24" }}
          >
            {`Make your video profile`}
          </h1>
        </header>
      );
    } else if (headerType[type] === "register") {
      headerComponent = (
        <Fragment>
          <header className={`large ${classProps} card-desktop-hide`}>
            <img src={logoCourse} alt="Kognisi" className="navbar-brand p-0" />
            <div className="float-right text-right">
              <h1 className="heading3">{provider}</h1>
              <div className="vertical-item--center">
                <h2 className="heading4">{course}</h2>
              </div>
            </div>
          </header>
          <header
            className={`small ${classProps} header-mobile-hide horizontal-item--between`}
          >
            <img src={logoCourse} alt="Kognisi" className="navbar-brand p-0" />
            <div className="inline text-right" style={{ maxWidth: "65%" }}>
              <h1 className="heading6 bold">{provider}</h1>
              <div className="vertical-item--center">
                <h2 className="heading6">{course}</h2>
              </div>
            </div>
          </header>
        </Fragment>
      );
    }

    return <Fragment>{headerComponent}</Fragment>;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations,
    fullDataCompany: state.company
  };
};

export default connect(mapStateToProps, null)(Header);
