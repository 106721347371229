import Swal from "sweetalert2";
import _axios from "./_axios";

export function checkConnection(arrayErrorMsg) {
  var start = new Date().getTime();

  const timeout = new Promise((resolve, reject) => {
    setTimeout(reject, 200000, "Request timed out");
  });

  var datastring = getRandomString(2);

  let fd = new FormData();
  fd.append("text", datastring);

  const request = _axios({
    url: "/api/v2/interview/uploadSpeed",
    method: "post",
    timeout: 30000,
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: fd
  });

  return Promise.race([timeout, request])
    .then(response => {
      let end = new Date().getTime();
      let time = end - start;

      let uploadTime = time / 1000.0;
      console.log(16 / uploadTime + "Mbps");
      if (16 / uploadTime <= 0.5) {
        throw uploadTime;
      }
    })
    .catch(error => {
      console.log(error);
      // let params = {
      //   event: "Check Connection", // string
      //   message: "No Connection on Candidates ...",
      //   status: "offline"
      // };
      // Logger.recordEvent(params);

      Swal({
        type: "warning",
        buttons: true,
        showCancelButton: true,
        confirmButtonText: arrayErrorMsg.buttonError,
        confirmButtonColor: "#FFFFFF",
        cancelButtonColor: "#5B63DB",
        allowOutsideClick: false,
        confirmButtonClass: "button button__secondary m-r-20",
        cancelButtonClass: "button",
        buttonsStyling: false,
        cancelButtonText: arrayErrorMsg.retryError,
        title: arrayErrorMsg.titleError,
        text: arrayErrorMsg.messageError,
        onOpen: function() {
          Swal.disableConfirmButton();
        }
      }).then(function(willReload) {
        if (willReload.value) {
          return true;
        } else {
          window.location.reload(true);
        }
      });

      throw error;
    });
}

function getRandomString(sizeInMb) {
  var chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+`-=[]{}|;':,./<>?", //random data prevents gzip effect
    iterations = sizeInMb * 1024 * 1024, //get byte count
    result = "";
  for (var index = 0; index < iterations; index++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}
