// @flow
import React from "react";
import Card from "./Card";
import Content from "./Content";
import ContentSoundCheck from "./ContentSoundCheck";
import Button from "../elements/Button";
import Loader from "../elements/Loader";
import MicrophoneTester from "../../lib/elements/MicrophoneTester";
import VideoSample from "../../images/video-sample.mp4";
import moment from "moment";
import "moment/locale/id";

type Props = {
  contentTitle: string,
  contentImage: string,
  contentImageAlt: string,
  contentText: string,
  className: string,
  buttonName: string,
  loaderName?: string,
  loaderClassName?: string,
  onClick: Function,
  isLoading: boolean
};

moment().locale("id");

function timeStamp(date) {
  var stillUtc = moment.utc(date).toDate();
  var local = moment(stillUtc)
    .local()
    .format("YYYY-MM-DD HH:mm:ss");
  return moment(local).fromNow();
}

class CardTopicUlasan extends React.Component<Props> {
  static defaultProps = {
    contentTitle: "",
    contentText: "",
    contentImage: "",
    contentImageAlt: "",
    className: "",
    buttonName: "",
    onClick: () => {},
    isLoading: false
  };

  render() {
    const {
      contentTitle,
      contentImage,
      contentImageAlt,
      contentText,
      className,
      buttonName,
      onClick,
      loaderName,
      loaderClassName,
      isLoading,
      reviewsData,
      reviewsPagination,
      reviewsRating,
      open_all
    } = this.props;

    let render_review = [];
    let render_open_all;
    if (reviewsData.size == 0) {
      render_review = (
        <div className="list-rating no-data">
          {/* <div className="flex-container flex-between">
                                    <div>
                                      <div className="bold">{review.get("fullname")}</div>
                                      <div>2 bulan lalu</div>
                                    </div>
                                    <div className="rating-score">
                                      <span>{review.get("rating")}</span>/10
                                    </div>
                                  </div> */}
          <div className="m-t-10">
            Belum ada ulasan.
            <br />
            Jadilah yang pertama memberikan ulasan
          </div>
        </div>
      );
    } else {
      reviewsData.map((review, index) => {
        if (index < 3) {
          render_review.push(
            <div key={index} className="list-rating">
              <div className="flex-container flex-between">
                <div>
                  <div className="bold">
                    {review.get("is_hide_review_name") == 1
                      ? review.get("fullname").replace(/(?!^)[\S](?!$)/g, "*")
                      : review.get("fullname")}
                  </div>
                  <div>
                    {timeStamp(review.get("candidate_finish_interview_time"))}
                  </div>
                </div>
                <div className="rating-score">
                  <span>{review.get("rating")}</span>/10
                </div>
              </div>
              <div className="m-t-5">{review.get("feedback")}</div>
            </div>
          );
        }
      });
    }

    if (reviewsData.size > 3) {
      render_open_all = (
        <div className="div-lihat-semua" onClick={open_all}>
          Lihat Semua <i className="fa fa-chevron-right" />
        </div>
      );
    }

    return (
      <Card theme="desc" className={className}>
        <div className="card-body p-0 card-desktop-hide">
          <h4 className="heading3 bold m-b-8">Ulasan</h4>
          <div className="div-ulasan">
            <div className="div-rating">
              <div className="kotak-rating horizontal-item--center">
                {reviewsRating.get("average_rating")}
              </div>
              <div className="m-t-5">
                {reviewsPagination.get("total")} Ulasan
              </div>
            </div>
            <div
              className={`div-rating-list ${
                reviewsData.size == 0 ? "no-data" : ""
              }`}
            >
              {render_review}

              {render_open_all}
            </div>
          </div>
        </div>
        <div className="card-body p-0 card-mobile-hide">
          <h4 className="heading3 bold m-b-8">Ulasan</h4>
          <div className="div-ulasan">
            <div className="div-rating-mobile">
              <div className="kotak-rating horizontal-item--center">
                {reviewsRating.get("average_rating")}
              </div>
              <div className="m-l-16 vertical-item--center">
                ({reviewsPagination.get("total")} Ulasan)
              </div>
            </div>
            <div
              className={`div-rating-list ${
                reviewsData.size == 0 ? "no-data" : ""
              }`}
            >
              {render_review}

              {render_open_all}
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

export default CardTopicUlasan;
