// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Map } from "immutable";
import Card from "./Card";
import Button from "../../lib/elements/Button";
import imgProcessingProgress from "../../images/processing-progress.png";
import imgProcessLoading from "../../images/process-loading.png";
import imgProcessFailed from "../../images/process-failed.png";
import imgProcessSuccess from "../../images/process-success.png";

import { t } from "../../helpers/global";

type Props = {
  className: string,
  companyTitle: string,
  translations: Map<string, any>,
  jobTitle: string
};

class CardProcessingProgress extends React.Component<Props> {
  static defaultProps = {
    className: "",
    companyTitle: "",
    jobTitle: ""
  };

  render() {
    const {
      className,
      translations,
      companyTitle,
      jobTitle,
      questions,
      question_answered_ids,
      errorCounter,
      handleSubmitClick
    } = this.props;

    var buttonDisabled;
    var processNotComplete;

    if (question_answered_ids.size >= questions.size) {
      buttonDisabled = false;
      processNotComplete = false;
    } else {
      buttonDisabled = true;
      processNotComplete = true;
    }

    if (errorCounter) {
      buttonDisabled = false;
    }

    let errorMessage: Object =
      errorCounter && processNotComplete ? (
        <div className="info-container flex-container">
          <div className="div-ico inline text-left p-0">
            <img src={imgProcessFailed} />
          </div>
          <div className="div-info inline text-left p-0">
            It seems one or more of your uploaded videos are corrupted. Please
            contact us at <b>help@astrnt.co</b> to get some help from our team.
          </div>
        </div>
      ) : (
        <div></div>
      );

    let number = 1;
    let processingProgressComponent: Object = (
      <Fragment>
        {questions.map((question, index) => (
          <Fragment>
            <div className="div-num inline p-0 text-left">
              {index + number}.
            </div>
            <div className="div-q inline text-left p-0" key={index}>
              {question.title}
            </div>
            {question_answered_ids.contains(question.id) ? (
              <div className="div-ico inline text-right p-0">
                <img src={imgProcessSuccess} />
              </div>
            ) : errorCounter ? (
              <div className="div-ico inline text-right p-0">
                <img src={imgProcessFailed} />
              </div>
            ) : (
              <div className="div-ico inline text-right p-0">
                <img className="spinning" src={imgProcessLoading} />
              </div>
            )}
          </Fragment>
        ))}
      </Fragment>
    );

    let videoSuccessCounter: Object =
      question_answered_ids.size + "/" + questions.size;

    return (
      <Card theme="verify" className={className}>
        <div className="card-body m-b-40">
          <img
            src={imgProcessingProgress}
            className="card-image"
            alt="Congratulations"
          />
          <h2 className="card-title">VERIFYING YOUR VIDEOS</h2>
          <hr></hr>
          <div className="row justify-content-center">
            <div className="col-sm-9">
              <div className="card-text m-t-24 p-b-10">
                <div className="q-container flex-container">
                  {processingProgressComponent}
                </div>

                {/*example data, if imgProcessLoading is used then className="spinning" is a must to spin the icon */}
                {/* <div className="q-container flex-container">
                  <div className="div-num inline p-0 text-left">2.</div>
                  <div className="div-q inline text-left p-0">
                    Why are you applying for this role?
                  </div>
                  <div className="div-ico inline text-right p-0">
                    <img className="spinning" src={imgProcessLoading} />
                  </div>
                </div>
                <div className="q-container flex-container">
                  <div className="div-num inline p-0 text-left">3.</div>
                  <div className="div-q inline text-left p-0">
                    What questions do you have about this opportunity?
                  </div>
                  <div className="div-ico inline text-right p-0">
                    <img src={imgProcessSuccess} />
                  </div>
                </div> */}
                {/*example data*/}
              </div>
              <div className="card-text m-t-24 p-b-10 flex-container flex-between bold-important">
                <span>Video Processed</span>
                <span>{videoSuccessCounter}</span>
              </div>
              {errorMessage}
            </div>
          </div>
          <Button
            theme="primary"
            disabled={buttonDisabled}
            className="m-t-30 m-b-30 bold"
            onClick={handleSubmitClick}
          >
            Next
          </Button>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    translations: state.translations
  };
};

export default connect(mapStateToProps, null)(CardProcessingProgress);
