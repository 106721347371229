// @flow

import React from 'react'
import classnames from 'classnames'

type Props = {
  label: string,
  type: string,
  className: string,
};

const alertType = {
  light: 'alert--light'
}

class Alert extends React.Component<Props> {

  static defaultProps = {
    type: 'light',
    label: '',
    className: '',
  }

  render() {
    const {
      label,
      type,
      className
    } = this.props

    const classProps:string = classnames(
      alertType[type],
      'alert--top',
      'horizontal-item--center',
      className
    )

    return(
      <div className={classProps}>
        <h5 className="text normal">{label}</h5>
      </div>
    )
  }
}

export default Alert
