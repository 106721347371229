// @flow

import React from 'react'
import Slider from 'react-slick'
import BoardingItem from './BoardingItem'

type Props = {
  handleToggleModal: Function,
  items: Array<Object>,
};

class CarouselBoardingItem extends React.Component<Props> {

  static defaultProps = {
    items: [],
    handleToggleModal: () => {},
  }

  render() {
    const {
      items,
      handleToggleModal,
    } = this.props

    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    }

    const slides = items.map((item, index) => {
      return (
        <BoardingItem
          key={index}
          index={index}
          title={item.title}
          text={item.text}
          imageUrl={item.img}
          imageAlt={item.alt}
          buttonName={item.buttonName}
          isClose={item.isClose}
          handleToggleModal={handleToggleModal} />
      )
    })

    return (
      <Slider {...settings}>
        {slides}
      </Slider>
    );
  }
}

export default CarouselBoardingItem
