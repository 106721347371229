// @flow

import React from 'react'

type Props = {
  data: string,
  className: string,
  children?: any,
};

class Duration extends React.Component<Props> {
  static defaultProps = {
      data: '',
      className: '',
      children: null,
  }

  render () {
    const {
      data,
      className,
      children
    } = this.props

    return (
      <span className={className}>
        {children}
        {data}
      </span>
    )

  }
}

export default Duration
