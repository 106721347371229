import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Switch, Router, withRouter } from "react-router";
import { Route } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import history from "./dependencies/history";
import configureStore from "./store/configureStore";
import bootstrap from "bootstrap";
import HomeIndex from "./pages/home/HomeIndex";
import McqShow from "./components/mcqs/McqShow";
import FtqShow from "./components/freetext/FtqShow";
import McqIndexWelcome from "./components/mcqs/McqIndexWelcome";
import FtqIndexWelcome from "./components/freetext/FtqIndexWelcome";
import InterviewIndexWelcome from "./components/interviews/InterviewIndexWelcome";
import InterviewPractice from "./components/interviews/InterviewPractice";
import InterviewInstruction from "./components/interviews/InterviewInstruction";
import InterviewShow from "./components/interviews/InterviewShow";
import SessionIndex from "./components/sessions/SessionIndex";
import SessionFinish from "./components/sessions/SessionFinish";
import CompletedSession from "./components/partials/CompletedSession";
import SessionVideoProcessingProgress from "./components/sessions/SessionVideoProcessingProgress";
import SectionInstruction from "./components/sections/SectionInstruction";
import SectionIndexWelcome from "./components/sections/SectionIndexWelcome";
import SectionReview from "./components/sections/SectionReview";
import NotInterested from "./components/partials/NotInterested";
import InterviewGDPRPage from "./components/partials/InterviewGDPRPage";
import McqGDPRPage from "./components/partials/McqGDPRPage";
import FtqGDPRPage from "./components/partials/FtqGDPRPage";
import SectionGDPRPage from "./components/partials/SectionGDPRPage";
import PostQnaRating from "./components/partials/PostQnaRating";
import RegisterPage from "./components/pages/RegisterPage";
import RegisterHome from "./components/pages/RegisterHome";
import LoginPage from "./components/pages/LoginPage";
import LoginHome from "./components/pages/LoginHome";
import NewPass from "./components/pages/NewPass";
import ResetPage from "./components/pages/ResetPage";
import PassChangeSuccessPage from "./components/pages/PassChangeSuccessPage";
import RequestSuccessPage from "./components/pages/RequestSuccessPage";

import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import "nprogress/nprogress.css";
import "./styles/css/style.css";
import "./styles/css/style-responsive.css";
//ada problem di home index untuk halaman login dan register navigasi yield push nya harus configure cara resolve masalah ini

export const store = configureStore();
ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <HomeIndex>
        <Switch>
          <Route exact path="/:ecourseCode" component={SessionIndex} />
          <Route path="/:sessionCode/reviews" component={SectionReview} />
          <Route exact path="/code/:sessionCode" component={SessionIndex} />
          <Route path="/code/:sessionCode/reviews" component={SectionReview} />
          <Route
            path="/code/:sessionCode/mcq-questions/:questionId"
            component={McqShow}
          />
          <Route
            path="/code/:sessionCode/ftq-questions/:questionId"
            component={FtqShow}
          />
          <Route
            path="/code/:sessionCode/interview-practice"
            component={InterviewPractice}
          />
          <Route
            path="/code/:sessionCode/interview-index-welcome"
            component={InterviewIndexWelcome}
          />
          <Route
            path="/code/:sessionCode/interview-questions/:questionId/instruction"
            component={InterviewInstruction}
          />
          <Route
            path="/code/:sessionCode/interview-questions/:questionId"
            component={InterviewShow}
          />
          <Route
            path="/code/:sessionCode/check-gdpr-interview"
            component={InterviewGDPRPage}
          />
          <Route
            path="/code/:sessionCode/check-gdpr-mcq"
            component={McqGDPRPage}
          />
          <Route
            path="/code/:sessionCode/check-gdpr-ftq"
            component={FtqGDPRPage}
          />
          <Route
            path="/code/:sessionCode/mcq-welcome-video"
            component={McqIndexWelcome}
          />
          <Route
            path="/code/:sessionCode/ftq-welcome-video"
            component={FtqIndexWelcome}
          />
          <Route
            path="/code/:sessionCode/check-gdpr-sections"
            component={SectionGDPRPage}
          />
          <Route
            path="/code/:sessionCode/post-qna-rating"
            component={PostQnaRating}
          />
          <Route
            path="/code/:sessionCode/section-welcome-video"
            component={SectionIndexWelcome}
          />
          <Route
            path="/code/:sessionCode/sections/:sectionId/instruction"
            component={SectionInstruction}
          />
          <Route
            path="/code/:sessionCode/sections/:sectionId/mcq-questions/:questionId"
            component={McqShow}
          />
          <Route
            path="/code/:sessionCode/sections/:sectionId/ftq-questions/:questionId"
            component={FtqShow}
          />
          <Route
            path="/code/:sessionCode/sections/:sectionId/interview-questions/:questionId/instruction"
            component={InterviewInstruction}
          />
          <Route
            path="/code/:sessionCode/sections/:sectionId/interview-questions/:questionId"
            component={InterviewShow}
          />
          <Route
            path="/code/:sessionCode/video/processing/progress"
            component={SessionVideoProcessingProgress}
          />
          <Route
            path="/code/:sessionCode/finish"
            component={CompletedSession}
          />
          <Route
            path="/code/:sessionCode/not-interested"
            component={NotInterested}
          />
          <Route exact path="/register/:id" component={RegisterPage} />
          <Route exact path="/login/:id" component={LoginPage} />
          <Route exact path="/new-pass/:id/:key" component={NewPass} />
          <Route path="/reset/:id" component={ResetPage} />
          <Route path="/requested/:id" component={RequestSuccessPage} />
          <Route path="/pass-success/:id" component={PassChangeSuccessPage} />
        </Switch>
      </HomeIndex>
    </Router>
  </Provider>,
  document.getElementById("root")
);
