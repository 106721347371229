// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import bindAll from "lodash/bindAll";
import { Map, List } from "immutable";
import InterviewBody from "./InterviewBody";
import PageTemplate from "../../lib/elements/PageTemplate";
import HeaderQuestionInterview from "../../lib/elements/HeaderQuestionInterview";
import CircleProgressBar from "../../lib/elements/CircleProgressBar";
import CircleCounter from "../../lib/elements/CircleCounter";
import ControlPanel from "../../lib/elements/ControlPanel";
import ControlPanelNoButton from "../../lib/elements/ControlPanelNoButton";
import ModalUploader from "../../lib/elements/ModalUploader";
import ModalDialog from "../../lib/elements/ModalDialog";
import Progress from "../../lib/elements/Progress";
import Button from "../../lib/elements/Button";
import Icon from "../../lib/elements/Icon";
import Indicator from "../../lib/elements/Indicator";
import Duration from "../../lib/elements/Duration";
import ModalLoaderInterviewCustom from "../../lib/elements/ModalLoaderInterviewCustom";
import * as Logger from "astrnt-web-logger";
import { sectionsStartNextInterviewQuestionRequest } from "../../actions/sections";
import { sendAstronautQnASessionEvent } from "../../actions/embedded-interview";

import { t } from "../../helpers/global";

import $ from "jquery";

import {
  interviewsStartRecordingRequest,
  interviewsRemoveCountdownAndStart,
  interviewsStartUploadRequest,
  interviewsStartNextQuestionRequest,
  interviewsRetakeRequest,
  interviewsReduceQuestionDurationCancel,
  interviewsAddFilterIsModalOpenSuccess,
  interviewsAddFilterIsLowSoundSuccess,
  interviewsResetQuestionAttemptRequest,
  interviewsFinishByTrigger,
  interviewsAddFilterIsModalInterviewCustomSuccess,
  interviewsAddFilterIsModalOpen
} from "../../actions/interviews";

import {
  interviewsInitRecord,
  interviewsGetQuestionDurationCircle,
  interviewsGetCountdownDurationCircle
} from "../../helpers/interviews";

import { globalBuildDurationDisplayHelper } from "../../helpers/global";

type Props = {
  question: Map<string, any>,
  translations: Map<string, any>,
  section: Map<string, any>,
  sections: List<Map<string, any>>,
  sectionsStartNextInterviewQuestionRequest: Function,
  sessionCode: string,
  sessionsFilterData: Map<string, any>,
  interviewsUiData: Map<string, any>,
  interviewsFilterData: Map<string, any>,
  interviewsStartRecordingRequest: Function,
  interviewsStartUploadRequest: Function,
  interviewsStartNextQuestionRequest: Function,
  interviewsRetakeRequest: Function,
  interviewsReduceQuestionDurationCancel: Function,
  interviewsAddFilterIsModalOpenSuccess: Function,
  interviewsAddFilterIsLowSoundSuccess: Function,
  interviewsResetQuestionAttemptRequest: Function,
  interviewsRemoveCountdownAndStart: Function
};

type State = {
  isVideoPlayerPlaying: boolean,
  countAttempButton: Number
};

const refVolumePercentage = React.createRef();

class InterviewShow extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isVideoPlayerPlaying: false,
      countAttempButton: 0
    };

    bindAll(this, [
      "resetQuestionAttemptOnInitRecord",
      "renderTimeIndicator",
      "renderUploadButton",
      "renderLowSoundIndicator",
      "handleFinishRecordingClick",
      "handleCountdownDurationClick",
      "handlePlayClick",
      "handlePauseClick",
      "handleRetakeClick",
      "handleContinueClick",
      "handleStartUploadClick",
      "handleStopRecord",
      "handleRetryClick",
      "interviewOnMount",
      "interviewUnMount",
      "handleLoaderCustom"
    ]);
  }

  interviewUnMount(e) {
    const confirmationMessage = "test";
    e.returnValue = "Hellooww";

    return confirmationMessage;
  }
  interviewOnMount(e) {
    const { section, sections, sessionsFilterData, question } = this.props;

    var jsonsections = sections.toJS();
    var jsonsection = section.toJS();
    var section_position;
    var question_position;
    if (!sessionsFilterData.get("duration_left")) {
      var eventLocation = "Section Info";
    } else {
      var eventLocation = "Section Info Continue";
    }

    jsonsections.map((value, key) =>
      value.id === section.get("id") ? (section_position = key + 1) : null
    );

    var params = {
      event: eventLocation, // string
      message: "Clicked Close or Reload button on section " + section_position,
      status: "online" // string
    };

    if (
      this.props.history.location.pathname.split("/").slice(2)[1] ===
      "instruction"
    ) {
      eventLocation = "Section Instruction";
    } else {
      switch (this.props.history.location.pathname.split("/").slice(-2)[0]) {
        case "interview-questions":
          var question_position;
          jsonsection.question_ids.map((value, key) =>
            value ===
            parseInt(
              this.props.history.location.pathname.split("/").slice(-2)[1]
            )
              ? (question_position = key + 1)
              : null
          );
          eventLocation = "Section Video Question";
          params = {
            event: eventLocation, // string
            message:
              "Clicked Close or Reload button on section = (" +
              section_position +
              ") on question = (" +
              question_position +
              ")",
            status: "offline"
          };

          break;
      }
    }

    Logger.recordEvent(params);
    const confirmationMessage = "test";
    e.returnValue = "Hellooww";
    return confirmationMessage;
  }

  handlePlayClick(e) {
    e.preventDefault();

    const { sendAstronautQnASessionEvent } = this.props;

    sendAstronautQnASessionEvent("Play Video");

    // $FlowFixMe
    document.getElementById("js-interview-video-player-recorded").play();

    this.setState({ isVideoPlayerPlaying: true });

    let params = {
      event: "Section Video Question", // string
      message: "Play Video",
      status: "offline"
    };

    Logger.recordEvent(params);
  }

  handlePauseClick(e) {
    e.preventDefault();

    const { sendAstronautQnASessionEvent } = this.props;

    sendAstronautQnASessionEvent("Pause Video");

    // $FlowFixMe
    document.getElementById("js-interview-video-player-recorded").pause();

    this.setState({ isVideoPlayerPlaying: false });
    let params = {
      event: "Section Video Question", // string
      message: "Paused Video",
      status: "offline"
    };

    Logger.recordEvent(params);
  }

  handleRetakeClick(e) {
    e.preventDefault();

    const { sendAstronautQnASessionEvent } = this.props;

    sendAstronautQnASessionEvent("Retake Recording");

    const {
      sessionCode,
      interviewsRetakeRequest,
      interviewsAddFilterIsLowSoundSuccess
    } = this.props;

    interviewsRetakeRequest(sessionCode);

    // $FlowFixMe
    document.getElementById("js-interview-video-player-recorded").pause();

    interviewsInitRecord(
      refVolumePercentage,
      this.handleStopRecord
      // interviewsAddFilterIsLowSoundSuccess,
      // () => this.resetQuestionAttemptOnInitRecord()
    );

    this.setState({ isVideoPlayerPlaying: false });

    let params = {
      event: "Section Video Question", // string
      message: "Retake Video",
      status: "offline"
    };

    Logger.recordEvent(params);
  }

  handleStartUploadClick(e) {
    e.preventDefault();

    const {
      sessionCode,
      interviewsStartUploadRequest,
      sendAstronautQnASessionEvent,
      interviewsAddFilterIsModalOpen
    } = this.props;

    if (this.props.interviewsUiData.get("upload_status") == "failed") {
      //this 1 for retry modal button u can see on renderupload()
      let params = {
        event: "Video Info", // string
        message: "Retry Click Button uploading",
        status: "offline" // string
      };

      Logger.recordEvent(params);
    } else {
      // console.log("clicked done button");
      // this one else because no needed happen on render retry button or it will make confused
      let params = {
        event: "Video Question",
        message:
          "Attempt ended by button Done video to start upload video clicked",
        status: "offline"
      };

      Logger.recordEvent(params);
    }
    interviewsAddFilterIsModalOpen(false).then(() => {
      interviewsStartUploadRequest(sessionCode);
    });
  }

  handleContinueClick(e) {
    e.preventDefault();

    const {
      interviewsStartNextQuestionRequest,
      sectionsStartNextInterviewQuestionRequest,
      sessionCode,
      sessionsFilterData,
      sendAstronautQnASessionEvent
    } = this.props;

    sendAstronautQnASessionEvent("Continue Next Question");

    if (sessionsFilterData.get("type") === "close section") {
      // console.log("close section");
      sectionsStartNextInterviewQuestionRequest(sessionCode);
    } else {
      // console.log("!close section");
      interviewsStartNextQuestionRequest(sessionCode);
    }
  }

  handleCountdownDurationClick(e) {
    e.preventDefault();

    const {
      sessionCode,
      interviewsFilterData,
      interviewsStartRecordingRequest,
      interviewsRemoveCountdownAndStart,
      sendAstronautQnASessionEvent
    } = this.props;

    if (interviewsFilterData.get("countdown_duration") > 3) {
      if (!interviewsFilterData.get("is_counting")) {
        console.log("clicked yang !iscounting");
        this.setState({ countAttempButton: 0 });
        interviewsStartRecordingRequest(sessionCode, true);

        let params = {
          event: "Section Video Question", // string
          message:
            "Start Capturing Video Attempt left " +
            interviewsFilterData.get("question_takes_count"),
          status: "offline" // string
        };

        Logger.recordEvent(params);
      } else {
        if (this.state.countAttempButton < 1) {
          interviewsStartRecordingRequest(sessionCode, false);
        }

        this.setState({ countAttempButton: this.state.countAttempButton + 1 });
      }
    }
  }

  handleFinishRecordingClick(e) {
    e.preventDefault();

    const {
      question,
      translations,
      interviewsFilterData,
      interviewsReduceQuestionDurationCancel,
      sendAstronautQnASessionEvent
    } = this.props;

    sendAstronautQnASessionEvent("Finish Recording");

    const questionDurationCircle: Map<
      string,
      any
    > = interviewsGetQuestionDurationCircle(
      interviewsFilterData.get("question_duration"),
      question.get("maxTime"),
      t(translations, "LABEL_FINISH")
    );

    if (questionDurationCircle.get("isShowFinish")) {
      interviewsReduceQuestionDurationCancel();
    }
  }

  handleLoaderCustom(e) {
    const {
      interviewsFinishByTrigger,
      interviewsAddFilterIsModalInterviewCustomSuccess,
      sessionCode
    } = this.props;

    const modal_fire = {
      isOpen: false
    };

    interviewsAddFilterIsModalInterviewCustomSuccess(modal_fire);
  }

  componentDidMount() {
    const { sessionsFilterData } = this.props;

    $("body").bind("cut copy paste", function(e) {
      e.preventDefault();
    });

    //Disable mouse right click
    $("body").on("contextmenu", function(e) {
      return false;
    });

    document.onkeydown = function(e) {
      if (e.keyCode == 123) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == "I".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == "C".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == "J".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == "U".charCodeAt(0)) {
        return false;
      }
    };

    interviewsInitRecord(
      refVolumePercentage,
      this.handleStopRecord
      // this.props.interviewsAddFilterIsLowSoundSuccess,
      // () => this.resetQuestionAttemptOnInitRecord()
    );
    var eventLocation;
    // window.addEventListener("beforeunload", this.interviewOnMount);

    if (sessionsFilterData.get("type") === "interview") {
      if (!sessionsFilterData.get("duration_left")) {
        eventLocation = "Video Info";
      } else {
        eventLocation = "Video Info Continue";
      }
    } else {
      if (!sessionsFilterData.get("duration_left")) {
        eventLocation = "Section Video Info";
      } else {
        eventLocation = "'Section Video Info Continue";
      }
    }

    let params = {
      event: eventLocation, // string
      message: "Enter Video Question",
      status: "offline" // string
    };

    Logger.recordEvent(params);
  }

  resetQuestionAttemptOnInitRecord() {
    const {
      sessionCode,
      interviewsFilterData,
      interviewsResetQuestionAttemptRequest
    } = this.props;

    if (
      interviewsFilterData.get("countdown_duration") === 0 &&
      interviewsFilterData.get("question_duration") > 5
    ) {
      interviewsResetQuestionAttemptRequest(sessionCode);
    }

    let params = {
      event: "Section Video Question", // string
      message: "Reset Attemp On Init Record",
      status: "offline"
    };

    Logger.recordEvent(params);
  }

  componentWillUnmount() {
    // window.removeEventListener("beforeunload", this.interviewUnMount);

    let params = {
      event: "Video Info", // string
      message: "Leave Video Question",
      status: "offline" // string
    };

    Logger.recordEvent(params);

    window.checkLowSoundSpeechEvents.stop();
    window.checkNoSoundSpeechEvents.stop();
    window.speechEvents.stop();
  }

  handleStopRecord() {
    const {
      sessionCode,
      interviewsFilterData,
      interviewsStartUploadRequest,
      question
    } = this.props;

    // console.log(interviewsFilterData.toJS());

    const blob = new Blob(window.recordedBlobs, { type: "video/webm" });
    // console.log(interviewsFilterData.get("question_takes_count"))
    if (interviewsFilterData.get("question_takes_count") == "0") {
      let params = {
        event: "Video Question",
        message:
          "No Attemp left video uploaded directly because no attempt left ",
        status: "offline"
      };

      Logger.recordEvent(params);
    } else {
      let params = {
        event: "Video Question",
        message:
          "Video stopped with " +
          interviewsFilterData.get("question_takes_count") +
          " attempt left ",
        status: "offline"
      };

      Logger.recordEvent(params);
    }
    // $FlowFixMe
    document.getElementById(
      "js-interview-video-player-recorded"
    ).src = URL.createObjectURL(blob);

    if (
      !interviewsFilterData.get("question_takes_count") &&
      !interviewsFilterData.get("is_no_sound")
    ) {
      interviewsStartUploadRequest(sessionCode);
    }

    let params = {
      event: "Section Video Question", // string
      message: "Video Record Stopped On Question Id : " + question.get("id"),
      status: "offline"
    };

    Logger.recordEvent(params);
  }

  handleRetryClick(e) {
    e.preventDefault();

    const { question, sessionCode } = this.props;

    window.location.href = `${
      window.location.origin
    }/code/${sessionCode}/interview-questions/${question.get(
      "id"
    )}/instruction`;
  }

  renderUploadButton() {
    const { translations } = this.props;
    // console.log(this.props.interviewsUiData.get("upload_status"));
    switch (this.props.interviewsUiData.get("upload_status")) {
      case "success":
        return (
          <center className="m-t-24">
            <Button
              type="button"
              theme="primary"
              size="medium"
              onClick={this.handleContinueClick}
            >
              {t(translations, "LABEL_CONTINUE")}
            </Button>
          </center>
        );
      case "failed":
        return (
          <center className="m-t-24">
            <Button
              type="button"
              theme="danger"
              size="medium"
              onClick={this.handleStartUploadClick}
            >
              {t(translations, "LABEL_RETRY")}
            </Button>
          </center>
        );
      default:
        return <Fragment />;
    }
  }

  renderTimeIndicator() {
    const { section, sections, sessionsFilterData, translations } = this.props;

    if (sessionsFilterData.get("type") === "close section") {
      let durationDisplay: string = "00:00";

      if (section.get("duration")) {
        durationDisplay = globalBuildDurationDisplayHelper(
          section.get("duration")
        );
      }

      let indicatorTheme: string = "success";

      if (section.get("duration") <= 5) {
        indicatorTheme = "danger";
      }

      return (
        <Indicator layout="between" theme={indicatorTheme}>
          <div>
            <b>
              {t(translations, "SECTION_LABEL")} {section.get("index") + 1}
            </b>{" "}
            {t(translations, "LABEL_OF")} {sections.size}
          </div>
          <Duration data={durationDisplay} className="bold">
            <Icon
              type="icon"
              name="icon-clock"
              align="bottom"
              className="m-r-8"
            />
          </Duration>
        </Indicator>
      );
    }
  }

  renderLowSoundIndicator() {
    const { translations, interviewsFilterData } = this.props;

    if (interviewsFilterData.get("is_low_sound")) {
      return (
        <Indicator layout="center" theme="danger" isTextUppercase={false}>
          {t(translations, "INTERVIEW_DATA_LOW_SOUND")}
        </Indicator>
      );
    }
  }

  render() {
    const {
      question,
      translations,
      interviewsUiData,
      interviewsFilterData
    } = this.props;

    const questionDurationCircle: Map<
      string,
      any
    > = interviewsGetQuestionDurationCircle(
      interviewsFilterData.get("question_duration"),
      question.get("maxTime"),
      t(translations, "LABEL_FINISH")
    );

    const modalLoaderData = interviewsFilterData
      .get("modal_loader_data")
      .toJS();
    // console.log(questionDurationCircle.toJS())
    const countdownDurationCircle: Map<
      string,
      any
    > = interviewsGetCountdownDurationCircle(
      interviewsFilterData.get("countdown_duration"),
      t(translations, "LABEL_START"),
      question.get("prepTime")
    );
    console.log(interviewsFilterData.get("question_takes_count"));
    return (
      <Fragment>
        <PageTemplate interviewMode>
          {this.renderTimeIndicator()}
          {this.renderLowSoundIndicator()}
          <HeaderQuestionInterview
            refVolumePercentage={refVolumePercentage}
            type={interviewsUiData.get("recording_dot_type")}
            label={interviewsUiData.get("recording_title")}
            questionText={interviewsUiData.get("question_title")}
          />
          {interviewsFilterData.get("is_finished") ? (
            [
              this.props.interviewsUiData.get("upload_status") == "default" ? (
                <ControlPanel
                  isPlaying={this.state.isVideoPlayerPlaying}
                  takesCount={interviewsFilterData.get("question_takes_count")}
                  handlePlayClick={this.handlePlayClick}
                  handlePauseClick={this.handlePauseClick}
                  handleRetakeClick={this.handleRetakeClick}
                  handleDoneClick={this.handleStartUploadClick}
                />
              ) : (
                <ControlPanelNoButton
                  isPlaying={this.state.isVideoPlayerPlaying}
                  takesCount={interviewsFilterData.get("question_takes_count")}
                  handlePlayClick={this.handlePlayClick}
                  handlePauseClick={this.handlePauseClick}
                  handleRetakeClick={this.handleRetakeClick}
                  handleDoneClick={this.handleStartUploadClick}
                />
              )
            ]
          ) : (
            <div className="footer-video horizontal-item--center">
              {interviewsFilterData.get("countdown_duration") ? (
                [
                  !interviewsFilterData.get("is_counting") ? (
                    <CircleCounter
                      handleCountdownDurationClick={
                        this.handleCountdownDurationClick
                      }
                      percentage={countdownDurationCircle.get("percentage")}
                      classForPercentage={countdownDurationCircle.get(
                        "classForPercentage"
                      )}
                      textForPercentage={t(translations, "LABEL_START")}
                    />
                  ) : (
                    [
                      interviewsFilterData.get("countdown_duration") <= 3 ? (
                        <CircleCounter
                          handleCountdownDurationClick={
                            this.handleCountdownDurationClick
                          }
                          percentage={countdownDurationCircle.get("percentage")}
                          classForTransparent={"after-three"}
                          classForPercentage={countdownDurationCircle.get(
                            "classForPercentage"
                          )}
                          textForPercentage=""
                        />
                      ) : (
                        <Button
                          onClick={this.handleCountdownDurationClick}
                          type="button"
                          theme="text"
                          className="button-start-now"
                          size="medium"
                        >
                          {t(translations, "LABEL_START_NOW")}
                        </Button>
                      )
                    ]
                  )
                ]
              ) : (
                <CircleProgressBar
                  handleFinishRecordingClick={this.handleFinishRecordingClick}
                  percentage={questionDurationCircle.get("percentage")}
                  classForPercentage={questionDurationCircle.get(
                    "classForPercentage"
                  )}
                  textForPercentage={questionDurationCircle.get(
                    "textForPercentage"
                  )}
                />
              )}
            </div>
          )}
          <InterviewBody />
        </PageTemplate>
        <ModalLoaderInterviewCustom
          {...modalLoaderData}
          handleClick={this.handleLoaderCustom}
        />
        <ModalUploader
          isOpen={interviewsFilterData.get("is_modal_open")}
          title={interviewsUiData.get("upload_title")}
          size="large"
        >
          <div className="progress-container">
            <Progress
              type={
                interviewsUiData.get("upload_status")
                  ? interviewsUiData.get("upload_status")
                  : "ongoing"
              }
              size="medium"
              value={interviewsUiData.get("upload_video_percentage")}
              className="m-t-12"
            />
          </div>
          {this.renderUploadButton()}
        </ModalUploader>
        {/*
          <ModalDialog
            isOpen={interviewsFilterData.get('is_no_sound')}
            title={t(translations, 'POPUP_TITLE_NO_SOUND')}
            text={t(translations, 'INTERVIEW_DATA_NO_SOUND')} >
            <Button
              type="button"
              theme="primary"
              size="small"
              onClick={this.handleRetryClick}>{t(translations, 'LABEL_RETRY')}</Button>
          </ModalDialog>
        */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    question: state.question,
    translations: state.translations,
    section: state.section,
    sections: state.sections,
    sessionCode: ownProps.match.params.sessionCode,
    sessionsFilterData: state.sessionsFilterData,
    interviewsUiData: state.interviewsUiData,
    interviewsFilterData: state.interviewsFilterData,
    question: state.question
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sectionsStartNextInterviewQuestionRequest: sessionCode =>
      dispatch(sectionsStartNextInterviewQuestionRequest(sessionCode)),
    interviewsStartRecordingRequest: (sessionCode, haltStart) =>
      dispatch(interviewsStartRecordingRequest(sessionCode, haltStart)),
    interviewsStartUploadRequest: sessionCode =>
      dispatch(interviewsStartUploadRequest(sessionCode)),
    interviewsStartNextQuestionRequest: sessionCode =>
      dispatch(interviewsStartNextQuestionRequest(sessionCode)),
    interviewsRetakeRequest: sessionCode =>
      dispatch(interviewsRetakeRequest(sessionCode)),
    interviewsReduceQuestionDurationCancel: () =>
      dispatch(interviewsReduceQuestionDurationCancel()),
    interviewsAddFilterIsModalOpenSuccess: isOpenModal =>
      dispatch(interviewsAddFilterIsModalOpenSuccess(isOpenModal)),
    interviewsAddFilterIsLowSoundSuccess: isLowSound =>
      dispatch(interviewsAddFilterIsLowSoundSuccess(isLowSound)),
    interviewsResetQuestionAttemptRequest: sessionCode =>
      dispatch(interviewsResetQuestionAttemptRequest(sessionCode)),
    interviewsRemoveCountdownAndStart: countdown_duration =>
      dispatch(interviewsRemoveCountdownAndStart(countdown_duration)),
    interviewsFinishByTrigger: sessionCode =>
      dispatch(interviewsFinishByTrigger(sessionCode)),
    interviewsAddFilterIsModalInterviewCustomSuccess: modal_loader_data =>
      dispatch(
        interviewsAddFilterIsModalInterviewCustomSuccess(modal_loader_data)
      ),
    interviewsAddFilterIsModalOpen: is_modal_open =>
      dispatch(interviewsAddFilterIsModalOpen(is_modal_open)),
    sendAstronautQnASessionEvent: astronautEvent =>
      dispatch(sendAstronautQnASessionEvent(astronautEvent))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InterviewShow);
