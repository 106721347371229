import { put, call, all, select, take } from "redux-saga/effects";
import { delay } from "redux-saga";
import { push } from "react-router-redux";
import localforage from "localforage";
import omit from "lodash/omit";
import { Map, List, fromJS } from "immutable";
import initializers from "../dependencies/initializers";
import authToken from "../dependencies/authStore";

import configurations from "../components/pages/misc/configurations";

import { change, startAsyncValidation, stopAsyncValidation } from "redux-form";

import {
  ecourseJobDetailApi,
  ecourseRegisterApi,
  ecourseCheckEmailApi,
  ecourseLoginApi
} from "../apis/ecourse";

export function* ecourseGetJobDetail({
  jobCode,
  resolve,
  reject
}: Object): Generator<*, *, *> {
  try {
    let response: Object = {};

    response = yield call(ecourseJobDetailApi, jobCode);
    if (response.status >= 200 && response.status < 300) {
      const initialData: Object = response.data;
      const ecourse_data: Object = initialData.ecourse_data;
      yield put({
        type: "ECOURSE_FETCH_JOB_DATA_SUCCESS",
        ecourse_job: ecourse_data
      });

      yield call(resolve);
    }

    //untuk mengaktifkan navigasi ada di fungsi shouldcomponent update home index
    yield put({
      type: "SESSIONS_ADD_FILTER_IS_DISPLAY_MAIN_LAYOUT_SUCCESS",
      is_display_main_layout: true
    });
  } catch (error) {
    console.log(error);
    yield call(resolve);
  }
}

export function* ecourseRegistration({
  jobCode,
  values,
  resolve,
  reject
}: Object): Generator<*, *, *> {
  try {
    let response: Object = {};

    response = yield call(ecourseRegisterApi, jobCode, values);
    if (response.status >= 200 && response.status < 300) {
      const data = response.data.user;
      yield call(authToken.set, data.token);

      yield call(resolve);
    }
  } catch (error) {
    yield call(reject);
    console.log(error);
  }
}

export function* ecourseAuth({
  payment,
  resolve,
  reject
}: Object): Generator<*, *, *> {
  yield put({
    type: "INTERVIEW_COURSE_AUTHORIZED_SUCCESS",
    is_course_auth: payment
  });

  yield call(resolve);
}

export function* ecourseAuthStatus({
  status,
  resolve,
  reject
}: Object): Generator<*, *, *> {
  yield put({
    type: "INTERVIEW_COURSE_PAYMENT_SUCCESS",
    ecourse_payment_status: status
  });

  yield call(resolve);
}

export function* ecourseValidateEmailOnUserRegisterWizardForm({
  email,
  jobCode
}: Object): Generator<*, *, *> {
  const errors: Object = {
    email: `<i style="font-size: 12px">This email is already registered. Would you like to <a href=/login/${jobCode} style="color: #a94442; text-decoration: underline">login?</a></i>`
  };

  try {
    yield put(change("RegisterPage", "email", email, true, true));

    if (configurations.emailRegex.test(email)) {
      yield put(startAsyncValidation("RegisterPage"));

      yield delay(1000);

      const response: Object = yield call(ecourseCheckEmailApi, email);

      if (response.status === 200) {
        if (response.data.status === "success") {
          yield put(stopAsyncValidation("RegisterPage", {}));
        } else {
          yield put(stopAsyncValidation("RegisterPage", errors));
        }
      } else if (response.status === 422) {
        yield put(stopAsyncValidation("RegisterPage", errors));
      } else {
        yield put(stopAsyncValidation("RegisterPage", errors));
      }
    }
    console.log(configurations.emailRegex.test(email));
  } catch (error) {
    yield put(stopAsyncValidation("RegisterPage", errors));
  }
}

export function* ecourseLogin({ values }: Object): Generator<*, *, *> {
  try {
    const response: Object = yield call(ecourseLoginApi, values);
    if (response.status >= 200 && response.status < 300) {
      const data = response.data;
      console.log(data);
    }
  } catch (error) {}
}
