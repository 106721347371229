// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { Map } from "immutable";
import bindAll from "lodash/bindAll";
import Header from "../../lib/elements/Header";
import Card from "../../lib/elements/Card";
import SectionQuestionFooter from "../sections/SectionQuestionFooter";
import SectionPanelOption from "../sections/SectionPanelOption";
import McqHeader from "./McqHeader";
import McqHeaderQuestionOnly from "./McqHeaderQuestionOnly";
import McqBody from "./McqBody";
import McqBodyAllPage from "./McqBodyAllPage";

import * as Logger from "astrnt-web-logger";

type Props = {
  job: Map<string, any>,
  company: Map<string, any>,
  translations: Map<string, any>,
  sessionsFilterData: Map<string, any>
};

class McqShow extends React.Component<Props> {
  constructor(props) {
    super(props);

    bindAll(this, ["renderSectionPanelAndFooter"]);
  }

  componentDidMount() {
    const { sessionsFilterData, section } = this.props;
    window.scrollTo(0, 0);

    $("body").bind("cut copy paste", function(e) {
      e.preventDefault();
    });

    //Disable mouse right click
    $("body").on("contextmenu", function(e) {
      return false;
    });

    document.onkeydown = function(e) {
      if (e.keyCode == 123) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == "I".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == "C".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == "J".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == "U".charCodeAt(0)) {
        return false;
      }
    };
    var eventLocation = "MCQ Info";
    if (sessionsFilterData.get("type") === "close test") {
      if (!sessionsFilterData.get("duration_left")) {
        eventLocation = "MCQ Info";
      } else {
        eventLocation = "MCQ Info Continue";
      }
    } else if (
      sessionsFilterData.get("type") === "close section without interview"
    ) {
      if (!sessionsFilterData.get("duration_left")) {
        eventLocation = "Section MCQ Info";
      } else {
        eventLocation = "Section MCQ Info Continue";
      }
    }

    let params = {
      event: eventLocation, // string
      message: "Entered MCQ Info",
      status: "offline" // string
    };

    Logger.recordEvent(params);
  }

  renderSectionPanelAndFooter() {
    const { sessionsFilterData, questionsOptionMcq } = this.props;
    const is_shopee_allpages = !questionsOptionMcq.get("is_next");
    let sectionPanelAndFooter: Object;

    if (
      sessionsFilterData.get("type") === "close section" ||
      sessionsFilterData.get("type") === "close section without interview"
    ) {
      sectionPanelAndFooter = (
        <Fragment>
          <aside className="justify-content-end">
            <div id="sidebar">
              <SectionPanelOption />
            </div>
          </aside>
          {is_shopee_allpages == true ? null : <SectionQuestionFooter />}
        </Fragment>
      );
    }

    return sectionPanelAndFooter;
  }

  render() {
    const {
      job,
      company,
      questionsOptionMcq,
      question,
      section,
      sessionsFilterData,
      candidate
    } = this.props;
    const time_indicator_stop = section.get("is_shopee") == 1 ? true : false;
    //false then its speed test shopee
    console.log(candidate);
    let decide_mcq;
    if (questionsOptionMcq.get("is_next") == false) {
      decide_mcq = (
        <Fragment>
          <Header
            provider={company.get("title")}
            course={job.get("title")}
            position={job.get("jobType")}
            location={job.get("locations")}
            type="primary"
            redirectCode={sessionsFilterData.get("ecourse_code")}
            learner={candidate}
            logoCourse={company.get("logoUrl")}
          />
          <div className="main-wrapper d-flex">
            <div className="container">
              <Card className="p-0">
                {question.map((mcq_question, index) =>
                  index == 0 ? (
                    <Fragment>
                      <McqHeader
                        id={`header-index-${mcq_question.get("id")}`}
                        not_all_pages={questionsOptionMcq.get("is_next")}
                        first_question={mcq_question}
                      />

                      {/* BODY mcq standard */}
                      <McqBodyAllPage
                        key={index}
                        not_all_pages={questionsOptionMcq.get("is_next")}
                        question_single_page={mcq_question}
                      />
                    </Fragment>
                  ) : (
                    <Fragment>
                      {/* Tiap question ke 2 dan seterus nya di loop didalam sini jika allpage */}
                      <McqHeaderQuestionOnly
                        id={`header-index-${mcq_question.get("id")}`}
                        indexquestion={index + 1}
                        question_single_page={mcq_question}
                      />
                      <McqBodyAllPage
                        key={index}
                        question_single_page={mcq_question}
                      />
                    </Fragment>
                  )
                )}

                <div style={{ height: "100px" }} />
              </Card>
            </div>
            {this.renderSectionPanelAndFooter()}
          </div>
        </Fragment>
      );
    } else {
      decide_mcq = (
        <Fragment>
          <Header
            provider={company.get("title")}
            course={job.get("title")}
            position={job.get("jobType")}
            location={job.get("locations")}
            type="primary"
            redirectCode={sessionsFilterData.get("ecourse_code")}
            learner={candidate}
            logoCourse={company.get("logoUrl")}
          />
          <div className="main-wrapper d-flex">
            <div className="container">
              <Card className="p-0">
                <McqHeader time_indicator_stop={time_indicator_stop} />
                {/* BODY mcq standard */}
                <McqBody />
                {/* body allpage template all page */}
                {/* <McqBodyAllPage /> */}
                {/* Tiap question ke 2 dan seterus nya di loop didalam sini jika allpage */}
                {/* <McqHeaderQuestionOnly /> */}
                {/* <McqBodyAllPage /> */}
              </Card>
            </div>
            {this.renderSectionPanelAndFooter()}
          </div>
        </Fragment>
      );
    }

    return <Fragment>{decide_mcq}</Fragment>;
  }
}

const mapStateToProps = state => {
  return {
    questionsOptionMcq: state.questionsOptionMcq,
    question: state.question,
    job: state.job,
    company: state.company,
    section: state.section,
    sessionsFilterData: state.sessionsFilterData,
    candidate: state.candidate
  };
};

export default connect(mapStateToProps, null)(McqShow);
