// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import bindAll from "lodash/bindAll";
import { Map } from "immutable";
import ModalLoader from "../../lib/elements/ModalLoader";
import BrokenLink from "../../components/partials/BrokenLink";
import DeadlinePassed from "../../components/partials/DeadlinePassed";
import TimeOutPage from "../../components/partials/TimeOutPage";
import CompletedSession from "../../components/partials/CompletedSession";
import SessionFinish from "../../components/sessions/SessionFinish";
import CompletedSessionWithForm from "../../components/partials/CompletedSessionWithForm";
import CompletedSessionWithLink from "../../components/partials/CompletedSessionWithLink";
import clearLogStorage from "../../dependencies/clearLogStorage";
import UnsupportedBrowser from "../../lib/pages/UnsupportedBrowser";
import * as Logger from "astrnt-web-logger";
import { sessionsFetchInitialDataRequest } from "../../actions/sessions";
import { ecourseGetJobDetail } from "../../actions/ecourse";
import UAParser from "ua-parser-js";
import { sendAstronautQnASessionEvent } from "../../actions/embedded-interview";
import _axios from "../../dependencies/_axios";
import queryString from "query-string";

import moment from "moment";

// import image1 from '../../images/404.png'
// import image2 from '../../images/alert.png'
// import image3 from '../../images/allow-camera.jpg'
// import image4 from '../../images/allow-mic.jpg'
// import image5 from '../../images/attempt.svg'
// import image6 from '../../images/bg-finish-page.jpg'
// import image7 from '../../images/blocked-chrome.gif'
// import image8 from '../../images/blocked-firefox.png'
// import image9 from '../../images/blocked-opera.png'
// import image10 from '../../images/broken.png'
// import image11 from '../../images/change-mind.png'
// import image12 from '../../images/check-device.png'
// import image13 from '../../images/check-document.png'
// import image14 from '../../images/check-email.png'
// import image15 from '../../images/check.png'
// import image16 from '../../images/check.svg'
// import image17 from '../../images/chrome.png'
// import image18 from '../../images/delete.svg'
// import image20 from '../../images/email.png'
// import image21 from '../../images/firefox.png'
// import image22 from '../../images/goodbye.png'
// import image23 from '../../images/img-appstore.png'
// import image24 from '../../images/img-gplay.png'
// import image25 from '../../images/info.svg'
// import image26 from '../../images/instruction-interview-1.svg'
// import image27 from '../../images/instruction-interview-2.svg'
// import image28 from '../../images/instruction-interview-3.svg'
// import image29 from '../../images/instruction-mcq-1.svg'
// import image30 from '../../images/instruction-mcq-2.svg'
// import image31 from '../../images/instruction-mcq-3.svg'
// import image32 from '../../images/internet-off.png'
// import image34 from '../../images/logo-black.png'
// import image35 from '../../images/logo-white.png'
// import image36 from '../../images/mail.svg'
// import image37 from '../../images/marked-calendar.png'
// import image38 from '../../images/menu.svg'
// import image39 from '../../images/mic-off.png'
// import image40 from '../../images/mic.svg'
// import image41 from '../../images/microphone.svg'
// import image42 from '../../images/next-sm.svg'
// import image43 from '../../images/next.png'
// import image44 from '../../images/next.svg'
// import image45 from '../../images/no-email.png'
// import image46 from '../../images/onboarding-1.png'
// import image47 from '../../images/onboarding-2.png'
// import image48 from '../../images/onboarding-3.png'
// import image49 from '../../images/onboarding-4.png'
// import image51 from '../../images/opera.png'
// import image52 from '../../images/pause.svg'
// import image53 from '../../images/phone.svg'
// import image55 from '../../images/prev.png'
// import image56 from '../../images/previous-sm.svg'
// import image57 from '../../images/previous.svg'
// import image58 from '../../images/profile.svg'
// import image59 from '../../images/retake-inactive.svg'
// import image61 from '../../images/sands.png'
// import image62 from '../../images/server-maintenance.png'
// import image63 from '../../images/server-off.png'
// import image64 from '../../images/space.png'
// import image65 from '../../images/stars.png'
// import image66 from '../../images/Storage.svg'
// import image67 from '../../images/test.svg'
// import image68 from '../../images/time.svg'
// import image69 from '../../images/timer.svg'
// import image70 from '../../images/track-danger.svg'
// import image71 from '../../images/track.svg'
// import image72 from '../../images/upload.svg'
// import image73 from '../../images/video-off.png'
// import image74 from '../../images/video.svg'
// import image75 from '../../images/volume-bar-filled.png'
// import image76 from '../../images/volume-bar.png'

import image54 from "../../images/play.svg";
import image60 from "../../images/retake.svg";
import image19 from "../../images/done.svg";
import image33 from "../../images/loader.gif";

import { globalHandlePageReloadHelper } from "../../helpers/global";
import authToken from "../../dependencies/authStore";

type Props = {
  children: Object,
  globalFetchDataError: string,
  questionId: string,
  subFeature: string,
  sessionCode: string,
  section: Map<string, any>,
  sections: List<Map<string, any>>,
  sessionsUiData: Map<string, any>,
  sessionsFilterData: Map<string, any>,
  sessionsFetchInitialDataRequest: Function
};

type State = {
  isConnected: boolean,
  mainWindow: boolean
};

class HomeIndex extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      isConnected: true,
      mainWindow: true
    };
    bindAll(this, [
      "decideMainLayout",
      "isAvailable",
      "handleLogDuration",
      "generateBrowserSupport"
    ]);
  }

  componentDidMount() {
    const {
      questionId,
      sessionsFetchInitialDataRequest,
      sessionCode,
      subFeature,
      newToken,
      locationProps,
      ecourseGetJobDetail
    } = this.props;

    const today = moment().unix();

    clearLogStorage.remove();
    if (locationProps == "code") {
      authToken.get().then(localToken => {
        const token = newToken ? newToken : localToken;
        sessionsFetchInitialDataRequest(
          sessionCode,
          questionId,
          subFeature,
          token
        ).then(() => {
          var ecourseDetail = this.props.company.toJS();
          //for change favicon
          var link = document.querySelector("link[rel~='icon']");
          if (!link) {
            link = document.createElement("link");
            link.rel = "icon";
            document.getElementsByTagName("head")[0].appendChild(link);
          }
          link.href = ecourseDetail.logoUrl + "?v=" + today;
        });
      });
    } else if (1 == this.props.slashCount) {
      authToken.get().then(token => {
        sessionsFetchInitialDataRequest(
          locationProps,
          questionId,
          subFeature,
          token,
          true
        ).then(() => {
          var ecourseDetail = this.props.company.toJS();
          //for change favicon
          var link = document.querySelector("link[rel~='icon']");
          if (!link) {
            link = document.createElement("link");
            link.rel = "icon";
            document.getElementsByTagName("head")[0].appendChild(link);
          }
          link.href = ecourseDetail.logoUrl + "?v=" + today;
        });
      });
    } else {
      // handle page /reviews
      if (
        "login" !== locationProps &&
        "register" !== locationProps &&
        "new-pass" !== locationProps &&
        "pass-success" !== locationProps &&
        "reset" !== locationProps
      ) {
        authToken.get().then(token => {
          sessionsFetchInitialDataRequest(
            locationProps,
            questionId,
            subFeature,
            token,
            true
          ).then(() => {
            var ecourseDetail = this.props.company.toJS();
            //for change favicon
            var link = document.querySelector("link[rel~='icon']");
            if (!link) {
              link = document.createElement("link");
              link.rel = "icon";
              document.getElementsByTagName("head")[0].appendChild(link);
            }
            link.href = ecourseDetail.logoUrl + "?v=" + today;
          });
        });
      } else {
        ecourseGetJobDetail(sessionCode).then(() => {
          var ecourseDetail = this.props.company.toJS();
          //for change favicon
          var link = document.querySelector("link[rel~='icon']");
          if (!link) {
            link = document.createElement("link");
            link.rel = "icon";
            document.getElementsByTagName("head")[0].appendChild(link);
          }
          link.href = ecourseDetail.logoUrl + "?v=" + today;
        });
      }
    }
  }

  generateBrowserSupport(): Object {
    const parser = new UAParser();
    const browserName = parser.getBrowser().name;
    return {
      isChrome: /chrome/i.test(browserName),
      isFirefox: /firefox/i.test(browserName),
      isOpera: /opera/i.test(browserName),
      isSafari: /safari/i.test(browserName)
    };
  }

  isAvailable = () => {
    const {
      questionId,
      sessionsFetchInitialDataRequest,
      sessionCode,
      subFeature
    } = this.props;
    var start = new Date().getTime();

    const timeout = new Promise((resolve, reject) => {
      setTimeout(reject, 60000, "Request timed out");
    });

    // Astronaut20
    const request = fetch("https://beta.astrnt.co/");

    return Promise.race([timeout, request])
      .then(response => {
        let end = new Date().getTime();
        let time = end - start;

        Logger.sendSavedEvents();
        if (!this.state.isConnected) {
          this.setState({ isConnected: true });
        }
      })
      .catch(error => {
        let params = {
          event: "Check Connection", // string
          message: "No Connection on Candidates ...",
          status: "offline"
        };
        Logger.recordEvent(params);
        this.setState({ isConnected: false });
        //  this.decideMainLayout("timed-out")
      });
  };

  shouldComponentUpdate(nextProps) {
    return nextProps.sessionsFilterData.get("is_display_main_layout");
  }

  handleLogDuration() {
    const { sections, section } = this.props;

    if (sections.size > 0) {
      // console.log(section.get("duration"));
      if (section.get("duration") === 1) {
        let sect = section.toJS();
        sections.toJS().map((data, key) => {
          if (data.id === sect.id) {
            let params = {
              event: "Section", // string
              message:
                "Duration ended in Section " + key + 1 + " on id =" + data.id,
              status: "offline" // string
            };
            // console.log(params);

            Logger.recordEvent(params);
          }
        });
      }
    }
  }

  decideMainLayout() {
    const {
      children,
      subFeature,
      globalFetchDataError,
      globalFetchDataIsProfile,
      candidateLink,
      sessionsUiData,
      sessionsFilterData,
      sessionCode,
      sendAstronautQnASessionEvent
    } = this.props;

    const brokenLink = [
      "code_not_exists",
      "code_not_found",
      "code_not_provided"
    ];

    const deadlinePassed = ["code_expired"];

    const completedSession = ["code_used"];

    const timeOut = ["session_end", "attempts_is_up"];

    const subFeatureList = ["not-interested"];

    const modalLoaderData: Object = sessionsUiData
      .get("modal_loader_data")
      .toJS();

    var url = document.URL;
    var lastPartUrl = url.split("/").pop();
    if (subFeature != "not-interested") {
      if (this.generateBrowserSupport().isSafari) {
        return <UnsupportedBrowser />;
      }
    }
    // console.log(globalFetchDataIsProfile.get("candidate_is_access_profile"));
    // console.log(globalFetchDataIsProfile.get("candidate_is_update_email"));
    // console.log(sessionsFilterData.get("is_display_main_layout"))
    if (sessionsFilterData.get("is_display_main_layout")) {
      if (subFeatureList.includes(subFeature)) {
        return <Fragment>{children}</Fragment>;
      } else if (brokenLink.includes(globalFetchDataError)) {
        return <BrokenLink />;
      } else if (deadlinePassed.includes(globalFetchDataError)) {
        return <DeadlinePassed />;
      } else if (timeOut.includes(globalFetchDataError)) {
        return <TimeOutPage />;
      } else if (
        completedSession.includes(globalFetchDataError) &&
        globalFetchDataIsProfile.get("candidate_is_access_profile") != 1
      ) {
        sendAstronautQnASessionEvent("Session Finished");
        var videos = [];
        var show_interview_progress = sessionsFilterData.get(
          "interview_settings_display_video_processing_process"
        );
        const token = sessionsFilterData.get("token");
        if (show_interview_progress) {
          // Get Video
          _axios({
            url: "/api/v2/get/finished/processed/video",
            method: "post",
            headers: {
              token
            },
            data: {
              interview_code: sessionCode
            }
          })
            .then(response => {
              if (response.data.status == "success") {
                videos = response.data.videos;
                const videos_url = JSON.stringify(videos);
                sendAstronautQnASessionEvent(
                  "astrnt_interview_video_result_url:" + videos_url
                );
              } else {
                videos = [];
              }
            })
            .catch(error => {
              console.log(error);
            });
        }

        if (
          sessionsFilterData.get(
            "interview_settings_skipped_interview_finished_page"
          ) == 1
        ) {
          return <SessionFinish />;
        } else {
          return <CompletedSession />;
        }
      } else if (
        completedSession.includes(globalFetchDataError) &&
        globalFetchDataIsProfile.get("candidate_is_access_profile") == 1 &&
        globalFetchDataIsProfile.get("candidate_is_link") != 1
      ) {
        return <CompletedSessionWithLink />;
      } else {
        return (
          <Fragment>
            {children}
            <ModalLoader {...modalLoaderData} />
          </Fragment>
        );
      }
    } else {
      return (
        <div className="wrapper-overlay" id="modalOnBoarding">
          <div className="overlay-content flex-container align-items-center">
            <img src={image33} className="img-responsive img-loader" alt="" />
          </div>
        </div>
      );
    }
  }

  render() {
    const {
      sessionDurationCount,
      locationProps,
      children,
      slashCount
    } = this.props;

    let renderPage;
    if (locationProps == "code" || slashCount == 1) {
      renderPage = (
        <Fragment>
          {this.state.mainWindow ? (
            <Fragment>
              {this.decideMainLayout()}
              {this.handleLogDuration()}
              <div className="hide">
                <img src={image54} alt="" />
                <img src={image60} alt="" />
                <img src={image19} alt="" />
              </div>
            </Fragment>
          ) : (
            <div>
              <p>ooopppsss wrong your page turn to be new</p>
            </div>
          )}
        </Fragment>
      );
    } else {
      renderPage = <Fragment>{children}</Fragment>;
    }
    return renderPage;
  }
}

const mapStateToProps = (state, ownProps) => {
  const query = queryString.parse(ownProps.location.search);
  return {
    questionId: ownProps.location.pathname.split("/")[4],
    subFeature: ownProps.location.pathname.split("/")[3],
    location_url: ownProps.location.search,
    globalFetchDataError: state.globalFetchDataError,
    globalFetchDataIsProfile: state.globalFetchDataIsProfile,
    candidateLink: state.candidateLink,
    sessionCode: ownProps.location.pathname.split("/")[2],
    sessionsUiData: state.sessionsUiData,
    sessionsFilterData: state.sessionsFilterData,
    section: state.section,
    sections: state.sections,
    sessionDurationCount: state.sessionDurationCount,
    locationProps: ownProps.location.pathname.split("/")[1],
    slashCount: ownProps.location.pathname.match(/\//g || []).length,
    ecourseJobDetail: state.ecourseJobDetail,
    company: state.company,
    newToken: query.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendAstronautQnASessionEvent: astronautEvent =>
      dispatch(sendAstronautQnASessionEvent(astronautEvent)),
    sessionsFetchInitialDataRequest: (
      sessionCode,
      questionId,
      subFeature,
      token,
      isJob
    ) =>
      dispatch(
        sessionsFetchInitialDataRequest(
          sessionCode,
          questionId,
          subFeature,
          token,
          isJob
        )
      ),
    ecourseGetJobDetail: jobCode => dispatch(ecourseGetJobDetail(jobCode))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomeIndex)
);
