import { combineReducers } from "redux";
import { routerReducer as routing } from "react-router-redux";
import { reducer as form } from "redux-form";
import { ecourseDetail, ecourseJobDetail, ecourseTeachers } from "./ecourse";

import { candidate, candidateError, candidateLink } from "./candidates";
import { company } from "./companies";
import { job } from "./jobs";
import { translations } from "./translations";
import {
  interviewsUiData,
  interviewsFilterData,
  interviewPracticeSound
} from "./interviews";

import { reviewsPagination, reviewsData, reviewsRating } from "./reviews";

import {
  globalFetchDataError,
  globalResponseData,
  globalFetchDataIsProfile
} from "./global";

import { section, sections } from "./sections";

import {
  sessionsUiData,
  sessionsFilterData,
  sessionsStateInformation,
  welcomeVideoInformation,
  videoProcessingProgressInfo,
  sessionDurationCount
} from "./sessions";

import { question, questionsFormData, questionsOptionMcq } from "./questions";

const reduxForm = form.plugin({
  SectionModalForm: (state, action) => {
    switch (action.type) {
      case "SECTIONS_RESET_SECTION_MODAL_FORM_SUCCESS":
        return {
          values: {},
          syncErrors: state.syncErrors,
          registeredFields: state.registeredFields
        };
      default:
        return state;
    }
  }
});

export default combineReducers({
  routing,
  company,
  candidate,
  candidateError,
  candidateLink,
  job,
  translations,
  interviewsUiData,
  interviewsFilterData,
  interviewPracticeSound,
  globalFetchDataError,
  globalFetchDataIsProfile,
  globalResponseData,
  sessionsUiData,
  sessionsFilterData,
  sessionsStateInformation,
  sessionDurationCount,
  question,
  questionsFormData,
  questionsOptionMcq,
  section,
  sections,
  videoProcessingProgressInfo,
  reviewsPagination,
  reviewsData,
  reviewsRating,
  ecourseDetail,
  form: reduxForm,
  ecourseJobDetail,
  ecourseTeachers
});
