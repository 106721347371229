// @flow

import { Map } from "immutable";
import padLeft from "voca/pad_left";
import UAParser from "ua-parser-js";
import Amplify, { Analytics } from "aws-amplify";
import * as Logger from "astrnt-web-logger";
import awsExports from "../dependencies/aws-exports";
import io from "socket.io-client";

// Amplify.configure(awsExports);
function round(x) {
  return x % 5 < 3
    ? x % 5 === 0
      ? x
      : Math.floor(x / 5) * 5
    : Math.ceil(x / 5) * 5;
}
// Duration parameter in seconds
export function globalGenerateUnitOfTimeHelper(duration: number): Object {
  const hour: number = Math.floor(duration / 3600);
  duration %= 3600;
  const minutes: number = Math.floor(duration / 60);
  const seconds: number = duration % 60;

  return Map({
    hour,
    minutes,
    seconds
  });
}

export function globalGenerateUnitOfTimeToCompleteHelper(
  duration: number
): Object {
  const hour: number = Math.floor(duration / 3600);
  duration %= 3600;
  const minutes: number = round(Math.floor(duration / 60));
  const seconds: number = duration % 60;

  return Map({
    hour,
    minutes,
    seconds
  });
}

export function globalBuildDurationDisplayHelper(duration: number): string {
  if (duration) {
    const _duration: Object = globalGenerateUnitOfTimeHelper(duration);
    let durationDisplay: string = "";

    if (_duration.get("hour") >= 1) {
      durationDisplay += `${padLeft(_duration.get("hour"), 2, "0")}:${padLeft(
        _duration.get("minutes"),
        2,
        "0"
      )}:${padLeft(_duration.get("seconds"), 2, "0")}`;
    } else {
      durationDisplay += `${padLeft(
        _duration.get("minutes"),
        2,
        "0"
      )}:${padLeft(_duration.get("seconds"), 2, "0")}`;
    }

    return durationDisplay;
  } else {
    return "";
  }
}

export function globalHandlePageReloadHelper(): void {
  window.addEventListener("beforeunload", function(e) {
    const currentPathArr: Array<string> = window.location.pathname.split("/");
    const lastIndexOfPathArr: string =
      currentPathArr[currentPathArr.length - 2];

    const listOfLastPath: Array<string> = [
      "single-section-preparation",
      "single-section-test-questions",
      "single-section-start-practice-answer",
      "single-section-interview-practice",
      "single-section-interview-preparation",
      "single-section-test-finish",
      "single-section-interview-questions",
      "single-section-interview-finish",
      "single-section-summary",
      "section-preview",
      "section-preparation",
      "section-test-finish",
      "section-summary",
      "section-test-questions",
      "section-start-practice-answer",
      "section-interview-practice",
      "section-interview-preparation",
      "section-interview-questions",
      "section-interview-finish",
      "start-answer",
      "start-practice-answer",
      "test-questions",
      "interview-practice",
      "interview-preparation",
      "interview-questions",
      "mcq-questions",
      "ftq-questions"
    ];
    console.log(lastIndexOfPathArr);
    if (listOfLastPath.includes(lastIndexOfPathArr)) {
      var params = {
        event: lastIndexOfPathArr, // string
        message: "Clicked Close or Reload button on " + lastIndexOfPathArr,
        status: "online" // string
      };

      Logger.recordEvent(params);

      const dialogText: string =
        "Your test is not saved  ! Are you sure you want to leave?";

      e.returnValue = dialogText;

      return dialogText;
    } else {
      return null;
    }
  });
}

export function globalDisconnectSocket(): void {
  if (process.env.NODE_ENV === "development") {
    io("http://localhost:3001").disconnect();
  } else if (process.env.NODE_ENV === "beta") {
    io("https://nodejs-beta.astrnt.co:3001").disconnect();
  } else {
    io("https://nodejs-beta.astrnt.co:3001").disconnect();
  }
}

export function replaceAll(word: string, params: Object): string {
  let _word: string = word;

  for (const x in params) {
    _word = _word.replace(new RegExp(x, "g"), params[x]);
  }

  return _word;
}

export function t(
  translations: Map<string, any>,
  key: string,
  params: Object = {}
): string {
  const word: string = translations.get(key);

  if (word) {
    return replaceAll(word, params);
  } else {
    return "";
  }
}

export function analyticsRecord(
  recordName: string,
  attributes: Object = {}
): void {
  if (process.env.NODE_ENV === "production" && recordName) {
    Analytics.record(recordName, attributes);
  }
}

export function setHttpHeaders(params: Object = {}): Object {
  const parser = new UAParser();
  const device = parser.getDevice();
  const os = parser.getOS();
  const browser = parser.getBrowser();

  const headers = {
    device: "",
    os: `${os.name} ${os.version}`,
    browser: `${browser.name} ${browser.version}`,
    screenresolution: `${window.screen.availHeight}x${window.screen.availWidth}`
  };

  if (Object.keys(params).length) {
    Object.assign(headers, params);
  }

  if (device.type) {
    Object.assign(headers, {
      device: `${device.type} ${device.model} ${device.vendor}`
    });
  }

  return headers;
}

function padZero(v) {
  return v < 10 ? "0" + v : v;
}

export function sToTime(t) {
  //to check jam
  // return padZero(parseInt((t / (60 * 60)) % 24)) + ":" +
  return padZero(parseInt((t / 60) % 60)) + ":" + padZero(parseInt(t % 60));
}

export function formatBytes(bytes, k = 1000, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}
