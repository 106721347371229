import { Map, fromJS } from "immutable";

const initCandidateError = fromJS({
  errorMessage: "",
  errorValidation: []
});
const initCandidateSuccessGetLink = fromJS({
  link: ""
});

export function candidate(state = Map({}), action) {
  switch (action.type) {
    case "CANDIDATES_GET_DETAIL_SUCCESS":
      return fromJS(action.candidate);
    default:
      return state;
  }
}

export function candidateError(state = initCandidateError, action) {
  switch (action.type) {
    case "CANDIDATES_GET_DETAIL_FAILED":
      return state.set("errorMessage", action.errorMessage);
    case "CANDIDATE_GET_DETAIL_FAILED_VALIDATION":
      return state.set("errorValidation", Map(action.errorValidation));
    case "CANDIDATES_GET_DETAIL_ERROR_RESET":
      return fromJS({
        errorMessage: "",
        errorValidation: []
      });
    default:
      return state;
  }
}

export function candidateLink(state = initCandidateSuccessGetLink, action) {
  switch (action.type) {
    case "CANDIDATE_HAVE_LINK_TO_PROFILE":
      return state.set("link", action.link);

    default:
      return state;
  }
}
