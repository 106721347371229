// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import hark from "hark";
import bindAll from "lodash/bindAll";
import { Map } from "immutable";
import Button from "../../lib/elements/Button";
import Progress from "../../lib/elements/Progress";

import {
  interviewsAddFilterIsNoSoundSuccess,
  interviewsStartPracticeRecordingSound,
  interviewsPracticeValidSuccess,
  interviewsPracticeRetrySuccess
} from "../../actions/interviews";
import { setRefStyleWidth } from "../../helpers/interviews";
import { t } from "../../helpers/global";

import RecPurple from "../../images/rec-circle-purple.png";
import RecGreen from "../../images/rec-circle-green.png";
import RecRed from "../../images/rec-circle-red.png";

import { soundInitRecords } from "../../helpers/soundcheck";

window.setMicrophoneTesterSpeechEvent = {};
window.setMicrophoneTesterSpeechEventCheck = [];
const refVolumePercentage = React.createRef();

type Props = {
  translations: Map<string, any>,
  interviewsFilterData: Map<string, any>,
  interviewsAddFilterIsNoSoundSuccess: Function
};

class MicrophoneTester extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.audioPlayer = React.createRef();
    this.state = {
      isRecording: false,
      is_play: false,
      percent_audio: 0
    };
    bindAll(this, [
      "setSpeechEvent",
      "handleRecord",
      "handleStopRecord",
      "handlePause",
      "handlePlayRecord",
      "handleDurationChange",
      "setSpeechEventCheck",
      "handleRetry"
    ]);
  }

  handleRetry() {
    const { interviewsPracticeRetrySuccess } = this.props;
    interviewsPracticeRetrySuccess();
    soundInitRecords(
      refVolumePercentage,
      this.handleStopRecord
      // this.props.interviewsAddFilterIsLowSoundSuccess,
      // () => this.resetQuestionAttemptOnInitRecord()
    );
  }

  handleDurationChange(e) {
    // console.log(e.target)
    const current_time = e.target.currentTime;
    const duration = e.target.duration;
    const percent = (current_time * 100) / duration;

    this.setState({ percent_audio: percent });
  }

  handlePause() {
    this.setState({ is_play: false });
  }

  handleRecord() {
    const { interviewsStartPracticeRecordingSound } = this.props;
    //start

    interviewsStartPracticeRecordingSound(true);

    // this.setState({isRecording:true})
  }

  handlePlayRecord() {
    let audio_url = document.getElementById("js-sound-check-recorder");

    this.setState({ is_play: !this.state.is_play }, () => {
      if (this.state.is_play == true) {
        audio_url.play();
      } else if (this.state.is_play == false) {
        audio_url.pause();
      }
    });
  }

  handleStopRecord() {
    // const { interviewsStartPracticeRecordingSound } = this.props;
    //stop

    const blob = new Blob(window.recordedSoundChecks, { type: "audio/webm" });
    // console.log(blob);
    document.getElementById(
      "js-sound-check-recorder"
    ).src = window.URL.createObjectURL(blob);

    // interviewsStartPracticeRecordingSound(false);
    // this.setState({isRecording:false})
    const stream = document.getElementById("js-sound-check-recorder");

    this.setState({ is_play: true }, () => {
      document.getElementById("js-sound-check-recorder").play();
    });
    this.setSpeechEventCheck(stream);
  }

  setSpeechEventCheck(stream) {
    const { interviewsPracticeValidSuccess } = this.props;
    if (window.setMicrophoneTesterSpeechEventCheck.length == 0) {
      window.setMicrophoneTesterSpeechEventCheck = hark(stream, {
        threshold: -50,
        interval: 500
      });
    } else {
    }

    // window.setMicrophoneTesterSpeechEvent.on("volume_change", db => {
    //   // if (db < -50 && db > -100) {

    //   // }

    //   setRefStyleWidth(refVolumePercentage, 100+db);

    // });

    // setRefStyleWidth(refVolumePercentage, 100+db);
    window.setMicrophoneTesterSpeechEventCheck.on("volume_change", db => {
      // console.log(db);
      if (db > -75) {
        interviewsPracticeValidSuccess(true);
      }
    });

    window.setMicrophoneTesterSpeechEventCheck.on("stopped_speaking", () => {
      setRefStyleWidth(refVolumePercentage, 0);
    });
  }

  setSpeechEvent(stream) {
    window.setMicrophoneTesterSpeechEvent = hark(stream, {
      threshold: -50,
      interval: 500
    });

    // window.setMicrophoneTesterSpeechEvent.on("volume_change", db => {
    //   // if (db < -50 && db > -100) {

    //   // }

    //   setRefStyleWidth(refVolumePercentage, 100+db);

    // });

    window.setMicrophoneTesterSpeechEvent.on("speaking", () => {
      this.props.interviewsAddFilterIsNoSoundSuccess(false);

      // setRefStyleWidth(refVolumePercentage, 100+db);
      window.setMicrophoneTesterSpeechEvent.on("volume_change", db => {
        // console.log(db);
        setRefStyleWidth(refVolumePercentage, 100 + db);
      });
    });

    window.setMicrophoneTesterSpeechEvent.on("stopped_speaking", () => {
      setRefStyleWidth(refVolumePercentage, 0);
    });
  }

  componentDidMount() {
    let audio_player = document.getElementById("js-sound-check-recorder");
    soundInitRecords(
      refVolumePercentage,
      this.handleStopRecord
      // this.props.interviewsAddFilterIsLowSoundSuccess,
      // () => this.resetQuestionAttemptOnInitRecord()
    );
    // $FlowFixMe
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(stream => this.setSpeechEvent(stream))
      .catch(error => {
        console.error(error);
      });

    audio_player.addEventListener("ended", this.handlePause);
    // audio_player.addEventListener('durationchange',this.handleDurationChange);
  }

  componentWillUnmount() {
    window.setMicrophoneTesterSpeechEvent.stop();
  }

  render() {
    const {
      translations,
      interviewsFilterData,
      interviewPracticeSound
    } = this.props;
    let duration_record = interviewPracticeSound.get("duration");
    let is_record = interviewPracticeSound.get("is_record");
    let is_done = interviewPracticeSound.get("is_done");
    let is_valid = interviewPracticeSound.get("valid");

    let button;
    if (is_record == true) {
      button = (
        <Button className="btn-record recording">
          {duration_record == 0 ? "finish" : duration_record}
        </Button>
      );
    } else {
      button = (
        <Button className="btn-record" onClick={this.handleRecord}>
          {t(translations, "LABEL_RECORD")}
        </Button>
      );
    }

    let message_success;
    if (!is_done) {
      message_success = (
        <Fragment>
          <p className="heading6 m-b-10">
            {t(translations, "LABEL_CLICK_THE_RECORD")}
            {/* Click the “Record” button below & speak normally for a few seconds. */}
          </p>
        </Fragment>
      );
    } else {
      if (!is_valid) {
        message_success = (
          <Fragment>
            <p className="heading6 m-b-10">
              {t(translations, "LABEL_MIC_TEST_FAILED")}
              {/* Microphone testing failed, you cannot start the session. */}
            </p>
          </Fragment>
        );
      } else {
        message_success = (
          <Fragment>
            <p className="heading6 m-b-10">
              {/*t(translations, "INTERVIEW_DATA_TEST_SOUND_SPEAK")*/}
              {t(translations, "LABEL_AWESOME_MIC_TEST")}
              {/* Awesome! Microphone testing was successful, you can start your
              session. */}
            </p>
          </Fragment>
        );
      }
    }
    return (
      <div className="audio-test">
        <h4 className="heading4 bold m-b-8">
          {/* Microphone Test */}
          {t(translations, "LABEL_MICROPHONE_TEST")}
        </h4>
        {message_success}
        <div className={`div-img ${is_done == true ? "" : "hide"}`}>
          {/* <img className="" src={RecPurple} /> */}
          {is_valid == true ? <img src={RecGreen} /> : <img src={RecRed} />}
        </div>
        <div className={`audio-container ${is_done == true ? "hide" : ""}`}>
          <div className="vertical-item--center horizontal-item--center">
            <div className="div-sound-input vertical-item--center flex-between">
              <div>
                <i
                  className={`fa fa-circle ${
                    is_record == true ? "recording" : ""
                  }`}
                />
              </div>
              <div className="vertical-item--center">
                <div className="div-label-input-level">
                  {t(translations, "LABEL_INPUT_LEVEL")}
                </div>
                <div className="volume-horizontal">
                  <div className="volume-meter-container">
                    <div className="volume-meter" ref={refVolumePercentage} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`div-button-record ${is_done == true ? "hide" : ""}`}>
          {button}
        </div>

        <div className={`audio-container ${is_done == true ? "" : "hide"}`}>
          <div className="vertical-item--center horizontal-item--center">
            <div className="div-sound-input vertical-item--center flex-between">
              <div className="progress-container" style={{ width: "90%" }}>
                <Progress
                  type="ongoing"
                  size="medium"
                  value={this.state.percent_audio}
                  className="m-t-12"
                />
              </div>
              <div
                className="vertical-item--center"
                onClick={this.handlePlayRecord}
              >
                {this.state.is_play == false ? (
                  <i className="fa fa-play" />
                ) : (
                  <i className="fa fa-stop" />
                )}
              </div>
            </div>
          </div>
          <audio
            onTimeUpdate={this.handleDurationChange}
            ref={el => (this.audioPlayer = el)}
            id="js-sound-check-recorder"
            controls="controls"
            type="audio/webm"
            className="hide"
          />
        </div>
        <div
          className={`div-button-record ${
            is_done == true && is_valid == false ? "" : "hide"
          }`}
        >
          <Button className="btn-record" onClick={this.handleRetry}>
            {t(translations, "LABEL_RETRY")}
          </Button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    interviewsAddFilterIsNoSoundSuccess: isNoSound =>
      dispatch(interviewsAddFilterIsNoSoundSuccess(isNoSound)),
    interviewsStartPracticeRecordingSound: start =>
      dispatch(interviewsStartPracticeRecordingSound(start)),
    interviewsPracticeValidSuccess: valid =>
      dispatch(interviewsPracticeValidSuccess(valid)),
    interviewsPracticeRetrySuccess: () =>
      dispatch(interviewsPracticeRetrySuccess())
  };
};

const mapStateToProps = state => {
  return {
    translations: state.translations,
    interviewsFilterData: state.interviewsFilterData,
    interviewPracticeSound: state.interviewPracticeSound,
    translations: state.translations
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MicrophoneTester);
