// @flow
import React from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import classnames from 'classnames'
import { t } from '../../helpers/global'

const textSize = {
  small: 'heading6',
  medium: 'heading4',
  large: 'heading1',
}

type Props = {
  translations: Map<string, any>,
  current: string | number,
  end: string | number,
  className: '',
  size: string,
};

class QuestionPosition extends React.Component<Props> {
  static defaultProps = {
    current: 1,
    end: 7,
    className: '',
    size: 'large',
  }

  render() {
    const {
      current,
      end,
      className,
      size,
      translations
    } = this.props

    const classProps: string = classnames(
      textSize[size],
      className
    )

    return(
      <p className={classProps}>
        <b>{t(translations,"LABEL_QUESTION")} {current}</b> {t(translations,"LABEL_OF")} {end}
      </p>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    translations: state.translations,
  }
}

export default connect(mapStateToProps, null)(QuestionPosition)
