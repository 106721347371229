// @flow

import React, { Fragment } from "react";
import ImageWithZoom from "../../lib/elements/ImageWithZoom";
import ImageWithModal from "../../lib/elements/ImageWithModal";

type Props = {
  image: string,
  alt: string,
  text: string,
  className: string
};

class QuestionContent extends React.Component<Props> {
  static defaultProps = {
    image: "",
    text: "",
    alt: "",
    className: ""
  };

  render() {
    const {
      image,
      text,
      alt,
      className,
      not_all_pages,
      id,
      childrenAudio
    } = this.props;
    var txtquestion = { __html: text.replace(/(?:\r\n|\r|\n)/g, "<br/>") };

    return (
      <div className={`p-16 ${className}`}>
        {image ? (
          <ImageWithModal
            imageUrl={image}
            alt={alt}
            className="img__question__ftq"
            classQuestion="img-question"
          />
        ) : null}
        {not_all_pages === false ? (
          <Fragment>
            <p id={id} className="heading5 table-cell">
              1.{" "}
            </p>
            <p
              className="heading5 table-cell p-l-5"
              dangerouslySetInnerHTML={txtquestion}
            />
          </Fragment>
        ) : (
          <Fragment>
            {childrenAudio}
            <p
              className="heading5 table-cell p-l-5"
              dangerouslySetInnerHTML={txtquestion}
            />
          </Fragment>
        )}
      </div>
    );
  }
}

export default QuestionContent;
