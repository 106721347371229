// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import bindAll from "lodash/bindAll";
import { Map } from "immutable";
import $ from "jquery";
import OptionNote from "../../lib/elements/OptionNote";
import ListAnswers from "../../lib/elements/ListAnswers";
import ListAnswersAllPage from "../../lib/elements/ListAnswersAllPage";
import Button from "../../lib/elements/Button";
import * as Logger from "astrnt-web-logger";
import {
  sectionsAnswerQuestionRequest,
  sectionsAnswerQuestionOnShowAllRequest
} from "../../actions/sections";
import { mcqsAnswerQuestionRequest } from "../../actions/mcqs";
import { questionsAddAnswersRequest } from "../../actions/questions";
import { sendAstronautQnASessionEvent } from "../../actions/embedded-interview";

import { t } from "../../helpers/global";

type Props = {
  translations: Map<string, any>,
  candidate: Map<string, any>,
  interviewsAddUiTestDurationClass: Function,
  sectionsAnswerQuestionRequest: Function,
  mcqsAnswerQuestionRequest: Function,
  sessionsFilterData: Map<string, any>,
  sessionCode: string,
  question: Map<string, any>,
  questionsFormData: Map<string, any>,
  questionsAddAnswersRequest: Function,
  questionsTestAnswer: Function,
  questionsTestAnswerFinish: Function
};

class McqBodyAllPage extends React.Component<Props> {
  constructor(props: Object): void {
    super(props);

    bindAll(this, [
      "alphabetAtIndex",
      "renderNonSectionSubmitButton",
      "handleAnswerClick",
      "handleSubmitClick"
    ]);
  }

  alphabetAtIndex(index: number): string {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    return alphabet.charAt(index);
  }

  handleAnswerClick(id: number): void {
    const {
      sectionsAnswerQuestionRequest,
      questionsAddAnswersRequest,
      sessionCode,
      sessionsFilterData,
      question,
      sectionsAnswerQuestionOnShowAllRequest,
      question_single_page
    } = this.props;

    if (sessionsFilterData.get("type") === "close test") {
      if (!sessionsFilterData.get("duration_left")) {
        var eventLocation = "MCQ";
      } else {
        var eventLocation = "MCQ Continue";
      }
    } else if (
      sessionsFilterData.get("type") === "close section without interview"
    ) {
      if (!sessionsFilterData.get("duration_left")) {
        var eventLocation = "Section MCQ";
      } else {
        var eventLocation = "Section MCQ Continue";
      }
    } else if (sessionsFilterData.get("type") === "close section") {
      if (!sessionsFilterData.get("duration_left")) {
        var eventLocation = "Section MCQ";
      } else {
        var eventLocation = "Section MCQ Continue";
      }
    }

    var questionPage = question.toJS();
    var json_session = sessionsFilterData.toJS();
    var question_position;
    json_session.question_ids.map((value, key) =>
      value ===
      parseInt(this.props.history.location.pathname.split("/").slice(-2)[1])
        ? (question_position = key + 1)
        : null
    );

    let params = {
      event: eventLocation, // string
      message:
        "Answered on mcq page " +
        question_position +
        " " +
        questionPage.title +
        " on id " +
        question.get("id"),
      status: "offline" // string
    };

    Logger.recordEvent(params);

    if (sessionsFilterData.get("type") === "close test") {
      questionsAddAnswersRequest(id);
    } else {
      sectionsAnswerQuestionOnShowAllRequest(
        id,
        sessionCode,
        question_single_page
      );
      // sectionsAnswerQuestionRequest(id, sessionCode);
    }
  }

  handleSubmitClick(e: Object): void {
    e.preventDefault();

    const {
      sessionCode,
      mcqsAnswerQuestionRequest,
      sessionsFilterData,
      question,
      sendAstronautQnASessionEvent
    } = this.props;

    sendAstronautQnASessionEvent("Multiple Choice Question Answered");

    var questionPage = question.toJS();
    var json_session = sessionsFilterData.toJS();
    var question_position;
    json_session.question_ids.map((value, key) =>
      value ===
      parseInt(this.props.history.location.pathname.split("/").slice(-2)[1])
        ? (question_position = key + 1)
        : null
    );

    mcqsAnswerQuestionRequest(sessionCode);

    if (sessionsFilterData.get("type") === "close test") {
      if (!sessionsFilterData.get("duration_left")) {
        var eventLocation = "MCQ";
      } else {
        var eventLocation = "MCQ Continue";
      }
    } else if (
      sessionsFilterData.get("type") === "close section without interview"
    ) {
      if (!sessionsFilterData.get("duration_left")) {
        var eventLocation = "Section MCQ";
      } else {
        var eventLocation = "Section MCQ Continue";
      }
    } else if (sessionsFilterData.get("type") === "close section") {
      if (!sessionsFilterData.get("duration_left")) {
        var eventLocation = "Section MCQ";
      } else {
        var eventLocation = "Section MCQ Continue";
      }
    }

    let params = {
      event: eventLocation, // string
      message: "Submited for mcq question page " + question_position,
      status: "offline" // string
    };

    Logger.recordEvent(params);
  }

  renderNonSectionSubmitButton() {
    const { questionsFormData, sessionsFilterData, translations } = this.props;

    let nonSectionSubmitButton: Object;

    if (sessionsFilterData.get("type") === "close test") {
      nonSectionSubmitButton = (
        <center>
          <Button
            theme="primary"
            disabled={!questionsFormData.get("answered_ids").size}
            className="m-b-40"
            onClick={this.handleSubmitClick}
          >
            {t(translations, "LABEL_SUBMIT")}
          </Button>
        </center>
      );
    }

    return nonSectionSubmitButton;
  }

  render(): Object {
    const { question_single_page, questionsFormData } = this.props;
    // console.log(questionsFormData
    //               .get("answered_ids").toJS())
    try {
      return (
        <Fragment>
          <div className="p-l-20 p-r-15">
            {question_single_page.get("answers").map((answer, index) => (
              <ListAnswersAllPage
                key={index}
                theme="circle"
                title={this.alphabetAtIndex(index)}
                image={answer.get("image_url")}
                type="checkbox"
                name="super"
                label={answer.get("label")}
                value={answer.get("id")}
                checked={questionsFormData
                  .get("answered_ids")
                  .includes(answer.get("id"))}
                onClick={() => this.handleAnswerClick(answer.get("id"))}
              />
            ))}
          </div>
          {this.renderNonSectionSubmitButton()}
        </Fragment>
      );
    } catch (error) {
      let params = {
        event: "Section MCQ", // string
        message: "Answered on mcq page on id " + question_single_page.get("id"),
        status: "offline" // string
      };
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations,
    candidate: state.candidate,
    sessionsFilterData: state.sessionsFilterData,
    sessionCode: ownProps.match.params.sessionCode,
    question: state.question,
    questionsFormData: state.questionsFormData,
    questionId: ownProps.match.params.questionId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sectionsAnswerQuestionRequest: (id, sessionCode) =>
      dispatch(sectionsAnswerQuestionRequest(id, sessionCode)),
    sectionsAnswerQuestionOnShowAllRequest: (id, sessionCode, question) =>
      dispatch(
        sectionsAnswerQuestionOnShowAllRequest(id, sessionCode, question)
      ),
    mcqsAnswerQuestionRequest: sessionCode =>
      dispatch(mcqsAnswerQuestionRequest(sessionCode)),
    questionsAddAnswersRequest: id => dispatch(questionsAddAnswersRequest(id)),
    sendAstronautQnASessionEvent: astronautEvent =>
      dispatch(sendAstronautQnASessionEvent(astronautEvent))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(McqBodyAllPage)
);
