// @flow
import React, { Fragment } from 'react'
import DotsWithLabel from './DotsWithLabel'

type Props = {
  questionText: string,
  label: string,
  type: string
};

class QuestionBar extends React.Component<Props> {
  static defaultProps = {
    questionText: '',
    label: '',
    type: 'default'
  }

  render() {
    const {
      questionText,
      label,
      type
    } = this.props

    return(
      <Fragment>
        <DotsWithLabel type={type} label={label} />
        <h6 className="m-l-24 m-r-16">{questionText}</h6>
      </Fragment>
    )
  }
}

export default QuestionBar
