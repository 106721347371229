// @flow

import React from 'react'

type Props = {
  image: string,
  text: string,
  alt: string,
  className: string,
};

class InstructionDesk extends React.Component<Props> {

  static defaultProps = {
    image: '',
    text: '',
    alt: '',
    className: '',
  }

  render() {
    const {
      image,
      text,
      alt,
      className
    } = this.props

    return (
      <div className={className}>
        <div className="p-2 d-flex align-items-center">
          <div className="flex-grow-1">
            <img src={image} alt={alt} />
          </div>
          <p className="p-2 m-0">{text}</p>
        </div>
      </div>
    )
  }
}

export default InstructionDesk
