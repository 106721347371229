// @flow

import { put, call, select, all } from "redux-saga/effects";
import { push } from "react-router-redux";
import { Map, toJS } from "immutable";
import * as Logger from "astrnt-web-logger";
import initializers from "../dependencies/initializers";
import { candidateUpdateApi } from "../apis/candidates";
import Swal from "sweetalert2";
import { getCandidate } from "../selectors";

import { t } from "../helpers/global";

export function* candidateUpdate({
  sessionCode,
  fullname,
  email
}: Object): Generator<*, *, *> {
  const candidate: Map<string, any> = yield select(getCandidate);
  try {
    const response: Object = yield call(
      candidateUpdateApi,
      sessionCode,
      fullname,
      email
    );
    var baseProfileUrl;
    if (initializers.api_host == "https://app.astrnt.co") {
      baseProfileUrl = "https://profile.astrnt.co/";
    } else {
      baseProfileUrl = "https://profile-beta.astrnt.co/";
    }

    if (response.data.status >= 200 && response.data.status < 300) {
      // console.log(response.data)

      yield put({
        type: "GLOBAL_FETCH_DATA_CANDIDATE_IS_LINK",
        candidate_is_link:
          baseProfileUrl +
          response.data.job_id +
          "/candidate/" +
          response.data.candidate_id
      });
    } else {
      // console.log(response.status)
      throw response;
    }

    //  yield all([put(push(`/code/${sessionCode}/mcq-welcome-video`))]);
  } catch (error) {
    yield put({
      type: "CANDIDATES_GET_DETAIL_ERROR_RESET"
    });

    if (typeof error.data != "undefined") {
      if (typeof error.data.message != "string") {
        var errorArray = {};
        if (error.data.message.email) {
          errorArray["email"] = error.data.message.email[0];
        }
        if (error.data.message.fullname) {
          errorArray["fullname"] = error.data.message.fullname[0];
        }

        yield put({
          type: "CANDIDATE_GET_DETAIL_FAILED_VALIDATION",
          errorValidation: errorArray
        });
      } else {
        yield put({
          type: "CANDIDATES_GET_DETAIL_FAILED",
          errorMessage: error.data.message
        });
      }
    } else {
      console.log("error");
    }
  }
}
