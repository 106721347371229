import { List, fromJS, Map } from "immutable";

const initFilterData = fromJS({
  token: "",
  gdpr_complied: "",
  gdpr_text: "",
  gdpr_aggrement_text: "",
  welcome_video_url: "",
  width: "",
  height: "",
  type: "",
  sub_type: "",
  invite_id: "",
  expired_date: "",
  duration_left: 0,
  attempt_left: 0,
  question_ids: [],
  is_cv_uploaded: false,
  is_section_started: false,
  is_access_profile: false,
  interview_settings_custom_pop_up: "",
  interview_settings_redirect_url: "",
  interview_settings_skipped_checking_browser_page: 0,
  interview_settings_skipped_introduction_page: 0,
  interview_settings_skipped_welcome_video_page: 0,
  interview_settings_skipped_interview_detail_page: 0,
  interview_settings_skipped_gdpr_page: 0,
  interview_settings_skipped_start_session_page: 0,
  interview_settings_skipped_question_info_page: 0,
  interview_settings_skipped_interview_finished_page: 0,
  interview_settings_display_video_processing_process: false,
  is_display_main_layout: false, // Only use this at HomeIndex component,
  is_course_auth: false,
  ecourse_payment_status: "",
  is_finish: false,
  video: []
});

const initUiData = fromJS({
  finished_or_failed_data: {},
  modal_loader_data: {}
});

const initVideoProcessingData = fromJS({
  questions: [],
  question_ids: []
});

const initCountDuration = fromJS({
  duration_left: 0,
  front_end_run_time: 0
});

export function sessionsStateInformation(state = Map({}), action) {
  switch (action.type) {
    case "SESSIONS_FETCH_STATE_INFORMATION_SUCCESS":
      return fromJS(action.information);
    case "SESSIONS_UPDATE_IS_ONBOARDING_ON_STATE_INFORMATION_SUCCESS":
      return state.set("is_onboarding", 1);
    case "SESSIONS_ADD_IS_WATCHED_ON_STATE_INFORMATION_SUCCESS":
      return state.set("is_watched", action.is_watched);
    default:
      return state;
  }
}

export function videoProcessingProgressInfo(
  state = initVideoProcessingData,
  action
) {
  switch (action.type) {
    case "VIDEO_PROCESSING_PROGRESS_GET_INFO_QUESTIONS_SUCCESS":
      return state.set("questions", List(action.questions));
    case "VIDEO_PROCESSING_PROGRESS_GET_INFO_QUESTION_IDS_SUCCESS":
      return state.set("question_ids", List(action.question_ids));
    default:
      return state;
  }
}

export function sessionsSetGDPR(state = Map({}), action) {
  switch (action.type) {
    case "SESSIONS_SET_GDPR_SUCCESS":
      return state.set("gdpr_complied", 1);
    default:
      return state;
  }
}

export function sessionsFilterData(state = initFilterData, action) {
  switch (action.type) {
    case "SESSIONS_ADD_FILTER_TOKEN_SUCCESS":
      return state.set("token", action.token);
    case "SESSIONS_ADD_GDPR_COMPLIED_SUCCESS":
      return state.set("gdpr_complied", action.gdpr_complied);
    case "SESSIONS_ADD_GDPR_TEXT_SUCCESS":
      return state.set("gdpr_text", action.gdpr_text);
    case "SESSIONS_ADD_GDPR_AGREE_SUCCESS":
      return state.set("gdpr_aggrement_text", action.gdpr_aggrement_text);
    case "SESSIONS_ADD_WELCOME_VIDEO_URL_SUCCESS":
      return state.set("welcome_video_url", action.welcome_video_url);
    case "SESSIONS_ADD_WELCOME_VIDEO_WIDTH_SUCCESS":
      return state.set("width", action.width);
    case "SESSIONS_ADD_WELCOME_VIDEO_HEIGHT_SUCCESS":
      return state.set("height", action.height);
    case "SESSIONS_ADD_FILTER_TYPE_SUCCESS":
      return state.set("type", action.interviewType);
    case "SESSIONS_ADD_FILTER_SUB_TYPE_SUCCESS":
      return state.set("sub_type", action.sub_type);
    case "SESSIONS_ADD_FILTER_INVITE_ID_SUCCESS":
      return state.set("invite_id", action.invite_id);
    case "SESSIONS_ADD_FILTER_EXPIRED_DATE_SUCCESS":
      return state.set("expired_date", action.expired_date);
    case "SESSIONS_ADD_FILTER_DURATION_LEFT_SUCCESS":
      return state.set("duration_left", action.duration_left);
    case "SESSIONS_ADD_FILTER_ATTEMPT_LEFT_SUCCESS":
      return state.set("attempt_left", action.attempt_left);
    case "SESSIONS_ADD_FILTER_QUESTION_IDS_SUCCESS":
      return state.set("question_ids", List(action.question_ids));
    case "SESSIONS_ADD_FILTER_IS_CV_UPLOADED_SUCCESS":
      return state.set("is_cv_uploaded", action.is_cv_uploaded);
    case "SESSIONS_ADD_FILTER_IS_SECTION_STARTED_SUCCESS":
      return state.set("is_section_started", action.is_section_started);
    case "SESSIONS_ADD_FILTER_IS_DISPLAY_MAIN_LAYOUT_SUCCESS":
      return state.set("is_display_main_layout", action.is_display_main_layout);
    case "SESSIONS_ADD_IS_ACCESS_PROFILE_SUCCESS":
      return state.set("is_access_profile", action.is_access_profile);
    case "INTERVIEW_SETTINGS_CUSTOM_POP_UP_SUCCESS":
      return state.set(
        "interview_settings_custom_pop_up",
        action.interview_settings_custom_pop_up
      );
    case "INTERVIEW_SETTINGS_REDIRECT_URL_SUCCESS":
      return state.set(
        "interview_settings_redirect_url",
        action.interview_settings_redirect_url
      );
    case "INTERVIEW_SETTINGS_SKIPPED_CHECKING_BROWSER_PAGE_SUCCESS":
      return state.set(
        "interview_settings_skipped_checking_browser_page",
        action.interview_settings_skipped_checking_browser_page
      );
    case "INTERVIEW_SETTINGS_SKIPPED_INTRODUCTION_PAGE_SUCCESS":
      return state.set(
        "interview_settings_skipped_introduction_page",
        action.interview_settings_skipped_introduction_page
      );
    case "INTERVIEW_SETTINGS_SKIPPED_WELCOME_VIDEO_PAGE_SUCCESS":
      return state.set(
        "interview_settings_skipped_welcome_video_page",
        action.interview_settings_skipped_welcome_video_page
      );
    case "INTERVIEW_SETTINGS_SKIPPED_INTERVIEW_DETAIL_PAGE_SUCCESS":
      return state.set(
        "interview_settings_skipped_interview_detail_page",
        action.interview_settings_skipped_interview_detail_page
      );
    case "INTERVIEW_SETTINGS_SKIPPED_GDPR_PAGE_SUCCESS":
      return state.set(
        "interview_settings_skipped_gdpr_page",
        action.interview_settings_skipped_gdpr_page
      );
    case "INTERVIEW_SETTINGS_SKIPPED_START_SESSION_PAGE_SUCCESS":
      return state.set(
        "interview_settings_skipped_start_session_page",
        action.interview_settings_skipped_start_session_page
      );
    case "INTERVIEW_SETTINGS_SKIPPED_QUESTION_INFO_PAGE_SUCCESS":
      return state.set(
        "interview_settings_skipped_question_info_page",
        action.interview_settings_skipped_question_info_page
      );
    case "INTERVIEW_SETTINGS_DISPLAY_VIDEO_PROCESSING_PROCESS_SUCCESS":
      return state.set(
        "interview_settings_display_video_processing_process",
        action.interview_settings_display_video_processing_process
      );
    case "INTERVIEW_SETTINGS_SKIPPED_INTERVIEW_FINISHED_PAGE_SUCCESS":
      return state.set(
        "interview_settings_skipped_interview_finished_page",
        action.interview_settings_skipped_interview_finished_page
      );
    case "INTERVIEW_COURSE_AUTHORIZED_SUCCESS":
      return state.set("is_course_auth", action.is_course_auth);
    case "INTERVIEW_COURSE_PAYMENT_SUCCESS":
      return state.set("ecourse_payment_status", action.ecourse_payment_status);
    case "INTERVIEW_COURSE_CODE_SUCCESS":
      return state.set("ecourse_code", action.ecourse_code);
    case "INTERVIEW_ADD_FINISH_SUCCESS":
      return state.set("is_finish", action.is_finish);
    case "SESSIONS_FETCH_VIDEO_INFORMATION_SUCCESS":
      return state.set("video", action.video);
    case "SESSIONS_CLEAR_FILTER_DATA_SUCCESS":
      return fromJS({
        token: "",
        gdpr_complied: "",
        gdpr_text: "",
        gdpr_aggrement_text: "",
        welcome_video_url: "",
        width: "",
        height: "",
        type: "",
        invite_id: "",
        expired_date: "",
        duration_left: 0,
        attempt_left: 0,
        question_ids: [],
        is_cv_uploaded: false,
        is_section_started: false,
        is_display_main_layout: false,
        interview_settings_custom_pop_up: "",
        interview_settings_redirect_url: "",
        interview_settings_skipped_checking_browser_page: 0,
        interview_settings_skipped_introduction_page: 0,
        interview_settings_skipped_welcome_video_page: 0,
        interview_settings_skipped_interview_detail_page: 0,
        interview_settings_skipped_gdpr_page: 0,
        interview_settings_skipped_start_session_page: 0,
        interview_settings_skipped_question_info_page: 0,
        interview_settings_skipped_interview_finished_page: 0,
        interview_settings_display_video_processing_process: false,
        is_access_profile: 0,
        is_course_auth: false,
        ecourse_payment_status: "",
        is_finish: false,
        video: []
      });
    default:
      return state;
  }
}

export function sessionDurationCount(state = initCountDuration, action) {
  switch (action.type) {
    case "SESSIONS_DURATION_TEST_FULL":
      return state.set("duration_left", action.duration_left);
    case "FRONT_END_RUN_TIME":
      return state.set("front_end_run_time", action.front_end_run_time);
    default:
      return state;
  }
}

export function sessionsUiData(state = initUiData, action) {
  switch (action.type) {
    case "SESSIONS_ADD_UI_FINISHED_OR_FAILED_DATA_SUCCESS":
      return state.set(
        "finished_or_failed_data",
        fromJS(action.finished_or_failed_data)
      );
    case "SESSIONS_ADD_UI_MODAL_LOADER_DATA_SUCCESS":
      return state.set("modal_loader_data", fromJS(action.modal_loader_data));
    case "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS":
      return state.set("loading", action.loading);
    case "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED":
      return state.set("disabled_button", action.disabled_button);
    case "SESSIONS_CLEAR_UI_DATA_SUCCESS":
      return fromJS({
        finished_or_failed_data: {},
        modal_loader_data: {}
      });
    default:
      return state;
  }
}
