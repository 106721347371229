// @flow

import { put, call, select, all } from "redux-saga/effects";
import { push } from "react-router-redux";
import { Map } from "immutable";
import * as Logger from "astrnt-web-logger";
import { sessionsFinish } from "./sessions";
import Swal from "sweetalert2";
import {
  sessionsStartApi,
  sessionsUpdateElapsedTimeApi,
  sessionsSetGDPRComplied,
  sessionsFetchInitialDataApi
} from "../apis/sessions";

import {
  questionsAnswer,
  questionsFinish,
  questionsGetDetail,
  questionsGetNextId
} from "./questions";

import {
  getQuestion,
  getTranslations,
  getSessionsFilterData,
  getSessionsStateInformation
} from "../selectors";

import { t } from "../helpers/global";

export function* mcqsStartWelcomeVideo({
  sessionCode
}: Object): Generator<*, *, *> {
  try {
    const sessionsFilterData: Map<string, any> = yield select(
      getSessionsFilterData
    );
    const sessionsStateInformation: Map<string, any> = yield select(
      getSessionsStateInformation
    );

    yield all([
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
        loading: true
      }),
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
        disabled_button: true
      })
    ]);

    yield call(
      sessionsSetGDPRComplied,
      sessionCode,
      sessionsFilterData.get("token")
    );

    yield all([
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
        loading: false
      }),
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
        disabled_button: false
      })
    ]);

    yield call(mcqsStart, { sessionCode });
    // yield all([put(push(`/code/${sessionCode}/mcq-welcome-video`))]);
  } catch (error) {
    yield all([
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
        loading: false
      }),
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
        disabled_button: false
      })
    ]);
    console.error(error);
  }
}

export function* mcqsStart({ sessionCode }: Object): Generator<*, *, *> {
  const translations: Map<string, any> = yield select(getTranslations);
  try {
    const sessionsFilterData: Map<string, any> = yield select(
      getSessionsFilterData
    );
    const sessionsStateInformation: Map<string, any> = yield select(
      getSessionsStateInformation
    );

    yield all([
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
        loading: true
      }),
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
        disabled_button: true
      })
    ]);

    yield call(
      sessionsSetGDPRComplied,
      sessionCode,
      sessionsFilterData.get("token")
    );

    const response: Object = yield call(
      sessionsStartApi,
      sessionCode,
      sessionsFilterData.get("token")
    );

    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data.message != "not authorized"
    ) {
      const questionId: number = sessionsFilterData
        .get("question_ids")
        .get(sessionsStateInformation.get("interviewIndex"));

      yield call(mcqsGoToNextQuestion, questionId, sessionCode);

      yield all([
        put({
          type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
          loading: false
        }),
        put({
          type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
          disabled_button: false
        })
      ]);
    } else {
      throw response;
    }
  } catch (error) {
    yield all([
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
        loading: false
      }),
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
        disabled_button: false
      })
    ]);
    console.error(error);
    // console.log(error.data)
    if (typeof error.data == "undefined") {
      console.log(error);
    } else {
      if (error.data.message == "not authorized") {
        Swal({
          type: "error",
          title: t(translations, "MULTIPLE_DEVICES_TITLE"),
          text: t(translations, "MULTIPLE_DEVICES_CONTENT")
        }).then(function() {
          window.location.reload(true);
        });

        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
}

export function* mcqsGDPR({ sessionCode }: Object): Generator<*, *, *> {
  try {
    const sessionsFilterData: Map<string, any> = yield select(
      getSessionsFilterData
    );

    const response: Object = yield call(
      getSessionsFilterData,
      sessionCode,
      sessionsFilterData.get("token"),
      sessionsFilterData.get("gdpr_complied"),
      sessionsFilterData.get("gdpr_text")
    );
    yield all([put(push(`/code/${sessionCode}/check-gdpr-mcq`))]);
  } catch (error) {
    console.error(error);
  }
}

export function* mcqsGoToNextQuestion(
  questionId: number,
  sessionCode: string
): Generator<*, *, *> {
  try {
    yield call(questionsGetDetail, questionId, sessionCode);

    yield all([
      put({
        type: "QUESTIONS_REDUCE_DURATION_LEFT_REQUEST",
        sessionCode: sessionCode
      }),
      put(push(`/code/${sessionCode}/mcq-questions/${questionId}`))
    ]);
  } catch (error) {
    console.log(error);
  }
}

export function* mcqsAnswerQuestion({
  sessionCode
}: Object): Generator<*, *, *> {
  try {
    const response: Object = yield call(questionsAnswer, sessionCode, "test");

    if (response.status >= 200 && response.status < 300) {
      const question: Map<string, any> = yield select(getQuestion);
      const sessionsFilterData: Map<string, any> = yield select(
        getSessionsFilterData
      );

      let params = {
        event: "MCQ Question", // string
        message:
          "Success send question to /api/v2/question/answer for question id = " +
          question.get("id"),
        status: "offline" // string
      };

      Logger.recordEvent(params);

      yield call(questionsFinish, question.get("id"), sessionCode);

      yield call(
        sessionsUpdateElapsedTimeApi,
        question.get("id"),
        "test",
        sessionCode,
        sessionsFilterData.get("token")
      );

      const questionId: number = yield call(questionsGetNextId);

      if (questionId) {
        yield call(questionsGetDetail, questionId, sessionCode);

        if (
          sessionsFilterData.get("type") === "close test" &&
          sessionsFilterData.get("sub_type") != "freetext"
        ) {
          yield put({
            type: "QUESTIONS_REDUCE_DURATION_LEFT_REQUEST",
            sessionCode: sessionCode
          });
        }
        yield put(push(`/code/${sessionCode}/mcq-questions/${questionId}`));
      } else {
        const translations: Map<string, any> = yield select(getTranslations);

        yield call(
          sessionsFinish,
          sessionCode,
          t(translations, "INTERVIEW_DATA_THATS"),
          t(translations, "INTERVIEW_DATA_YOU_HAVE")
        );
      }
    } else {
      throw response;
    }
  } catch (error) {
    console.log(error);
  }
}
