import { fromJS } from "immutable";

const initUiData = fromJS({
  question_title: "",
  recording_title: "",
  recording_dot_type: "",
  upload_title: "",
  upload_status: "",
  upload_video_percentage: 0
});

const initPracticeRecordsound = fromJS({
  is_record: false,
  duration: 5,
  is_done: false,
  valid: false
});

const initFilterData = fromJS({
  is_practice: false,
  is_finished: false,
  is_counting: false,
  is_modal_open: false,
  is_low_sound: false,
  is_no_sound: false,
  is_practice_no_sound: false,
  is_test_sound: false,
  question_takes_count: 0,
  question_used_attempt: 0,
  question_duration: 0,
  countdown_duration: 0,
  modal_loader_data: []
});

export function interviewPracticeSound(
  state = initPracticeRecordsound,
  action
) {
  switch (action.type) {
    case "INTERVIEWS_PRACTICE_SOUND_START_SUCCESS":
      return state.set("is_record", action.is_record);
    case "INTERVIEWS_PRACTICE_SOUND_DURATION_SUCCESS":
      return state.set("duration", action.duration);
    case "INTERVIEWS_PRACTICE_SOUND_IS_DONE_SUCCESS":
      return state.set("is_done", action.is_done);
    case "INTERVIEWS_PRACTICE_VALID_SUCCESS":
      return state.set("valid", action.valid);
    case "INTERVIEWS_PRACTICE_RETRY_SUCCESS":
      return fromJS({
        is_record: false,
        duration: 5,
        is_done: false,
        valid: false
      });
    default:
      return state;
  }
}

export function interviewsUiData(state = initUiData, action) {
  switch (action.type) {
    case "INTERVIEWS_ADD_UI_QUESTION_TITLE_SUCCESS":
      return state.set("question_title", action.question_title);
    case "INTERVIEWS_ADD_UI_RECORDING_TITLE_SUCCESS":
      return state.set("recording_title", action.recording_title);
    case "INTERVIEWS_ADD_UI_RECORDING_DOT_TYPE_SUCCESS":
      return state.set("recording_dot_type", action.recording_dot_type);
    case "INTERVIEWS_ADD_UI_UPLOAD_TITLE_SUCCESS":
      return state.set("upload_title", action.upload_title);
    case "INTERVIEWS_ADD_UI_UPLOAD_STATUS_SUCCESS":
      return state.set("upload_status", action.upload_status);
    case "INTERVIEWS_ADD_UI_UPLOAD_VIDEO_PERCENTAGE_SUCCESS":
      return state.set(
        "upload_video_percentage",
        action.upload_video_percentage
      );
    default:
      return state;
  }
}

export function interviewsFilterData(state = initFilterData, action) {
  switch (action.type) {
    case "INTERVIEWS_ADD_FILTER_IS_PRACTICE_SUCCESS":
      return state.set("is_practice", action.is_practice);
    case "INTERVIEWS_ADD_FILTER_IS_FINISHED_SUCCESS":
      return state.set("is_finished", action.is_finished);
    case "INTERVIEWS_ADD_FILTER_IS_COUNTING_SUCCESS":
      return state.set("is_counting", action.is_counting);
    case "INTERVIEWS_ADD_FILTER_IS_MODAL_OPEN_SUCCESS":
      return state.set("is_modal_open", action.is_modal_open);
    case "INTERVIEWS_ADD_FILTER_IS_MODAL_INTERVIEW_CUSTOM_SUCCESS":
      return state.set("modal_loader_data", fromJS(action.modal_loader_data));
    case "INTERVIEWS_ADD_FILTER_IS_LOW_SOUND_SUCCESS":
      return state.set("is_low_sound", action.is_low_sound);
    case "INTERVIEWS_ADD_FILTER_IS_NO_SOUND_SUCCESS":
      return state.set("is_no_sound", action.is_no_sound);
    case "INTERVIEWS_ADD_FILTER_IS_PRACTICE_NO_SOUND_SUCCESS":
      return state.set("is_practice_no_sound", action.is_practice_no_sound);
    case "INTERVIEWS_ADD_FILTER_IS_TEST_SOUND_SUCCESS":
      return state.set("is_test_sound", action.is_test_sound);
    case "INTERVIEWS_ADD_FILTER_QUESTION_TAKES_COUNT_SUCCESS":
      return state.set("question_takes_count", action.question_takes_count);
    case "INTERVIEWS_ADD_FILTER_QUESTION_USED_ATTEMPT_SUCCESS":
      return state.set("question_used_attempt", action.question_used_attempt);
    case "INTERVIEWS_ADD_FILTER_QUESTION_DURATION_SUCCESS":
      return state.set("question_duration", action.question_duration);
    case "INTERVIEWS_REDUCE_QUESTION_DURATION_SUCCESS":
      return state.set("question_duration", action.question_duration);
    case "INTERVIEWS_ADD_FILTER_COUNTDOWN_DURATION_SUCCESS":
      return state.set("countdown_duration", action.countdown_duration);
    case "INTERVIEWS_REDUCE_COUNTDOWN_DURATION_SUCCESS":
      return state.set("countdown_duration", action.countdown_duration);
    case "INTERVIEWS_REMOVE_COUNTDOWN_AND_START":
      return state.set("countdown_duration", action.countdown_duration);
    case "INTERVIEWS_ADD_VOLUME_CHECKER":
      return state.set("is_check_sound", action.is_check_sound);
    default:
      return state;
  }
}
