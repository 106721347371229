import _axios from "../dependencies/_axios";

export function candidateUpdateApi(sessionCode, fullname, email) {
  return _axios({
    url: "/api/web/Mmvp/candidate/" + sessionCode + "/candidateUpdate",
    method: "post",
    data: {
      fullname: fullname,
      email: email
    }
  });
}
