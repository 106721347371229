// @flow

import React from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import pluralize from  'pluralize'
import Button from './Button'
import ButtonLabel from './ButtonLabel'
import MediaInstruction from './MediaInstruction'

import { t } from '../../helpers/global'

type Props = {
  isPlaying?: boolean,
  takesCount?: number,
  translations: Map<string, any>,
  handlePlayClick?: Function,
  handlePauseClick?: Function,
  handleRetakeClick?: Function,
  handleDoneClick?: Function
};

class ControlPanel extends React.Component<Props> {
  render() {
    const {
      takesCount,
      isPlaying,
      translations,
      handlePlayClick,
      handlePauseClick,
      handleRetakeClick,
      handleDoneClick
    } = this.props

    return(
      <div className="footer-video flex-row flex-column center">
        {takesCount ?
          <MediaInstruction
            text={t(
              translations,
              "CONTROL_PANEL_YOU_HAVE",
              {
                ':takes_count': takesCount,
                ':pluralize_take': pluralize("take", takesCount)
              }
            )} />
          :
          ''
        }
        <div className="horizontal-item--center">
          <div className={`video-control d-flex justify-content-center vertical-item--center`}>
            <ButtonLabel
              handleActionClick={handleRetakeClick}
              type="button"
              theme="image"
              className="btn-retry"
              buttonLabel={t(translations, "LABEL_RETAKE")}
              disabled={!takesCount} />
            {isPlaying ?
              <Button
                onClick={handlePauseClick}
                theme="image"
                className="btn-pause" />
              :
              <Button
                onClick={handlePlayClick}
                theme="image"
                className="btn-play" />
            }
            <ButtonLabel
              handleActionClick={handleDoneClick}
              type="button"
              theme="image"
              className="btn-checklist"
              buttonLabel={t(translations, "LABEL_DONE")} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations
  }
}

export default connect(mapStateToProps, null)(ControlPanel)
