// @flow

import React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import LoadingBlock from "../elements/LoadingBlock";
import Button from "../elements/Button";
type Props = {
  isOpen: boolean,
  title: string,
  text: string,
  style: Object
};

class ModalLoaderInterviewCustom extends React.Component<Props> {
  render() {
    const {
      isOpen,
      title,
      text,
      style,
      isLoading,
      handleClick,
      buttonText,
      body
    } = this.props;

    return (
      <Modal isOpen={isOpen} style={style}>
        <ModalBody>
          {isLoading ? (
            <LoadingBlock className="heading1 larger m-b-12" />
          ) : null}

          <h2 className="heading2">{title}</h2>
          <div dangerouslySetInnerHTML={{ __html: text }} />
          <div className="m-b-10" dangerouslySetInnerHTML={{ __html: body }} />
        </ModalBody>
        {buttonText == "" ? null : (
          <ModalFooter>
            <Button
              type="button"
              theme="primary"
              size="medium"
              className="button-impress"
              onClick={handleClick}
            >
              {buttonText}
            </Button>
          </ModalFooter>
        )}
      </Modal>
    );
  }
}

export default ModalLoaderInterviewCustom;
