// @flow

import React from "react";
import bindAll from "lodash/bindAll";
import classnames from "classnames";
import ReactImageMagnify from "react-image-magnify";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

type Props = {
  className: string,
  imageUrl: string,
  alt: string
};

type State = {
  isOpen: boolean
};

class ImageWithZoom extends React.Component<Props, State> {
  constructor(props: Object) {
    super(props);

    bindAll(this, ["handleOpenModal"]);

    this.state = {
      photoIndex: 0,
      isOpen: false
    };
  }

  static defaultProps = {
    className: "",
    imageUrl: "",
    alt: ""
  };

  handleOpenModal() {
    this.setState({ isOpen: true });
  }

  render() {
    const { className, imageUrl, alt } = this.props;

    const { isOpen } = this.state;

    const classProps = classnames("img-responsive", className);
    const imageZoom = imageUrl;
    const userAgent = navigator.userAgent || navigator.vendor;
    var img = new Image();
    img.src = imageZoom;
    var original_width = img.naturalWidth;
    var original_height = img.naturalHeight;
    let imageZoomContainer = (
      <div style={{ display: "inline-block" }}>
        {/*<ReactImageMagnify
          {...{
            smallImage: {
              alt: alt,
              isFluidWidth: true,
              src: imageZoom,
              srcSet: imageZoom + " 1200w",
              sizes: "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 430px"
            },
            largeImage: {
              src: imageZoom,
              width: 400,
              height: 250,
              sizes: "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 430px"
            },
            imageClassName: classProps, 
            isHintEnabled: false, 
            enlargedImageClassName : 'enlarged_image',
            enlargedImageContainerDimensions: {
              width: "120%",
              height: "100%"
            }
          }}
        />*/}
        <ReactImageMagnify
          {...{
            smallImage: {
              alt: alt,
              isFluidWidth: true,
              src: imageZoom,
              srcSet: imageZoom + " 1200w",
              sizes: "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 430px"
            },
            largeImage: {
              src: imageZoom,
              width: original_width,
              height: original_height
            },
            imageClassName: classProps,
            isHintEnabled: false,
            enlargedImageClassName: "enlarge_image",
            shouldHideHintAfterFirstActivation: false,
            enlargedImageContainerDimensions: {
              width: "100%",
              height: "100%"
            }
          }}
        />
      </div>
    );

    if (
      /android/i.test(userAgent) ||
      (/ipad|iphone|ipod/i.test(userAgent) && !window.MSStream)
    ) {
      imageZoomContainer = (
        <a onClick={this.handleOpenModal}>
          <img src={imageZoom} alt={alt} className={classProps} />
        </a>
      );
    }

    return (
      <div className="img-container">
        {imageZoomContainer}
        {isOpen && (
          <Lightbox
            mainSrc={imageZoom}
            onCloseRequest={() => this.setState({ isOpen: false })}
          />
        )}
      </div>
    );
  }
}

export default ImageWithZoom;
