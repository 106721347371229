// @flow

import React from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import classnames from 'classnames'
import pluralize from 'pluralize'
import { t } from '../../helpers/global'

const textSize = {
  medium: 'heading6',
  large: 'heading4',
}

type Props = {
  translations: Map<string, any>,
  current: string | number,
  end: string | number,
  className: string,
  size: string,
};


class QuestionAnswered extends React.Component<Props> {
  static defaultProps = {
    current: 1,
    end: 7,
    className: '',
    size: 'large',
  }

  render() {
    const {
      current,
      end,
      className,
      size,
      translations
    } = this.props

    const classProps: string = classnames(
      textSize[size],
      className
    )

    return (
      <h4 className={classProps}>
        <b>{current}</b> {t(translations,"LABEL_OF")} {end} {t(translations,"LABEL_PLURAL_QUESTION",{
          ":pluralize_question":pluralize("Question",end)
        })} {t(translations, "MCQ_ANSWERED")}
      </h4>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    translations: state.translations,
  }
}

export default connect(mapStateToProps, null)(QuestionAnswered)
