// @flow

import React from 'react'
import CircularProgressbar from 'react-circular-progressbar'

type Props = {
  percentage: number,
  classForPercentage: string,
  textForPercentage: string,
  handleFinishRecordingClick: Function
};

class CircleProgressBar extends React.Component<Props> {
  static defaultProps = {
    percentage: 0,
    classForPercentage: '',
    textForPercentage: '',
    handleFinishRecordingClick: () => {}
  }

  render() {
    const {
      handleFinishRecordingClick,
      percentage,
      classForPercentage,
      textForPercentage
    } = this.props

    return (
      <div style={{width:'59px',height:'59px'}}>
        <a
          onClick={handleFinishRecordingClick}>
          <CircularProgressbar
            className={`circular-counter ${classForPercentage}`}
            percentage={percentage}
            background="#FFFFFF"
            strokeWidth="6"
            backgroundPadding="0"
            text={textForPercentage} />
        </a>
      </div>
    )
  }
}

export default CircleProgressBar
