// @flow

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import bindAll from "lodash/bindAll";
import { Map } from "immutable";
import { finishOnBoarding } from "../../apis/global";
import {
  sessionsUpdateIsOnBoardingSuccess,
  sessionsAddIsWatchedOnStateInformation
} from "../../actions/sessions";
import { sToTime } from "../../helpers/global";
import Button from "../../lib/elements/Button";
import ReactJWPlayer from "react-jw-player";

type Props = {
  text: string,
  title: string,
  style: ?Object,
  children?: any,
  isOpen: boolean,
  sessionCode: string,
  translations: Map<string, any>,
  sessionsStateInformation: Map<string, any>,
  sessionsUpdateIsOnBoardingSuccess: Function,
  sessionsFilterData: Map<string, any>,
  isVideoFinished: boolean
};

class ModalWelcomeVideo extends React.Component<Props> {
  constructor(props: Object) {
    super(props);

    this.state = {
      isModalOpen: true,
      isCloseButtonVisible: false,
      isHidePlay: true,
      is_play: false,
      percent_audio: 0,
      max_duration: "",
      current_duration: "",
      muted: false,
      videoElement: <div id="welcomeVideo"></div>
    };

    bindAll(this, [
      "handleToggleModal",
      "handleCloseButtonVisibility",
      "handleRemoveModalOpenClass",
      "handleOpenModal",
      "videoStyle",
      "handleDurationChange",
      "handlePlayVideoClick",
      "handleResetPlayButton",
      "handleVolumeVideoClick"
    ]);
  }

  static defaultProps = {
    title: "",
    text: "",
    style: null,
    children: null,
    isOpen: true,
    modal: false
  };

  handleResetPlayButton() {
    this.setState({ is_play: false });
  }

  handleVolumeVideoClick() {
    this.setState({ muted: !this.state.muted });
    let audio_url = document.getElementById("welcomeVideo");
    audio_url.muted = !this.state.muted;
  }

  handlePlayVideoClick() {
    let audio_url = document.getElementById("welcomeVideo");

    this.setState({ is_play: !this.state.is_play }, () => {
      if (this.state.is_play == true) {
        audio_url.play();
      } else if (this.state.is_play == false) {
        audio_url.pause();
      }
    });
  }

  handleDurationChange(e) {
    const current_time = e.target.currentTime;
    const duration = e.target.duration;
    const percent = (current_time * 100) / duration;
    let currentTime = sToTime(current_time);

    let __duration = sToTime(duration);

    this.setState({ max_duration: __duration }, () => {
      this.setState({ current_duration: currentTime }, () => {
        this.setState({ percent_audio: percent }, () => {
          if (this.state.current_duration == this.state.max_duration) {
            this.handleResetPlayButton();
          }
        });
      });
    });
  }

  componentDidMount() {
    this.props.onRef(this);
    const { sessionsStateInformation, sessionsFilterData } = this.props;
    document
      .getElementById("welcomeVideo")
      .addEventListener("ended", this.handleCloseButtonVisibility, false);
    var isWatched = sessionsStateInformation.get("is_watched");
    if (!sessionsStateInformation.get("is_onboarding")) {
      this.setState({ isModalOpen: false });
    } else if (sessionsFilterData.get("welcome_video_url") == "") {
      this.setState({ isModalOpen: false });
    }
    if (isWatched == 1 || sessionsFilterData.get("is_access_profile") == 1) {
      this.setState({ isModalOpen: false });
    }
    if (
      sessionsFilterData.get("interview_settings_skipped_welcome_video_page") ==
      1
    ) {
      this.setState({ isModalOpen: false });
    }
    const videoStyle = this.videoStyle();
    document.getElementsByClassName("welcome-video-modal")[0].style.width =
      videoStyle.width;
    document.getElementsByClassName("welcome-video-modal")[0].style.height =
      videoStyle.height;
    var vids = document.getElementById("welcomeVideo");
    vids.controls = false;
  }

  handleToggleModal(e) {
    e.preventDefault();

    const { sessionCode, sessionsUpdateIsOnBoardingSuccess } = this.props;

    finishOnBoarding(sessionCode);
    sessionsUpdateIsOnBoardingSuccess();
    this.handleRemoveModalOpenClass();
  }

  handleCloseButtonVisibility() {
    const { sessionsAddIsWatchedOnStateInformation, sessionCode } = this.props;
    sessionsAddIsWatchedOnStateInformation(sessionCode);
    this.setState({ isCloseButtonVisible: true });
  }

  handleRemoveModalOpenClass() {
    document.getElementsByTagName("Body")[0].classList.remove("modal-open");
    this.setState({ isModalOpen: false });
  }

  handleOpenModal(buttonClose) {
    this.setState({ isModalOpen: true });
    const videoStyle = this.videoStyle();
    const welcome_video = document.getElementsByClassName(
      "welcome-video-modal"
    );
    setTimeout(function() {
      welcome_video[0].style.width = videoStyle.width;
      welcome_video[0].style.height = videoStyle.height;
    }, 100);
    if (buttonClose == false) {
      this.setState({ isCloseButtonVisible: true });
    } else {
      this.setState({ isCloseButtonVisible: false });
    }
    setTimeout(() => {
      var vids = document.getElementById("welcomeVideo");
      vids.controls = false;

      const video = this.props.sessionsFilterData.get("video");
      let elm =
        video["type"] == "jwplayer" ? (
          <ReactJWPlayer
            playerId="welcomeVideo"
            // playerScript="https://cdn.jwplayer.com/libraries/GNOuvQkL.js" // using account januar@astrnt.co
            // playlist="https://content.jwplatform.com/feeds/Pc84XccB.json"
            playerScript={video["player_script"]}
            playlist={video["playlist_url"]}
          />
        ) : (
          <ReactJWPlayer
            playerId="welcomeVideo"
            playerScript="https://cdn.jwplayer.com/libraries/GNOuvQkL.js" // using account januar@astrnt.co
            file={video["welcome_video_url"]}
          />
        );
      this.setState({ videoElement: elm });
    }, 1000);
  }

  videoStyle() {
    const { sessionsFilterData } = this.props;

    const widthOri = sessionsFilterData.get("width");
    const heightOri = sessionsFilterData.get("height");

    const width = sessionsFilterData.get("width") + 64;
    const height = sessionsFilterData.get("height") + 82;
    var finalWidth = "";
    var finalHeight = "";
    var landscapeSquare = width / height;
    var portraitSquare = height / width;
    if (width > height) {
      finalWidth = "auto";
      finalHeight = "70%";
    } else if (heightOri == 640 && widthOri == 288) {
      finalWidth = "20%";
      finalHeight = "27%";
    } else {
      finalWidth = "24%";
      finalHeight = "27%";
    }
    if (
      (landscapeSquare <= 1.2 && landscapeSquare >= 1) ||
      (portraitSquare >= 0.8 && portraitSquare <= 1)
    ) {
      finalWidth = "50%";
      finalHeight = "50%";
    }
    return { width: `${finalWidth}`, height: `${finalHeight}` };
  }
  render() {
    // const jwplayerMediaJson = 'https://content.jwplatform.com/feeds/' + sessionsFilterData.get("welcome_video_url").split('/')[4] + '.json';

    return (
      <Modal
        isOpen={this.state.isModalOpen}
        className="welcome-video-modal"
        centered
      >
        <ModalBody className="content">{this.state.videoElement}</ModalBody>
        <ModalFooter className={this.state.isCloseButtonVisible ? "" : "hide"}>
          <Button
            type="button"
            theme="secondary"
            size="small"
            className="close-button"
            onClick={this.handleRemoveModalOpenClass}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations,
    sessionCode: ownProps.match.params.sessionCode,
    sessionsStateInformation: state.sessionsStateInformation,
    sessionsFilterData: state.sessionsFilterData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sessionsUpdateIsOnBoardingSuccess: () =>
      dispatch(sessionsUpdateIsOnBoardingSuccess()),
    sessionsAddIsWatchedOnStateInformation: sessionCode =>
      dispatch(sessionsAddIsWatchedOnStateInformation(sessionCode))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalWelcomeVideo)
);
