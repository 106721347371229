// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import DetectRTC from "detectrtc";
import bindAll from "lodash/bindAll";
import { Map, List } from "immutable";
import UAParser from "ua-parser-js";
import AllowDeviceChrome from "../partials/AllowDeviceChrome";
import AllowDeviceFirefox from "../partials/AllowDeviceFirefox";
import AllowDeviceOpera from "../partials/AllowDeviceOpera";
import AllowDeviceSafari from "../partials/AllowDeviceSafari";
import ModalOnBoarding from "../partials/ModalOnBoarding";
import InterviewIndex from "../interviews/InterviewIndex";
import InterviewGDPRPage from "../partials/InterviewGDPRPage";
import McqIndex from "../mcqs/McqIndex";
import McqIndexInProgress from "../mcqs/McqIndexInProgress";
import FtqIndex from "../freetext/FtqIndex";
import FtqIndexInProgress from "../freetext/FtqIndexInProgress";
import SectionIndex from "../sections/SectionIndex";
import SectionIndexInProgress from "../sections/SectionIndexInProgress";
import CheckingBrowser from "../../lib/pages/CheckingBrowser";
import UnsupportedBrowser from "../../lib/pages/UnsupportedBrowser";
import CompletedSessionWithForm from "../../components/partials/CompletedSessionWithForm";
import Swal from "sweetalert2";
import * as Logger from "astrnt-web-logger";
import { sendAstronautQnASessionEvent } from "../../actions/embedded-interview";

import {
  interviewsCheckGDPRRequest,
  interviewsStartSessionRequest,
  interviewsStartWelcomeVideoRequest,
  interviewsStartSessionPracticeRequest
} from "../../actions/interviews";

import { sessionRunTimeWatcher } from "../../actions/sessions";

import { frontEndRunTime } from "../../dependencies/frontEndRuntime";

import { checkConnection } from "../../dependencies/checkConnection";

import { t, globalHandlePageReloadHelper } from "../../helpers/global";

import image33 from "../../images/loader.gif";

type Props = {
  history: Object,
  sessionCode: string,
  sessionsFilterData: Map<string, any>,
  sessionsStateInformation: Map<string, any>,
  section: Map<string, any>,
  sections: List<Map<string, any>>,
  job: Map<string, any>,
  candidate: Map<string, any>,
  company: Map<string, any>
};

type State = {
  mainLayoutComponent: Object
};

class SessionIndex extends React.Component<Props, State> {
  constructor(props: Object): void {
    super(props);

    this.state = {
      mainLayoutComponent: <div />,
      isConnected: false,
      isBrowserChecking: true
    };

    bindAll(this, [
      "decideMcqIndexComponent",
      "decideFtqIndexComponent",
      "decideSectionIndexComponent",
      "renderComponentThatContainVideo",
      "buttonPropsFormControlNextSession"
    ]);
  }

  generateBrowserSupport(): Object {
    const parser = new UAParser();
    const browserName = parser.getBrowser().name;

    return {
      isChrome: /chrome/i.test(browserName),
      isFirefox: /firefox/i.test(browserName),
      isOpera: /opera/i.test(browserName),
      isSafari: /safari/i.test(browserName)
    };
  }

  renderFailComponent(): void {
    var url = document.URL;
    var lastPartUrl = url.split("/").pop();
    if (
      !DetectRTC.isWebsiteHasWebcamPermissions ||
      !DetectRTC.isWebsiteHasMicrophonePermissions
    ) {
      if (this.generateBrowserSupport().isChrome) {
        this.setState({ mainLayoutComponent: <AllowDeviceChrome /> });
      } else if (this.generateBrowserSupport().isFirefox) {
        this.setState({ mainLayoutComponent: <AllowDeviceFirefox /> });
      } else if (this.generateBrowserSupport().isOpera) {
        this.setState({ mainLayoutComponent: <AllowDeviceOpera /> });
      } else if (this.generateBrowserSupport().isSafari) {
        if (lastPartUrl != "not-interested") {
          this.setState({ mainLayoutComponent: <UnsupportedBrowser /> });
        }
      }
    } else {
      if (lastPartUrl != "not-interested") {
        this.setState({ mainLayoutComponent: <UnsupportedBrowser /> });
      }
    }
  }

  buttonPropsFormControlNextSession() {
    const { sessionsFilterData } = this.props;
    if (sessionsFilterData.get("type") === "close section") {
      this.decideSectionIndexComponent();
    } else if (sessionsFilterData.get("type") === "close interview") {
      this.setState({ mainLayoutComponent: <InterviewIndex /> });
    }
  }

  renderComponentThatContainVideo(): void {
    const {
      sessionsFilterData,
      sessionCode,
      interviewsCheckGDPRRequest,
      interviewsStartSessionRequest,
      interviewsStartWelcomeVideoRequest,
      translations,
      globalFetchDataIsProfile
    } = this.props;

    var url = document.URL;
    var lastPartUrl = url.split("/").pop();

    var arrayErrorMsg = [];
    arrayErrorMsg.messageError = t(translations, "CONNECTION_ERROR_MESSAGE");
    arrayErrorMsg.titleError = t(translations, "CONNECTION_ERROR_TITLE");
    arrayErrorMsg.buttonError = t(translations, "CONNECTION_ERROR_BUTTON");
    arrayErrorMsg.retryError = t(translations, "CONNECTION_ERROR_RETRY");

    checkConnection(arrayErrorMsg)
      .then(() => {
        this.setState({ isConnected: true });
        if (
          (this.generateBrowserSupport().isChrome ||
            this.generateBrowserSupport().isFirefox ||
            this.generateBrowserSupport().isOpera ||
            this.generateBrowserSupport().isSafari) &&
          DetectRTC.isWebRTCSupported
        ) {
          if (
            sessionsFilterData.get(
              "interview_settings_skipped_interview_detail_page"
            ) == 0
          ) {
            if (
              globalFetchDataIsProfile.get("candidate_is_link") == "" &&
              sessionsFilterData.get("is_access_profile") == 1
            ) {
              this.setState({
                mainLayoutComponent: (
                  <CompletedSessionWithForm
                    buttonPropsFormControlNextSession={
                      this.buttonPropsFormControlNextSession
                    }
                  />
                )
              });
            } else {
              if (sessionsFilterData.get("type") === "close section") {
                this.decideSectionIndexComponent();
              } else if (sessionsFilterData.get("type") === "close interview") {
                this.setState({ mainLayoutComponent: <InterviewIndex /> });
              }
            }
          } else {
            if (sessionsFilterData.get("gdpr_complied") == "0") {
              interviewsCheckGDPRRequest(sessionCode);
            } else if (sessionsFilterData.get("welcome_video_url") == "") {
              interviewsStartSessionRequest(sessionCode);
            } else {
              if (
                sessionsFilterData.get(
                  "interview_settings_skipped_start_session_page"
                ) == 1
              ) {
                interviewsStartSessionRequest(sessionCode);
              } else {
                interviewsStartWelcomeVideoRequest(sessionCode);
              }
            }
          }
        } else if (this.generateBrowserSupport().isSafari) {
          if (lastPartUrl != "not-interested") {
            this.setState({ mainLayoutComponent: <UnsupportedBrowser /> });
          }
        }
      })
      .catch(error => {
        this.setState({ isConnected: false });
      });
    this.setState({ isBrowserChecking: false });
  }

  decideMcqIndexComponent() {
    this.setState({ isConnected: true });
    let mcqIndexComponent: Object = <McqIndexInProgress />;

    if (this.props.sessionsStateInformation.get("prevQuestStates").isEmpty()) {
      mcqIndexComponent = <McqIndex />;
    }

    this.setState({ mainLayoutComponent: mcqIndexComponent });
  }

  decideFtqIndexComponent() {
    this.setState({ isConnected: true });
    let ftqIndexComponent: Object = <FtqIndexInProgress />;

    if (this.props.sessionsStateInformation.get("prevQuestStates").isEmpty()) {
      ftqIndexComponent = <FtqIndex />;
    }

    this.setState({ mainLayoutComponent: ftqIndexComponent });
  }

  decideSectionIndexComponent() {
    const { sessionsFilterData, sessionDurationCount } = this.props;
    this.setState({ isConnected: true });
    let sectionIndexComponent: Object = <SectionIndex />;

    // if (sessionsFilterData.get("is_section_started")) {
    //   frontEndRunTime(
    //     sessionDurationCount.get("front_end_run_time"),
    //     sessionRunTimeWatcher,
    //     false
    //   );
    //   sectionIndexComponent = <SectionIndexInProgress />;
    // }

    this.setState({ mainLayoutComponent: sectionIndexComponent });
  }

  renderComponentWithDeviceSupportValidation(): void {
    const { sessionsFilterData } = this.props;

    this.decideSectionIndexComponent();

    // if (
    //   sessionsFilterData.get("type") === "close test" &&
    //   sessionsFilterData.get("sub_type") != "freetext"
    // ) {
    //   this.decideMcqIndexComponent();
    // } else if (
    //   sessionsFilterData.get("type") === "close test" &&
    //   sessionsFilterData.get("sub_type") == "freetext"
    // ) {
    //   this.decideFtqIndexComponent();
    // } else if (
    //   sessionsFilterData.get("type") === "close section without interview"
    // ) {
    //   this.decideSectionIndexComponent();
    // } else {
    //   if (window.navigator.mediaDevices) {
    //     try {
    //       if (
    //         sessionsFilterData.get(
    //           "interview_settings_skipped_checking_browser_page"
    //         ) != 1
    //       ) {
    //         this.setState({ mainLayoutComponent: <CheckingBrowser /> });
    //       }

    //       window.navigator.mediaDevices
    //         .getUserMedia({
    //           video: true,
    //           audio: true
    //         })
    //         .then(() => {
    //           this.renderComponentThatContainVideo();
    //           let params = {
    //             event: "Welcome Page", // string
    //             message: "Media Device Allowed",
    //             status: "offline" // string
    //           };
    //           Logger.recordEvent(params);
    //         })
    //         .catch(error => {
    //           this.renderFailComponent();
    //           let params = {
    //             event: "Welcome Page", // string
    //             message: "Media Device Error " + error,
    //             status: "offline" // string
    //           };
    //           Logger.recordEvent(params);
    //         });
    //     } catch (error) {
    //       this.renderFailComponent();

    //       let params = {
    //         event: "Welcome Page", // string
    //         message: "Media Device Error " + error,
    //         status: "offline" // string
    //       };

    //       Logger.recordEvent(params);
    //     }
    //   } else {
    //     var url = document.URL;
    //     var lastPartUrl = url.split("/").pop();
    //     if (lastPartUrl != "not-interested") {
    //       this.setState({ mainLayoutComponent: <UnsupportedBrowser /> });
    //     }

    //     let params = {
    //       event: "Welcome Page", // string
    //       message: "UnsupportedBrowser",
    //       status: "offline" // string
    //     };

    //     Logger.recordEvent(params);
    //   }
    // }
  }

  componentDidMount(): void {
    const {
      sessionCode,
      sessionsFilterData,
      sessionsStateInformation,
      section,
      sections,
      job,
      candidate,
      company,
      sendAstronautQnASessionEvent
    } = this.props;

    var env;

    if (process.env.REACT_APP_API_HOST === "https://app.astrnt.co") {
      env = "production";
    } else {
      env = "beta";
    }
    const baseParam = {
      interview_code: sessionCode, // string
      candidate_id: candidate.get("id"), // number
      job_id: job.get("id"), // number
      company_id: company.get("id") // number
    };

    Logger.initialize(env, baseParam);

    let params = {
      event: "Welcome Page", // string
      message: "Welcome Page",
      status: "offline" // string
    };

    Logger.recordEvent(params);
    if (
      sessionsFilterData.get("interview_settings_custom_pop_up") != "impress"
    ) {
      globalHandlePageReloadHelper();
    }

    sendAstronautQnASessionEvent("Start Interview Session");

    if (sessionsFilterData.get("interview_settings_redirect_url") != "") {
      sendAstronautQnASessionEvent(
        "astrnt_interview_redirect_url:" +
          sessionsFilterData.get("interview_settings_redirect_url")
      );
    }

    this.renderComponentWithDeviceSupportValidation();
  }

  render() {
    const { sessionsFilterData } = this.props;
    const { mainLayoutComponent, isConnected, isBrowserChecking } = this.state;
    return (
      <Fragment>
        {sessionsFilterData.get(
          "interview_settings_skipped_checking_browser_page"
        ) != 1 || isBrowserChecking == false ? null : (
          <div className="wrapper-overlay" id="modalOnBoarding">
            <div className="overlay-content flex-container align-items-center">
              <img src={image33} className="img-responsive img-loader" alt="" />
            </div>
          </div>
        )}
        {mainLayoutComponent}
        {isConnected == true ? null : null}
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations,
    sessionCode: ownProps.match.params.sessionCode,
    sessionsFilterData: state.sessionsFilterData,
    sessionsStateInformation: state.sessionsStateInformation,
    section: state.section,
    sections: state.sections,
    job: state.job,
    candidate: state.candidate,
    company: state.company,
    globalFetchDataIsProfile: state.globalFetchDataIsProfile,
    sessionDurationCount: state.sessionDurationCount
  };
};

const mapDispatchToProps = dispatch => {
  return {
    interviewsCheckGDPRRequest: sessionCode =>
      dispatch(interviewsCheckGDPRRequest(sessionCode)),
    interviewsStartSessionRequest: sessionCode =>
      dispatch(interviewsStartSessionRequest(sessionCode)),
    interviewsStartWelcomeVideoRequest: sessionCode =>
      dispatch(interviewsStartWelcomeVideoRequest(sessionCode)),
    interviewsStartSessionPracticeRequest: sessionCode =>
      dispatch(interviewsStartSessionPracticeRequest(sessionCode)),
    sendAstronautQnASessionEvent: astronautEvent =>
      dispatch(sendAstronautQnASessionEvent(astronautEvent))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionIndex);
