// @flow

import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import Header from '../elements/Header'
import CardBasicLayout from '../elements/CardBasicLayout'
import imgCheckDevice from '../../images/check-device.png'

import { t } from '../../helpers/global'

type Props = {
  translations: Map<string, any>
};

class CheckingBrowser extends React.Component<Props> {
	render() {
    const { translations } = this.props

		return(
			<Fragment>
        <Header
          company="Astronaut Technologies"
          type="default" />
        <div className="container">
          <CardBasicLayout
            contentTitle={t(translations, 'CHECKING_BROWSER_TITLE')}
            contentImage={imgCheckDevice}
            contentImageAlt="Check device"
            contentText={t(translations, 'CHECKING_BROWSER_WE_NEED')}
            loaderName={t(translations, 'CHECKING_BROWSER_CHECKING')}
            loaderClassName="m-t-40"
            isLoading />
        </div>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations
  }
}

export default connect(mapStateToProps, null)(CheckingBrowser)
