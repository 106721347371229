// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Map } from "immutable";
import bindAll from "lodash/bindAll";
import pluralize from "pluralize";
import Header from "../../lib/elements/Header";
import CardSingleContent from "../../lib/elements/CardSingleContent";
import CardWithLabel from "../../lib/elements/CardWithLabel";
import InstructionDesk from "../../lib/elements/InstructionDesk";
import Button from "../../lib/elements/Button";
import Time from "../../lib/elements/Time";
import Icon from "../../lib/elements/Icon";
import ModalDialog from "../../lib/elements/ModalDialog";
import imgInstruction1 from "../../images/instruction-interview-1.svg";
import imgInstruction2 from "../../images/instruction-interview-2.svg";
import imgInstruction3 from "../../images/instruction-interview-3.svg";
import { sendAstronautQnASessionEvent } from "../../actions/embedded-interview";
import * as Logger from "astrnt-web-logger";
import { globalGenerateUnitOfTimeHelper } from "../../helpers/global";

import { t } from "../../helpers/global";

import {
  interviewsCheckGDPRRequest,
  interviewsStartSessionRequest,
  interviewsStartSessionPracticeRequest
} from "../../actions/interviews";

type Props = {
  history: Object,
  job: Map<string, any>,
  company: Map<string, any>,
  candidate: Map<string, any>,
  translations: Map<string, any>,
  interviewsCheckGDPRRequest: Function,
  interviewsStartSessionRequest: Function,
  interviewsStartSessionPracticeRequest: Function,
  sessionCode: string,
  sessionsFilterData: Map<string, any>
};

type State = {
  modal: boolean
};

class InterviewIndex extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      isModalOpen: false
    };

    bindAll(this, [
      "handleContinueClick",
      "handleModalToggle",
      "handlePracticeRecordingClick"
    ]);
  }

  componentDidMount() {
    let params = {
      event: "Video Info", // string
      message: "Enter Video Info",
      status: "offline"
    };
    window.scrollTo(0, 0);
    Logger.recordEvent(params);
  }

  handleContinueClick(e) {
    e.preventDefault();

    const {
      sessionCode,
      interviewsCheckGDPRRequest,
      interviewsStartSessionRequest,
      sessionsFilterData,
      sendAstronautQnASessionEvent
    } = this.props;

    sendAstronautQnASessionEvent("Start Interview Session");
    interviewsStartSessionRequest(sessionCode);
  }

  handleModalToggle(e) {
    e.preventDefault();
    this.setState({ modal: !this.state.modal });
  }

  handlePracticeRecordingClick(e) {
    e.preventDefault();

    const { sessionCode, interviewsStartSessionPracticeRequest } = this.props;

    interviewsStartSessionPracticeRequest(sessionCode);
  }
  render() {
    const {
      job,
      company,
      candidate,
      sessionsFilterData,
      translations,
      sessionsUiData
    } = this.props;

    const { modal } = this.state;
    const { isModalOpen } = this.state;

    const duration: Map<string, any> = globalGenerateUnitOfTimeHelper(
      sessionsFilterData.get("duration_left")
    );
    const questionIdsSize: number = sessionsFilterData.get("question_ids").size;
    return (
      <Fragment>
        <Header
          company={company.get("title")}
          job={job.get("title")}
          position={job.get("jobType")}
          location={job.get("locations")}
          type="primary"
        />
        <div className="container">
          <section>
            <h2 className="heading4 normal m-t-24">
              {t(translations, "LABEL_WELCOME")},{" "}
              <b className="bold">
                {" "}
                {sessionsFilterData.get("is_access_profile") == 1
                  ? "Friends"
                  : candidate.get("fullname")}
              </b>
              ! {t(translations, "LABEL_WHAT_TO_EXPECT")}:
            </h2>
            <CardSingleContent
              className="m-t-32"
              number={questionIdsSize}
              text={t(translations, "QUESTION_TYPE_VIDEO_PLURAL_WITHOUT_N", {
                ":pluralize_question": pluralize("question", questionIdsSize)
              })}
            >
              <Icon
                type="icon"
                name="icon-test"
                size="extralarge"
                align="bottom"
                className="m-r-8"
              />
            </CardSingleContent>
            <CardWithLabel
              label={t(translations, "INSTRUCTION_TIME_TO_COMPLETE")}
            >
              <Time data={duration} className="m-b-0 bold">
                <Icon
                  type="icon"
                  name="icon-timer"
                  size="extralarge"
                  align="middle"
                  className="m-r-8"
                />
              </Time>
            </CardWithLabel>
          </section>
          <section>
            <h2 className="heading4 normal m-t-32">
              <b>{t(translations, "HOW_IT_WORKS_HOW_LABEL")}</b>{" "}
              {t(translations, "HOW_IT_WORKS_WORKS_LABEL")}
            </h2>
            <div className="row no-gutters m-t-20">
              <InstructionDesk
                image={imgInstruction1}
                text={t(translations, "HOW_IT_WORKS_VIDEO_PLACE")}
                className="col-sm-4 bg-white"
              />
              <InstructionDesk
                image={imgInstruction2}
                text={t(translations, "HOW_IT_WORKS_VIDEO_CAPTURE")}
                className="col-sm-4 bg-white"
              />
              <InstructionDesk
                image={imgInstruction3}
                text={t(translations, "HOW_IT_WORKS_VIDEO_REFRESH")}
                className="col-sm-4 bg-white"
              />
            </div>
          </section>
          <section>
            <center className="m-t-40 m-b-40">
              <Button
                className="m-t-40 m-b-40"
                onClick={this.handleContinueClick}
              >
                {t(translations, "INSTRUCTION_START_SESSION")}
              </Button>
            </center>
          </section>
        </div>
        <ModalDialog
          isOpen={modal}
          toggle={this.handleModalToggle}
          title={t(translations, "INSTRUCTION_START_SESSION") + "?"}
          text={t(translations, "POPUP_CONTENT_START_SESSION")}
        >
          <Button
            type="button"
            theme="secondary"
            size="small"
            className="m-r-20"
            onClick={this.handleModalToggle}
          >
            {t(translations, "LABEL_CANCEL")}
          </Button>
          <Button
            type="button"
            theme="primary"
            size="small"
            onClick={this.handleContinueClick}
            buttonLoading={sessionsUiData.get("loading")}
            disabled={sessionsUiData.get("disabled_button")}
          >
            {t(translations, "LABEL_LETS_GO")}
          </Button>
        </ModalDialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations,
    job: state.job,
    company: state.company,
    candidate: state.candidate,
    sessionCode: ownProps.match.params.sessionCode,
    sessionsFilterData: state.sessionsFilterData,
    sessionsUiData: state.sessionsUiData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    interviewsCheckGDPRRequest: sessionCode =>
      dispatch(interviewsCheckGDPRRequest(sessionCode)),
    interviewsStartSessionRequest: sessionCode =>
      dispatch(interviewsStartSessionRequest(sessionCode)),
    interviewsStartSessionPracticeRequest: sessionCode =>
      dispatch(interviewsStartSessionPracticeRequest(sessionCode)),
    sendAstronautQnASessionEvent: astronautEvent =>
      dispatch(sendAstronautQnASessionEvent(astronautEvent))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(InterviewIndex)
);
