// @flow
import React from 'react'
import classnames from 'classnames'

type Props = {
  text: string,
  className: string,
};

class PageTitle extends React.Component<Props> {

  static defaultProps = {
    text: '',
    className: '',
  }

  render() {
    const {
      text,
      className
    } = this.props

    const classProps: string = classnames(
      'heading1',
      'm-t-24',
      className
    )

    return (
      <h2 className={classProps}>
        {text}
      </h2>
    )
  }
}

export default PageTitle
