// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Map, List } from "immutable";
import pluralize from "pluralize";
import bindAll from "lodash/bindAll";
import Header from "../../lib/elements/Header";
import Card from "../../lib/elements/Card";
import CardSingleContent from "../../lib/elements/CardSingleContent";
import Button from "../../lib/elements/Button";
import Icon from "../../lib/elements/Icon";
import Indicator from "../../lib/elements/Indicator";
import Duration from "../../lib/elements/Duration";
import InstructionDesk from "../../lib/elements/InstructionDesk";
import imgInterviewInstruction1 from "../../images/instruction-interview-1.svg";
import imgInterviewInstruction2 from "../../images/instruction-interview-2.svg";
import imgInterviewInstruction3 from "../../images/instruction-interview-3.svg";
import imgMcqInstruction1 from "../../images/instruction-mcq-1.svg";
import imgMcqInstruction2 from "../../images/instruction-mcq-2.svg";
import imgMcqInstruction3 from "../../images/instruction-mcq-3.svg";
import { sendAstronautQnASessionEvent } from "../../actions/embedded-interview";
import imgFtqInstruction1 from "../../images/instruction-ftq-1.png";
import imgFtqInstruction2 from "../../images/instruction-ftq-2.png";
import imgFtqInstruction3 from "../../images/instruction-ftq-3.png";
import store_shopee from "../../dependencies/forShopeeStorage";
import * as Logger from "astrnt-web-logger";
import ModalDialog from "../../lib/elements/ModalDialog";
import AudioElement from "../../lib/elements/AudioElement";
import CardTopicDescVideo from "../../lib/elements/CardTopicDescVideo";
import CardTopicInstruksi from "../../lib/elements/CardTopicInstruksi";
import CardTopicAll from "../../lib/elements/CardTopicAll";
import CardMaterialDownload from "../../lib/elements/CardMaterialDownload";
import LogoAstrntWhite from "../../images/logo-white.png";
import ReactJWPlayer from "react-jw-player";

import {
  sectionsStartMcqRequest,
  sectionsStartFtqRequest,
  sectionsStartInterviewRequest
} from "../../actions/sections";

import {
  globalGenerateUnitOfTimeHelper,
  globalBuildDurationDisplayHelper,
  sToTime
} from "../../helpers/global";

import { t } from "../../helpers/global";

type Props = {
  job: Map<string, any>,
  company: Map<string, any>,
  candidate: Map<string, any>,
  translations: Map<string, any>,
  sectionsStartMcqRequest: Function,
  sectionsStartFtqRequest: Function,
  sectionsStartInterviewRequest: Function,
  section: Map<string, any>,
  sections: List<Map<string, any>>,
  sectionsStartSessionRequest: Function,
  sessionCode: string,
  sessionsFilterData: Map<string, any>
};

class SectionInstruction extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.state = {
      have_opened: 0,
      is_play: false,
      percent_audio: 0,
      max_duration: "",
      current_duration: "",
      muted: false
    };

    bindAll(this, [
      "handleStartSectionClick",
      "renderTimeIndicator",
      "renderInterviewCardComponent",
      "renderMcqCardComponent",
      "renderFtqCardComponent",
      "renderHowItWorksComponent",
      "handleModalToggleMcq",
      "handlePlayClick",
      "handleVolumeClick",
      "createInstruction",
      "handleDurationChange",
      "handleResetPlayButton",
      "handleVolumeVideoClick",
      "handlePlayVideoClick",
      "redirectHome"
    ]);
  }

  redirectHome() {
    const { redirect, sessionCode } = this.props;

    redirect(`/code/${sessionCode}`);
  }

  handleResetPlayButton() {
    this.setState({ is_play: false });
  }

  componentDidMount() {
    const { section } = this.props;
    let params = {
      event: "Section Instruction", // string
      message: "Enter Section Instruction",
      status: "offline"
    };
    window.scrollTo(0, 0);

    Logger.recordEvent(params);
    if (section.get("audio_url")) {
      document.getElementById("js-sound-player-section").src = section.get(
        "audio_url"
      );
    }
    let $this = this;
  }

  handleDurationChange(e) {
    // console.log(e.target)
    const current_time = e.target.currentTime;
    const duration = e.target.duration;
    const percent = (current_time * 100) / duration;
    let currentTime = sToTime(current_time);

    let __duration = sToTime(duration);

    this.setState({ max_duration: __duration }, () => {
      this.setState({ current_duration: currentTime }, () => {
        this.setState({ percent_audio: percent }, () => {
          if (this.state.current_duration == this.state.max_duration) {
            this.handleResetPlayButton();
          }
        });
      });
    });
  }

  handlePlayClick() {
    let audio_url = document.getElementById("js-sound-player-section");

    this.setState({ is_play: !this.state.is_play }, () => {
      if (this.state.is_play == true) {
        audio_url.play();
      } else if (this.state.is_play == false) {
        audio_url.pause();
      }
    });
  }

  handlePlayVideoClick() {
    let audio_url = document.getElementById("js-video-player-section");

    this.setState({ is_play: !this.state.is_play }, () => {
      if (this.state.is_play == true) {
        audio_url.play();
      } else if (this.state.is_play == false) {
        audio_url.pause();
      }
    });
  }

  handleVolumeVideoClick() {
    this.setState({ muted: !this.state.muted });
    // console.log(this.state.muted)
    let audio_url = document.getElementById("js-video-player-section");
    audio_url.muted = !this.state.muted;
  }

  handleVolumeClick() {
    this.setState({ muted: !this.state.muted });
    // console.log(this.state.muted)
    let audio_url = document.getElementById("js-sound-player-section");
    audio_url.muted = !this.state.muted;
  }

  handleModalToggleMcq() {
    const { sessionCode } = this.props;
    this.setState({ have_opened: 1 });

    store_shopee.setModalOpenedOnce("was_open", sessionCode);
  }
  createInstruction(value) {
    return { __html: value };
  }
  renderTimeIndicator() {
    const { section, sections, translations, sessionCode } = this.props;

    const sectionNumber: number = section.get("index") + 1;
    let time_left_ten_min: false;
    let questionDuration: number;

    if (section.get("preparation")) {
      const durationDisplay: string = globalBuildDurationDisplayHelper(
        section.get("preparation")
      );

      return (
        <Indicator layout="center" theme="info">
          {t(translations, "SECTION_STARTS_IN")}{" "}
          <Duration data={durationDisplay} className="m-l-4" />
        </Indicator>
      );
    } else if (section.get("duration")) {
      //shopee
      questionDuration = section.get("duration");
      var durationDisplay;
      if (section.get("is_shopee") == 1 && store_shopee.get() != "open") {
        durationDisplay = globalBuildDurationDisplayHelper(
          section.get("duration") - 600
        );
      } else {
        durationDisplay = globalBuildDurationDisplayHelper(
          section.get("duration")
        );
      }

      if (section.get("is_shopee") == 1) {
        questionDuration = questionDuration - 600;
        if (this.state.have_opened == 1) {
          questionDuration = questionDuration + 600;
        }
      }

      let indicatorTheme: string = "success";

      if (section.get("is_shopee") == 1) {
        if (store_shopee.get() != "open") {
          if (questionDuration <= 5) {
            indicatorTheme = "danger";
          }
        } else {
          if (questionDuration + 600 <= 5) {
            indicatorTheme = "danger";
          }
        }
      } else {
        if (section.get("duration") <= 5) {
          indicatorTheme = "danger";
        }
      }

      if (section.get("is_shopee") === 1) {
        // 10 menit shopee ada diparent nya mcqshow

        if (questionDuration <= 0) {
          if (this.state.have_opened == 0) {
            store_shopee.set("open");
            if (store_shopee.getModalOpenedOnce(sessionCode) === "was_open") {
            } else {
              time_left_ten_min = true;
            }
          } else {
            time_left_ten_min = false;
          }
        }
      }

      if (section.get("duration") === 1) {
        let sect = section.toJS();
        sections.toJS().map((data, key) => {
          if (data.id === sect.id) {
            let params = {
              event: "Section", // string
              message:
                "Duration ended in Section " + key + 1 + " on id =" + data.id,
              status: "offline" // string
            };
            // console.log(params);

            Logger.recordEvent(params);
          }
        });
      }

      return (
        <Fragment>
          <ModalDialog
            isOpen={time_left_ten_min}
            title={`Time’s Up!`}
            text={`We are giving you an extra 10 minutes to answer our questions. Don't miss this opportunity to let us know more about you!`}
          >
            <Button
              type="button"
              theme="secondary"
              size="small"
              className="m-r-20"
              onClick={this.handleModalToggleMcq}
            >
              OK
            </Button>
          </ModalDialog>
          <Indicator layout="between" theme={indicatorTheme}>
            <div>
              <b>
                {t(translations, "SECTION_LABEL")} {section.get("index") + 1}
              </b>{" "}
              {t(translations, "LABEL_OF")} {sections.size}
            </div>
            <Duration data={durationDisplay} className="bold">
              <Icon
                type="icon"
                name="icon-clock"
                align="bottom"
                className="m-r-8"
              />
            </Duration>
          </Indicator>
        </Fragment>
      );
    }
  }

  renderInterviewCardComponent() {
    const { section, translations } = this.props;

    // const duration: Map<string, any> = globalGenerateUnitOfTimeHelper(
    //   section.get("duration")
    // );

    return (
      <Fragment>
        <CardSingleContent
          className="m-t-16"
          number={section.get("unanswered_question_ids").size}
          text={t(translations, "LABEL_PLURAL_QUESTION", {
            ":pluralize_question": pluralize(
              "QUESTION",
              section.get("unanswered_question_ids").size
            )
          })}
        >
          <Icon
            type="icon"
            name="icon-video"
            size="extralarge"
            align="bottom"
            className="m-r-8"
          />
        </CardSingleContent>
      </Fragment>
    );
  }

  renderMcqCardComponent() {
    const { section, translations } = this.props;
    //shopee
    // var duration: Map<string, any>;
    // if (section.get("is_shopee") == 1 && store_shopee.get() != "open") {
    //   duration = globalGenerateUnitOfTimeHelper(section.get("duration") - 600);
    // } else {
    //   duration = globalGenerateUnitOfTimeHelper(section.get("duration"));
    // }

    return (
      <Fragment>
        <CardSingleContent
          className="m-t-16"
          number={section.get("question_ids").size}
          text={t(translations, "QUESTION_TYPE_MCQ")}
        >
          <Icon
            type="icon"
            name="icon-test"
            size="extralarge"
            align="bottom"
            className="m-r-8"
          />
        </CardSingleContent>
        {/* <CardWithLabel label={t(translations, "INSTRUCTION_TIME_TO_COMPLETE")}>
          <Time data={duration} className="m-b-0 bold">
            <Icon
              type="icon"
              name="icon-timer"
              size="extralarge"
              align="middle"
              className="m-r-8"
            />
          </Time>
        </CardWithLabel> */}
      </Fragment>
    );
  }

  renderFtqCardComponent() {
    const { section, translations } = this.props;

    // const duration: Map<string, any> = globalGenerateUnitOfTimeHelper(
    //   section.get("duration")
    // );
    return (
      <Fragment>
        <CardSingleContent
          className="m-t-16"
          number={section.get("question_ids").size}
          text={t(translations, "QUESTION_TYPE_FTQ")}
        >
          <Icon
            type="icon"
            name="icon-test"
            size="extralarge"
            align="bottom"
            className="m-r-8"
          />
        </CardSingleContent>
        {/* <CardWithLabel label={t(translations, "INSTRUCTION_TIME_TO_COMPLETE")}>
          <Time data={duration} className="m-b-0 bold">
            <Icon
              type="icon"
              name="icon-timer"
              size="extralarge"
              align="middle"
              className="m-r-8"
            />
          </Time>
        </CardWithLabel> */}
      </Fragment>
    );
  }

  renderHowItWorksComponent() {
    const { translations } = this.props;
    let howItWorksComponent: Object = (
      <Fragment>
        <h2 className="heading4 normal m-t-32">
          <b>{t(translations, "HOW_IT_WORKS_HOW_LABEL")}</b>{" "}
          {t(translations, "HOW_IT_WORKS_WORKS_LABEL")}
        </h2>
        <div className="row no-gutters m-t-20">
          <InstructionDesk
            image={imgInterviewInstruction1}
            text={t(translations, "HOW_IT_WORKS_VIDEO_PLACE")}
            className="col-sm-4 bg-white"
          />
          <InstructionDesk
            image={imgInterviewInstruction2}
            text={t(translations, "HOW_IT_WORKS_VIDEO_CAPTURE")}
            className="col-sm-4 bg-white"
          />
          <InstructionDesk
            image={imgInterviewInstruction3}
            text={t(translations, "HOW_IT_WORKS_VIDEO_REFRESH")}
            className="col-sm-4 bg-white"
          />
        </div>
      </Fragment>
    );

    if (this.props.section.get("type") === "test") {
      if (this.props.section.get("sub_type") === "mcq") {
        howItWorksComponent = (
          <Fragment>
            <h2 className="heading4 normal m-t-24">
              <b>{t(translations, "HOW_IT_WORKS_HOW_LABEL")}</b>{" "}
              {t(translations, "HOW_IT_WORKS_WORKS_LABEL")}
            </h2>
            <div className="row no-gutters m-t-12">
              <InstructionDesk
                image={imgMcqInstruction1}
                text={t(translations, "HOW_IT_WORKS_MCQ_TIMER")}
                className="col-sm-4 bg-white"
              />
              <InstructionDesk
                image={imgMcqInstruction2}
                text={t(translations, "HOW_IT_WORKS_MCQ_TOOLS")}
                className="col-sm-4 bg-white"
              />
              <InstructionDesk
                image={imgMcqInstruction3}
                text={t(translations, "HOW_IT_WORKS_MCQ_INSTRUCTION")}
                className="col-sm-4 bg-white"
              />
            </div>
          </Fragment>
        );
      } else if (this.props.section.get("sub_type") === "freetext") {
        howItWorksComponent = (
          <Fragment>
            <h2 className="heading4 normal m-t-24">
              <b>{t(translations, "HOW_IT_WORKS_HOW_LABEL")}</b>{" "}
              {t(translations, "HOW_IT_WORKS_WORKS_LABEL")}
            </h2>
            <div className="row no-gutters m-t-12">
              <InstructionDesk
                image={imgFtqInstruction1}
                text={t(translations, "HOW_IT_WORKS_FTQ_TIMER")}
                className="col-sm-4 bg-white"
              />
              <InstructionDesk
                image={imgFtqInstruction2}
                text={t(translations, "HOW_IT_WORKS_FTQ_TOOLS")}
                className="col-sm-4 bg-white"
              />
              <InstructionDesk
                image={imgFtqInstruction3}
                text={t(translations, "HOW_IT_WORKS_FTQ_INSTRUCTION")}
                className="col-sm-4 bg-white"
              />
            </div>
          </Fragment>
        );
      }
    } else if (this.props.section.get("type") === "freetext") {
      howItWorksComponent = (
        <Fragment>
          <h2 className="heading4 normal m-t-24">
            <b>{t(translations, "HOW_IT_WORKS_HOW_LABEL")}</b>{" "}
            {t(translations, "HOW_IT_WORKS_WORKS_LABEL")}
          </h2>
          <div className="row no-gutters m-t-12">
            <InstructionDesk
              image={imgFtqInstruction1}
              text={t(translations, "HOW_IT_WORKS_FTQ_TIMER")}
              className="col-sm-4 bg-white"
            />
            <InstructionDesk
              image={imgFtqInstruction2}
              text={t(translations, "HOW_IT_WORKS_FTQ_TOOLS")}
              className="col-sm-4 bg-white"
            />
            <InstructionDesk
              image={imgFtqInstruction3}
              text={t(translations, "HOW_IT_WORKS_FTQ_INSTRUCTION")}
              className="col-sm-4 bg-white"
            />
          </div>
        </Fragment>
      );
    } else if (this.props.section.get("type") === "freetext") {
      howItWorksComponent = (
        <Fragment>
          <h2 className="heading4 normal m-t-24">
            <b>{t(translations, "HOW_IT_WORKS_HOW_LABEL")}</b>{" "}
            {t(translations, "HOW_IT_WORKS_WORKS_LABEL")}
          </h2>
          <div className="row no-gutters m-t-12">
            <InstructionDesk
              image={imgFtqInstruction1}
              text={t(translations, "HOW_IT_WORKS_FTQ_TIMER")}
              className="col-sm-4 bg-white"
            />
            <InstructionDesk
              image={imgFtqInstruction2}
              text={t(translations, "HOW_IT_WORKS_FTQ_TOOLS")}
              className="col-sm-4 bg-white"
            />
            <InstructionDesk
              image={imgFtqInstruction3}
              text={t(translations, "HOW_IT_WORKS_FTQ_INSTRUCTION")}
              className="col-sm-4 bg-white"
            />
          </div>
        </Fragment>
      );
    }

    return howItWorksComponent;
  }

  handleStartSectionClick(e) {
    e.preventDefault();

    let params = {
      event: "Section Info", // string
      message: "Start Section Question",
      status: "offline"
    };
    Logger.recordEvent(params);

    const {
      section,
      sessionCode,
      sectionsStartMcqRequest,
      sectionsStartFtqRequest,
      sectionsStartInterviewRequest,
      sendAstronautQnASessionEvent
    } = this.props;

    sendAstronautQnASessionEvent("Start QnA Session");

    if (
      section.get("type") === "test" &&
      section.get("sub_type") !== "freetext"
    ) {
      sectionsStartMcqRequest(sessionCode);
    } else if (
      section.get("type") === "test" &&
      section.get("sub_type") === "freetext"
    ) {
      sectionsStartFtqRequest(sessionCode);
    } else {
      sectionsStartInterviewRequest(sessionCode);
    }
  }

  render() {
    const {
      job,
      company,
      section,
      sections,
      sessionsUiData,
      candidate,
      sessionsFilterData
    } = this.props;

    const video = section.get("media").toJS();
    const isVideo = section.get("video_url") ? "" : "hidden";
    const isAudio = section.get("audio_url") ? "" : "hidden";

    return (
      <Fragment>
        <Header
          provider={company.get("title")}
          course={job.get("title")}
          position={job.get("jobType")}
          location={job.get("locations")}
          type="primary"
          learner={candidate}
          redirectCode={sessionsFilterData.get("ecourse_code")}
          logoCourse={company.get("logoUrl")}
        />
        <div className="container">
          {this.renderTimeIndicator()}

          <CardTopicAll
            current={section.get("index") + 1}
            end={sections.size}
            handleClick={this.redirectHome}
          />
          <CardTopicDescVideo
            className={isVideo}
            title={section.get("title")}
            video_url={section.get("video_url")}
            video_component={
              video["type"] == "jwplayer" ? (
                <ReactJWPlayer
                  playerId="js-video-player-section"
                  playerScript={video["player_script"]}
                  playlist={video["playlist_url"]}
                />
              ) : (
                <ReactJWPlayer
                  playerId="js-video-player-section"
                  playerScript="https://cdn.jwplayer.com/libraries/GNOuvQkL.js" // using account januar@astrnt.co
                  file={video["media_url"]}
                />
              )
            }
            // instruction={section.get("instruction")}
          />
          <div className={`row ${isAudio}`}>
            <div className="col-sm-12">
              <Card className="m-t-24">
                <audio
                  onTimeUpdate={this.handleDurationChange}
                  ref={el => (this.audioPlayer = el)}
                  id="js-sound-player-section"
                  controls="controls"
                  type="audio/webm"
                  className="hide"
                />
                <AudioElement
                  hidden={isAudio}
                  muted={this.state.muted}
                  current_duration={this.state.current_duration}
                  max_duration={this.state.max_duration}
                  progress={this.state.percent_audio}
                  is_play={this.state.is_play}
                  handlePlayClick={this.handlePlayClick}
                  handleVolumeClick={this.handleVolumeClick}
                />
              </Card>
            </div>
          </div>
          <CardTopicInstruksi instruction={section.get("instruction")} />
          {section.get("support_materials").size > 0 && (
            <section>
              <CardMaterialDownload
                zipUrl={section.get("support_materials_url")}
                companyId={company.get("id")}
                sectionId={section.get("id")}
                supportMaterial={section.get("support_materials")}
              />
            </section>
          )}
          {section.get("sub_type") === "mcq"
            ? this.renderMcqCardComponent()
            : section.get("sub_type") === "freetext"
            ? this.renderFtqCardComponent()
            : this.renderInterviewCardComponent()}
          <div className="row"></div>
          <div>
            <center>
              <Button
                className="m-t-40 m-b-40"
                onClick={this.handleStartSectionClick}
                buttonLoading={sessionsUiData.get("loading")}
                disabled={sessionsUiData.get("disabled_button")}
              >
                {/* {t(translations, "INSTRUCTION_START_SECTION")} */}
                Mulai Kuis
              </Button>
            </center>
          </div>
        </div>
        <footer className="footer-astrnt horizontal-item--center">
          Powered by <img src={LogoAstrntWhite} /> All Rights Reserved.
        </footer>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations,
    job: state.job,
    company: state.company,
    candidate: state.candidate,
    section: state.section,
    sections: state.sections,
    sessionCode: ownProps.match.params.sessionCode,
    sessionsFilterData: state.sessionsFilterData,
    sessionsUiData: state.sessionsUiData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sectionsStartMcqRequest: sessionCode =>
      dispatch(sectionsStartMcqRequest(sessionCode)),
    sectionsStartFtqRequest: sessionCode =>
      dispatch(sectionsStartFtqRequest(sessionCode)),
    sectionsStartInterviewRequest: sessionCode =>
      dispatch(sectionsStartInterviewRequest(sessionCode)),
    sendAstronautQnASessionEvent: astronautEvent =>
      dispatch(sendAstronautQnASessionEvent(astronautEvent)),
    redirect: link => dispatch(push(link))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionInstruction);
