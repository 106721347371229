import _axios from "../dependencies/_axios";

//bahasa indo untuk ecourse
export function ecourseJobDetailApi(jobCode) {
  return _axios({
    url: "/api/ecourse/initial-data-v2",
    method: "post",
    data: {
      ecourse_code: jobCode,
      is_translation: false
    }
  });
}

export function ecourseRegisterApi(jobCode, values) {
  return _axios({
    url: `/api/ecourse/register`,
    method: "post",
    data: values
  });
}

export function ecourseCheckEmailApi(email: string) {
  return _axios({
    url: "/api/ecourse/check-email",
    timeout: 20000,
    method: "post",
    responseType: "json",
    // headers: {'X-Requested-With': 'XMLHttpRequest'},
    data: {
      email
    }
  });
}

export function ecourseLoginApi(data) {
  return _axios({
    url: `/api/ecourse/login`,
    timeout: 20000,
    method: `post`,
    responseType: `json`,
    data: data
  });
}
