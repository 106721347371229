// @flow
import React from "react";

type Props = {
  contentImage: string,
  contentImageAlt: string,
  contentTitle: string,
  children?: any,
  className: string
};

class ContentThankYou extends React.Component<Props> {
  static defaultProps = {
    contentImage: "",
    contentImageAlt: "",
    contentTitle: "",
    children: null,
    className: "",
    formFinishText: ""
  };

  render() {
    const classforNextStep = "heading2 m-b-16";
    const classDefaultFinish = "heading2 m-b-16 text-capitalize";
    const {
      contentImage,
      contentImageAlt,
      contentTitle,
      children,
      className,
      formFinishText
    } = this.props;

    return (
      <div className={className}>
        {contentImage == "" ? null : (
          <img src={contentImage} alt={contentImageAlt} />
        )}

        <h2
          className={
            formFinishText == "" ? classDefaultFinish : classforNextStep
          }
        >
          {formFinishText}
        </h2>
        {children}
      </div>
    );
  }
}

export default ContentThankYou;
