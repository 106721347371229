import React, { Fragment } from "react";
import { connect } from "react-redux";
import imgCheckDocument from "../../images/check-document.png";
import Header from "../../lib/elements/Header";
import CardFooterLayout from "../../lib/elements/CardFooterLayout";
import CardThankYouForm from "../../lib/elements/CardThankYouForm";
import CardThankYouFormFinish from "../../lib/elements/CardThankYouFormFinish";

import { t } from "../../helpers/global";

type Props = {
  job: Map<string, any>,
  company: Map<string, any>,
  candidate: Map<string, any>,
  translations: Map<string, any>
};

class CompletedSessionWithLink extends React.Component<Props> {
  render() {
    const { job, company, candidate, translations } = this.props;
    return (
      <Fragment>
        <Header type="default" />
        <div className="container">
          <CardThankYouFormFinish
            contentTitle={t(translations, "COMPLETED_SESSION_QNA_ALREADY")}
            companyNameText={"Please copy & paste as necessary."}
            formFinishText={"Almost done!"}
            contentText={t(translations, "COMPLETED_SESSION_YOU_HAVE_ALREADY", {
              ":candidate_name": candidate.get("fullname"),
              ":job_title": job.get("title"),
              ":company_title": company.get("title")
            })}
          />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    job: state.job,
    company: state.company,
    candidate: state.candidate,
    translations: state.translations
  };
};

export default connect(
  mapStateToProps,
  null
)(CompletedSessionWithLink);
