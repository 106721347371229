import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "react-router-redux";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";
import Raven from "raven-js";
import Swal from "sweetalert2";
import history from "../dependencies/history";
import appReducer from "../reducers";
import rootSaga from "../sagas";
import initializers from "../dependencies/initializers";
import _axios from "../dependencies/_axios";
import UAParser from "ua-parser-js";

let composeEnhancers = compose;
let dsn;

if (process.env.NODE_ENV === "development") {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
  dsn = "https://d1c1bab57fad47cc921e42e315c16b7d@sentry.io/239411";
}

Raven.config(dsn).install();

Raven.setTagsContext({
  environment: process.env.NODE_ENV,
  version: initializers.version
});

// setExtraContext from Sentry before error happen
_axios.interceptors.response.use(
  response => {
    Raven.setExtraContext({ response: response });

    return response;
  },
  error => {
    const parser = new UAParser();
    const browserName = parser.getBrowser().name;
    if (browserName == "Safari") {
      throw error;
    } else {
      console.log(error.response);
      if (typeof error.response == "undefined") {
        Swal({
          type: "warning",
          text: "Hmmm... please check your connection & try again."
        });
        throw error;
      }
      if (error.response.status == 401) {
        if (error.response.data.message == "code_expired") {
          // Swal({
          //   type: "error",
          //   title: error.response.data.translations.DEADLINE_TITLE_EXPIRED,
          //   text: error.response.data.translations.DEADLINE_MESSAGE_EXPIRED
          // });
        } else if (error.response.data.message == "code_not_found") {
          // Swal({
          //   type: "error",
          //   title: "Whoops!",
          //   text: "The code is not found"
          // });
        } else if (error.response.data.message == "code_used") {
          //no error display
        } else if (error.response.data.message === "session_end") {
          // Swal({
          //   type: "error",
          //   title: "Whoops!",
          //   text: "The Session for this code has ended"
          // });
        } else if (error.response.data.message == "not authorized") {
          Swal({
            type: "error",
            title: "Whoops!",
            text: "The Session is expired. Please Login."
          });
        } else {
          Swal({
            type: "error",
            title: "Whoops!",
            text:
              "It seems that you are offline. Please check your internet connection."
          });
        }
        throw error;
      }
    }
  }
);

const rootReducer = (state, action) => {
  if (action.type === "GLOBAL_RESET_STATE_SUCCESS") {
    state = undefined;
  }

  return appReducer(state, action);
};

const crashReporter = store => next => action => {
  try {
    return next(action);
  } catch (err) {
    console.error("Caught an exception!", err);

    Raven.captureException(err, {
      extra: {
        action,
        state: store.getState()
      }
    });

    throw err;
  }
};

export default function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  const _routerMiddleware = routerMiddleware(history);

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(thunk, sagaMiddleware, _routerMiddleware, crashReporter)
    )
  );

  sagaMiddleware.run(rootSaga);

  return store;
}
