import _axios from "../dependencies/_axios";
import omit from "lodash/omit";
import { List, fromJS, Map } from "immutable";

export function loadMoreReview(page, job_id, reviewsData) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      dispatch({
        type: "REVIEWS_RATING_ON_LOADING_SUCCESS",
        is_loading: true
      });
      _axios({
        url: `/api/ecourse/courses/${job_id}/reviews?page=${page}`,
        method: "get",
        timeout: 200000
      }).then(response => {
        let ecourse_review = response.data.ecourse_review;
        var data_concat = reviewsData.toJS();
        var old_data_size = reviewsData.size;
        var new_data = fromJS(omit(ecourse_review.data, "average_rating"));

        new_data.map((data, index) => {
          let new_idx = parseInt(index);
          let key = old_data_size + new_idx;
          data_concat[key] = data.toJS();
        });

        dispatch({
          type: "REVIEWS_FETCH_PAGINATION_SUCCESS",
          pagination: omit(ecourse_review, "data")
        });
        dispatch({
          type: "REVIEWS_FETCH_DATA_SUCCESS",
          reviews_data: data_concat
        });
        dispatch({
          type: "REVIEWS_RATING_ON_LOADING_SUCCESS",
          is_loading: false
        });
        resolve();
      });
    });
  };
}
