// @flow

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Modal, ModalBody } from "reactstrap";
import bindAll from "lodash/bindAll";
import { Map } from "immutable";
import CarouselBoardingItem from "../../lib/elements/CarouselBoardingItem";
import imgBoarding1 from "../../images/onboarding-1.png";
import imgBoarding2 from "../../images/onboarding-2.png";
import imgBoarding3 from "../../images/onboarding-3.png";
import imgBoarding4 from "../../images/onboarding-4.png";
import { finishOnBoarding } from "../../apis/global";
import {
  sessionsUpdateIsOnBoardingSuccess,
  sessionsAddIsWatchedOnStateInformation
} from "../../actions/sessions";
import UAParser from "ua-parser-js";
import { t } from "../../helpers/global";
import ModalWelcomeVideo from "../partials/ModalWelcomeVideo";
import CardWelcomeVideo from "../../lib/elements/CardWelcomeVideo";
import initializers from "../../dependencies/initializers";

type Props = {
  text: string,
  title: string,
  style: ?Object,
  children?: any,
  isOpen: boolean,
  sessionCode: string,
  translations: Map<string, any>,
  sessionsStateInformation: Map<string, any>,
  sessionsFilterData: Map<string, any>,
  sessionsUpdateIsOnBoardingSuccess: Function
};

class ModalOnBoarding extends React.Component<Props> {
  constructor(props: Object) {
    super(props);

    bindAll(this, [
      "handleToggleModal",
      "handleRemoveModalOpenClass",
      "handleCloseButtonVisibility"
    ]);
  }

  static defaultProps = {
    title: "",
    text: "",
    style: null,
    children: null,
    isOpen: true,
    modal: false
  };

  handleToggleModal(e) {
    e.preventDefault();

    const {
      sessionCode,
      sessionsUpdateIsOnBoardingSuccess,
      sessionsFilterData
    } = this.props;

    finishOnBoarding(sessionCode);
    sessionsUpdateIsOnBoardingSuccess();
    if (sessionsFilterData.get("welcome_video_url") != "") {
      this.ModalWelcomeVideo.handleOpenModal(true);
      setTimeout(function() {
        document
          .getElementById("welcomeVideo")
          .addEventListener("ended", this.handleCloseButtonVisibility, false);
      }, 500);
      setTimeout(() => {
        var vids = document.getElementById("welcomeVideo");
        const parser = new UAParser();
        const browserName = parser.getBrowser().name;
        vids.controls = false;
        var playButton = document.getElementById("play_button");
        // Event listener for the play/pause button
        playButton.addEventListener("click", function() {
          if (vids.paused == true) {
            // Play the video
            vids.play();
            // Update the button text to 'Pause'
            playButton.innerHTML = "<i class='fa fa-pause'></i>";
          } else {
            // Pause the video
            vids.pause();
            // Update the button text to 'Play'
            playButton.innerHTML = "<i class='fa fa-play'></i>";
          }
        });
      }, 500);
    }
    this.handleRemoveModalOpenClass();
  }
  handleCloseButtonVisibility() {
    const { sessionsAddIsWatchedOnStateInformation, sessionCode } = this.props;
    // document.cookie = "isWatched=true;";
    sessionsAddIsWatchedOnStateInformation(sessionCode);
    //set Properti disini untuk is_watched
    document.getElementsByClassName("close-button").classList.remove("hide");
  }
  handleRemoveModalOpenClass() {
    document.getElementsByTagName("Body")[0].classList.remove("modal-open");
  }
  render() {
    const {
      style,
      translations,
      sessionsStateInformation,
      fullDataCompany,
      sessionsFilterData
    } = this.props;

    var host;
    if (initializers.api_host == "https://beta.astrnt.co") {
      host = 746;
      if (fullDataCompany.get("id") == host) {
        host = true;
      } else {
        host = false;
      }
    } else if (initializers.api_host == "https://app.astrnt.co") {
      host = 1526;
      if (fullDataCompany.get("id") == host) {
        host = true;
      } else {
        host = false;
      }
    } else if (initializers.api_host == "http://astrnt.test") {
      host = 261;
      if (fullDataCompany.get("id") == host) {
        host = true;
      }
    } else {
      host = false;
    }

    if (
      sessionsFilterData.get("interview_settings_skipped_introduction_page") ==
      1
    ) {
      host = true;
    }

    const items: Array<Object> = [
      {
        img: imgBoarding1,
        alt: "On boarding 1",
        title: t(translations, "ON_BOARDING1_TEXT"),
        text: t(translations, "ON_BOARDING1_DESC_TEXT")
      },
      {
        img: imgBoarding2,
        alt: "On boarding 2",
        title: t(translations, "ON_BOARDING2_TEXT"),
        text: t(translations, "ON_BOARDING2_DESC_TEXT")
      },
      {
        img: imgBoarding3,
        alt: "On boarding 3",
        title: t(translations, "ON_BOARDING3_TEXT"),
        text: t(translations, "ON_BOARDING3_DESC_TEXT")
      },
      {
        img: imgBoarding4,
        alt: "On boarding 4",
        title: t(translations, "ON_BOARDING4_TEXT"),
        text: t(translations, "ON_BOARDING4_DESC_TEXT"),
        buttonName: t(translations, "LABEL_LETS_GO"),
        isClose: true
      }
    ];
    let modal;
    if (!sessionsStateInformation.get("is_onboarding") && host == false) {
      modal = (
        <ModalWelcomeVideo onRef={ref => (this.ModalWelcomeVideo = ref)} />
      );
    } else {
      modal = "";
    }
    return (
      <div className="open-modal">
        <Modal
          isOpen={
            host == true
              ? false
              : !sessionsStateInformation.get("is_onboarding")
          }
          size="lg"
          style={style}
        >
          <ModalBody>
            <CarouselBoardingItem
              items={items}
              handleToggleModal={this.handleToggleModal}
            />
          </ModalBody>
        </Modal>
        {modal}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations,
    sessionCode: ownProps.match.params.sessionCode,
    sessionsStateInformation: state.sessionsStateInformation,
    sessionsFilterData: state.sessionsFilterData,
    fullDataCompany: state.company
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sessionsUpdateIsOnBoardingSuccess: () =>
      dispatch(sessionsUpdateIsOnBoardingSuccess()),
    sessionsAddIsWatchedOnStateInformation: sessionCode =>
      dispatch(sessionsAddIsWatchedOnStateInformation(sessionCode))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ModalOnBoarding)
);
