// @flow
import React from 'react'

type Props = {
  text: string,
};

class MediaInstruction extends React.Component<Props> {
  static defaultProps = {
    text: '',
  }

  render() {
    const { text } = this.props

    return (
      <div className="text-white">
        <h6 className="text">{text}</h6>
      </div>
    )
  }
}

export default MediaInstruction
