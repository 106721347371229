// @flow

import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import Card from './Card'
import imgCheckDocument from '../../images/check-document.png'
import imgCheckEmail from '../../images/check-email.png'

import { t } from '../../helpers/global'

type Props = {
  className: string,
  companyTitle: string,
  translations: Map<string, any>,
  isCvUploaded: boolean,
  jobTitle: string,
  jobRequireCv: number
};

class CardEndSession extends React.Component<Props> {
  static defaultProps = {
    className: '',
    companyTitle: '',
    isCvUploaded: false,
    jobTitle: '',
    jobRequireCv: 0,
  }

  render() {
    const {
      className,
      translations,
      companyTitle,
      isCvUploaded,
      jobTitle,
      jobRequireCv
    } = this.props

    let congratsComponent: Object =
      <Fragment>
        <div dangerouslySetInnerHTML={{__html: t(
          translations,
          'CARD_END_SESSION_CONGRATULATIONS',
          {
            ':job_title': jobTitle,
            ':company_title': companyTitle
          }
        )}} />
      </Fragment>

    if (jobRequireCv && !isCvUploaded) {
      congratsComponent =
        <Fragment>
          <div dangerouslySetInnerHTML={{__html: t(
            translations,
            'CARD_END_SESSION_CONGRATULATIONS_REQUIRE_CV',
            {
              ':job_title': jobTitle,
              ':company_title': companyTitle
            }
          )}} />
        </Fragment>
    }

    return (
      <Card theme="custom" className={className}>
        <div className="card-body m-b-40">
          <img
            src={jobRequireCv && !isCvUploaded ? imgCheckEmail : imgCheckDocument}
            className="card-image"
            alt="Congratulations" />
          <h2 className="card-title">{t(translations, "CARD_END_SESSION_FINISHED")}</h2>
          <div className="row justify-content-center">
            <div className="col-sm-8">
              <p className="card-text m-t-24">
                {congratsComponent}
              </p>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="d-flex justify-content-around">
            <a
              href="mailto:feedback@astrnt.co"
              target="_blank"
              rel="noopener noreferrer"
              className="card-link">
              {t(translations, "TIMEOUT_PAGE_SEND")}
            </a>
            <a
              href="http://astrnt.co"
              target="_blank"
              rel="noopener noreferrer"
              className="card-link">
              {t(translations, "TIMEOUT_PAGE_LEARN")}
            </a>
          </div>
        </div>
      </Card>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations
  }
}

export default connect(mapStateToProps, null)(CardEndSession)
