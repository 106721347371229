// @flow
import React from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import { t } from '../../helpers/global'

type Props = {
  plural: boolean,
  className: string,
  translations: Map<string, any>
};

class OptionNote extends React.Component<Props> {

  static defaultProps = {
    plural: true,
    className: '',
  }

  render() {
    const {
      plural,
      className,
      translations
    } = this.props

    return(
      <div className={`p-l-24 p-b-8 ${className}`}>
        {
          plural ? <p>{t(translations, 'OPTION_NOTE_SELECT_MORE')}</p> : <p>{t(translations, 'OPTION_NOTE_SELECT_ONE')}</p>
        }
      </div>
    )

  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations
  }
}

export default connect(mapStateToProps, null)(OptionNote)
