// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import bindAll from "lodash/bindAll";
import { Map, List } from "immutable";
import pluralize from "pluralize";
import Header from "../../lib/elements/Header";
import CardSingleContent from "../../lib/elements/CardSingleContent";
import Card from "../../lib/elements/Card";
import Button from "../../lib/elements/Button";
import Icon from "../../lib/elements/Icon";
import Checkbox from "../../lib/elements/Checkbox";
import PageTitle from "../../lib/elements/PageTitle";
import Indicator from "../../lib/elements/Indicator";
import Duration from "../../lib/elements/Duration";
import MicrophoneTester from "../../lib/elements/MicrophoneTester";
import * as Logger from "astrnt-web-logger";
import { globalBuildDurationDisplayHelper } from "../../helpers/global";
import { sendAstronautQnASessionEvent } from "../../actions/embedded-interview";

import { checkConnection } from "../../dependencies/checkConnection";

import { t } from "../../helpers/global";

type Props = {
  history: Object,
  company: Map<string, any>,
  question: Map<string, any>,
  translations: Map<string, any>,
  interviewsFilterData: Map<string, any>,
  section: Map<string, any>,
  sections: List<Map<string, any>>,
  sessionCode: string,
  sessionsFilterData: Map<string, any>
};

type State = {
  isUnderstand: boolean
};

class InterviewInstruction extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isUnderstand: false
    };

    bindAll(this, [
      "renderTimeIndicator",
      "renderPageTitle",
      "handleUnderstandClick",
      "handleContinueClick"
    ]);
  }

  componentDidMount() {
    const {
      translations,
      history,
      question,
      sessionCode,
      sessionsFilterData
    } = this.props;
    window.scrollTo(0, 0);
    //set to true
    if (
      sessionsFilterData.get("interview_settings_skipped_question_info_page") ==
      1
    ) {
      history.push(
        `/code/${sessionCode}/interview-questions/${question.get("id")}`
      );
    }

    let params = {
      event: "Video Info", // string
      message: "Enter Video Instruction",
      status: "offline" // string
    };

    Logger.recordEvent(params);
  }

  handleUnderstandClick(e) {
    e.preventDefault();

    this.setState({ isUnderstand: !this.state.isUnderstand });

    let params = {
      event: "Video Info", // string
      message: "I understand Checked",
      status: "offline" // string
    };

    Logger.recordEvent(params);
  }

  handleContinueClick(e) {
    e.preventDefault();

    const {
      history,
      question,
      section,
      sessionCode,
      sessionsFilterData,
      sendAstronautQnASessionEvent
    } = this.props;

    if (sessionsFilterData.get("type") === "close section") {
      history.push(
        `/code/${sessionCode}/sections/${section.get(
          "id"
        )}/interview-questions/${question.get("id")}`
      );
    } else {
      sendAstronautQnASessionEvent("Video Question");
      history.push(
        `/code/${sessionCode}/interview-questions/${question.get("id")}`
      );
    }
  }

  renderTimeIndicator() {
    const { section, sections, sessionsFilterData, translations } = this.props;

    if (
      section.get("duration") &&
      sessionsFilterData.get("type") === "close section"
    ) {
      const durationDisplay: string = globalBuildDurationDisplayHelper(
        section.get("duration")
      );
      let indicatorTheme: string = "success";

      if (section.get("duration") <= 5) {
        indicatorTheme = "danger";
      }

      return (
        <Indicator layout="between" theme={indicatorTheme}>
          <div>
            <b>
              {t(translations, "SECTION_LABEL")} {section.get("index") + 1}
            </b>{" "}
            {t(translations, "LABEL_OF")} {sections.size}
          </div>
          <Duration data={durationDisplay} className="bold">
            <Icon
              type="icon"
              name="icon-clock"
              align="bottom"
              className="m-r-8"
            />
          </Duration>
        </Indicator>
      );
    }
  }

  renderPageTitle() {
    const { section, question, sessionsFilterData, translations } = this.props;

    let questionIds: List<number> = sessionsFilterData.get("question_ids");

    if (sessionsFilterData.get("type") === "close section") {
      questionIds = section.get("question_ids");
    }

    const questionIdState: number = questionIds.indexOf(question.get("id")) + 1;

    return (
      <PageTitle
        text={t(translations, "LABEL_QUESTION_X_OF_Y", {
          ":question_number": questionIdState,
          ":question_total": questionIds.size
        })}
      />
    );
  }

  render() {
    const {
      company,
      question,
      interviewsFilterData,
      translations
    } = this.props;

    const { isUnderstand } = this.state;

    return (
      <Fragment>
        <Header company={company.get("title")} type="secondary" />
        <div className="container">
          {this.renderTimeIndicator()}
          {this.renderPageTitle()}
          <div className="row">
            <div className="col-sm-6">
              <CardSingleContent
                className="m-t-16 m-t-small"
                number={interviewsFilterData.get("question_takes_count")}
                text={t(translations, "LABEL_PLURAL_ATTEMPT", {
                  ":pluralize_attempt": pluralize(
                    "Attempts",
                    interviewsFilterData.get("question_takes_count")
                  )
                })}
              >
                <Icon
                  type="icon"
                  name="icon-attempt"
                  size="extralarge"
                  align="bottom"
                  className="m-r-8"
                />
              </CardSingleContent>
            </div>
            <div className="col-sm-6">
              <CardSingleContent
                className="m-t-16 m-t-small"
                number={question.get("maxTime")}
                text={t(translations, "TIME_LABEL_SECOND", {
                  ":pluralize_sec": "Seconds"
                })}
              >
                <Icon
                  type="icon"
                  name="icon-video"
                  size="extralarge"
                  align="bottom"
                  className="m-r-8"
                />
              </CardSingleContent>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <Card className="m-t-24 m-t-small">
                <h4 className="heading4 bold uppercase">
                  {t(translations, "LABEL_PLEASE_NOTE")}
                </h4>
                <p className="heading6 m-t-12 m-b-24 m-t-small m-b-small">
                  {t(translations, "LABEL_PLEASE_NOTE_CONTENT")}
                </p>
                <Checkbox
                  onClick={this.handleUnderstandClick}
                  label={t(translations, "LABEL_I_UNDERSTAND")}
                  className="bold"
                  checked={isUnderstand}
                />
                {/*!interviewsFilterData.get("is_test_sound") ? (
                  <MicrophoneTester />
                ) : (
                  ""
                )*/}
              </Card>
            </div>
          </div>
          <div>
            <center>
              <Button
                className="m-t-40 m-b-40"
                disabled={!isUnderstand}
                onClick={this.handleContinueClick}
              >
                {t(translations, "LABEL_CONTINUE")}
              </Button>
            </center>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations,
    company: state.company,
    question: state.question,
    interviewsFilterData: state.interviewsFilterData,
    section: state.section,
    sections: state.sections,
    sessionCode: ownProps.match.params.sessionCode,
    sessionsFilterData: state.sessionsFilterData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendAstronautQnASessionEvent: astronautEvent =>
      dispatch(sendAstronautQnASessionEvent(astronautEvent))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InterviewInstruction);
