import React from 'react'

class VolumeBar extends React.Component {
  render() {
    return (
      <div className="header-volume">
        <div className="volumes">
          <div className="volume-bar-container">
            <div className="volume-bar" ref={this.props.refVolumePercentage}></div>
          </div>
        </div>
      </div>
    )
  }
}

export default VolumeBar
