// @flow
import React from "react";
import classnames from "classnames";

const progressType = {
  default: "",
  failed: "bg-danger",
  ongoing: "bg-success",
  completed: "bg-info"
};

const progressSize = {
  default: "",
  medium: "md"
};

type Props = {
  type: string,
  size: string,
  value: string | number,
  children?: any,
  className: string,
  disabled: boolean
};

class Progress extends React.Component<Props> {
  static defaultProps = {
    type: progressType.default,
    className: "",
    disabled: false,
    value: 0,
    size: "",
    children: null
  };

  render() {
    const { type, children, size, value, className } = this.props;

    const classType: string = classnames(progressType[type]);
    const classSize: string = classnames(progressSize[size]);

    return (
      <div className={`progress ${classSize} ${className}`}>
        <div
          className={`progress-bar ${classType}`}
          role="progressbar"
          style={{ width: value + "%" }}
          aria-valuenow="0"
          aria-valuemin="0"
          aria-valuemax="100"
        />
        {children}
      </div>
    );
  }
}

export default Progress;
