// @flow
import React from "react";

type Props = {
  contentImage: string,
  contentImageAlt: string,
  contentTitle: string,
  children?: any,
  className: string
};

class ContentSoundCheck extends React.Component<Props> {
  static defaultProps = {
    contentImage: "",
    contentImageAlt: "",
    contentTitle: "",
    children: null,
    className: ""
  };

  render() {
    const {
      contentImage,
      contentImageAlt,
      contentTitle,
      children,
      className
    } = this.props;

    return (
      <div className={className}>
        <h2 className="heading2 m-b-16 text-capitalize">{contentTitle}</h2>
        {children}
      </div>
    );
  }
}

export default ContentSoundCheck;
