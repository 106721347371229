// @flow

import React from "react";
import { connect } from "react-redux";
import { Map } from "immutable";
import pluralize from "pluralize";
import Button from "./Button";
import ButtonLabel from "./ButtonLabel";
import MediaInstruction from "./MediaInstruction";

import { t } from "../../helpers/global";

type Props = {
  isPlaying?: boolean,
  takesCount?: number,
  translations: Map<string, any>,
  handlePlayClick?: Function,
  handlePauseClick?: Function,
  handleRetakeClick?: Function,
  handleDoneClick?: Function
};

class ControlPanel extends React.Component<Props> {
  render() {
    const {
      takesCount,
      isPlaying,
      translations,
      handlePlayClick,
      handlePauseClick,
      handleRetakeClick,
      handleDoneClick
    } = this.props;

    return <div className="footer-video flex-row flex-column center" />;
  }
}

const mapStateToProps = state => {
  return {
    translations: state.translations
  };
};

export default connect(
  mapStateToProps,
  null
)(ControlPanel);
