// @flow
import React from "react";
import Card from "./Card";
import Content from "./Content";
import ContentSoundCheck from "./ContentSoundCheck";
import Button from "../elements/Button";
import Loader from "../elements/Loader";
import MicrophoneTester from "../../lib/elements/MicrophoneTester";

type Props = {
  contentTitle: string,
  contentImage: string,
  contentImageAlt: string,
  contentText: string,
  className: string,
  buttonName: string,
  loaderName?: string,
  loaderClassName?: string,
  onClick: Function,
  isLoading: boolean
};

class CardTopicInstruksi extends React.Component<Props> {
  static defaultProps = {
    contentTitle: "",
    contentText: "",
    contentImage: "",
    contentImageAlt: "",
    className: "",
    buttonName: "",
    onClick: () => {},
    isLoading: false
  };

  createInstruction(value) {
    return { __html: value };
  }

  render() {
    const {
      contentTitle,
      contentImage,
      contentImageAlt,
      contentText,
      className,
      buttonName,
      onClick,
      loaderName,
      loaderClassName,
      isLoading,
      instruction
    } = this.props;

    return (
      <Card theme="desc" className="card m-t-24 m-t-small">
        <div>
          <h3 className="heading4 bold m-0">Deskripsi Video</h3>
        </div>
        <div
          className="m-t-15"
          dangerouslySetInnerHTML={this.createInstruction(instruction)}
        />
      </Card>
    );
  }
}

export default CardTopicInstruksi;
