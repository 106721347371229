// @flow

import * as React from "react";
import Card from "./Card";
import classnames from "classnames";

type Props = {
  number: string | number,
  text: string | number,
  number2?: string | number,
  text2?: string | number,
  number3?: string | number,
  text3?: string | number,
  children?: any,
  className: string
};

class CardSingleContent extends React.Component<Props> {
  render() {
    const {
      number,
      text,
      number2,
      text2,
      number3,
      text3,
      children,
      className
    } = this.props;

    const classProps: string = classnames(
      "flex-row align-items-center",
      className
    );

    return (
      <Card className={classProps}>
        {children}
        <h3 className="heading3 d-flex align-items-center">
          <p className="heading1 m-r-8 m-b-0 bold">{number}</p>
          <p className="heading3 normal m-b-0">{text}</p>
          {number2 && text2 ? (
            <React.Fragment>
              <p className="heading1 m-r-8 m-b-0 bold m-l-8">{number2}</p>
              <p className="heading3 normal m-b-0">{text2}</p>
            </React.Fragment>
          ) : null}
          {number3 && text3 ? (
            <React.Fragment>
              <p className="heading1 m-r-8 m-b-0 bold m-l-8">{number3}</p>
              <p className="heading3 normal m-b-0">{text3}</p>
            </React.Fragment>
          ) : null}
        </h3>
      </Card>
    );
  }
}

export default CardSingleContent;
