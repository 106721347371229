// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import bindAll from "lodash/bindAll";
import { Map } from "immutable";
import pluralize from "pluralize";
import InterviewBody from "./InterviewBody";
import PageTemplate from "../../lib/elements/PageTemplate";
import HeaderQuestionInterview from "../../lib/elements/HeaderQuestionInterview";
import CircleProgressBar from "../../lib/elements/CircleProgressBar";
import CircleCounter from "../../lib/elements/CircleCounter";
import ControlPanel from "../../lib/elements/ControlPanel";
import ModalDialog from "../../lib/elements/ModalDialog";
import Button from "../../lib/elements/Button";
import Indicator from "../../lib/elements/Indicator";
import { sendAstronautQnASessionEvent } from "../../actions/embedded-interview";

import {
  sectionsStartSessionRequest,
  sectionsStartSessionWelcomeVideoRequest,
  sectionsCheckGDPRRequest
} from "../../actions/sections";

import {
  interviewsCheckGDPRRequest,
  interviewsStartSessionRequest,
  interviewsStartWelcomeVideoRequest,
  interviewsStartRecordingPracticeRequest,
  interviewsRetakeRequest,
  interviewsReduceQuestionDurationCancel,
  interviewsAddFilterIsModalOpenSuccess,
  interviewsAddFilterIsLowSoundSuccess,
  interviewsAddFilterIsPracticeNoSoundSuccess
} from "../../actions/interviews";

import {
  interviewsInitRecord,
  interviewsGetQuestionDurationCircle,
  interviewsGetCountdownDurationCircle
} from "../../helpers/interviews";

import { t, analyticsRecord } from "../../helpers/global";

import initializers from "../../dependencies/initializers";

type Props = {
  job: Map<string, any>,
  company: Map<string, any>,
  candidate: Map<string, any>,
  question: Map<string, any>,
  translations: Map<string, any>,
  sectionsStartSessionRequest: Function,
  sessionCode: string,
  sessionsFilterData: Map<string, any>,
  interviewsUiData: Map<string, any>,
  interviewsFilterData: Map<string, any>,
  interviewsCheckGDPRRequest: Function,
  interviewsStartSessionRequest: Function,
  sectionsCheckGDPRRequest: Function,
  interviewsStartRecordingPracticeRequest: Function,
  interviewsRetakeRequest: Function,
  interviewsReduceQuestionDurationCancel: Function,
  interviewsAddFilterIsModalOpenSuccess: Function,
  interviewsAddFilterIsLowSoundSuccess: Function,
  interviewsAddFilterIsPracticeNoSoundSuccess: Function
};

type State = {
  isVideoPlayerPlaying: boolean
};

const refVolumePercentage = React.createRef();

class InterviewPractice extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isVideoPlayerPlaying: false
    };

    bindAll(this, [
      "renderLowSoundIndicator",
      "resetQuestionAttemptOnInitRecord",
      "handleFinishRecordingClick",
      "handleCountdownDurationClick",
      "handlePlayClick",
      "handlePauseClick",
      "handleRetakeClick",
      "handleDoneClick",
      "handleStopRecord",
      "handleSubmitButton"
    ]);
  }

  handlePlayClick(e) {
    e.preventDefault();

    // $FlowFixMe
    document.getElementById("js-interview-video-player-recorded").play();

    this.setState({ isVideoPlayerPlaying: true });
  }

  handlePauseClick(e) {
    e.preventDefault();

    // $FlowFixMe
    document.getElementById("js-interview-video-player-recorded").pause();

    this.setState({ isVideoPlayerPlaying: false });
  }

  handleRetakeClick(e) {
    e.preventDefault();

    const {
      sessionCode,
      interviewsRetakeRequest,
      interviewsAddFilterIsLowSoundSuccess
    } = this.props;

    interviewsRetakeRequest(sessionCode);

    // $FlowFixMe
    document.getElementById("js-interview-video-player-recorded").pause();

    interviewsInitRecord(
      refVolumePercentage,
      this.handleStopRecord
      // interviewsAddFilterIsLowSoundSuccess,
      // this.resetQuestionAttemptOnInitRecord
    );

    this.setState({ isVideoPlayerPlaying: false });
  }

  handleDoneClick(e) {
    e.preventDefault();

    const { interviewsAddFilterIsModalOpenSuccess } = this.props;

    interviewsAddFilterIsModalOpenSuccess(true);
  }

  handleSubmitButton(e) {
    e.preventDefault();

    const {
      sectionsStartSessionRequest,
      sectionsStartSessionWelcomeVideoRequest,
      interviewsCheckGDPRRequest,
      sectionsCheckGDPRRequest,
      interviewsStartSessionRequest,
      interviewsStartWelcomeVideoRequest,
      sessionCode,
      sessionsFilterData,
      sendAstronautQnASessionEvent
    } = this.props;

    sendAstronautQnASessionEvent("Practice Recording Finished");

    if (sessionsFilterData.get("type") === "close interview") {
      if (sessionsFilterData.get("gdpr_complied") == "0") {
        interviewsCheckGDPRRequest(sessionCode);
      } else if (sessionsFilterData.get("welcome_video_url") == "") {
        interviewsStartSessionRequest(sessionCode);
      } else {
        interviewsStartWelcomeVideoRequest(sessionCode);
      }
    } else if (sessionsFilterData.get("type") === "close section") {
      if (sessionsFilterData.get("gdpr_complied") == "0") {
        sectionsCheckGDPRRequest(sessionCode);
      } else if (sessionsFilterData.get("welcome_video_url") == "") {
        sectionsStartSessionRequest(sessionCode);
      } else {
        sectionsStartSessionWelcomeVideoRequest(sessionCode);
      }
    }
  }

  handleCountdownDurationClick(e) {
    e.preventDefault();

    const {
      sessionCode,
      interviewsFilterData,
      interviewsStartRecordingPracticeRequest
    } = this.props;

    if (!interviewsFilterData.get("is_counting")) {
      interviewsStartRecordingPracticeRequest(sessionCode, true);
    }
  }

  handleFinishRecordingClick(e) {
    e.preventDefault();

    const {
      question,
      translations,
      interviewsFilterData,
      interviewsReduceQuestionDurationCancel
    } = this.props;

    const questionDurationCircle: Map<
      string,
      any
    > = interviewsGetQuestionDurationCircle(
      interviewsFilterData.get("question_duration"),
      question.get("maxTime"),
      t(translations, "LABEL_FINISH")
    );

    if (questionDurationCircle.get("isShowFinish")) {
      interviewsReduceQuestionDurationCancel();
    }
  }

  componentDidMount() {
    interviewsInitRecord(
      refVolumePercentage,
      this.handleStopRecord
      // this.props.interviewsAddFilterIsLowSoundSuccess,
      // this.resetQuestionAttemptOnInitRecord
    );

    this.submitAnalyticsRecord();
  }

  resetQuestionAttemptOnInitRecord() {
    const {
      interviewsFilterData,
      interviewsReduceQuestionDurationCancel,
      interviewsAddFilterIsPracticeNoSoundSuccess
    } = this.props;

    if (
      interviewsFilterData.get("countdown_duration") === 0 &&
      interviewsFilterData.get("question_duration") > 5
    ) {
      interviewsAddFilterIsPracticeNoSoundSuccess(true);
      interviewsReduceQuestionDurationCancel();
    }
  }

  componentWillUnmount() {
    window.checkLowSoundSpeechEvents.stop();
    window.checkNoSoundSpeechEvents.stop();
    window.speechEvents.stop();
  }

  submitAnalyticsRecord() {
    const { job, company, candidate } = this.props;

    analyticsRecord("OpenedPractice", {
      DeviceType: "web",
      Version: initializers.version,
      CompanyId: company.get("id").toString(),
      JobId: job.get("id").toString(),
      CandidateEmail: candidate.get("email")
    });
  }

  handleStopRecord() {
    const {
      interviewsFilterData,
      interviewsAddFilterIsModalOpenSuccess
    } = this.props;

    const blob = new Blob(window.recordedBlobs, { type: "video/webm" });

    // $FlowFixMe
    document.getElementById(
      "js-interview-video-player-recorded"
    ).src = URL.createObjectURL(blob);

    if (
      !interviewsFilterData.get("question_takes_count") &&
      !interviewsFilterData.get("is_practice_no_sound")
    ) {
      interviewsAddFilterIsModalOpenSuccess(true);
    }
  }

  handleRetryClick(e) {
    e.preventDefault();

    window.location.reload();
  }

  renderLowSoundIndicator() {
    const { translations, interviewsFilterData } = this.props;

    if (interviewsFilterData.get("is_low_sound")) {
      return (
        <Indicator layout="center" theme="danger" isTextUppercase={false}>
          {t(translations, "INTERVIEW_DATA_LOW_SOUND")}
        </Indicator>
      );
    }
  }

  render() {
    const {
      question,
      translations,
      interviewsUiData,
      interviewsFilterData,
      sendAstronautQnASessionEvent,
      sessionsUiData
    } = this.props;

    const questionDurationCircle: Map<
      string,
      any
    > = interviewsGetQuestionDurationCircle(
      interviewsFilterData.get("question_duration"),
      question.get("maxTime"),
      t(translations, "LABEL_FINISH")
    );
    const countdownDurationCircle: Map<
      string,
      any
    > = interviewsGetCountdownDurationCircle(
      interviewsFilterData.get("countdown_duration"),
      t(translations, "LABEL_START")
    );

    sendAstronautQnASessionEvent("Practice Recording");

    return (
      <Fragment>
        <PageTemplate interviewMode>
          {/*this.renderLowSoundIndicator()*/}
          <HeaderQuestionInterview
            refVolumePercentage={refVolumePercentage}
            type={interviewsUiData.get("recording_dot_type")}
            label={interviewsUiData.get("recording_title")}
            questionText={interviewsUiData.get("question_title")}
          />
          {interviewsFilterData.get("is_modal_open") ? (
            <div className="footer-video flex-row flex-column center" />
          ) : interviewsFilterData.get("is_finished") ? (
            <ControlPanel
              isPlaying={this.state.isVideoPlayerPlaying}
              takesCount={interviewsFilterData.get("question_takes_count")}
              handlePlayClick={this.handlePlayClick}
              handlePauseClick={this.handlePauseClick}
              handleRetakeClick={this.handleRetakeClick}
              handleDoneClick={this.handleDoneClick}
            />
          ) : (
            <div className="footer-video horizontal-item--center">
              {interviewsFilterData.get("countdown_duration") ? (
                [
                  !interviewsFilterData.get("is_counting") ? (
                    <CircleCounter
                      handleCountdownDurationClick={
                        this.handleCountdownDurationClick
                      }
                      percentage={countdownDurationCircle.get("percentage")}
                      classForPercentage={countdownDurationCircle.get(
                        "classForPercentage"
                      )}
                      classForTransparent={"start"}
                      textForPercentage={t(translations, "LABEL_START")}
                    />
                  ) : (
                    <CircleCounter
                      handleCountdownDurationClick={
                        this.handleCountdownDurationClick
                      }
                      percentage={countdownDurationCircle.get("percentage")}
                      classForTransparent={"after-three"}
                      classForPercentage={countdownDurationCircle.get(
                        "classForPercentage"
                      )}
                      textForPercentage=""
                    />
                  )
                ]
              ) : (
                <CircleProgressBar
                  handleFinishRecordingClick={this.handleFinishRecordingClick}
                  percentage={questionDurationCircle.get("percentage")}
                  classForPercentage={questionDurationCircle.get(
                    "classForPercentage"
                  )}
                  textForPercentage={questionDurationCircle.get(
                    "textForPercentage"
                  )}
                />
              )}
            </div>
          )}
          <InterviewBody />
        </PageTemplate>
        <ModalDialog
          isOpen={interviewsFilterData.get("is_modal_open")}
          title={t(translations, "POPUP_TITLE_READY_TO_GO")}
          text={t(translations, "POPUP_CONTENT_READY_TO_GO", {
            ":pluralize_time": pluralize(
              "time",
              interviewsFilterData.get("question_used_attempt")
            ),
            ":practice_counter": interviewsFilterData.get(
              "question_used_attempt"
            )
          })}
        >
          <Button
            type="button"
            theme="primary"
            size="small"
            onClick={this.handleSubmitButton}
            buttonLoading={sessionsUiData.get("loading")}
            disabled={sessionsUiData.get("disabled_button")}
          >
            {t(translations, "LABEL_LETS_GO")}
          </Button>
        </ModalDialog>
        {/*
          <ModalDialog
            isOpen={interviewsFilterData.get('is_practice_no_sound')}
            title={t(translations, 'POPUP_TITLE_NO_SOUND')}
            text={t(translations, 'INTERVIEW_DATA_NO_SOUND_PRACTICE')} >
            <Button
              type="button"
              theme="primary"
              size="small"
              onClick={this.handleRetryClick}>{t(translations, 'LABEL_RETRY')}</Button>
          </ModalDialog>
        */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    job: state.job,
    company: state.company,
    question: state.question,
    candidate: state.candidate,
    translations: state.translations,
    sessionsFilterData: state.sessionsFilterData,
    sessionCode: ownProps.match.params.sessionCode,
    interviewsUiData: state.interviewsUiData,
    interviewsFilterData: state.interviewsFilterData,
    sessionsUiData: state.sessionsUiData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sectionsStartSessionRequest: sessionCode =>
      dispatch(sectionsStartSessionRequest(sessionCode)),
    sectionsStartSessionWelcomeVideoRequest: sessionCode =>
      dispatch(sectionsStartSessionWelcomeVideoRequest(sessionCode)),
    interviewsCheckGDPRRequest: sessionCode =>
      dispatch(interviewsCheckGDPRRequest(sessionCode)),
    sectionsCheckGDPRRequest: sessionCode =>
      dispatch(sectionsCheckGDPRRequest(sessionCode)),
    interviewsStartSessionRequest: sessionCode =>
      dispatch(interviewsStartSessionRequest(sessionCode)),
    interviewsStartWelcomeVideoRequest: sessionCode =>
      dispatch(interviewsStartWelcomeVideoRequest(sessionCode)),
    interviewsStartRecordingPracticeRequest: (sessionCode, haltStart) =>
      dispatch(interviewsStartRecordingPracticeRequest(sessionCode, haltStart)),
    interviewsRetakeRequest: sessionCode =>
      dispatch(interviewsRetakeRequest(sessionCode)),
    interviewsReduceQuestionDurationCancel: () =>
      dispatch(interviewsReduceQuestionDurationCancel()),
    interviewsAddFilterIsModalOpenSuccess: isOpenModal =>
      dispatch(interviewsAddFilterIsModalOpenSuccess(isOpenModal)),
    interviewsAddFilterIsLowSoundSuccess: isLowSound =>
      dispatch(interviewsAddFilterIsLowSoundSuccess(isLowSound)),
    interviewsAddFilterIsPracticeNoSoundSuccess: isNoSound =>
      dispatch(interviewsAddFilterIsPracticeNoSoundSuccess(isNoSound)),
    sendAstronautQnASessionEvent: astronautEvent =>
      dispatch(sendAstronautQnASessionEvent(astronautEvent))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InterviewPractice);
