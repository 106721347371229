// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Map } from "immutable";
import Card from "./Card";
import Content from "./Content";
import Button from "../../lib/elements/Button";

import { t } from "../../helpers/global";

type Props = {
  className: string,
  translations: Map<string, any>,
  contentTitle: string,
  contentImage: string,
  contentImageAlt: string,
  contentText: string
};

class CardFooterLayout extends React.Component<Props> {
  static defaultProps = {
    contentTitle: "",
    contentImage: "",
    contentImageAlt: "",
    contentText: "",
    className: ""
  };

  render() {
    const {
      className,
      translations,
      contentTitle,
      contentImage,
      contentImageAlt,
      contentText,
      sessionCode
    } = this.props;

    return (
      <Fragment>
        <Card theme="custom" className={`${className} m-b-44`}>
          <div className="card-body">
            <Content
              contentTitle={contentTitle}
              contentImage={contentImage}
              contentImageAlt={contentImageAlt}
            >
              <div className="row justify-content-center">
                <div className="col-sm-8">
                  <p className="heading4">
                    Selamat! kamu telah menyelesaikan kursus
                  </p>
                  <p
                    className="heading4"
                    dangerouslySetInnerHTML={{ __html: contentText }}
                  />
                  <p className="heading4">
                    Terima kasih sudah menggunakan Kognisi dan Astronaut!
                  </p>
                  <center>
                    <Button
                      className={`btn-kembali-kognisi`}
                      disabled={false}
                      type="button"
                      onClick={() => {
                        window.location = "/code/" + sessionCode;
                      }}
                    >
                      Kembali ke Kognisi
                    </Button>
                  </center>
                </div>
              </div>
            </Content>
          </div>
          <div className="card-footer">
            <div className="d-flex justify-content-around">
              <a
                href="mailto:feedback@astrnt.co"
                target="_blank"
                rel="noopener noreferrer"
                className="card-link"
              >
                {t(translations, "TIMEOUT_PAGE_SEND")}
              </a>
              <a
                href="http://astrnt.co"
                target="_blank"
                rel="noopener noreferrer"
                className="card-link"
              >
                {t(translations, "TIMEOUT_PAGE_LEARN")}
              </a>
            </div>
          </div>
        </Card>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    translations: state.translations
  };
};

export default connect(mapStateToProps, null)(CardFooterLayout);
