// @flow

import React from 'react'
import Button from './Button'

type Props = {
  handleToggleModal: Function,
  title: string,
  text: string,
  imageUrl: string,
  imageAlt: string,
  buttonName: string,
  isClose: boolean,
  index: string | number,
};

class BoardingItem extends React.Component<Props> {
  static defaultProps = {
    title: '',
    text: '',
    imageUrl: '',
    imageAlt: '',
    buttonName: '',
    isClose: false,
    index: 0,
    handleToggleModal: () => {}
  }

  render() {
    const { title, text, imageUrl, imageAlt, isClose, buttonName, index, handleToggleModal } = this.props

    return (
      <div className="boarding-item" key={index}>
        <img src={imageUrl} alt={imageAlt} className="img-responsive" />
        <h2 className="heading3 m-t-16">{title}</h2>
        <p className="heading4 normal">{text}</p>
        {
          isClose ? <Button type="button" className="m-t-40" onClick={handleToggleModal}>{buttonName}</Button> : null
        }
      </div>
    )
  }
}

export default BoardingItem
