import _axios from "../dependencies/_axios";

export function questionsAttemptApi(
  candidateId,
  questionId,
  sessionCode,
  token,
  sectionId = ""
) {
  return _axios({
    url: "/api/v2/question/attempt",
    method: "post",
    headers: {
      token
    },
    data: {
      candidate_id: candidateId,
      question_id: questionId,
      interview_code: sessionCode,
      section_id: sectionId
    }
  });
}

export function questionsGetDetailApi(
  inviteId,
  candidateId,
  questionId,
  sessionCode,
  token,
  sectionId = "",
  front_end_runtime
) {
  return _axios({
    url: "/api/web/interview/question",
    method: "post",
    headers: {
      token
    },
    data: {
      invite_id: inviteId,
      candidate_id: candidateId,
      section_id: sectionId,
      question_id: questionId,
      interview_code: sessionCode,
      frontend_run_time: front_end_runtime
    }
  });
}

export function questionsRegisterApi(candidateId, sessionCode) {
  return _axios({
    url: "/api/v2/question/register",
    method: "post",
    data: {
      candidate_id: candidateId,
      interview_code: sessionCode,
      regId: "-"
    }
  });
}

export function questionsFinishApi(
  candidateId,
  questionId,
  sessionCode,
  token,
  sectionId = ""
) {
  return _axios({
    url: "/api/v2/question/finish",
    method: "post",
    headers: {
      token
    },
    data: {
      candidate_id: candidateId,
      section_id: sectionId,
      question_id: questionId,
      interview_code: sessionCode
    }
  });
}

export function questionsAnswerApi(
  inviteId,
  candidateId,
  questionId,
  type,
  interviewType,
  answerIds,
  sessionCode,
  token,
  textAnswer = "",
  front_end_run_time
) {
  if (textAnswer == "") {
    return _axios({
      url: "/api/v2/question/answer",
      method: "post",
      headers: {
        token
      },
      data: {
        invite_id: inviteId,
        candidate_id: candidateId,
        question_id: questionId,
        type: type,
        interview_type: interviewType,
        answer_ids: answerIds,
        text_answer: textAnswer,
        interview_code: sessionCode,
        frontend_run_time: 0
      }
    });
  } else {
    let formData = new FormData();
    formData.append("invite_id", inviteId);
    formData.append("candidate_id", candidateId);
    formData.append("question_id", questionId);
    formData.append("type", type);
    formData.append("interview_type", interviewType);
    formData.append("answer_ids", answerIds);
    formData.append("interview_code", sessionCode);
    formData.append("text_answer", textAnswer);
    return _axios({
      url: "/api/v2/question/answer",
      method: "post",
      responseType: "json",
      headers: {
        token
      },
      data: formData
    });
  }
}

export function questionsResetAttemptApi(
  inviteId,
  candidateId,
  questionId,
  sessionCode,
  isTestSound = false
) {
  return _axios({
    url: "/api/v2/question/reset/attempt",
    method: "post",
    data: {
      invite_id: inviteId,
      candidate_id: candidateId,
      question_id: questionId,
      interview_code: sessionCode,
      is_test_sound: isTestSound
    }
  });
}
