// @flow
import React from "react";
import Card from "./Card";
import Content from "./Content";
import ContentSoundCheck from "./ContentSoundCheck";
import Button from "../elements/Button";
import Loader from "../elements/Loader";
import MicrophoneTester from "../../lib/elements/MicrophoneTester";

type Props = {
  contentTitle: string,
  contentImage: string,
  contentImageAlt: string,
  contentText: string,
  className: string,
  buttonName: string,
  loaderName?: string,
  loaderClassName?: string,
  onClick: Function,
  isLoading: boolean
};

class CardSoundCheck extends React.Component<Props> {
  static defaultProps = {
    contentTitle: "",
    contentText: "",
    contentImage: "",
    contentImageAlt: "",
    className: "",
    buttonName: "",
    onClick: () => {},
    isLoading: false
  };

  render() {
    const {
      contentTitle,
      contentImage,
      contentImageAlt,
      contentText,
      className,
      buttonName,
      onClick,
      loaderName,
      loaderClassName,
      isLoading
    } = this.props;

    return (
      <Card theme="sound" className={className}>
        <div className="card-body p-0">
          <MicrophoneTester />
          <audio
            id="js-sound-check"
            controls="controls"
            className="hide"
            autoPlay
            muted
          />
        </div>
      </Card>
    );
  }
}

export default CardSoundCheck;
