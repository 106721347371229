// @flow
import React from 'react'
import classnames from 'classnames'

type Props = {
  text: string,
  className: string,
  filled:boolean,
};

class SignContent extends React.Component<Props> {

  static defaultProps = {
    text: '',
    className: '',
    filled: false,
  }

  render() {
    const {
      text,
      className,
      filled
    } = this.props

    const classProps: string = classnames(
      'd-flex align-items-center',
      className
    )

    return(
      <div className={classProps}>
        <div className={`sign m-r-8 ${ filled? 'fullfill': '' }`}></div>
        <p className="m-0">{text}</p>
      </div>
    )
  }
}

export default SignContent
