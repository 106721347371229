// @flow

import hark from "hark";
import { Map } from "immutable";
import Promise from "bluebird";
import * as Logger from "astrnt-web-logger";

window.recordedBlobs = [];
window.checkLowSoundSpeechEvents = {
  stop: () => {}
};
window.checkNoSoundSpeechEvents = {
  stop: () => {}
};

window.speechEvents = {
  stop: () => {}
};

// please check sagas/interviews at method interviewsReduceCountdownDuration
window.arrNoDb = [];

export function interviewsGetQuestionDurationCircle(
  questionDuration: number,
  questionMaxTime: number,
  finishString: string
): Map<string, any> {
  const percentage = (questionDuration / questionMaxTime) * 100;
  const isShowFinish = questionMaxTime - questionDuration >= 5;

  let classForPercentage: string;
  let textForPercentage: string | number;

  if (percentage === 100) {
    classForPercentage = "start";
  } else if (isShowFinish) {
    classForPercentage = "finish";
    textForPercentage = finishString;
  } else {
    classForPercentage = "";
    textForPercentage = questionDuration;
  }

  return Map({
    percentage,
    isShowFinish,
    classForPercentage,
    textForPercentage
  });
}

export function interviewsGetCountdownDurationCircle(
  countdownDuration: number,
  startString: string,
  prepTime: number = 100
): Map<string, any> {
  const percentage: number = (countdownDuration / 10) * 100;
  const prep_time: number = (prepTime / 10) * 100;
  let classForPercentage: string;
  let textForPercentage: string | number;

  if (percentage === prep_time) {
    classForPercentage = "start";
    textForPercentage = startString;
  } else {
    textForPercentage = countdownDuration;
  }

  return Map({
    percentage,
    textForPercentage,
    classForPercentage
  });
}

export function interviewsInitRecord(
  ref: Object,
  callbackStopRecord: Function,
  callbackIsLowSound: Function,
  callbackIsNoSound: Function
): void {
  try {
    window.recordedBlobs = [];

    setVideoPlayerRecordedListener();

    // $FlowFixMe
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: true
      })
      .then(stream => setMediaRecorderAndPlayer(stream, callbackStopRecord))
      .then(stream =>
        setSpeechEvents(stream, ref, callbackIsLowSound, callbackIsNoSound)
      )
      .catch(error => {
        let params = {
          event: "Video Recording", // string
          message: "error" + error,
          status: "offline" // string
        };

        Logger.recordEvent(params);

        throw error;
      });
  } catch (error) {
    console.error(error);
  }
}

export function interviewsInitAudio(ref: Object): void {
  try {
    // $FlowFixMe
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(stream => setSpeechEvents(stream, ref))
      .catch(error => {
        throw error;
      });
  } catch (error) {
    console.error(error);
  }
}

export function setRefStyleHeight(ref: Object, percent: number): void {
  if (ref) {
    if (ref.current) {
      ref.current.style.height = `${percent}%`;
    }
  }
}

export function setRefStyleWidth(ref: Object, percent: number): void {
  if (ref) {
    if (ref.current) {
      ref.current.style.width = `${percent}%`;
    }
  }
}

function decideMediaRecorderOptions() {
  let options = {};

  // $FlowFixMe
  if (MediaRecorder.isTypeSupported("video/mp4;codecs=h264")) {
    options = {
      type: "video",
      timeSlice: 1000,
      mimeType: "video/mp4;codecs=h264"
    };
  } else if (MediaRecorder.isTypeSupported("video/webm;codecs=vp9")) {
    options = {
      type: "video",
      timeSlice: 1000,
      mimeType: "video/webm;codecs=vp9"
    };
  } else if (MediaRecorder.isTypeSupported("video/webm;codecs=vp8,opus")) {
    options = {
      type: "video",
      timeSlice: 1000,
      mimeType: "video/webm;codecs=vp8,opus"
    };
  }

  return options;
}

function setVideoPlayerRecordedListener() {
  // $FlowFixMe
  document
    .getElementById("js-interview-video-player-recorded")
    .addEventListener(
      "error",
      ev => {
        console.error("MediaRecording.recordedMedia.error()");

        // $FlowFixMe
        console.error(
          "Your browser cannot play\n\n" +
            document.getElementById("js-interview-video-player-recorded").src +
            "\n\n media clip. event: " +
            JSON.stringify(ev)
        );

        throw ev;
      },
      true
    );
}

function setSpeechEvents(
  stream,
  ref,
  callbackIsLowSound = null,
  callbackIsNoSound = null
) {
  let countLowDb: number = 0;

  if (callbackIsLowSound !== null) {
    window.checkLowSoundSpeechEvents = hark(stream, {
      threshold: -50,
      interval: 500
    });

    window.checkLowSoundSpeechEvents.on("volume_change", db => {
      if (db < -50 && db > -100) {
        countLowDb++;
      }

      if (countLowDb >= 10) {
        countLowDb = 0;

        callbackIsLowSound(true);
      }
    });
  }

  if (callbackIsNoSound !== null) {
    window.checkNoSoundSpeechEvents = hark(stream, {
      threshold: -50,
      interval: 500
    });

    window.checkNoSoundSpeechEvents.on("volume_change", db => {
      window.arrNoDb.push(db);

      if (window.arrNoDb.length === 20) {
        const accArrNoDb: number =
          window.arrNoDb.reduce((x, y) => x + y, 0) / 20;

        if (accArrNoDb <= -75) {
          callbackIsNoSound();
        }

        window.arrNoDb = [];
      }
    });
  }

  window.speechEvents = hark(stream, {
    threshold: -50
  });

  window.speechEvents.on("speaking", () => {
    setRefStyleHeight(ref, 100);

    if (callbackIsLowSound !== null) {
      countLowDb = 0;

      callbackIsLowSound(false);
    }
  });

  window.speechEvents.on("stopped_speaking", () => setRefStyleHeight(ref, 0));
}

function setMediaRecorderAndPlayer(stream, callbackStopRecord): ?Promise {
  try {
    return new Promise(resolve => {
      // $FlowFixMe
      const interviewVideoPlayerElem: Object = document.getElementById(
        "js-interview-video-player"
      );

      if (interviewVideoPlayerElem) {
        interviewVideoPlayerElem.srcObject = stream;
      }

      const options: Object = decideMediaRecorderOptions();
      // $FlowFixMe
      const mediaRecorder: Object = new MediaRecorder(stream, options);

      mediaRecorder.onstop = callbackStopRecord;
      mediaRecorder.ondataavailable = handleDataAvailableRecord;

      window.mediaRecorder = mediaRecorder;

      resolve(stream);
    });
  } catch (error) {
    let params = {
      event: "Video Recording", // string
      message: "Media Recording error " + error,
      status: "offline" // string
    };

    Logger.recordEvent(params);

    console.error("Exception while creating MediaRecorder: " + error);
  }
}

function handleDataAvailableRecord(event) {
  if (event.data && event.data.size > 0) {
    window.recordedBlobs.push(event.data);
  }
}
