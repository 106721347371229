// @flow

import React from "react";
import classnames from "classnames";

const cardTheme = {
  custom: "card__custom",
  material: "card__material",
  loading: "card--loading",
  note: "card__note",
  basic: "card__basic",
  rating: "card__rating",
  sound: "card__sound",
  verify: "card__verify",
  footer: "card__footer",
  form: "card__form",
  list: "card__list",
  title: "card__title",
  desc: "card__desc",
  price: "card__price"
};

type Props = {
  children?: any,
  className: string,
  theme: string
};

class Card extends React.Component<Props> {
  static defaultProps = {
    theme: "",
    className: "",
    children: null
  };

  render() {
    const { children, className, theme } = this.props;

    const classProps: string = classnames("card", cardTheme[theme], className);

    return <div className={classProps}>{children}</div>;
  }
}

export default Card;
