// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Map, List } from "immutable";
import bindAll from "lodash/bindAll";
import Header from "../../lib/elements/Header";
import CardSingleContent from "../../lib/elements/CardSingleContent";
import CardWithLabel from "../../lib/elements/CardWithLabel";
import InstructionDesk from "../../lib/elements/InstructionDesk";
import Button from "../../lib/elements/Button";
import Time from "../../lib/elements/Time";
import Icon from "../../lib/elements/Icon";
import Indicator from "../../lib/elements/Indicator";
import Duration from "../../lib/elements/Duration";
import imgInstruction1 from "../../images/instruction-ftq-1.png";
import imgInstruction2 from "../../images/instruction-ftq-2.png";
import imgInstruction3 from "../../images/instruction-ftq-3.png";

import { sessionsReduceDurationLeftRequest } from "../../actions/sessions";

import { ftqStartRequest } from "../../actions/ftq";

import { questionsGetDetailOnStartedTestRequest } from "../../actions/questions";

import { t } from "../../helpers/global";

import {
  globalGenerateUnitOfTimeHelper,
  globalBuildDurationDisplayHelper
} from "../../helpers/global";

type Props = {
  history: Object,
  job: Map<string, any>,
  company: Map<string, any>,
  candidate: Map<string, any>,
  translations: Map<string, any>,
  ftqStartRequest: Function,
  question: Map<string, any>,
  questionsGetDetailOnStartedTestRequest: Function,
  sessionCode: string,
  sessionsFilterData: Map<string, any>,
  sessionsReduceDurationLeftRequest: Function
};

class FtqIndexInProgress extends React.Component<Props> {
  constructor(props: Object): void {
    super(props);

    bindAll(this, ["handleContinueClick"]);
  }

  handleContinueClick(e: Object): void {
    e.preventDefault();

    const { sessionCode, ftqStartRequest } = this.props;

    ftqStartRequest(sessionCode);
  }

  componentDidMount() {
    const {
      questionsGetDetailOnStartedTestRequest,
      sessionCode,
      sessionsReduceDurationLeftRequest
    } = this.props;

    window.scrollTo(0, 0);

    questionsGetDetailOnStartedTestRequest(sessionCode);
    sessionsReduceDurationLeftRequest();
  }

  render() {
    const {
      job,
      company,
      candidate,
      question,
      sessionsFilterData,
      translations,
      sessionsUiData
    } = this.props;

    const duration: Map<string, any> = globalGenerateUnitOfTimeHelper(
      sessionsFilterData.get("duration_left")
    );
    const questionIds: List<number> = sessionsFilterData.get("question_ids");
    const questionIdState: number = questionIds.indexOf(question.get("id")) + 1;
    const questionDurationLeft: number = question.get("question_duration_left");
    const questionDurationLeftDisplay: string = globalBuildDurationDisplayHelper(
      questionDurationLeft
    );

    let indicatorTheme: string = "success";

    if (questionDurationLeft <= 5) {
      indicatorTheme = "danger";
    }

    return (
      <Fragment>
        <Header
          company={company.get("title")}
          job={job.get("title")}
          position={job.get("jobType")}
          location={job.get("locations")}
          redirectCode={sessionsFilterData.get("ecourse_code")}
          learner={candidate}
        />
        <div className="container">
          <Indicator layout="between" theme={indicatorTheme}>
            <div>
              <b>
                {t(translations, "LABEL_QUESTION")} {questionIdState}
              </b>{" "}
              {t(translations, "INSTRUCTION_ALREADY_IN_PROGRESS")}
            </div>
            <Duration data={questionDurationLeftDisplay} className="bold">
              <Icon
                type="icon"
                name="icon-clock"
                align="bottom"
                className="m-r-8"
              />
            </Duration>
          </Indicator>
          <section>
            <h2 className="heading4 normal m-t-24">
              {t(translations, "LABEL_WELCOME_BACK")}{" "}
              <b className="bold">{candidate.get("fullname")}</b>!<br />
              {t(translations, "LABEL_LETS_CONTINUE")}
            </h2>
            <CardSingleContent
              className="m-t-16"
              number={`${questionIdState} ${t(translations, "LABEL_OF")} ${
                questionIds.size
              }`}
              text={t(translations, "LABEL_FREETEXT")}
            >
              <Icon
                type="icon"
                name="icon-ftq"
                size="extralarge"
                align="bottom"
                className="m-r-8"
              />
            </CardSingleContent>
            <CardWithLabel
              label={t(translations, "INSTRUCTION_TIME_TO_COMPLETE")}
            >
              <Time data={duration} className="m-b-0 bold">
                <Icon
                  type="icon"
                  name="icon-timer"
                  size="extralarge"
                  align="middle"
                  className="m-r-8"
                />
              </Time>
            </CardWithLabel>
          </section>
          <section>
            <h2 className="heading4 uppercase normal m-t-32">
              <b>{t(translations, "HOW_IT_WORKS_HOW_LABEL")}</b>{" "}
              {t(translations, "HOW_IT_WORKS_WORKS_LABEL")}
            </h2>
            <div className="row no-gutters m-t-20">
              <InstructionDesk
                image={imgInstruction1}
                text={t(translations, "HOW_IT_WORKS_FTQ_TIMER")}
                className="col-sm-4 bg-white vertical-item--center"
              />
              <InstructionDesk
                image={imgInstruction2}
                text={t(translations, "HOW_IT_WORKS_FTQ_TOOLS")}
                className="col-sm-4 bg-white vertical-item--center"
              />
              <InstructionDesk
                image={imgInstruction3}
                text={t(translations, "HOW_IT_WORKS_FTQ_INSTRUCTION")}
                className="col-sm-4 bg-white vertical-item--center"
              />
            </div>
          </section>
          <section>
            <center>
              <Button
                className="m-t-40 m-b-40"
                onClick={this.handleContinueClick}
                buttonLoading={sessionsUiData.get("loading")}
                disabled={sessionsUiData.get("disabled_button")}
              >
                {t(translations, "INSTRUCTION_CONTINUE_SESSION")}
              </Button>
            </center>
          </section>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations,
    job: state.job,
    company: state.company,
    candidate: state.candidate,
    question: state.question,
    sessionsFilterData: state.sessionsFilterData,
    sessionCode: ownProps.match.params.sessionCode,
    sessionsUiData: state.sessionsUiData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ftqStartRequest: sessionCode => dispatch(ftqStartRequest(sessionCode)),
    questionsGetDetailOnStartedTestRequest: sessionCode =>
      dispatch(questionsGetDetailOnStartedTestRequest(sessionCode)),
    sessionsReduceDurationLeftRequest: () =>
      dispatch(sessionsReduceDurationLeftRequest())
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FtqIndexInProgress)
);
