const TIME_EXTENDED = "TIME_EXTENDED";

let store_shopee = {
  get() {
    let timeDataStr = localStorage.getItem(TIME_EXTENDED);

    const time_extend = JSON.parse(timeDataStr);
    return time_extend;
  },

  set(time_extend) {
    let pass = time_extend != null;
    if (pass) {
      localStorage.setItem(TIME_EXTENDED, JSON.stringify(time_extend));
    }
  },

  remove() {
    localStorage.removeItem(TIME_EXTENDED);
  },

  setModalOpenedOnce(isOpen, sessionCodeKey) {
    let pass = isOpen != null;
    if (pass) {
      localStorage.setItem(sessionCodeKey, JSON.stringify(isOpen));
    }
  },

  getModalOpenedOnce(sessionCodeKey) {
    let dataStr = localStorage.getItem(sessionCodeKey);

    const data = JSON.parse(dataStr);
    return data;
  }
};

export default store_shopee;
