export const getJob = state => state.job;
export const getCompany = state => state.company;
export const getCandidate = state => state.candidate;
export const getTranslations = state => state.translations;
export const getSection = state => state.section;
export const getSections = state => state.sections;
export const getInterviewsUiData = state => state.interviewsUiData;
export const getInterviewsFilterData = state => state.interviewsFilterData;
export const getQuestion = state => state.question;
export const getQuestionsFormData = state => state.questionsFormData;
export const getSessionsFilterData = state => state.sessionsFilterData;
export const getSessionsStateInformation = state =>
  state.sessionsStateInformation;
export const getwelcomeVideoInformation = state =>
  state.welcomeVideoInformation;
export const getinterviewPracticeSound = state => state.interviewPracticeSound;
export const getsessionDurationCount = state => state.sessionDurationCount;
