export function mcqsStartRequest(sessionCode) {
  return {
    type: "MCQS_START_REQUEST",
    sessionCode
  };
}

export function mcqsStartWelcomeVideoRequest(sessionCode) {
  return {
    type: "MCQS_START_WELCOME_VIDEO",
    sessionCode
  };
}

export function mcqsAnswerQuestionRequest(sessionCode) {
  return {
    type: "MCQS_ANSWER_QUESTION_REQUEST",
    sessionCode
  };
}

export function mcqsGDPRRequest(sessionCode) {
  return {
    type: "MCQS_GDPR_REQUEST",
    sessionCode
  };
}
