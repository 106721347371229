import $ from "jquery";
import configurations from "./misc/configurations";

const validate = values => {
  const errors = {};

  if (!values.fullname || $.trim(values.fullname) === "") {
    errors.fullname = `Nama lengkap tidak boleh kosong`;
  }

  if (!values.email || $.trim(values.email) === "") {
    errors.email = `Email tidak boleh kosong`;
  }

  if (!values.name || $.trim(values.name) === "") {
    errors.name = `Nama tidak boleh kosong`;
  }

  if (!values.password || $.trim(values.password) === "") {
    errors.password = `Password tidak boleh kosong`;
  }

  if (values.password && values.password.length < 8) {
    errors.password = `Kata sandi harus memiliki panjang minimal 8 karakter, terdiri dari angka, huruf kapital dan huruf kecil.`;
  }

  var pattern = /^.*(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$/;
  if (values.password && !pattern.test(values.password)) {
    errors.password = `Kata sandi harus memiliki panjang minimal 8 karakter, terdiri dari angka, huruf kapital dan huruf kecil.`;
  }

  if (
    values.password &&
    values.password2 &&
    values.password !== values.password2
  ) {
    errors.password2 = "Password tidak cocok";
  }

  if (!values.email2 || $.trim(values.email2) === "") {
    errors.email2 = `Konfirmasi alamat email tidak boleh kosong`;
  }

  if (!values.phone || $.trim(values.phone) === "") {
    errors.phone = `Nomor HP tidak boleh kosong`;
  }

  if (values.phone && values.phone.length < 8) {
    errors.phone = `Nomor HP minimal 8 angka.`;
  }

  if (values.phone && !configurations.phoneRegex.test(values.phone)) {
    errors.phone = "Nomor HP tidak valid";
  }

  if (values.email && !configurations.emailRegex.test(values.email)) {
    errors.email = "Alamat email tidak valid";
  }

  if (values.email2 && !configurations.emailRegex.test(values.email)) {
    errors.email2 = "Alamat email tidak valid";
  }

  if (values.email && values.email2 && values.email !== values.email2) {
    errors.email2 = "Email tidak cocok";
  }

  if (!values.field_work || $.trim(values.field_work) === "") {
    errors.field_work = `Bidang Pekerjaan tidak boleh kosong`;
  }

  if (!values.organization || $.trim(values.organization) === "") {
    errors.organization = `Organisasi/Institusi tidak boleh kosong`;
  }

  return errors;
};

export default validate;
