// @flow

/**
 * Sagas for sessions
 *
 * @module sagas/sessions
 */

import { put, call, all, select, take } from "redux-saga/effects";
import { delay } from "redux-saga";
import { push } from "react-router-redux";
import localforage from "localforage";
import omit from "lodash/omit";
import { Map, List, fromJS } from "immutable";
import initializers from "../dependencies/initializers";
import { countdown } from "./global";
import { cvsDecideSendEmail } from "./cvs";
import authToken from "../dependencies/authStore";
import {
  sessionsFinishApi,
  sessionsFetchInitialDataApi,
  sessionsUpdateIsWatchedApi,
  sessionsTokenAuthApi,
  sessionsFetchInitialDataApiV2,
  sessionsFetchUserDataApi
} from "../apis/sessions";

import { getCompany, getSessionsFilterData } from "../selectors";

import { checkWindow } from "../dependencies/tabsCheck";

/**
 * Fetch initial data when candidate first time doing the test/interview
 *
 * @memberof sagas/sessions
 * @param {Object} params Data that received from store
 * @param {String} params.sessionCode Interview code that generated from the backend
 */
export function* sessionsFetchInitialData({
  sessionCode,
  questionId,
  subFeature,
  tokenCourse,
  isJob,
  resolve,
  reject
}: Object): Generator<*, *, *> {
  try {
    let response: Object = {};
    let _token = tokenCourse;
    let urlRedirect = `/code/${sessionCode}`;

    if (null !== _token && "" !== _token && isJob) {
      let isUserError = true;
      try {
        const user = yield call(sessionsFetchUserDataApi, _token, sessionCode);
        // console.log(user);
        if (user.status === 200) {
          isUserError = false;
          if (user.data.user.interview_code) {
            const interviewCode = user.data.user.interview_code;
            urlRedirect = `/code/${interviewCode}`;
            response = yield call(
              sessionsFetchInitialDataApi,
              interviewCode,
              false
            );
          } else {
            // missing interview_code
            urlRedirect = `/${sessionCode}`;
            response = yield call(
              sessionsFetchInitialDataApiV2,
              sessionCode,
              _token
            );
          }
        } else {
          _token = null;
          yield call(authToken.remove);
          urlRedirect = `/${sessionCode}`;
          response = yield call(
            sessionsFetchInitialDataApiV2,
            sessionCode,
            _token
          );
        }
      } catch (error) {
        if (isUserError == true) {
          _token = null;
          yield call(authToken.remove);
        }

        urlRedirect = `/${sessionCode}`;
        response = yield call(
          sessionsFetchInitialDataApiV2,
          sessionCode,
          _token
        );
      }
    } else {
      if (isJob) {
        // url "/code" and token is NULL
        urlRedirect = `/${sessionCode}`;
        response = yield call(sessionsFetchInitialDataApiV2, sessionCode);
      } else {
        response = yield call(sessionsFetchInitialDataApi, sessionCode);
      }
    }

    if (response.status >= 200 && response.status < 300) {
      // console.log(response.data);
      const initialData: Object = response.data;
      const ecourse_data: Object = initialData.ecourse_data;
      const translations: Object = initialData.translations;
      const information: Object = initialData.information;
      const token: string = initialData.token;
      const gdpr_complied: integer = initialData.gdpr_complied;
      const gdpr_text: string = initialData.gdpr_text;
      const gdpr_aggrement_text: string = initialData.gdpr_aggrement_text;
      const welcome_video: Object = initialData.welcome_video;
      const candidate_detail: Object = initialData.learner;
      const ecourse_review: Object = initialData.ecourse_review;
      const ecourse_detail: Object = initialData.ecourse_detail;
      const ecourse_code: string = initialData.ecourse_code;
      //checkmultitabs
      var arrayErrorMsg = [];
      arrayErrorMsg.messageError = ""; //translations.MULTIPLE_DEVICES_CONTENT;
      arrayErrorMsg.titleError = ""; //translations.MULTIPLE_DEVICES_TITLE;

      checkWindow(arrayErrorMsg);
      const interview_settings: Object = initialData.interview_settings;

      yield call(
        fetchInitialDataMain,
        ecourse_data,
        translations,
        information,
        token,
        gdpr_complied,
        gdpr_text,
        gdpr_aggrement_text,
        welcome_video,
        candidate_detail,
        interview_settings,
        ecourse_review,
        ecourse_detail
      );

      yield put({
        type: "INTERVIEW_ADD_FINISH_SUCCESS",
        is_finish: initialData.finished_status
      });

      if (_token) {
        yield call(authToken.set, _token);
        let authorization = yield call(
          sessionsTokenAuthApi,
          sessionCode,
          ecourse_detail.job_id,
          _token
        );

        if (!isJob && sessionCode != authorization.data.interview_code) {
          let pathName = window.location.pathname;
          pathName = pathName.substring(pathName.length - 14);
          if (pathName != "not-interested") {
            window.location = ecourse_detail ? `/${ecourse_code}` : "/";
          }
          // window.location = ecourse_detail ? `/${ecourse_code}` : "/";
        }

        if (authorization.data.payment) {
          if (
            authorization.data.payment.status_payment == "capture" ||
            authorization.data.payment.status_payment == "settlement"
          ) {
            yield put({
              type: "INTERVIEW_COURSE_AUTHORIZED_SUCCESS",
              is_course_auth: true
            });
          }
          yield put({
            type: "INTERVIEW_COURSE_PAYMENT_SUCCESS",
            ecourse_payment_status: authorization.data.payment.status_payment
          });
        } else {
          yield put({
            type: "INTERVIEW_COURSE_PAYMENT_SUCCESS",
            ecourse_payment_status: authorization.data.message
          });
        }
      } else {
        urlRedirect = ecourse_detail ? `/${ecourse_code}` : "/";
        if (
          candidate_detail &&
          candidate_detail["partners_url"] &&
          subFeature != "not-interested"
        ) {
          window.location = candidate_detail["partners_url"];
        }
      }
      if (subFeature == "not-interested") {
        yield call(subFeatureErrorHandler, sessionCode, subFeature);
      } else {
        yield put(push(urlRedirect));
      }

      yield put({
        type: "SESSIONS_ADD_FILTER_IS_DISPLAY_MAIN_LAYOUT_SUCCESS",
        is_display_main_layout: true
      });

      yield put({
        type: "INTERVIEW_COURSE_CODE_SUCCESS",
        ecourse_code: ecourse_code
      });

      yield call(resolve);
    } else {
      throw response;
    }
  } catch (error) {
    console.log(error);
    if (error.response) {
      console.log(error.response);
      const errors: Object = error.response.data;
      const finishedOrFailedData: Object = omit(errors, [
        "message",
        "translations"
      ]);

      // const processing_progress: Object =
      //   error.response.data.interview_settings.processing_progress;
      const token: string = error.response.data.token;

      // yield put({
      //   type: "INTERVIEW_SETTINGS_DISPLAY_VIDEO_PROCESSING_PROCESS_SUCCESS",
      //   interview_settings_display_video_processing_process: processing_progress
      // });
      yield put({ type: "SESSIONS_ADD_FILTER_TOKEN_SUCCESS", token: token });

      //construct data for error handler

      let errCandidate = { fullname: errors.candidate_name };
      let errCompany = { title: errors.company_title };
      let errJob = { title: errors.job_title };
      var baseProfileUrl;
      if (initializers.api_host == "https://app.astrnt.co") {
        baseProfileUrl = "https://profile.astrnt.co/";
      } else {
        baseProfileUrl = "https://profile-beta.astrnt.co/";
      }

      yield all([
        put({ type: "GLOBAL_FETCH_DATA_ERROR", error: errors.message }),
        put({
          type: "TRANSLATIONS_FETCH_DATA_SUCCESS",
          translations: errors.translations
        }),
        put({
          type: "SESSIONS_ADD_UI_FINISHED_OR_FAILED_DATA_SUCCESS",
          finished_or_failed_data: finishedOrFailedData
        }),
        put({ type: "CANDIDATES_GET_DETAIL_SUCCESS", candidate: errCandidate }),
        put({ type: "COMPANIES_GET_DETAIL_SUCCESS", company: errCompany }),
        put({ type: "JOBS_GET_DETAIL_SUCCESS", job: errJob })
      ]);
      if (errors.candidate != undefined) {
        yield put({
          type: "GLOBAL_FETCH_DATA_CANDIDATE_IS_LINK",
          candidate_is_link:
            baseProfileUrl +
            errors.candidate.job_id +
            "/candidate/" +
            errors.candidate.id
        });
      }
      if (errors.candidate != undefined) {
        yield all([
          put({
            type: "GLOBAL_FETCH_DATA_CANDIDATE_IS_ACCESS_PROFILE",
            candidate_is_access_profile: errors.candidate.is_access_profile
          }),
          put({
            type: "GLOBAL_FETCH_DATA_CANDIDATE_IS_UPDATE_EMAIL",
            candidate_is_update_email: errors.candidate.is_update_email
          })
        ]);
      }
      yield put({
        type: "SESSIONS_ADD_FILTER_IS_DISPLAY_MAIN_LAYOUT_SUCCESS",
        is_display_main_layout: true
      });
      yield call(subFeatureErrorHandler, sessionCode, subFeature);
    }
  }
}

export function* sessionsFinish(
  sessionCode: string,
  title: string,
  body: string,
  paramVideoProcessingProgress
): Generator<*, *, *> {
  const company: Map<string, any> = yield select(getCompany);

  let _paramVideoProcessingProgress = false;
  if (paramVideoProcessingProgress !== undefined) {
    _paramVideoProcessingProgress = paramVideoProcessingProgress;
  }

  const sessionsFilterData: Map<string, any> = yield select(
    getSessionsFilterData
  );

  try {
    if (_paramVideoProcessingProgress) {
      yield put(push(`/code/${sessionCode}/video/processing/progress`));
    } else {
      yield put({
        type: "SESSIONS_ADD_UI_MODAL_LOADER_DATA_SUCCESS",
        modal_loader_data: {
          isOpen: true,
          title: title,
          text: body
        }
      });

      const response: Object = yield call(
        sessionsFinishApi,
        sessionCode,
        sessionsFilterData.get("token")
      );
      if (response.status >= 200 && response.status < 300) {
        yield call(cvsDecideSendEmail, sessionCode);

        yield delay(800);

        yield put({
          type: "SESSIONS_ADD_UI_MODAL_LOADER_DATA_SUCCESS",
          modal_loader_data: { isOpen: false }
        });

        if (company.get("finish_page_url")) {
          window.location = company.get("finish_page_url");
        } else {
          yield put(push(`/code/${sessionCode}/post-qna-rating`));
        }
        yield put({
          type: "QUESTIONS_REDUCE_DURATION_CANCEL"
        });
      } else {
        throw response;
      }
    }
  } catch (error) {
    console.log(error);
    yield call(cvsDecideSendEmail, sessionCode);

    // yield call(sessionsShowFinishModal, title, body);
    yield delay(800);

    yield put({
      type: "SESSIONS_ADD_UI_MODAL_LOADER_DATA_SUCCESS",
      modal_loader_data: { isOpen: false }
    });

    if (company.get("finish_page_url")) {
      window.location = company.get("finish_page_url");
    } else {
      yield put(push());
    }
  }
}

export function* sessionsFinishCustom(sessionCode: string): Generator<*, *, *> {
  // console.log(sessionCode);
  const company: Map<string, any> = yield select(getCompany);
  const sessionsFilterData: Map<string, any> = yield select(
    getSessionsFilterData
  );
  try {
    const title = "Nice Work!";
    const text = "Thank you for your answer! We have uploaded your response.";
    const body =
      "To proceed, please close this window, return to the chat interface and click on " +
      '"' +
      "I've completed the video question" +
      '"';
    // const buttonText = "I've completed the video question";

    yield put({
      type: "INTERVIEWS_ADD_FILTER_IS_MODAL_INTERVIEW_CUSTOM_SUCCESS",
      modal_loader_data: {
        isOpen: true,
        isLoading: false,
        title: title,
        text: text,
        body: body,
        buttonText: ""
      }
    });

    const response: Object = yield call(
      sessionsFinishApi,
      sessionCode,
      sessionsFilterData.get("token")
    );
    if (response.status >= 200 && response.status < 300) {
      yield call(cvsDecideSendEmail, sessionCode);

      yield delay(800);
    } else {
      throw response;
    }
  } catch (error) {
    console.log(error);
    yield call(cvsDecideSendEmail, sessionCode);

    yield call(sessionsFinishCustom, sessionCode);
  }
}

/**
 * Dispatch several actions that handle initial data
 *
 * @memberof sagas/sessions
 * @param {Object} interview Data that contain lots of object, mostly ids
 * @param {String} translations Data that contain translation object
 */
export function* fetchInitialDataMain(
  ecourse_data: Object,
  translations: Object,
  information: Object,
  token: string,
  gdpr_complied: string,
  gdpr_text: string,
  gdpr_aggrement_text: string,
  welcome_video: Object,
  candidate_detail: Object,
  interview_settings: Object,
  ecourse_review: Object,
  ecourse_detail: Object
): Generator<*, *, *> {
  if (ecourse_data.topics && ecourse_data.topics.length > 0) {
    const topics: List<Map<string, any>> = fromJS(ecourse_data.topics).map(
      (section, index) => {
        return section.set("index", index);
      }
    );

    yield all([
      put({
        type: "SECTIONS_GET_DETAIL_SUCCESS",
        section: topics.get(0)
      }),
      put({ type: "SECTIONS_FETCH_DATA_SUCCESS", sections: topics })
    ]);
  }

  if (ecourse_data.is_test_sound) {
    yield all([
      put({
        type: "INTERVIEWS_ADD_FILTER_IS_NO_SOUND_SUCCESS",
        is_no_sound: true
      }),
      put({
        type: "INTERVIEWS_ADD_FILTER_IS_TEST_SOUND_SUCCESS",
        is_test_sound: true
      })
    ]);
  }

  if (ecourse_data.volume_checker) {
    yield all([
      put({
        type: "INTERVIEWS_ADD_VOLUME_CHECKER",
        is_check_sound: true
      })
    ]);
  }

  if (candidate_detail.is_update_email == 1) {
    var baseProfileUrl;
    if (initializers.api_host == "https://app.astrnt.co") {
      baseProfileUrl = "https://profile.astrnt.co/";
    } else {
      baseProfileUrl = "https://profile-beta.astrnt.co/";
    }
    yield all([
      put({
        type: "GLOBAL_FETCH_DATA_CANDIDATE_IS_LINK",
        candidate_is_link:
          baseProfileUrl +
          candidate_detail.job_id +
          "/candidate/" +
          candidate_detail.id
      }),
      put({
        type: "GLOBAL_FETCH_DATA_CANDIDATE_IS_UPDATE_EMAIL",
        candidate_is_update_email: candidate_detail.is_update_email
      })
    ]);
  }
  const front_end_duration = ecourse_data.duration_left * 1000;
  let learner = ecourse_data.learner || [];
  if (candidate_detail) {
    learner["partners_url"] = candidate_detail["partners_url"];
  }
  yield all([
    put({
      type: "TRANSLATIONS_FETCH_DATA_SUCCESS",
      translations: translations
    }),
    put({
      type: "CANDIDATES_GET_DETAIL_SUCCESS",
      candidate: learner || []
    }),
    put({
      type: "COMPANIES_GET_DETAIL_SUCCESS",
      company: ecourse_data.provider
    }),
    put({ type: "JOBS_GET_DETAIL_SUCCESS", job: ecourse_data.course }),

    put({
      type: "SESSIONS_ADD_IS_WATCHED_ON_STATE_INFORMATION_SUCCESS",
      is_watched: candidate_detail.is_watched
    }),
    put({
      type: "SESSIONS_FETCH_VIDEO_INFORMATION_SUCCESS",
      video: welcome_video
    }),
    put({
      type: "SESSIONS_ADD_FILTER_TYPE_SUCCESS",
      interviewType: ecourse_data.type
    }),
    put({
      type: "SESSIONS_ADD_FILTER_SUB_TYPE_SUCCESS",
      sub_type: ecourse_data.sub_type
    }),
    put({
      type: "SESSIONS_ADD_FILTER_INVITE_ID_SUCCESS",
      invite_id: ecourse_data.invite_id
    }),
    put({
      type: "SESSIONS_ADD_FILTER_EXPIRED_DATE_SUCCESS",
      expired_date: ecourse_data.expired_date
    }),
    //dont need duration later this remove
    put({
      type: "SESSIONS_ADD_FILTER_DURATION_LEFT_SUCCESS",
      duration_left: ecourse_data.total_media_duration
    }),
    put({
      type: "FRONT_END_RUN_TIME",
      front_end_run_time: front_end_duration
    }),
    put({
      type: "SESSIONS_ADD_FILTER_QUESTION_IDS_SUCCESS",
      question_ids: ecourse_data.question_ids
    }),
    put({ type: "SESSIONS_ADD_FILTER_TOKEN_SUCCESS", token: token }),
    put({ type: "SESSIONS_ADD_GDPR_TEXT_SUCCESS", gdpr_text: gdpr_text }),
    put({
      type: "SESSIONS_ADD_GDPR_AGREE_SUCCESS",
      gdpr_aggrement_text: gdpr_aggrement_text
    }),
    put({
      type: "SESSIONS_ADD_WELCOME_VIDEO_URL_SUCCESS",
      welcome_video_url:
        welcome_video.type == "jwplayer"
          ? welcome_video.video_url
          : welcome_video.welcome_video_url
    }),
    put({
      type: "SESSIONS_ADD_WELCOME_VIDEO_WIDTH_SUCCESS",
      width: welcome_video.width ? welcome_video.width : 600
    }),
    put({
      type: "SESSIONS_ADD_WELCOME_VIDEO_HEIGHT_SUCCESS",
      height: welcome_video.height ? welcome_video.height : 400
    }),
    put({
      type: "SESSIONS_ADD_IS_ACCESS_PROFILE_SUCCESS",
      is_access_profile: candidate_detail.is_access_profile
    }),
    put({
      type: "SESSIONS_DURATION_TEST_FULL",
      duration_left: front_end_duration
    }),
    put({
      type: "REVIEWS_FETCH_PAGINATION_SUCCESS",
      pagination: omit(ecourse_review, "data")
    }),
    put({
      type: "REVIEWS_FETCH_DATA_SUCCESS",
      reviews_data: omit(ecourse_review.data, "average_rating")
    }),
    put({
      type: "REVIEWS_RATING_FETCH_DATA_SUCCESS",
      average_rating: ecourse_review.data.average_rating
    })
  ]);
  yield put({
    type: "ECOURSE_DETAIL_DATA_SUCCESS",
    ecourse_detail
  });
  yield put({
    type: "SESSIONS_ADD_GDPR_COMPLIED_SUCCESS",
    gdpr_complied: gdpr_complied
  });
  yield put({
    type: "ECOURSE_TEACHERS_DATA_SUCCESS",
    ecourse_teachers: ecourse_detail.teachers.reverse()
  });
}

/**
 * Fetch initial data that already stored at local storage
 *
 * @memberof sagas/sessions
 * @param {String} sessionCode Interview code that generated from the backend
 */
export function* fetchInitialDataFromLocalStorage(sessionCode: string): Object {
  let _value: ?Object;
  let isGetInitialData: boolean = false;

  yield localforage
    .getItem("sessionCode")
    .then(value => (isGetInitialData = sessionCode === value));

  yield localforage.getItem("initial_data").then(value => {
    if (isGetInitialData) {
      _value = value;
    }
  });

  return _value;
}

/**
 * Fetch initial data that already stored at local storage
 *
 * @memberof sagas/sessions
 * @param {Object} initialData Data that contain 2 main objects, interview object and translations object
 * @param {String} sessionCode Interview code that generated from the backend
 */
export function* refreshInitialDataOnLocalStorage(
  initialData: Object,
  sessionCode: string
): Generator<*, *, *> {
  yield localforage.clear();

  yield all([
    localforage.setItem("initial_data", initialData),
    localforage.setItem("interview_code", sessionCode)
  ]);
}

export function* sessionsReduceDurationLeft(): Generator<*, *, *> {
  const sessionsFilterData: Map<string, any> = yield select(
    getSessionsFilterData
  );
  const channel = yield call(
    countdown,
    sessionsFilterData.get("duration_left")
  );

  try {
    while (true) {
      const seconds = yield take(channel);

      yield put({
        type: "SESSIONS_ADD_FILTER_DURATION_LEFT_SUCCESS",
        duration_left: seconds
      });
    }
  } finally {
    channel.close();
  }
}

export function* subFeatureErrorHandler(
  sessionCode: string,
  subFeature: string
): Generator<*, *, *> {
  if (subFeature === "not-interested") {
    yield put(push(`/code/${sessionCode}/${subFeature}`));
  }
}

export function* sessionsShowFinishModal(
  title: string,
  text: string
): Generator<*, *, *> {
  yield put({
    type: "SESSIONS_ADD_UI_MODAL_LOADER_DATA_SUCCESS",
    modal_loader_data: {
      isOpen: true,
      title: title,
      text: text
    }
  });

  yield delay(2000);

  yield put({
    type: "SESSIONS_ADD_UI_MODAL_LOADER_DATA_SUCCESS",
    modal_loader_data: { isOpen: false }
  });
}

export function* sessionsAddIsWatchedOnState({
  sessionCode
}): Generator<*, *, *> {
  try {
    const sessionsFilterData: Map<string, any> = yield select(
      getSessionsFilterData
    );
    const invite_id = sessionsFilterData.get("invite_id");
    const response = yield call(
      sessionsUpdateIsWatchedApi,
      sessionCode,
      invite_id
    );
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: "SESSIONS_ADD_IS_WATCHED_ON_STATE_INFORMATION_SUCCESS",
        is_watched: 1
      });
    } else {
      throw response;
    }
  } catch (error) {
    yield put({
      type: "SESSIONS_ADD_IS_WATCHED_ON_STATE_INFORMATION_SUCCESS",
      is_watched: 0
    });
  }
}
