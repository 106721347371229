// @flow
import React from 'react'
import classnames from 'classnames'

type Props = {
  className: string,
  children?: any,
  interviewMode: boolean,
};

class PageTemplate extends React.Component<Props> {

  static defaultProps = {
    className: '',
    children: null,
    interviewMode: true,
  }

  render() {
    const {
      interviewMode,
      children
    } = this.props

    const classProps: string = classnames(
      {
        'wrapper-fullscreen' : interviewMode
      }
    )

    return(
      <div className={classProps}>
        {children}
      </div>
    )

  }
}

export default PageTemplate
