// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Map, List } from "immutable";
import bindAll from "lodash/bindAll";
import pluralize from "pluralize";
import Header from "../../lib/elements/Header";
import CardWithLabel from "../../lib/elements/CardWithLabel";
import Button from "../../lib/elements/Button";
import Time from "../../lib/elements/Time";
import Icon from "../../lib/elements/Icon";
import Indicator from "../../lib/elements/Indicator";
import Duration from "../../lib/elements/Duration";
import Card from "../../lib/elements/Card";
import TextList from "../../lib/elements/TextList";
import SectionTotal from "../../lib/elements/SectionTotal";
import { sendAstronautQnASessionEvent } from "../../actions/embedded-interview";
import ModalDialog from "../../lib/elements/ModalDialog";
import store_shopee from "../../dependencies/forShopeeStorage";
import * as Logger from "astrnt-web-logger";
import CardSoundCheck from "../../lib/elements/CardSoundCheck";
import { t } from "../../helpers/global";

import {
  globalGenerateUnitOfTimeHelper,
  globalBuildDurationDisplayHelper
} from "../../helpers/global";

import {
  sectionsStartSessionRequest,
  sectionsDecideReduceTimerRequest
} from "../../actions/sections";

import { sessionsReduceDurationLeftRequest } from "../../actions/sessions";

type Props = {
  job: Map<string, any>,
  company: Map<string, any>,
  candidate: Map<string, any>,
  translations: Map<string, any>,
  section: Map<string, any>,
  sections: List<Map<string, any>>,
  sectionsStartSessionRequest: Function,
  sectionsDecideReduceTimerRequest: Function,
  sessionCode: string,
  sessionsFilterData: Map<string, any>,
  sessionsReduceDurationLeftRequest: Function
};

type State = {
  modal: boolean
};

class SectionIndexInProgress extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      have_opened: 0
    };

    bindAll(this, [
      "handleContinueSessionClick",
      "renderTimeIndicator",
      "setDurationString",
      "setDurationStringWithSec",
      "setSectionTypeString",
      "onMount",
      "unMount",
      "handleModalToggleMcq"
    ]);
  }
  unMount(e) {
    const confirmationMessage = "test";
    e.returnValue = "Hellooww";
    return confirmationMessage;
  }
  onMount(e) {
    const { section, sections, sessionsFilterData } = this.props;

    var jsonsections = sections.toJS();
    var jsonsection = section.toJS();
    var section_position;
    var question_position;
    if (!sessionsFilterData.get("duration_left")) {
      var eventLocation = "Section Info";
    } else {
      var eventLocation = "Section Info Continue";
    }

    jsonsections.map((value, key) =>
      value.id === section.get("id") ? (section_position = key + 1) : null
    );

    var params = {
      event: eventLocation, // string
      message: "Clicked Close or Reload button on section " + section_position,
      status: "online" // string
    };

    if (
      this.props.history.location.pathname.split("/").slice(2)[1] ===
      "instruction"
    ) {
      eventLocation = "Section Instruction";
    } else {
      switch (this.props.history.location.pathname.split("/").slice(-2)[0]) {
        case "mcq-questions":
          jsonsection.question_ids.map((value, key) =>
            value ===
            parseInt(
              this.props.history.location.pathname.split("/").slice(-2)[1]
            )
              ? (question_position = key + 1)
              : null
          );
          eventLocation = "Section MCQ Question";
          params = {
            event: eventLocation, // string
            message:
              "Clicked Close or Reload button on section = (" +
              section_position +
              ") on question = (" +
              question_position +
              ")",
            status: "offline"
          };

          break;
        case "interview-questions":
          var question_position;
          jsonsection.question_ids.map((value, key) =>
            value ===
            parseInt(
              this.props.history.location.pathname.split("/").slice(-2)[1]
            )
              ? (question_position = key + 1)
              : null
          );

          eventLocation = "Section Video Question";
          params = {
            event: eventLocation, // string
            message:
              "Clicked Close or Reload button on section = (" +
              section_position +
              ") on question = (" +
              question_position +
              ")",
            status: "offline"
          };

          break;
      }
    }

    Logger.recordEvent(params);
    const confirmationMessage = "test";
    e.returnValue = "Hellooww";
    return confirmationMessage;
  }

  componentWillUnmount() {
    // remove event beforeUnload ketika sectionINprogress beres
    // window.removeEventListener("beforeunload", this.unMount);
  }

  handleModalToggleMcq() {
    const { sessionCode } = this.props;
    this.setState({ have_opened: 1 });

    store_shopee.setModalOpenedOnce("was_open", sessionCode);
  }

  setDurationString(duration): string {
    const { translations } = this.props;
    const minutes: number = Math.floor(duration / 60);

    return `${minutes} ${t(translations, "TIME_LABEL_MIN")}`;
  }
  setDurationStringWithSec(duration): string {
    const { translations } = this.props;
    const minutes: number = Math.floor(duration / 60);
    var mind = duration % (60 * 60);
    var secd = mind % 60;
    const seconds: number = Math.ceil(secd);

    if (duration < 60) {
      return `${duration} ${t(translations, "TIME_LABEL_SEC")}`;
    } else if (seconds == 0) {
      return `${minutes} ${t(translations, "TIME_LABEL_MIN")}`;
    } else {
      return `${minutes} ${t(translations, "TIME_LABEL_MIN")} ${seconds} ${t(
        translations,
        "TIME_LABEL_SEC"
      )}`;
    }
  }

  setSectionTypeString(sectionType, questionSize): string {
    const { translations } = this.props;

    let sectionTypeString: string;
    if (sectionType === "mcq") {
      sectionTypeString = t(translations, "QUESTION_TYPE_MCQ_PLURAL", {
        ":pluralize_question": pluralize("Question", questionSize),
        ":n_question": questionSize
      });
    } else if (sectionType === "freetext") {
      sectionTypeString = questionSize + " Freetext Question";
    } else {
      sectionTypeString = t(translations, "QUESTION_TYPE_VIDEO_PLURAL", {
        ":pluralize_question": pluralize("Question", questionSize),
        ":n_question": questionSize
      });
    }

    return sectionTypeString;
  }

  handleContinueSessionClick(e) {
    e.preventDefault();

    const {
      sessionCode,
      sectionsStartSessionRequest,
      sessionsFilterData,
      sendAstronautQnASessionEvent
    } = this.props;

    sendAstronautQnASessionEvent("Continue QnA Session");

    if (!sessionsFilterData.get("duration_left")) {
      var eventLocation = "Section Info";
    } else {
      var eventLocation = "Section Info Continue";
    }

    let params = {
      event: eventLocation, // string
      message: "End Section",
      status: "offline" // string
    };
    Logger.recordEvent(params);

    sectionsStartSessionRequest(sessionCode);
  }

  componentDidMount() {
    const {
      sessionCode,
      sectionsDecideReduceTimerRequest,
      sessionsReduceDurationLeftRequest,
      sessionsFilterData,
      section,
      sections
    } = this.props;
    window.scrollTo(0, 0);
    sectionsDecideReduceTimerRequest(sessionCode);
    sessionsReduceDurationLeftRequest(sessionCode);

    if (!sessionsFilterData.get("duration_left")) {
      var eventLocation = "Section Info";
    } else {
      var eventLocation = "Section Info Continue";
    }

    let params = {
      event: eventLocation, // string
      message: "Enter Section Info",
      status: "offline" // string
    };
    Logger.recordEvent(params);

    // window.addEventListener("beforeunload", this.onMount);
  }

  renderTimeIndicator() {
    const { section, translations, sections, sessionCode } = this.props;
    // console.log(section.toJS())
    const sectionNumber: number = section.get("index") + 1;
    let time_left_ten_min: false;
    let questionDuration: number;

    if (section.get("preparation")) {
      const durationDisplay: string = globalBuildDurationDisplayHelper(
        section.get("preparation")
      );

      return (
        <div className="container">
          <Indicator layout="center" theme="info" className="uppercase">
            {`${t(
              translations,
              "SECTION_SECTION_PREPARATION"
            )} ${sectionNumber} ${t(
              translations,
              "SECTION_PREPARATION_IN_PROGRESS"
            )}`}
            <br />
            <Duration data={durationDisplay} className="m-l-4" />
          </Indicator>
        </div>
      );
    } else if (section.get("duration")) {
      //isshopee
      questionDuration = section.get("duration");
      var durationDisplay: string;
      if (section.get("is_shopee") == 1 && store_shopee.get() != "open") {
        durationDisplay = globalBuildDurationDisplayHelper(
          section.get("duration") - 600
        );
      } else {
        durationDisplay = globalBuildDurationDisplayHelper(
          section.get("duration")
        );
      }

      if (section.get("is_shopee") == 1) {
        questionDuration = questionDuration - 600;
        if (this.state.have_opened == 1) {
          questionDuration = questionDuration + 600;
        }
      }

      let indicatorTheme: string = "success";
      if (section.get("is_shopee") == 1) {
        if (store_shopee.get() != "open") {
          if (questionDuration <= 5) {
            indicatorTheme = "danger";
          }
        } else {
          if (questionDuration + 600 <= 5) {
            indicatorTheme = "danger";
          }
        }
      } else {
        if (section.get("duration") <= 5) {
          indicatorTheme = "danger";
        }
      }

      if (section.get("is_shopee") === 1) {
        // 10 menit shopee ada diparent nya mcqshow

        if (questionDuration <= 0) {
          if (this.state.have_opened == 0) {
            store_shopee.set("open");
            if (store_shopee.getModalOpenedOnce(sessionCode) === "was_open") {
            } else {
              time_left_ten_min = true;
            }
          } else {
            time_left_ten_min = false;
          }
        }
      }

      if (section.get("duration") === 1) {
        let sect = section.toJS();
        sections.toJS().map((data, key) => {
          if (data.id === sect.id) {
            let params = {
              event: "Section", // string
              message:
                "Duration ended in Section " + key + 1 + " on id =" + data.id,
              status: "offline" // string
            };

            Logger.recordEvent(params);
          }
        });
      }

      return (
        <Fragment>
          <ModalDialog
            isOpen={time_left_ten_min}
            title={`Time’s Up!`}
            text={`We are giving you an extra 10 minutes to answer our questions. Don't miss this opportunity to let us know more about you!`}
          >
            <Button
              type="button"
              theme="secondary"
              size="small"
              className="m-r-20"
              onClick={this.handleModalToggleMcq}
            >
              OK
            </Button>
          </ModalDialog>
          <div className="container">
            <Indicator layout="between" theme={indicatorTheme}>
              <div className="uppercase">
                <b>
                  {t(translations, "SECTION_LABEL")} {sectionNumber}
                </b>{" "}
                {t(translations, "INSTRUCTION_ALREADY_IN_PROGRESS")}
              </div>
              <Duration data={durationDisplay} className="bold">
                <Icon
                  type="icon"
                  name="icon-clock"
                  align="bottom"
                  className="m-r-8"
                />
              </Duration>
            </Indicator>
          </div>
        </Fragment>
      );
    }
  }

  render() {
    const {
      job,
      company,
      candidate,
      section,
      sections,
      sessionsFilterData,
      translations,
      sessionsUiData,
      sessionCode,
      interviewPracticeSound,
      interviewsFilterData
    } = this.props;

    const is_valid = interviewPracticeSound.get("valid");
    let renderButtonStart;
    let renderCheckCard;
    // console.log(interviewsFilterData.get('is_check_sound'))
    // this interviewsFilterData.get('is_no_sound') for check sound params from backend volume_checker
    if (interviewsFilterData.get("is_check_sound")) {
      renderCheckCard = (
        <CardSoundCheck className="m-t-16 m-t-small">
          <Icon
            type="icon"
            name="icon-test"
            size="extralarge"
            align="bottom"
            className="m-r-8"
          />
        </CardSoundCheck>
      );
      renderButtonStart = (
        <Button
          onClick={this.handleContinueSessionClick}
          buttonLoading={sessionsUiData.get("loading")}
          disabled={
            sessionsUiData.get("disabled_button")
              ? sessionsUiData.get("disabled_button")
              : !is_valid
          }
        >
          {t(translations, "INSTRUCTION_CONTINUE_SESSION")}
        </Button>
      );
    } else {
      renderButtonStart = (
        <Button
          onClick={this.handleContinueSessionClick}
          buttonLoading={sessionsUiData.get("loading")}
          disabled={sessionsUiData.get("disabled_button")}
        >
          {t(translations, "INSTRUCTION_CONTINUE_SESSION")}
        </Button>
      );
    }
    // console.log(section.get("is_shopee"))
    const duration: Map<string, any> = globalGenerateUnitOfTimeHelper(
      section.get("is_shopee") == 1
        ? [
            store_shopee.getModalOpenedOnce(sessionCode) === "was_open"
              ? sessionsFilterData.get("duration_left")
              : sessionsFilterData.get("duration_left") - 600
          ]
        : sessionsFilterData.get("duration_left")
    );

    return (
      <Fragment>
        <Header
          company={company.get("title")}
          job={job.get("title")}
          position={job.get("jobType")}
          location={job.get("locations")}
          type="primary"
        />
        {this.renderTimeIndicator()}

        <div className="container">
          <section>
            <h2 className="heading4 normal m-t-24">
              {t(translations, "LABEL_WELCOME_BACK")}{" "}
              <b className="bold">{candidate.get("fullname")}</b>!<br />
              {t(translations, "LABEL_LETS_CONTINUE")}
            </h2>
            <Card className="m-t-24">
              <SectionTotal total={sections.size} className="m-b-12" />
              <ul className="list-unstyled">
                {sections.map((_section, index) => (
                  <TextList
                    key={index}
                    number={index + 1}
                    sectionTitle={_section.get("title")}
                    sectionType={this.setSectionTypeString(
                      _section.get("sub_type"),
                      _section.get("question_ids").size
                    )}
                    prepTimeTotal={this.setDurationStringWithSec(
                      _section.get("preparation")
                    )}
                    sectionTimeTotal={this.setDurationString(
                      //is shopee
                      _section.get("is_shopee") == 1 &&
                        store_shopee.get() != "open"
                        ? _section.get("duration") - 600
                        : _section.get("duration")
                    )}
                    checked={index < section.get("index")}
                  />
                ))}
              </ul>
            </Card>
            <CardWithLabel label={t(translations, "INDEX_TIME_TO_COMPLETE")}>
              <Time
                withoutSeconds={true}
                data={duration}
                className="m-b-0 bold"
              >
                <Icon
                  type="icon"
                  name="icon-timer"
                  size="extralarge"
                  align="middle"
                  className="m-r-8"
                />
              </Time>
            </CardWithLabel>
          </section>
          {renderCheckCard}
          <section>
            <center className="m-t-40 m-b-40">{renderButtonStart}</center>
          </section>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations,
    job: state.job,
    company: state.company,
    candidate: state.candidate,
    section: state.section,
    sections: state.sections,
    sessionCode: ownProps.match.params.sessionCode,
    sessionsFilterData: state.sessionsFilterData,
    sessionsUiData: state.sessionsUiData,
    interviewPracticeSound: state.interviewPracticeSound,
    interviewsFilterData: state.interviewsFilterData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sectionsStartSessionRequest: sessionCode =>
      dispatch(sectionsStartSessionRequest(sessionCode)),
    sectionsDecideReduceTimerRequest: sessionCode =>
      dispatch(sectionsDecideReduceTimerRequest(sessionCode)),
    sessionsReduceDurationLeftRequest: sessionCode =>
      dispatch(sessionsReduceDurationLeftRequest(sessionCode)),
    sendAstronautQnASessionEvent: astronautEvent =>
      dispatch(sendAstronautQnASessionEvent(astronautEvent))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SectionIndexInProgress)
);
