// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "react-router-redux";
import { Map, List } from "immutable";
import pluralize from "pluralize";
import bindAll from "lodash/bindAll";
import Header from "../../lib/elements/Header";
import CardWithLabel from "../../lib/elements/CardWithLabel";
import Button from "../../lib/elements/Button";
import Time from "../../lib/elements/Time";
import Icon from "../../lib/elements/Icon";
import Card from "../../lib/elements/Card";
import TextList from "../../lib/elements/TextList";
import SectionTotal from "../../lib/elements/SectionTotal";
import CardSoundCheck from "../../lib/elements/CardSoundCheck";
import * as Logger from "astrnt-web-logger";
import authToken from "../../dependencies/authStore";
import {
  globalGenerateUnitOfTimeHelper,
  globalGenerateUnitOfTimeToCompleteHelper
} from "../../helpers/global";

import CardSingleContent from "../../lib/elements/CardSingleContent";
import CardTopicTitle from "../../lib/elements/CardTopicTitle";
import CardTopicDesc from "../../lib/elements/CardTopicDesc";
import CardTopicInstruksi from "../../lib/elements/CardTopicInstruksi";
import CardTopicAll from "../../lib/elements/CardTopicAll";
import CardTopicList from "../../lib/elements/CardTopicList";
import CardTopicUlasanFullPage from "../../lib/elements/CardTopicUlasanFullPage";

import LogoAstrntWhite from "../../images/logo-white.png";

import { t } from "../../helpers/global";

import {
  sectionsStartSessionRequest,
  sectionsStartSessionWelcomeVideoRequest,
  sectionsCheckGDPRRequest,
  sectionDetail
} from "../../actions/sections";

import { loadMoreReview } from "../../actions/reviews";

import { interviewsStartSessionPracticeRequest } from "../../actions/interviews";

type Props = {
  job: Map<string, any>,
  company: Map<string, any>,
  candidate: Map<string, any>,
  translations: Map<string, any>,
  interviewsStartSessionPracticeRequest: Function,
  sections: List<Map<string, any>>,
  sectionsStartSessionRequest: Function,
  sectionsStartSessionWelcomeVideoRequest: Function,
  sectionsCheckGDPRRequest: Function,
  sessionCode: string,
  sessionsFilterData: Map<string, any>,
  sessionsStateInformation: Map<string, any>
};

type State = {
  modal: boolean
};

class SectionReview extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      token: null
    };

    bindAll(this, ["redirectHome", "handleLoadMore"]);
  }

  componentDidMount() {
    authToken.get().then(token => {
      this.setState({ token: token });
    });
  }

  handleLoadMore() {
    const {
      reviewsData,
      job,
      reviewsPagination,
      loadMoreReview,
      slashCount
    } = this.props;
    const page = reviewsPagination.get("current_page") + 1;

    let jobId = slashCount === 2 ? job.get("job_id") : job.get("id");

    loadMoreReview(page, jobId, reviewsData);
  }

  redirectHome() {
    const { redirect, sessionCode, slashCount } = this.props;

    if (slashCount === 2) {
      redirect(`/${sessionCode}`);
    } else {
      redirect(`/code/${sessionCode}`);
    }
  }

  render() {
    const {
      job,
      company,
      candidate,
      sections,
      sessionsFilterData,
      translations,
      sessionsStateInformation,
      sessionsUiData,
      interviewPracticeSound,
      reviewsData,
      reviewsRating,
      reviewsPagination,
      sessionCode
    } = this.props;

    const token = sessionsFilterData.get("token")
      ? sessionsFilterData.get("token")
      : this.state.token;

    return (
      <Fragment>
        <Header
          provider={company.get("title")}
          course={job.get("title")}
          position={job.get("jobType")}
          location={job.get("locations")}
          token={token}
          redirectCode={sessionsFilterData.get("ecourse_code")}
          learner={candidate}
          type="primary"
          logoCourse={company.get("logoUrl")}
        />
        <div className="container">
          <section>
            <CardTopicUlasanFullPage
              reviewsData={reviewsData}
              reviewsPagination={reviewsPagination}
              reviewsRating={reviewsRating}
              open_topic_page={this.redirectHome}
              open_pagination={this.handleLoadMore}
            />
            {/*<CardSingleContent
              className="m-t-16 m-t-small"
              number="3"
              text="Pertanyaan Pilihan Ganda"
            >
              <Icon
                type="icon"
                name="icon-test"
                size="extralarge"
                align="bottom"
                className="m-r-8"
              />
            </CardSingleContent>*/}
          </section>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations,
    job: state.job,
    company: state.company,
    candidate: state.candidate,
    interviewPracticeSound: state.interviewPracticeSound,
    sections: state.sections,
    sessionCode: ownProps.match.params.sessionCode,
    sessionsFilterData: state.sessionsFilterData,
    sessionsStateInformation: state.sessionsStateInformation,
    sessionsUiData: state.sessionsUiData,
    reviewsData: state.reviewsData,
    reviewsRating: state.reviewsRating,
    reviewsPagination: state.reviewsPagination,
    slashCount: ownProps.location.pathname.match(/\//g || []).length
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sectionsCheckGDPRRequest: sessionCode =>
      dispatch(sectionsCheckGDPRRequest(sessionCode)),
    sectionsStartSessionRequest: sessionCode =>
      dispatch(sectionsStartSessionRequest(sessionCode)),
    sectionsStartSessionWelcomeVideoRequest: sessionCode =>
      dispatch(sectionsStartSessionWelcomeVideoRequest(sessionCode)),
    interviewsStartSessionPracticeRequest: sessionCode =>
      dispatch(interviewsStartSessionPracticeRequest(sessionCode)),
    sectionDetail: section => dispatch(sectionDetail(section)),
    redirect: link => dispatch(push(link)),
    loadMoreReview: (page, job_id, reviewsData) =>
      dispatch(loadMoreReview(page, job_id, reviewsData))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SectionReview)
);
