// @flow

import React from "react";
import classnames from "classnames";
import ImageWithZoom from "../../lib/elements/ImageWithZoom";
import ImageWithModal from "../../lib/elements/ImageWithModal";

const listSize = {
  small: "",
  medium: "md"
};

const listTheme = {
  square: "square",
  circle: "circle"
};

type Props = {
  size: string,
  theme: string,
  label: string | number,
  title: string | number,
  className: string,
  value: string | number,
  onClick: Function,
  disabled: boolean,
  checked: boolean,
  image: string
};

class ListAnswers extends React.Component<Props> {
  static defaultProps = {
    size: "medium",
    theme: "square",
    className: "",
    disabled: false,
    label: "",
    title: "",
    value: "",
    onClick: () => {},
    checked: false,
    image: ""
  };

  render() {
    const {
      size,
      value,
      label,
      theme,
      image,
      title,
      className,
      onClick,
      checked,
      disabled
    } = this.props;

    const classLabel: string = classnames({
      disabled: disabled
    });
    const classProps: string = classnames(listSize[size], {
      checked: checked
    });

    const classTheme: string = classnames(listTheme[theme]);

    return (
      <div className="form-group">
        <label className={`label__list ${classLabel}`}>
          <div className="flex-container">
            <div>
              <button
                className={`list ${classTheme} ${classProps} ${className}`}
                data-checked={checked ? "1" : "0"}
                onClick={onClick}
                value={value}
                checked={checked}
                disabled={disabled}
              >
                {title}
              </button>
            </div>
            <div className="label__answer m-t-6">
              {label}
              {image ? (
                <div className="m-t-6">
                  <ImageWithModal
                    imageUrl={image}
                    alt=""
                    className="img__answer"
                  />
                </div>
              ) : null}
            </div>
          </div>
        </label>
      </div>
    );
  }
}

export default ListAnswers;
