// @flow

import * as React from "react";
import Card from "./Card";
import Icon from "./Icon";
import classnames from "classnames";

type Props = {
  number: string | number,
  text: string | number,
  number2?: string | number,
  text2?: string | number,
  number3?: string | number,
  text3?: string | number,
  children?: any,
  className: string
};

class CardTopicAll extends React.Component<Props> {
  render() {
    const {
      number,
      text,
      number2,
      text2,
      number3,
      text3,
      children,
      className,
      current,
      end,
      handleClick
    } = this.props;

    const classProps: string = classnames(
      "flex-row align-items-center",
      className
    );

    return (
      <Card className={`m-t-20 ${classProps} flex-between`}>
        <h3 className="heading3 d-flex justify-content-around align-items-center">
          <p className="heading4 normal m-b-0">
            <b>Topik {current}</b> dari {end}
          </p>
        </h3>
        <div className="btn-all-topic" onClick={handleClick}>
          <p className="heading4 inline m-b-0">Lihat Semua Topik</p>
          <i className="fa fa-caret-right"></i>
        </div>
      </Card>
    );
  }
}

export default CardTopicAll;
