// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Map } from "immutable";
import CardEndSession from "../../lib/elements/CardEndSession";
import CardThankYouForm from "../../lib/elements/CardThankYouForm";
import CardThankYouFormFinish from "../../lib/elements/CardThankYouFormFinish";
import Header from "../../lib/elements/Header";
import * as Logger from "astrnt-web-logger";
import { t } from "../../helpers/global";
import imgCheckDocument from "../../images/check-document.png";
import { sendAstronautQnASessionEvent } from "../../actions/embedded-interview";

type Props = {
  job: Map<string, any>,
  company: Map<string, any>,
  sessionsFilterData: Map<string, any>
};

class SessionsFinish extends React.Component<Props> {
  componentDidMount() {
    const { sendAstronautQnASessionEvent } = this.props;

    let params = {
      event: "Finish Page", // string
      message: "Entered Finish Page",
      status: "offline" // string
    };

    sendAstronautQnASessionEvent("Session Finished");

    Logger.recordEvent(params);

    Logger.sendSavedEvents();
  }
  render() {
    const {
      job,
      company,
      sessionsFilterData,
      translations,
      candidate
    } = this.props;

    return [
      sessionsFilterData.get(
        "interview_settings_skipped_interview_finished_page"
      ) == 1 ? (
        <Fragment />
      ) : (
        <Fragment>
          <Header
            company={company.get("title")}
            job={job.get("title")}
            position={job.get("jobType")}
            location={job.get("locations")}
            type="secondary"
          />
          <div className="container">
            {sessionsFilterData.get("is_access_profile") == 0 ? (
              <CardEndSession
                companyTitle={company.get("title")}
                isCvUploaded={sessionsFilterData.get("is_cv_uploaded")}
                jobTitle={job.get("title")}
                jobRequireCv={job.get("requireCv")}
              />
            ) : (
              <CardThankYouFormFinish
                contentTitle={t(translations, "COMPLETED_SESSION_QNA_ALREADY")}
                companyNameText={"Please copy & paste as necessary."}
                formFinishText={"Almost done!"}
                contentText={t(
                  translations,
                  "COMPLETED_SESSION_YOU_HAVE_ALREADY",
                  {
                    ":candidate_name": candidate.get("fullname"),
                    ":job_title": job.get("title"),
                    ":company_title": company.get("title")
                  }
                )}
              />
            )}
          </div>
        </Fragment>
      )
    ];
  }
}

const mapStateToProps = state => {
  return {
    job: state.job,
    company: state.company,
    sessionsFilterData: state.sessionsFilterData,
    translations: state.translations,
    candidate: state.candidate
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendAstronautQnASessionEvent: astronautEvent =>
      dispatch(sendAstronautQnASessionEvent(astronautEvent))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SessionsFinish);
