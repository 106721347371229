// @flow

import React from 'react'
import classnames from 'classnames'

const iconType = {
  fa: 'fa',
  icon: 'icon',
}

const iconAlign = {
  top: 'valign-top',
  middle: 'valign-middle',
  bottom: 'valign-bottom',
}

const iconSize = {
  small: 'icon--sm',
  medium: 'icon--md',
  large: 'icon--lg',
  extralarge: 'icon--xl',
}

type Props = {
  number: string | number,
  text: string,
  children?: any,
  animate: string,
  align: string,
  size: string,
  name: string,
  type: string,
  className: string,
};

class Icon extends React.Component<Props> {

  static defaultProps = {
    number: 0,
    text: '',
    children: null,
    animate: '',
    align: '',
    size: '',
    name: '',
    type: '',
    className: '',
  }

  render() {
    const {
      type,
      align,
      size,
      name,
      animate,
      className
    } = this.props

    const classIcon: string = classnames(
      iconType[type],
      iconSize[size],
      iconAlign[align],
      name,
      animate,
      className
    )
    return (
      <i className={classIcon} />
    )
  }
}

export default Icon
