// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Map, List } from "immutable";
import pluralize from "pluralize";
import bindAll from "lodash/bindAll";
import Header from "../../lib/elements/Header";
import CardWithLabel from "../../lib/elements/CardWithLabel";
import Button from "../../lib/elements/Button";
import Time from "../../lib/elements/Time";
import Icon from "../../lib/elements/Icon";
import Card from "../../lib/elements/Card";
import TextList from "../../lib/elements/TextList";
import SectionTotal from "../../lib/elements/SectionTotal";
import ModalDialog from "../../lib/elements/ModalDialog";
import * as Logger from "astrnt-web-logger";
import { globalGenerateUnitOfTimeHelper } from "../../helpers/global";
import { sendAstronautQnASessionEvent } from "../../actions/embedded-interview";
import { t } from "../../helpers/global";

import {
  sectionsStartSessionRequest,
  sectionsCheckGDPRRequest
} from "../../actions/sections";

import { interviewsStartSessionPracticeRequest } from "../../actions/interviews";

type Props = {
  job: Map<string, any>,
  company: Map<string, any>,
  candidate: Map<string, any>,
  translations: Map<string, any>,
  interviewsStartSessionPracticeRequest: Function,
  sections: List<Map<string, any>>,
  sectionsStartSessionRequest: Function,
  sectionsCheckGDPRRequest: Function,
  sessionCode: string,
  sessionsFilterData: Map<string, any>
};

type State = {
  modal: boolean
};

class SectionIndexWelcome extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      isModalOpen: false
    };

    bindAll(this, [
      "handleModalToggle",
      "handleLetsGoClick",
      "handlePracticeRecordingClick",
      "setDurationString",
      "setDurationStringWithSec",
      "setSectionTypeString"
    ]);
  }
  componentDidMount() {
    const { sessionsFilterData } = this.props;
    var isWatched = this.getCookie("isWatched");
    if (isWatched || sessionsFilterData.get("is_access_profile") == 1) {
      this.setState({ isModalOpen: false });
    }
    window.scrollTo(0, 0);

    let params = {
      event: "Section Info", // string
      message: "Enter Section Info",
      status: "offline"
    };
    Logger.recordEvent(params);
  }
  setDurationString(duration): string {
    const minutes: number = Math.floor(duration / 60);

    return `${minutes} min`;
  }

  setDurationStringWithSec(duration): string {
    const { translations } = this.props;
    const minutes: number = Math.floor(duration / 60);
    var mind = duration % (60 * 60);
    var secd = mind % 60;
    const seconds: number = Math.ceil(secd);

    if (duration < 60) {
      return `${duration} ${t(translations, "TIME_LABEL_SEC")}`;
    } else if (seconds == 0) {
      return `${minutes} ${t(translations, "TIME_LABEL_MIN")}`;
    } else {
      return `${minutes} ${t(translations, "TIME_LABEL_MIN")} ${seconds} ${t(
        translations,
        "TIME_LABEL_SEC"
      )}`;
    }
  }

  setSectionTypeString(sectionType, questionSize): string {
    const { translations } = this.props;

    let sectionTypeString: string;
    if (sectionType === "test") {
      sectionTypeString = t(translations, "QUESTION_TYPE_MCQ_PLURAL", {
        ":pluralize_question": pluralize("Question", questionSize),
        ":n_question": questionSize
      });
    } else if (sectionType === "freetext") {
      sectionTypeString = t(translations, "QUESTION_TYPE_FTQ_PLURAL", {
        ":pluralize_question": pluralize("Question", questionSize),
        ":n_question": questionSize
      });
    } else {
      sectionTypeString = t(translations, "QUESTION_TYPE_VIDEO_PLURAL", {
        ":pluralize_question": pluralize("Question", questionSize),
        ":n_question": questionSize
      });
    }

    return sectionTypeString;
  }

  handleLetsGoClick(e) {
    e.preventDefault();

    let params = {
      event: "Section Info", // string
      message: "Lets Go Button Clicked",
      status: "offline"
    };
    Logger.recordEvent(params);

    const {
      sessionCode,
      sectionsStartSessionRequest,
      sectionsCheckGDPRRequest,
      sessionsFilterData,
      sendAstronautQnASessionEvent
    } = this.props;

    sendAstronautQnASessionEvent("Start QnA Session");

    sectionsStartSessionRequest(sessionCode);
  }

  handleModalToggle(e) {
    e.preventDefault();

    this.setState({ modal: !this.state.modal });
  }

  handlePracticeRecordingClick(e) {
    e.preventDefault();

    const { sessionCode, interviewsStartSessionPracticeRequest } = this.props;

    interviewsStartSessionPracticeRequest(sessionCode);
  }

  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  render() {
    const {
      job,
      company,
      candidate,
      sections,
      sessionsFilterData,
      translations,
      sessionsUiData
    } = this.props;

    const { isModalOpen } = this.state;

    const duration: Map<string, any> = globalGenerateUnitOfTimeHelper(
      sessionsFilterData.get("duration_left")
    );
    return (
      <Fragment>
        <Header
          company={company.get("title")}
          job={job.get("title")}
          position={job.get("jobType")}
          location={job.get("locations")}
          type="primary"
        />
        <div className="container">
          <section>
            <h2 className="heading4 normal m-t-24">
              {t(translations, "LABEL_WELCOME")},{" "}
              <b className="bold">
                {" "}
                {sessionsFilterData.get("is_access_profile") == 1
                  ? "Friends"
                  : candidate.get("fullname")}
              </b>
              ! {t(translations, "LABEL_WHAT_TO_EXPECT")}:
            </h2>
            <Card className="m-t-32">
              <SectionTotal total={sections.size} className="m-b-12" />
              <ul className="list-unstyled">
                {sections.map((section, index) => (
                  <TextList
                    key={index}
                    number={index + 1}
                    sectionTitle={section.get("title")}
                    sectionType={this.setSectionTypeString(
                      section.get("sub_type"),
                      section.get("question_ids").size
                    )}
                    prepTimeTotal={this.setDurationStringWithSec(
                      section.get("preparation")
                    )}
                    sectionTimeTotal={this.setDurationString(
                      section.get("duration")
                    )}
                  />
                ))}
              </ul>
            </Card>
            <CardWithLabel
              label={t(translations, "INSTRUCTION_TIME_TO_COMPLETE")}
            >
              <Time data={duration} className="m-b-0 bold">
                <Icon
                  type="icon"
                  name="icon-timer"
                  size="extralarge"
                  align="middle"
                  className="m-r-8"
                />
              </Time>
            </CardWithLabel>
          </section>
          <section>
            <center className="m-t-40 m-b-40">
              {sessionsFilterData.get("type") === "close section" ? "" : ""}
              <Button onClick={this.handleModalToggle}>
                {t(translations, "INSTRUCTION_START_SESSION")}
              </Button>
            </center>
          </section>
        </div>
        <ModalDialog
          isOpen={this.state.modal}
          toggle={this.handleModalToggle}
          title={`${t(translations, "INSTRUCTION_START_SESSION")}?`}
          text={t(translations, "POPUP_CONTENT_START_SESSION")}
        >
          <Button
            type="button"
            theme="secondary"
            size="small"
            className="m-r-20"
            onClick={this.handleModalToggle}
          >
            {t(translations, "LABEL_CANCEL")}
          </Button>
          <Button
            type="button"
            theme="primary"
            size="small"
            onClick={this.handleLetsGoClick}
            buttonLoading={sessionsUiData.get("loading")}
            disabled={sessionsUiData.get("disabled_button")}
          >
            {t(translations, "LABEL_LETS_GO")}
          </Button>
        </ModalDialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations,
    job: state.job,
    company: state.company,
    candidate: state.candidate,
    sections: state.sections,
    sessionCode: ownProps.match.params.sessionCode,
    sessionsFilterData: state.sessionsFilterData,
    sessionsUiData: state.sessionsUiData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sectionsCheckGDPRRequest: sessionCode =>
      dispatch(sectionsCheckGDPRRequest(sessionCode)),
    sectionsStartSessionRequest: sessionCode =>
      dispatch(sectionsStartSessionRequest(sessionCode)),
    interviewsStartSessionPracticeRequest: sessionCode =>
      dispatch(interviewsStartSessionPracticeRequest(sessionCode)),
    sendAstronautQnASessionEvent: astronautEvent =>
      dispatch(sendAstronautQnASessionEvent(astronautEvent))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SectionIndexWelcome)
);
