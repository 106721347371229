// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Map } from "immutable";
import bindAll from "lodash/bindAll";
import Header from "../../lib/elements/Header";
import Card from "../../lib/elements/Card";
import SectionQuestionFooter from "../sections/SectionQuestionFooter";
import SectionPanelOption from "../sections/SectionPanelOption";
import FtqHeader from "./FtqHeader";
import FtqBody from "./FtqBody";
import $ from "jquery";
import Indicator from "../../lib/elements/Indicator";
import Duration from "../../lib/elements/Duration";
import Icon from "../../lib/elements/Icon";
import QuestionContent from "../../lib/elements/QuestionContent";

import { globalBuildDurationDisplayHelper } from "../../helpers/global";

import { t } from "../../helpers/global";

import Button from "../../lib/elements/Button";

type Props = {
  job: Map<string, any>,
  company: Map<string, any>,
  translations: Map<string, any>,
  sessionsFilterData: Map<string, any>,
  questionsFormData: Map<string, any>
};

class FtqShow extends React.Component<Props> {
  constructor(props) {
    super(props);

    bindAll(this, [
      "renderSectionPanelAndFooter",
      "renderIndicatorComponent",
      "renderNonSectionSubmitButton"
    ]);
  }

  componentDidMount() {
    $("body").bind("cut copy paste", function(e) {
      e.preventDefault();
    });

    //Disable mouse right click
    $("body").on("contextmenu", function(e) {
      return false;
    });

    document.onkeydown = function(e) {
      if (e.keyCode == 123) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == "I".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == "C".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == "J".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == "U".charCodeAt(0)) {
        return false;
      }
    };
  }

  renderIndicatorComponent() {
    const {
      question,
      sessionsFilterData,
      section,
      sections,
      translations
    } = this.props;

    let indicatorTheme: string = "success";
    let id: number;
    let ids: List<number>;
    let questionDuration: number;
    let questionDurationDisplay: string;
    let indicatorTypeString: string;

    if (
      sessionsFilterData.get("type") === "close test" &&
      sessionsFilterData.get("sub_type") === "freetext"
    ) {
      indicatorTypeString = t(translations, "LABEL_QUESTION");
      id = question.get("id");
      ids = sessionsFilterData.get("question_ids");
      questionDuration = question.get("question_duration_left");
    } else {
      indicatorTypeString = t(translations, "SECTION_LABEL");
      id = section.get("id");
      ids = sections.map(_section => _section.get("id"));
      questionDuration = section.get("duration");
    }

    questionDurationDisplay = globalBuildDurationDisplayHelper(
      questionDuration
    );

    if (questionDuration <= 5) {
      indicatorTheme = "danger";
    }

    return (
      <Indicator layout="evenly" theme={indicatorTheme}>
        <div>
          <b>{`${indicatorTypeString} ${ids.indexOf(id) + 1}`} </b>
          {t(translations, "LABEL_OF")} {ids.size}
        </div>
        <Duration data={questionDurationDisplay} className="bold">
          <Icon
            type="icon"
            name="icon-clock"
            align="bottom"
            className="m-r-8"
          />
        </Duration>
      </Indicator>
    );
  }

  renderNonSectionSubmitButton() {
    const { questionsFormData, sessionsFilterData, translations } = this.props;

    let nonSectionSubmitButton: Object;

    if (
      sessionsFilterData.get("type") === "close test" &&
      sessionsFilterData.get("sub_type") === "freetext"
    ) {
      nonSectionSubmitButton = (
        <center>
          <Button
            theme="primary"
            disabled={!questionsFormData.get("answered_ids").size}
            className="m-t-30 m-b-30"
            onClick={this.handleSubmitClick}
          >
            {t(translations, "LABEL_SUBMIT")}
          </Button>
        </center>
      );
    }

    return nonSectionSubmitButton;
  }

  renderSectionPanelAndFooter() {
    const { sessionsFilterData } = this.props;

    let sectionPanelAndFooter: Object;

    if (
      sessionsFilterData.get("type") === "close section" ||
      sessionsFilterData.get("type") === "close section without interview"
    ) {
      sectionPanelAndFooter = (
        <Fragment>
          <aside className="justify-content-end">
            <div id="sidebar">
              <SectionPanelOption />
            </div>
          </aside>
          <SectionQuestionFooter />
        </Fragment>
      );
    }

    return sectionPanelAndFooter;
  }

  render() {
    const { job, company, candidate, sessionsFilterData } = this.props;

    return (
      <Fragment>
        <Header
          provider={company.get("title")}
          course={job.get("title")}
          position={job.get("jobType")}
          location={job.get("locations")}
          type="primary"
          redirectCode={sessionsFilterData.get("ecourse_code")}
          learner={candidate}
          logoCourse={company.get("logoUrl")}
        />
        {/*{this.renderIndicatorComponent()}*/}
        <div className="main-wrapper d-flex">
          <div className="container">
            <Card className="p-0">
              <FtqHeader />
              <FtqBody />
            </Card>
            {/*{this.renderNonSectionSubmitButton()}*/}
          </div>
          {this.renderSectionPanelAndFooter()}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    job: state.job,
    company: state.company,
    translations: state.translations,
    section: state.section,
    sections: state.sections,
    question: state.question,
    sessionsFilterData: state.sessionsFilterData,
    questionsFormData: state.questionsFormData,
    candidate: state.candidate
  };
};

export default connect(mapStateToProps, null)(FtqShow);
