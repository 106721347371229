import { List, fromJS, Map } from "immutable";

const initReviewsData = fromJS({
  average_rating: "",
  is_loading: false
});

export function reviewsPagination(state = Map({}), action) {
  switch (action.type) {
    case "REVIEWS_FETCH_PAGINATION_SUCCESS":
      return Map(action.pagination);
    default:
      return state;
  }
}

export function reviewsData(state = List([]), action) {
  switch (action.type) {
    case "REVIEWS_FETCH_DATA_SUCCESS":
      return fromJS(action.reviews_data);
    default:
      return state;
  }
}

export function reviewsRating(state = initReviewsData, action) {
  switch (action.type) {
    case "REVIEWS_RATING_FETCH_DATA_SUCCESS":
      return state.set("average_rating", action.average_rating);
    case "REVIEWS_RATING_ON_LOADING_SUCCESS":
      return state.set("is_loading", action.is_loading);
    case "REVIEWS_RATING_CLEAR_DATA_SUCCESS":
      return fromJS({
        average_rating: "",
        is_loading: false
      });
    default:
      return state;
  }
}
