// @flow
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import Alert from '../../lib/elements/Alert'
import BigCounter from '../../lib/elements/BigCounter'

import { t } from '../../helpers/global'

type Props = {
  translations: Map<string, any>,
  interviewsFilterData: Map<string, any>
};

class InterviewBody extends React.Component<Props> {
  render() {
    const { interviewsFilterData, translations} = this.props

    let videoPlayerClass: string
    let videoPlayerRecordedClass: string

    if (interviewsFilterData.get('is_finished')) {
      videoPlayerClass = 'hide'
      videoPlayerRecordedClass = ''
    } else {
      videoPlayerClass = ''
      videoPlayerRecordedClass = 'hide'
    }

    return (
      <div className="body-video vertical-item--center">
        <video
          className={`video-player ${videoPlayerClass}`}
          id="js-interview-video-player"
          autoPlay
          muted />
        <video
          className={`video-player ${videoPlayerRecordedClass}`}
          id="js-interview-video-player-recorded" />
        {interviewsFilterData.get('is_counting') && interviewsFilterData.get('countdown_duration') ?
          <Fragment>
            <Alert type="light" label={t(translations, "RECORDING_STARTS_IN")} />
            <BigCounter counter={interviewsFilterData.get('countdown_duration')} />
          </Fragment>
          :
          ''
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations,
    interviewsFilterData: state.interviewsFilterData
  }
}

export default connect(mapStateToProps, null)(InterviewBody)
