import Promise from "bluebird";

export function sectionsStartSessionRequest(sessionCode) {
  return {
    type: "SECTIONS_START_SESSION_REQUEST",
    sessionCode
  };
}

export function sectionsStartSessionWelcomeVideoRequest(sessionCode) {
  return {
    type: "SECTIONS_START_SESSION_WELCOME_VIDEO_REQUEST",
    sessionCode
  };
}

export function sectionDetailSuccess(section) {
  return {
    type: "SECTIONS_GET_DETAIL_SUCCESS",
    section: section
  };
}

export function sectionDetail(section) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      resolve(dispatch(sectionDetailSuccess(section)));
    });
  };
}

export function sectionsCheckGDPRRequest(sessionCode) {
  return {
    type: "SECTIONS_CHECK_GDPR_REQUEST",
    sessionCode
  };
}

export function sectionsStartInterviewRequest(sessionCode) {
  return {
    type: "SECTIONS_START_INTERVIEW_REQUEST",
    sessionCode
  };
}

export function sectionsStartMcqRequest(sessionCode) {
  return {
    type: "SECTIONS_START_MCQ_REQUEST",
    sessionCode
  };
}

export function sectionsStartFtqRequest(sessionCode) {
  return {
    type: "SECTIONS_START_FTQ_REQUEST",
    sessionCode
  };
}

export function sectionsStartNextInterviewQuestionRequest(sessionCode) {
  return {
    type: "SECTIONS_START_NEXT_INTERVIEW_QUESTION_REQUEST",
    sessionCode
  };
}

export function sectionsDecideReduceTimerRequest(sessionCode) {
  return {
    type: "SECTIONS_DECIDE_REDUCE_TIMER_REQUEST",
    sessionCode
  };
}

export function sectionsAnswerQuestionRequest(id, sessionCode) {
  return {
    type: "SECTIONS_ANSWER_QUESTION_REQUEST",
    id,
    sessionCode
  };
}

export function sectionsAnswerQuestionOnShowAllRequest(
  id,
  sessionCode,
  question
) {
  return {
    type: "SECTIONS_ANSWER_QUESTION_ON_SHOW_ALL_REQUEST",
    id,
    sessionCode,
    question
  };
}

export function sectionsAnswerQuestionSendRequest(sessionCode) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: "SECTIONS_ANSWER_QUESTION_SEND_REQUEST",
        sessionCode,
        resolve,
        reject
      });
    });
  };
}

export function sectionsAnswerTextQuestionRequest(
  text,
  question_id,
  sessionCode
) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: "SECTIONS_ANSWER_TEXT_QUESTION_REQUEST",
        text,
        question_id,
        sessionCode,
        resolve,
        reject
      });
    });
  };
}

export function sectionsStartNextMcqSessionRequest(sessionCode) {
  return {
    type: "SECTIONS_START_NEXT_MCQ_SESSION_REQUEST",
    sessionCode
  };
}

export function sectionsStartNextFtqSessionRequest(sessionCode) {
  return {
    type: "SECTIONS_START_NEXT_FTQ_SESSION_REQUEST",
    sessionCode
  };
}
