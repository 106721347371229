import _axios from "../dependencies/_axios";

export function sectionsStartApi(
  candidateId,
  sectionId,
  sessionCode,
  token,
  duration_used
) {
  return _axios({
    url: "/api/v2/section/start",
    method: "post",
    headers: {
      token
    },
    data: {
      candidate_id: candidateId,
      section_id: sectionId,
      interview_code: sessionCode
      // frontend_run_time: duration_used
    }
  });
}

export function sectionsStopApi(candidateId, sectionId, sessionCode, token) {
  return _axios({
    url: "/api/v2/section/stop",
    method: "post",
    headers: {
      token
    },
    data: {
      candidate_id: candidateId,
      section_id: sectionId,
      interview_code: sessionCode
    }
  });
}
