// @flow
import React from "react";
import Card from "./Card";
import Content from "./Content";
import ContentSoundCheck from "./ContentSoundCheck";
import Button from "../elements/Button";
import Loader from "../elements/Loader";
import MicrophoneTester from "../../lib/elements/MicrophoneTester";
import VideoSample from "../../images/video-sample.mp4";

type Props = {
  contentTitle: string,
  contentImage: string,
  contentImageAlt: string,
  contentText: string,
  className: string,
  buttonName: string,
  loaderName?: string,
  loaderClassName?: string,
  onClick: Function,
  isLoading: boolean
};

class CardTopicDesc extends React.Component<Props> {
  static defaultProps = {
    contentTitle: "",
    contentText: "",
    contentImage: "",
    contentImageAlt: "",
    className: "",
    buttonName: "",
    onClick: () => {},
    isLoading: false
  };

  createMarkup(message) {
    return { __html: message };
  }

  render() {
    const {
      contentTitle,
      contentImage,
      contentImageAlt,
      contentText,
      className,
      buttonName,
      onClick,
      loaderName,
      loaderClassName,
      isLoading,
      description
    } = this.props;

    return (
      <Card theme="desc" className={className}>
        <div className="card-body p-0">
          <h4 className="heading3 bold m-b-8">Deskripsi</h4>
          {/*<div className="div-vid-ins">
            <video
              className="video-instruksi"
              src={VideoSample}
              controls
              disablePictureInPicture
              controlsList="nodownload"
            />
          </div>*/}
          <div dangerouslySetInnerHTML={this.createMarkup(description)} />
          {/* <p
            className="heading6 m-b-8"
            style={{ color: "#707070", lineHeight: "19px" }}
          >
            This SECTION is suitable for graduates who aspire to become
            investment bankers as it includes a well-structured DCF model and
            goes through the theoretical concepts behind it. Moreover, it will
            encourage you to be more confident when coping with daily tasks and
            will give you the edge when the firm must decide whether to take you
            on for a full-time position.
            <br />
            <br />
            Learning material for the section
            <ol>
              <li>
                <b style={{ color: "#564FDB" }}>
                  Finance for Non-Financial Managers.pdf
                </b>
              </li>
              <li>
                <b style={{ color: "#564FDB" }}>Ripple for finance.pdf</b>
              </li>
              <li>
                <b style={{ color: "#564FDB" }}>Finance Best Practice.pdf</b>
              </li>
            </ol>
          </p> */}
        </div>
      </Card>
    );
  }
}

export default CardTopicDesc;
