// @flow

import React from "react";
import PrepTime from "./PrepTime";
import PrepTimeLabelOnly from "./PrepTimeLabelOnly";
import PrepTimeNoLabel from "./PrepTimeNoLabel";
import RelativeTime from "./RelativeTime";

type Props = {
  number: string | number,
  sectionTitle: string,
  sectionType: string,
  prepTimeTotal: string,
  sectionTimeTotal: string,
  checked: boolean
};

class TextList extends React.Component<Props> {
  static defaultProps = {
    number: 1,
    sectionTitle: "Section Name Here",
    sectionType: "4 video questions",
    prepTimeTotal: "20 min",
    sectionTimeTotal: "40 min",
    checked: false
  };

  render() {
    const {
      number,
      sectionTitle,
      sectionType,
      prepTimeTotal,
      sectionTimeTotal,
      checked
    } = this.props;

    return (
      <li className="media flex-between align-items-start">
        <p className="heading2 align-self-start normal m-b-0 m-r-8">{number}</p>
        <div style={{ flexGrow: "1", width: "80%" }}>
          <PrepTimeLabelOnly total={prepTimeTotal} className="heading6 m-b-0" />
          <h3 className="heading2 bold">{sectionTitle}</h3>
          <p className="heading5 normal m-b-0 text-lowercase">{sectionType}</p>
        </div>
        {!checked ? (
          <div className="text-right" style={{ width: "15%" }}>
            <PrepTimeNoLabel total={prepTimeTotal} className="heading6 m-b-0" />
            <RelativeTime total={sectionTimeTotal} className="heading4 bold" />
          </div>
        ) : (
          <div className="text-right" style={{ width: "15%" }}>
            <i className="icon-check" />
          </div>
        )}
      </li>
    );
  }
}

export default TextList;
