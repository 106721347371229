// @flow

import React from "react";
import classnames from "classnames";

const indicatorTheme = {
  info: "info",
  success: "success",
  danger: "danger"
};

const indicatorLayout = {
  center: "horizontal-item--center",
  between: "horizontal-item--between",
  evenly: "horizontal-item--evenly"
};

type Props = {
  theme: string,
  layout: string,
  children?: any,
  isTextUppercase?: boolean
};

class Indicator extends React.Component<Props> {
  static defaultProps = {
    theme: "",
    layout: "",
    children: null,
    isTextUppercase: true
  };

  render() {
    const { theme, layout, children, isTextUppercase } = this.props;

    const classTheme: string = classnames(
      indicatorTheme[theme],
      indicatorLayout[layout]
    );

    return (
      <div
        className={`indicator ${
          isTextUppercase ? "uppercase" : ""
        } ${classTheme}`}
      >
        {children}
      </div>
    );
  }
}

export default Indicator;
