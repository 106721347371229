// @flow
import React from 'react'
import Card from './Card'
import Content from './Content'
import Button from '../elements/Button'
import Loader from '../elements/Loader'


type Props = {
  contentTitle: string,
  contentImage: string,
  contentImageAlt: string,
  contentText: string,
  className: string,
  buttonName: string,
  loaderName?: string,
  loaderClassName?: string,
  onClick: Function,
  isLoading: boolean
};

class CardBasicLayout extends React.Component<Props> {

  static defaultProps = {
    contentTitle: 'This will be a title',
    contentText: 'this will be a text',
    contentImage: '',
    contentImageAlt: '',
    className: '',
    buttonName: '',
    onClick: () => {},
    isLoading: false
  }

  render () {
    const {
      contentTitle,
      contentImage,
      contentImageAlt,
      contentText,
      className,
      buttonName,
      onClick,
      loaderName,
      loaderClassName,
      isLoading,
    } = this.props

    return(
      <Card theme="basic" className={className}>
        <div className="card-body p-0">
          <Content
            contentTitle={contentTitle}
            contentImage={contentImage}
            contentImageAlt={contentImageAlt}>
            <div className="row justify-content-center">
              <div className="col-sm-8">
                <p className="card-text" dangerouslySetInnerHTML={{__html: contentText}} />
              </div>
            </div>
            {
              buttonName?
              <Button onClick={onClick} className="m-t-40">{buttonName}</Button> : null
            }
            {
              isLoading?
              <Loader label={loaderName} className={loaderClassName} /> : null
            }
          </Content>
        </div>
      </Card>
    )
  }
}

export default CardBasicLayout
