// @flow

import React from 'react'
import Button from './Button'

type Props = {
  theme: string,
  type: string,
  buttonLabel: string,
  className: string,
  disabled:boolean,
  handleActionClick: Function
};

class ButtonLabel extends React.Component<Props> {
  static defaultProps = {
    theme: '',
    type: '',
    buttonLabel: '',
    className: '',
    disabled: false,
    handleActionClick: () => {}
  }

  render () {
    const {
      theme,
      type,
      buttonLabel,
      disabled,
      className,
      handleActionClick
    } = this.props

    return(
      <center>
        <Button onClick={handleActionClick} type={type} theme={theme} className={className} disabled={disabled} />
        <p className="m-0 line-height-sm"><small style={{lineHeight:'0'}} className="uppercase text-white">{buttonLabel}</small></p>
      </center>
    )
  }
}

export default ButtonLabel
