// @flow

import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import classnames from 'classnames'

type Props = {
  title: string,
  isOpen: boolean,
  toggle: Function,
  style: ?Object,
  children?: any,
  size: string,
  className: string
};

const modalSize = {
  small: 'modal-sm',
  medium: 'modal-md',
  large: 'modal-lg',
}

class ModalUploader extends React.Component<Props> {
  static defaultProps = {
    title: '',
    style: null,
    children: null,
    isOpen: false,
    size: '',
    className: '',
    toggle: () => {}
  }

  render() {
    const {
      title,
      children,
      style,
      isOpen,
      toggle,
      size,
      className
    } = this.props

    const classProps: string = classnames(
      modalSize[size],
      className
    )

    return(
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        style={style}>
        <ModalBody className={classProps}>
          <h2 className="heading2">{title}</h2>
          {children}
        </ModalBody>
      </Modal>
    )
  }
}

export default ModalUploader
