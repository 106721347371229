import _axios from "../dependencies/_axios";
import Promise from "bluebird";
import { push } from "react-router-redux";

export function sessionsFetchInitialDataRequest(
  sessionCode,
  questionId,
  subFeature,
  tokenCourse,
  isJob
) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: "SESSIONS_FETCH_INITIAL_DATA_REQUEST",
        sessionCode,
        questionId,
        subFeature,
        tokenCourse,
        isJob,
        resolve,
        reject
      });
    });
  };
}

export function sessionsReduceDurationLeftRequest() {
  return {
    type: "SESSIONS_REDUCE_DURATION_LEFT_REQUEST"
  };
}

export function sessionsUpdateIsOnBoardingSuccess() {
  return {
    type: "SESSIONS_UPDATE_IS_ONBOARDING_ON_STATE_INFORMATION_SUCCESS"
  };
}

export function sessionsSetGDPRSuccess() {
  return {
    type: "SESSIONS_SET_GDPR_SUCCESS"
  };
}

export function sessionsAddIsWatchedOnStateInformation(sessionCode) {
  return {
    type: "SESSIONS_ADD_IS_WATCHED_ON_STATE_INFORMATION",
    sessionCode
  };
}

export function setVideoProcessingProgressQuestionsInfo(questions) {
  return {
    type: "VIDEO_PROCESSING_PROGRESS_GET_INFO_QUESTIONS_SUCCESS",
    questions
  };
}

export function setVideoProcessingProgressQuestionIdsInfo(question_ids) {
  return {
    type: "VIDEO_PROCESSING_PROGRESS_GET_INFO_QUESTION_IDS_SUCCESS",
    question_ids
  };
}

export function getProcessingProgessVideoData(sessionCode, token) {
  return dispatch => {
    return new Promise((res, rej) => {
      _axios({
        url: "/api/v2/get/finished/processed/video",
        method: "post",
        headers: {
          token
        },
        data: {
          interview_code: sessionCode
        }
      })
        .then(response => {
          if (response.data.status == "success") {
            console.log(response.data);
            dispatch(
              setVideoProcessingProgressQuestionsInfo(response.data.questions)
            );
            dispatch(
              setVideoProcessingProgressQuestionIdsInfo(
                response.data.question_ids
              )
            );
          } else {
            throw response;
          }
        })
        .catch(error => {
          console.log(error);
        });
    });
  };
}

export function sessionRunTimeWatcherSuccess(front_end_run_time) {
  return {
    type: "FRONT_END_RUN_TIME",
    front_end_run_time: front_end_run_time
  };
}

export function sessionsDisabledButtonSuccess(disabled_button) {
  return {
    type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
    disabled_button
  };
}

export function sessionsDisabledButton(disabled_button) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      resolve(dispatch(sessionsDisabledButtonSuccess(disabled_button)));
    });
  };
}

export function sessionRunTimeWatcher(front_end_run_time) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      resolve(dispatch(sessionRunTimeWatcherSuccess(front_end_run_time)));
    });
  };
}

export function postQnaDataRating(
  data,
  invite_id,
  candidate_id,
  sessionCode,
  token
) {
  return dispatch => {
    _axios({
      url: `/api/web/interview/code/${sessionCode}/feedback_rating`,
      method: "post",
      headers: {
        token
      },
      data: {
        invite_id: invite_id,
        rating: data.rating,
        candidate_id: candidate_id,
        feedback: data.feedback,
        hide_review_name: data.isHideName
      }
    })
      .then(response => {
        dispatch(push(`/code/${sessionCode}/finish`));
      })
      .catch(error => {
        console.log(error);
      });
  };
}

export function sessionsPaymentCourse(
  interview_code,
  ecourse_detail_id,
  token
) {
  return dispatch => {
    return new Promise((res, rej) => {
      _axios({
        url: `/api/ecourse/payment`,
        method: "post",
        headers: {
          token: token
        },
        data: {
          ecourse_detail_id: ecourse_detail_id
        }
      })
        .then(response => {
          let data = response.data;

          if (data.is_free) {
            _axios({
              url: `/api/ecourse/payment_free_notification`,
              method: "post",
              data: {
                order_id: data.snap_token,
                transaction_status: "settlement",
                payment_type: "gratis"
              }
            }).then(response => {
              dispatch({
                type: "INTERVIEW_COURSE_AUTHORIZED_SUCCESS",
                is_course_auth: true
              });
              // dispatch(push(`/${interview_code}`));
              dispatch(
                sessionsFetchInitialDataRequest(
                  interview_code,
                  0,
                  0,
                  token,
                  true
                )
              );
            });
          } else {
            window.snap.pay(data.snap_token, {
              // Optional
              onSuccess: function(result) {
                dispatch({
                  type: "INTERVIEW_COURSE_AUTHORIZED_SUCCESS",
                  is_course_auth: true
                });
                // dispatch(push(`/${interview_code}`));
                dispatch(
                  sessionsFetchInitialDataRequest(
                    interview_code,
                    0,
                    0,
                    token,
                    true
                  )
                );
              },
              // Optional
              onPending: function(result) {
                dispatch(sessionsPaymentStatusSuccess("pending"));
                res(dispatch(push(`/${interview_code}`)));
              },
              onClose: function() {
                res(dispatch(push(`/${interview_code}`)));
              },
              // Optional
              onError: function(result) {
                res(dispatch(push(`/${interview_code}`)));
              }
            });
          }
        })
        .catch(e => {
          console.log(e);
          // TODO
        });
    });
  };
}

export function sessionsPaymentStatusSuccess(paymentStatus) {
  return {
    type: "INTERVIEW_COURSE_PAYMENT_SUCCESS",
    ecourse_payment_status: paymentStatus
  };
}
