import React from "react";

class VideoElement extends React.Component<Props> {
  render() {
    const {
      hidden,
      handlePlayClick,
      handleVolumeClick,
      onTimeUpdate,
      current_duration,
      max_duration,
      progress,
      style,
      is_play,
      url,
      chances,
      handleResetPlayButton,
      customWidth,
      id,
      muted,
      isQuestion
    } = this.props;

    let chancesLabel = <span />;
    if (chances) {
      chancesLabel = <span>{chances} Chances left</span>;
    } else if (chances === 0) {
      chancesLabel = <span>0 Chances left</span>;
    }

    let render_duration = <span>00:00 / 00:00</span>;
    if (max_duration != 0 && max_duration != "NaN:NaN") {
      render_duration = (
        <span>
          {current_duration} /{" "}
          <font style={{ color: "#FFFFFF80" }}>{max_duration}</font>
        </span>
      );
    }
    let renderButton;
    if (isQuestion) {
      renderButton = (
        <button
          type="button"
          onClick={handlePlayClick}
          className={`button-play-video ${is_play ? "hide" : ""} ${
            chances < 1 ? "grey" : ""
          }`}
        >
          <i className="fa fa-play" />
        </button>
      );
    } else {
      renderButton = (
        <button
          type="button"
          onClick={handlePlayClick}
          className={`button-play-video ${is_play ? "hide" : ""}`}
        >
          <i className="fa fa-play" />
        </button>
      );
    }

    return (
      <div className={`${hidden}`}>
        <div className="video-question-container">
          {renderButton}
          <video
            onTimeUpdate={onTimeUpdate}
            id={id}
            className="video-question"
            src={url}
          />
        </div>
        <div className="text-center p-video-control" style={style}>
          <div className="video-playback-container vertical-item--center">
            <i className="icon icon-video-yt" onClick={handlePlayClick} />
            {chancesLabel}
            <div
              style={{ width: customWidth }}
              className="div-timeline-audio vertical-item--center"
            >
              <div
                style={{ width: +progress + "%" }}
                className="div-timeline-audio-progress"
              />
            </div>
            {/*render_duration*/}
            <i
              className={`hide ${
                muted == false ? "fa fa-volume-up" : "fa fa-volume-off"
              }`}
              onClick={handleVolumeClick}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default VideoElement;
