import { call, select, put } from "redux-saga/effects";

import { cvsStartApi, cvsStatusApi } from "../apis/cvs";

import { getJob, getCompany, getCandidate } from "../selectors";

export function* cvsDecideSendEmail(sessionCode: string): Generator<*, *, *> {
  try {
    const company: Map<string, any> = yield select(getCompany);
    const job: Map<string, any> = yield select(getJob);
    const candidate: Map<string, any> = yield select(getCandidate);

    if (job.get("requireCv")) {
      const response = yield call(
        cvsStatusApi,
        company.get("id"),
        job.get("id"),
        candidate.get("id"),
        sessionCode
      );

      if (response.status >= 200 && response.status < 300) {
        if (response.data.status === "error") {
          yield call(
            cvsStartApi,
            company.get("id"),
            job.get("id"),
            candidate.get("id"),
            sessionCode
          );
        } else {
          yield put({
            type: "SESSIONS_ADD_FILTER_IS_CV_UPLOADED_SUCCESS",
            is_cv_uploaded: true
          });
        }
      } else {
        throw response;
      }
    }
  } catch (error) {
    console.error(error);
  }
}
