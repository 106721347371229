import { Map, fromJS } from "immutable";

const initGlobalFetchIsProfile = fromJS({
  candidate_is_access_profile: "",
  candidate_is_update_email: "",
  candidate_is_link: ""
});

export function globalFetchDataError(state = "", action) {
  switch (action.type) {
    case "GLOBAL_FETCH_DATA_ERROR":
      return action.error;
    default:
      return state;
  }
}

export function globalFetchDataIsProfile(
  state = initGlobalFetchIsProfile,
  action
) {
  switch (action.type) {
    case "GLOBAL_FETCH_DATA_CANDIDATE_IS_ACCESS_PROFILE":
      return state.set(
        "candidate_is_access_profile",
        action.candidate_is_access_profile
      );
    case "GLOBAL_FETCH_DATA_CANDIDATE_IS_UPDATE_EMAIL":
      return state.set(
        "candidate_is_update_email",
        action.candidate_is_update_email
      );
    case "GLOBAL_FETCH_DATA_CANDIDATE_IS_LINK":
      return state.set("candidate_is_link", action.candidate_is_link);
    default:
      return state;
  }
}

export function globalResponseData(state = Map({}), action) {
  switch (action.type) {
    case "GLOBAL_RESPONSE_DATA_SUCCESS":
      return fromJS(action.response);
    default:
      return state;
  }
}
