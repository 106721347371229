// @flow

import React from "react";

class AudioElement extends React.Component<Props> {
  render() {
    const {
      hidden,
      handlePlayClick,
      handleVolumeClick,
      style,
      progress,
      is_play,
      current_duration,
      max_duration,
      muted,
      isQuestion,
      chances
    } = this.props;

    let chancesLabel = "";
    if (chances) {
      chancesLabel = (
        <span>
          <i className="fa fa-volume-up" />
          {chances} Chances left
        </span>
      );
    } else if (chances === 0) {
      chancesLabel = (
        <span>
          <i className="fa fa-volume-up" />0 Chances left
        </span>
      );
    }

    let renderButton =
      is_play == false ? (
        <i className="fa fa-play" onClick={handlePlayClick} />
      ) : (
        <i className="fa fa-pause" onClick={handlePlayClick} />
      );

    let render_duration = <p>00:00 / 00:00</p>;
    if (max_duration != 0 && max_duration != "NaN:NaN") {
      render_duration = (
        <p>
          {current_duration} / {max_duration}
        </p>
      );
    }

    let renderButtonBig;
    if (isQuestion) {
      renderButtonBig = (
        <button
          type="button"
          onClick={is_play ? null : handlePlayClick}
          className={`button-play-audio vertical-item--center ${
            chances < 1 && !is_play ? "grey" : ""
          }`}
        >
          <i className={`fa ${is_play ? "fa-volume-up" : "fa-play"}`} />
        </button>
      );
    } else {
      renderButtonBig = (
        <button
          type="button"
          onClick={is_play ? null : handlePlayClick}
          className={`button-play-audio vertical-item--center`}
        >
          <i className={`fa ${is_play ? "fa-volume-up" : "fa-play"}`} />
        </button>
      );
    }

    return (
      <div className={`text-center ${hidden}`} style={style}>
        <div className="audio-playback-container vertical-item--center">
          <div className="w-100 m-b-10">{renderButtonBig}</div>
          <div className="w-100">{render_duration}</div>
          <div className="w-100">{chancesLabel}</div>
        </div>
      </div>
    );
  }
}

export default AudioElement;
