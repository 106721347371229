import React from "react";
import Card from "./Card";
import NumberFormat from "react-number-format";

class CardPrice extends React.Component<props> {
  render() {
    const { className, price } = this.props;
    return (
      <Card theme="price" className={className}>
        <div className="card-body p-0 horizontal-item--between vertical-item--center">
          <h4 className="heading4 bold">Harga</h4>
          <div className="rupiah">
            {price > 0 ? (
              <NumberFormat
                value={price}
                thousandSeparator={"."}
                decimalSeparator={","}
                displayType={"text"}
                prefix={"Rp"}
              />
            ) : (
              <span>Gratis</span>
            )}
          </div>
        </div>
      </Card>
    );
  }
}

export default CardPrice;
