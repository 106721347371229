import { eventChannel, END } from "redux-saga";

export function countdown(seconds) {
  return eventChannel(emitter => {
    const interval: IntervalID = setInterval(() => {
      seconds -= 1;

      if (seconds >= 0) {
        emitter(seconds);
      } else {
        emitter(END);

        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  });
}

export function countdownFinish(seconds) {
  return eventChannel(emitter => {
    const interval: IntervalID = setInterval(() => {
      seconds -= 1;

      if (seconds >= 0) {
        emitter(seconds);
      } else {
        emitter(END);

        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  });
}

export function countdownPractice(seconds, is_record) {
  return eventChannel(emitter => {
    const interval: IntervalID = setInterval(() => {
      seconds -= 1;
      if (seconds >= 0 && is_record) {
        emitter(seconds);
      } else {
        emitter(END);

        clearInterval(seconds);
      }
      // console.log(seconds)
    }, 1000);

    return () => clearInterval(interval);
  });
}
