// @flow
import React from "react";
import Card from "./Card";
import IconCeklis from "../../images/icon-ceklis-ungu.png";
import moment from "moment";
type Props = {
  contentTitle: string,
  contentImage: string,
  contentImageAlt: string,
  contentText: string,
  className: string,
  buttonName: string,
  loaderName?: string,
  loaderClassName?: string,
  onClick: Function,
  isLoading: boolean
};

class CardTopicList extends React.Component<Props> {
  static defaultProps = {
    contentTitle: "",
    contentText: "",
    contentImage: "",
    contentImageAlt: "",
    className: "",
    buttonName: "",
    onClick: () => {},
    isLoading: false
  };

  durationFormat(total_duration) {
    if (total_duration && !isNaN(total_duration)) {
      return moment()
        .startOf("day")
        .seconds(total_duration)
        .format("H:mm:ss");
    }
    return "0:00:00";
  }

  componentDidUpdate() {
    var total_duration = 0;
    this.props.topics.map((topic, index) => {
      let vid = document.getElementById(`ex_video_${index}`);
      if (vid) {
        vid.onloadedmetadata = function() {
          let lVid = document.getElementsByClassName("first-div");
          if (lVid && lVid.length > index) {
            let duration = "0:00:00";
            if (this.duration && !isNaN(this.duration)) {
              total_duration += this.duration;
              duration = moment()
                .startOf("day")
                .seconds(this.duration)
                .format("H:mm:ss");
            }
            if (topic.get("unanswered_question_ids").size !== 0)
              lVid[index].nextSibling.innerHTML = duration;

            let div3 = document.getElementById("total_topic_duration");
            if (div3)
              div3.innerHTML =
                "Durasi Belajar " +
                moment()
                  .startOf("day")
                  .seconds(total_duration)
                  .format("H:mm:ss");
          }
        };
      }
    });
  }

  render() {
    const { className, total_duration, topics, handleTopicClick } = this.props;

    let renderTopic = [];

    topics.map((topic, index) => {
      let duration = <img src={IconCeklis} />;
      if (topic.get("unanswered_question_ids")) {
        if (topic.get("unanswered_question_ids").size !== 0) {
          duration = this.durationFormat(topic.get("media_duration"));
        }
      } else {
        duration = this.durationFormat(topic.get("media_duration"));
      }

      renderTopic.push(
        <div className="div-topic-list vertical-item--center" key={index}>
          <div className="first-div" onClick={e => handleTopicClick(e, index)}>
            <span>{topic.get("title")}</span>
            <i className="fa fa-caret-right m-l-15" />
            {!(topic.get("media") && topic.get("media").get("media_url")) ? (
              ""
            ) : (
              <video
                style={{ display: "none" }}
                id={`ex_video_${index}`}
                width="100"
                height="100"
                controls
              >
                <source
                  src={topic.get("media").get("media_url")}
                  type="video/mp4"
                />
              </video>
            )}
          </div>
          <div
            id={`topic_${index}`}
            className="last-div"
            onClick={e => handleTopicClick(e, index)}
          >
            {duration}
          </div>
        </div>
      );
    });

    return (
      <Card theme="desc" className={className}>
        <div className="card-body p-0">
          <div className="flex-container flex-between vertical-item--center">
            <h4 className="heading3 bold m-b-8">Topik</h4>
            <h4 className="heading4 bold m-b-8" style={{ color: "#707070" }}>
              <div id="total_topic_duration">
                Durasi Belajar {this.durationFormat(total_duration)}
              </div>
            </h4>
          </div>
          {renderTopic}
        </div>
      </Card>
    );
  }
}

export default CardTopicList;
