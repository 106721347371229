// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { List, Map } from "immutable";
import bindAll from "lodash/bindAll";

import Indicator from "../../lib/elements/Indicator";
import Duration from "../../lib/elements/Duration";
import Icon from "../../lib/elements/Icon";
import QuestionContent from "../../lib/elements/QuestionContent";
import QuestionContentMcqAll from "../../lib/elements/QuestionContentMcqAll";

import { globalBuildDurationDisplayHelper } from "../../helpers/global";

import { t } from "../../helpers/global";

type Props = {
  question: Map<string, any>,
  sessionsFilterData: Map<string, any>,
  translations: Map<string, any>,
  section: Map<string, any>,
  sections: List<Map<string, any>>
};

class McqHeaderQuestionOnly extends React.Component<Props> {
  constructor(props) {
    super(props);

    bindAll(this, ["renderIndicatorComponent"]);
  }

  renderIndicatorComponent() {
    const {
      question,
      sessionsFilterData,
      section,
      sections,
      translations
    } = this.props;

    let indicatorTheme: string = "success";
    let id: number;
    let ids: List<number>;
    let questionDuration: number;
    let questionDurationDisplay: string;
    let indicatorTypeString: string;

    if (sessionsFilterData.get("type") === "close test") {
      indicatorTypeString = t(translations, "LABEL_QUESTION");
      id = question.get("id");
      ids = sessionsFilterData.get("question_ids");
      questionDuration = question.get("question_duration_left");
    } else {
      indicatorTypeString = t(translations, "SECTION_LABEL");
      id = section.get("id");
      ids = sections.map(_section => _section.get("id"));
      questionDuration = section.get("duration");
    }

    questionDurationDisplay = globalBuildDurationDisplayHelper(
      questionDuration
    );

    if (questionDuration <= 5) {
      indicatorTheme = "danger";
    }

    return (
      <Indicator layout="between" theme={indicatorTheme}>
        <div>
          <b>{`${indicatorTypeString} ${ids.indexOf(id) + 1}`} </b>
          {t(translations, "LABEL_OF")} {ids.size}
        </div>
        <Duration data={questionDurationDisplay} className="bold">
          <Icon
            type="icon"
            name="icon-clock"
            align="bottom"
            className="m-r-8"
          />
        </Duration>
      </Indicator>
    );
  }

  render() {
    const { question_single_page, indexquestion, id } = this.props;

    return (
      <Fragment>
        <QuestionContentMcqAll
          id={id}
          indexquestion={indexquestion}
          text={question_single_page.get("title")}
          image={question_single_page.get("image_url")}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    translations: state.translations,
    section: state.section,
    sections: state.sections,
    question: state.question,
    sessionsFilterData: state.sessionsFilterData
  };
};

export default connect(
  mapStateToProps,
  null
)(McqHeaderQuestionOnly);
