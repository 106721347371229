// @flow

import React from "react";
import ImageWithModal from "../../lib/elements/ImageWithModal";
import AudioElement from "../../lib/elements/AudioElement";

type Props = {
  image: string,
  alt: string,
  text: string,
  className: string
};

class QuestionContentFTQ extends React.Component<Props> {
  static defaultProps = {
    image: "",
    text: "",
    alt: "",
    className: ""
  };

  render() {
    const { image, text, alt, className, childrenAudio } = this.props;
    var txtquestion = { __html: text.replace(/(?:\r\n|\r|\n)/g, "<br/>") };
    return (
      <div className={`p-20 question-ftq-container ${className}`}>
        {image ? (
          <ImageWithModal
            imageUrl={image}
            alt={alt}
            className="img__question__ftq"
          />
        ) : null}
        {childrenAudio}
        <p className="heading-ftq" dangerouslySetInnerHTML={txtquestion} />
      </div>
    );
  }
}

export default QuestionContentFTQ;
