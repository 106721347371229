// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Map } from "immutable";
import Card from "./Card";
import ContentThankYou from "./ContentThankYou";
import Button from "../../lib/elements/Button";
import { t } from "../../helpers/global";
import bindAll from "lodash/bindAll";
import { candidateUpdateRequest } from "../../actions/candidates";
type Props = {
  className: string,
  translations: Map<string, any>,
  contentTitle: string,
  contentImage: string,
  sessionCode: string,
  contentImageAlt: string,
  contentText: string,
  candidateUpdateRequest: Function
};

class CardThankYouFormFinish extends React.Component<Props> {
  constructor(props: Object): void {
    super(props);
    bindAll(this, ["handleUpdateCandidate", "handleCopyKey"]);
  }
  static defaultProps = {
    contentTitle: "",
    contentImage: "",
    contentImageAlt: "",
    contentText: "",
    className: "",
    formFinishText: ""
  };
  handleUpdateCandidate(e) {
    // e.preventDefault();
    const { candidateUpdateRequest, sessionCode } = this.props;
    var fullname = document.getElementById("fullname").value;
    var email = document.getElementById("email").value;
    var pathname = window.location.pathname;
    var interviewCode = pathname.split("/")[2];
    candidateUpdateRequest(interviewCode, fullname, email);
  }
  handleCopyKey(e): void {
    e.preventDefault();

    var keyText = document.getElementById("fullname").select();

    document.execCommand("copy");
  }
  render() {
    const {
      className,
      translations,
      contentTitle,
      contentImage,
      contentImageAlt,
      contentText,
      candidateLink,
      handleSubmit,
      companyNameText,
      formFinishText,
      globalFetchDataIsProfile
    } = this.props;
    // console.log(globalFetchDataIsProfile.get("candidate_is_link"));
    return (
      <Fragment>
        <Card theme="custom" className={className}>
          <div className="card-body">
            <ContentThankYou>
              <div className="row justify-content-center">
                <div className="col-sm-8 m-b-10">
                  <p className="heading4">Here is your link:</p>
                </div>
                <form
                  onSubmit={this.handleUpdateCandidate}
                  className="form-thankyou"
                >
                  <div className="col-sm-12 text-left div-thankyou">
                    <p className="heading4 profilelink">{companyNameText}</p>
                    <input
                      type="text"
                      id="fullname"
                      className="input-thankyou-link"
                      value={
                        candidateLink.get("link") == ""
                          ? globalFetchDataIsProfile.get("candidate_is_link")
                          : candidateLink.get("link")
                      }
                      readOnly
                    />
                    <Button
                      id="copy-key-button"
                      onClick={this.handleCopyKey}
                      className="button-copy"
                    >
                      Copy
                    </Button>
                    <p
                      className="heading4 profilelink"
                      style={{ color: "#999999" }}
                    >
                      We have also sent the link to your email address. Good
                      luck!
                    </p>
                  </div>
                </form>
              </div>
            </ContentThankYou>
          </div>
          <div className="card-footer">
            <div className="d-flex justify-content-center">
              <a
                href="mailto:feedback@astrnt.co"
                target="_blank"
                rel="noopener noreferrer"
                className="card-link bold"
                style={{ fontSize: "12px" }}
              >
                {t(translations, "TIMEOUT_PAGE_SEND")}
              </a>
            </div>
          </div>
        </Card>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations,
    candidateLink: state.candidateLink,
    globalFetchDataIsProfile: state.globalFetchDataIsProfile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    candidateUpdateRequest: (sessionCode, fullname, email) =>
      dispatch(candidateUpdateRequest(sessionCode, fullname, email))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardThankYouFormFinish);
