// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Map } from "immutable";
import Card from "./Card";
import ContentThankYou from "./ContentThankYou";
import Button from "../../lib/elements/Button";
import { t } from "../../helpers/global";
import bindAll from "lodash/bindAll";
import {
  candidateUpdateRequest,
  candidateResetFormError
} from "../../actions/candidates";
type Props = {
  className: string,
  translations: Map<string, any>,
  contentTitle: string,
  contentImage: string,
  sessionCode: string,
  contentImageAlt: string,
  contentText: string,
  candidateUpdateRequest: Function
};

class CardThankYouForm extends React.Component<Props> {
  constructor(props: Object): void {
    super(props);
    this.state = {
      isMatch: true
    };
    bindAll(this, ["handleUpdateCandidate", "handleInput"]);
  }
  static defaultProps = {
    contentTitle: "",
    contentImage: "",
    contentImageAlt: "",
    contentText: "",
    className: ""
  };
  handleUpdateCandidate(e) {
    e.preventDefault();
    this.setState({ isMatch: true });
    const { candidateUpdateRequest, sessionCode } = this.props;
    var fullname = document.getElementById("fullname").value;
    var email = document.getElementById("email").value;
    var confirm_email = document.getElementById("confirm_email").value;
    var pathname = window.location.pathname;
    var interviewCode = pathname.split("/")[2];
    if (email == confirm_email) {
      candidateUpdateRequest(interviewCode, fullname, email);
    } else {
      this.setState({ isMatch: false });
    }
  }

  componentDidUpdate() {
    const {
      buttonPropsFormControlNextSession,
      globalFetchDataIsProfile
    } = this.props;
    if (globalFetchDataIsProfile.get("candidate_is_link") != "") {
      buttonPropsFormControlNextSession();
    }
  }

  handleInput(e) {
    const { candidateResetFormError } = this.props;

    if (e.target.id == "confirm_email") {
      var email = document.getElementById("email").value;
      if (e.target.value != email) {
        this.setState({ isMatch: false });
      } else {
        this.setState({ isMatch: true });
      }
    }

    candidateResetFormError();
  }

  render() {
    const {
      className,
      translations,
      contentTitle,
      contentImage,
      contentImageAlt,
      contentText,
      handleSubmit,
      candidateError,
      formFinishText
    } = this.props;
    return (
      <Fragment>
        <Card theme="custom" className={className}>
          <div className="card-body">
            <ContentThankYou>
              <div className="row justify-content-center">
                <div className="col-sm-8 m-b-10">
                  <p
                    className="heading5 m-b-30"
                    style={{ fontWeight: "normal", lineHeight: "30px" }}
                  >
                    Before answering your questions by video-selfie,
                    <br />
                    please enter your name & email below.
                  </p>
                </div>
                <form
                  onSubmit={this.handleUpdateCandidate}
                  className="form-thankyou"
                >
                  <div className="col-sm-12 text-left div-thankyou">
                    <p className="heading5 m-b-4" style={{ color: "#555555" }}>
                      Name
                    </p>
                    <input
                      type="text"
                      id="fullname"
                      onChange={this.handleInput}
                      className={`input-thankyou m-b-0 ${
                        typeof candidateError.getIn([
                          "errorValidation",
                          "fullname"
                        ]) == "undefined"
                          ? null
                          : "input-error"
                      }`}
                      placeholder="Type Your Name Here"
                    />
                    {typeof candidateError.getIn([
                      "errorValidation",
                      "fullname"
                    ]) == "undefined" ? null : (
                      <small className="text-danger">
                        {candidateError.getIn(["errorValidation", "fullname"])}
                      </small>
                    )}

                    <p
                      className="heading5 m-b-4 m-t-20"
                      style={{ color: "#555555" }}
                    >
                      Email Address
                    </p>
                    <input
                      type="email"
                      id="email"
                      onChange={this.handleInput}
                      className={`input-thankyou m-b-0 ${
                        typeof candidateError.getIn([
                          "errorValidation",
                          "email"
                        ]) == "undefined"
                          ? null
                          : "input-error"
                      }`}
                      placeholder="Type Your Email Address Here"
                    />
                    {typeof candidateError.getIn([
                      "errorValidation",
                      "email"
                    ]) == "undefined" ? null : (
                      <small className="text-danger">
                        {candidateError.getIn(["errorValidation", "email"])}
                      </small>
                    )}
                    {candidateError.get("errorMessage") == "" ? null : (
                      <small className="text-danger">
                        {candidateError.get("errorMessage")}
                      </small>
                    )}

                    <p
                      className="heading5 m-b-4 m-t-20"
                      style={{ color: "#555555" }}
                    >
                      Confirm Email Address
                    </p>
                    <input
                      type="email"
                      id="confirm_email"
                      onChange={this.handleInput}
                      className={`input-thankyou m-b-0 ${
                        this.state.isMatch ? null : "input-error"
                      }`}
                      placeholder="Type Your Confirm Email Address Here"
                    />
                    {this.state.isMatch ? null : (
                      <small className="text-danger">
                        Confirm Email Address is not match
                      </small>
                    )}
                    <div className="m-t-25 text-center">
                      <Button type="submit" className="button-save">
                        Next
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </ContentThankYou>
          </div>
        </Card>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations,
    candidateError: state.candidateError,
    candidateLink: state.candidateLink,
    globalFetchDataIsProfile: state.globalFetchDataIsProfile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    candidateUpdateRequest: (sessionCode, fullname, email) =>
      dispatch(candidateUpdateRequest(sessionCode, fullname, email)),
    candidateResetFormError: () => dispatch(candidateResetFormError())
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardThankYouForm);
