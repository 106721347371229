// @flow

import * as React from "react";
import Card from "./Card";
import Icon from "./Icon";
import classnames from "classnames";
import IconPlayBig from "../../images/icon-play-besar.png";

type Props = {
  number: string | number,
  text: string | number,
  number2?: string | number,
  text2?: string | number,
  number3?: string | number,
  text3?: string | number,
  children?: any,
  className: string
};

class CardWelcomeVideo extends React.Component<Props> {
  render() {
    const {
      number,
      text,
      number2,
      text2,
      number3,
      text3,
      children,
      className
    } = this.props;

    const classProps: string = classnames(
      "flex-row align-items-center horizontal-item--between card-welcome",
      className
    );

    return (
      <Card className={classProps}>
        <h3 className="heading3 d-flex justify-content-around align-items-center">
          <Icon
            type="icon"
            name="icon-play-purple"
            size="extralarge"
            align="bottom"
            className=""
          />
          <p className="heading4 normal m-b-0">
            <b>Preview Kursus Video</b>
          </p>
        </h3>
        <img
          className="pull-right m-l-20 playLeft play-button-welcome"
          src={IconPlayBig}
        />
        {/*<Icon
          type="icon"
          name="icon-play-button-purple"
          size="extralarge"
          align="bottom"
          className="pull-right m-l-20 playLeft"
        />*/}
      </Card>
    );
  }
}

export default CardWelcomeVideo;
