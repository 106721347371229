import React, { Fragment } from "react";
import { connect } from "react-redux";
import imgCheckDocument from "../../images/check-document.png";
import Header from "../../lib/elements/Header";
import CardFooterLayout from "../../lib/elements/CardFooterLayout";
import CardThankYouForm from "../../lib/elements/CardThankYouForm";
import CardThankYouFormFinish from "../../lib/elements/CardThankYouFormFinish";

import LogoAstrntWhite from "../../images/logo-white.png";

import iconLulus from "../../images/icon-lulus.png";
import iconGagal from "../../images/icon-gagal.png";
import iconAmplop from "../../images/icon-amplop.png";

import { t } from "../../helpers/global";

type Props = {
  job: Map<string, any>,
  company: Map<string, any>,
  candidate: Map<string, any>,
  translations: Map<string, any>
};

class CompletedSession extends React.Component<Props> {
  render() {
    const {
      job,
      company,
      candidate,
      translations,
      sessionCode,
      sessionsFilterData
    } = this.props;
    return (
      <Fragment>
        <Header
          provider={company.get("title")}
          course={job.get("title")}
          position={job.get("jobType")}
          location={job.get("locations")}
          type="primary"
          redirectCode={sessionsFilterData.get("ecourse_code")}
          learner={candidate}
          logoCourse={company.get("logoUrl")}
        />
        <div className="container">
          <CardFooterLayout
            contentTitle="Menunggu Hasil!"
            contentImage={iconAmplop}
            contentImageAlt={t(translations, "COMPLETED_SESSION_FINISHED")}
            contentText={`“${job.get("title")}”`}
            sessionCode={sessionCode}
          />
        </div>
        <footer className="footer-astrnt horizontal-item--center">
          Powered by <img src={LogoAstrntWhite} /> All Rights Reserved.
        </footer>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    job: state.job,
    company: state.company,
    candidate: state.candidate,
    sessionCode: ownProps.match.params.sessionCode,
    translations: state.translations,
    sessionsFilterData: state.sessionsFilterData
  };
};

export default connect(mapStateToProps, null)(CompletedSession);
