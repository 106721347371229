// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Map } from "immutable";
import classnames from "classnames";
import logoBlack from "../../images/logo-black.svg";

import { t } from "../../helpers/global";

const headerType = {
  deafult: "default",
  primary: "primary",
  secondary: "secondary"
};

type Props = {
  company: string,
  job: string,
  position: string,
  location: string,
  className: string,
  type: string,
  translations: Map<string, any>
};

class HeaderUnsupported extends React.Component<Props> {
  static defaultProps = {
    company: "",
    job: "",
    position: "",
    location: "",
    className: "",
    type: "default"
  };

  render() {
    const {
      className,
      company,
      job,
      position,
      location,
      type,
      translations
    } = this.props;

    const classProps = classnames("header__unsupported", className);

    let headerComponent: Object = (
      <header className={classProps}>
        <img
          src={logoBlack}
          alt="Astronaut Technologies Q&amp;A"
          className="navbar-brand img-header-unsupported p-0"
        />
      </header>
    );

    if (headerType[type] === "primary") {
      headerComponent = (
        <header className={`large ${classProps}`}>
          <h1 className="heading5">{company}</h1>
          <div className="vertical-item--center">
            <h2 className="heading1 m-r-10">{job}</h2>
            <h3 className="heading5 m-0">
              {position ? <small>{position}</small> : ""}
              {location ? <small>{location}</small> : ""}
            </h3>
          </div>
        </header>
      );
    } else if (headerType[type] === "secondary") {
      headerComponent = (
        <header className={classProps}>
          <h1 className="heading3">
            {t(translations, "PAGE_TITLE", {
              ":company_name": company
            })}
          </h1>
        </header>
      );
    }

    return <Fragment>{headerComponent}</Fragment>;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations
  };
};

export default connect(
  mapStateToProps,
  null
)(HeaderUnsupported);
