// @flow
import React from 'react'
import Icon from './Icon'

type Props = {
  className: string,
};

class LoadingBlock extends React.Component<Props> {

  static defaultProps = {
    className: 'heading1 larger center',
  }

  render() {
    const { className } = this.props

    return (
      <h3 className={className}>
        <Icon type="fa" name="fa-spinner" animate="fa-spin" className="text-purple" />
      </h3>
    )
  }
}

export default LoadingBlock
