export function candidateUpdateRequest(sessionCode, fullname, email) {
  return {
    type: "CANDIDATES_UPDATE_REQUEST",
    sessionCode,
    fullname,
    email
  };
}

export function candidateResetFormError() {
  return {
    type: "CANDIDATES_GET_DETAIL_ERROR_RESET"
  };
}
