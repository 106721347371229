// @flow

import React from 'react'
import classnames from 'classnames'

type Props = {
  counter: number,
  className: string,
};

class BigCounter extends React.Component<Props> {

  static defaultProps = {
    counter: '',
    className: '',
  }

  render() {
    const {
      counter,
      className
    } = this.props

    const classProps: string = classnames(
      'big-counter',
      className
    )

    return(
      <div className={classProps}>
        <h1 className="heading1 text">{counter}</h1>
      </div>
    )
  }
}

export default BigCounter
