// @flow

import { put, call, select, take, all, fork, cancel } from "redux-saga/effects";
import { push } from "react-router-redux";
import { Map, List, fromJS } from "immutable";
import * as Logger from "astrnt-web-logger";
import { countdown } from "./global";
import Swal from "sweetalert2";
import { sessionsFinish } from "./sessions";
import { delay } from "redux-saga";
import {
  questionsFinishApi,
  questionsAnswerApi,
  questionsGetDetailApi
} from "../apis/questions";

import {
  getSection,
  getCandidate,
  getTranslations,
  getSessionsFilterData,
  getSessionsStateInformation,
  getQuestion,
  getQuestionsFormData,
  getsessionDurationCount
} from "../selectors";

import { t } from "../helpers/global";

import { sessionRunTimeWatcher } from "../actions/sessions";

import { frontEndRunTime } from "../dependencies/frontEndRuntime";

// This function applies to section or non section
// This function applies to section or non section
export function* questionsGetNextId(): Generator<*, *, *> | number {
  const question: Map<string, any> = yield select(getQuestion);
  const sessionsFilterData: Map<string, any> = yield select(
    getSessionsFilterData
  );

  let questionIds: List<number>;
  let questionId: number = question.get("id");

  if (
    sessionsFilterData.get("type") === "close section" ||
    sessionsFilterData.get("type") === "close section without interview"
  ) {
    const section: Map<string, any> = yield select(getSection);

    questionIds = section.get("unanswered_question_ids");

    if (section.get("unanswered_question_ids").size == 0) {
      questionIds = section.get("question_ids");
    }
  } else {
    questionIds = sessionsFilterData.get("question_ids");
  }

  if (!questionId) {
    questionId = questionIds.get(0);
  } else {
    const questionIndex: number = questionIds.indexOf(questionId);
    questionId = questionIds.get(questionIndex + 1);

    if (!questionId) {
      questionId = question.get("id");
    }
  }

  return questionId;
}

// This function only for section
// isNext is boolean
export function* questionsGetPrevOrNextId({
  isNext,
  sessionCode
}: Object): Generator<*, *, *> | number {
  //agar tidak dispam
  yield delay(200);
  const section: Map<string, any> = yield select(getSection);
  const question: Map<string, any> = yield select(getQuestion);

  const questionIds: List<number> = section.get("question_ids");
  const questionId: number = question.get("id");
  const questionIndex: number = questionIds.indexOf(questionId);

  let index: number;

  if (isNext) {
    index = questionIndex + 1;
  } else {
    index = questionIndex - 1;
  }
  try {
    if (index >= 0) {
      const _questionId: number = questionIds.get(index);
      if (_questionId) {
        yield call(questionsGetDetail, _questionId, sessionCode);
        if (section.get("sub_type") == "freetext") {
          yield put(
            push(
              `/code/${sessionCode}/sections/${section.get(
                "id"
              )}/ftq-questions/${_questionId}`
            )
          );
        } else {
          yield put(
            push(
              `/code/${sessionCode}/sections/${section.get(
                "id"
              )}/mcq-questions/${_questionId}`
            )
          );
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* questionsFinish(
  id: number | string,
  sessionCode: string
): Generator<*, *, *> {
  try {
    const sessionsFilterData: Map<string, any> = yield select(
      getSessionsFilterData
    );
    const candidate: Map<string, any> = yield select(getCandidate);
    const section: Map<string, any> = yield select(getSection);
    let params;
    if (section.get("id")) {
      params = {
        event: "Section Finish API", // string
        message: "Section Flag Position on id :" + section.get("id"),
        status: "offline" // string
      };
    } else {
      params = {
        event: "Question Finish API", // string
        message: "Question flag on id :" + id,
        status: "offline" // string
      };
    }

    Logger.recordEvent(params);

    yield call(
      questionsFinishApi,
      candidate.get("id"),
      id,
      sessionCode,
      sessionsFilterData.get("token"),
      section.get("id")
    );
  } catch (error) {
    console.log(error);
  }
}

export function* questionsAnswer(
  sessionCode: string,
  sessionType: string
): Object {
  const sessionsFilterData: Map<string, any> = yield select(
    getSessionsFilterData
  );
  const candidate: Map<string, any> = yield select(getCandidate);
  //kalo allpages question id nya gak punya harus dikirim
  const question: Map<string, any> = yield select(getQuestion);
  const questionsFormData: Map<string, any> = yield select(
    getQuestionsFormData
  );
  var type: Number = 0;
  if (question.get("type_child") == "freetext") {
    type = 1;

    let params = {
      event: "FTQ Question", // string
      message:
        "send question to /api/v2/question/answer for question id = " +
        question.get("id"),
      status: "offline" // string
    };

    Logger.recordEvent(params);
  }
  // const sessionDurationCount: Map<string, any> = yield select(
  //   getsessionDurationCount
  // );
  // frontEndRunTime(
  //   sessionDurationCount.get("front_end_run_time"),
  //   sessionRunTimeWatcher,
  //   true
  // );
  // const duration_used =
  //   sessionDurationCount.get("duration_left") -
  //   sessionDurationCount.get("front_end_run_time");
  const response: Object = yield call(
    questionsAnswerApi,
    sessionsFilterData.get("invite_id"),
    candidate.get("id"),
    question.get("id"),
    type,
    sessionType,
    questionsFormData.get("answered_ids"),
    sessionCode,
    sessionsFilterData.get("token"),
    questionsFormData.get("text_answer")
    // duration_used
  );

  // frontEndRunTime(
  //   sessionDurationCount.get("front_end_run_time"),
  //   sessionRunTimeWatcher,
  //   false
  // );

  // if (question.get("type_child") == "freetext") {
  //   let params = {
  //     event: "FTQ Question", // string
  //     message:
  //       "Success send question to /api/v2/question/answer for question id = " +
  //       question.get("id"),
  //     status: "offline" // string
  //   };

  //   Logger.recordEvent(params);
  // } else {
  //   let params = {
  //     event: "MCQ Question", // string
  //     message:
  //       "Success send question to /api/v2/question/answer for question id = " +
  //       question.get("id"),
  //     status: "offline" // string
  //   };

  //   Logger.recordEvent(params);
  // }

  return response;
}

export function* questionsAnswerOnShowAll(
  sessionCode: string,
  sessionType: string,
  question,
  id
): Object {
  const sessionsFilterData: Map<string, any> = yield select(
    getSessionsFilterData
  );
  const candidate: Map<string, any> = yield select(getCandidate);

  //kalo allpages question id nya gak punya harus dikirim
  //gw matiin ambil dari atas parameter aja
  // const question: Map<string, any> = yield select(getQuestion);
  const questionsFormData: Map<string, any> = yield select(
    getQuestionsFormData
  );

  var answerIds: List<number> = questionsFormData.get("answered_ids");

  var __questionid = question.get("answers").map(q => q.get("id"));

  var type: Number = 0;
  if (question.get("type_child") == "freetext") {
    type = 1;

    let params = {
      event: "FTQ Question", // string
      message:
        "send question to /api/v2/question/answer for question id = " +
        question.get("id"),
      status: "offline" // string
    };

    Logger.recordEvent(params);
  }

  let resultAnswer = answerIds.filter(value => __questionid.includes(value));

  const response: Object = yield call(
    questionsAnswerApi,
    sessionsFilterData.get("invite_id"),
    candidate.get("id"),
    question.get("id"),
    type,
    sessionType,
    resultAnswer,
    sessionCode,
    sessionsFilterData.get("token"),
    questionsFormData.get("text_answer")
  );

  if (question.get("type_child") == "freetext") {
    let params = {
      event: "FTQ Question", // string
      message:
        "Sending question to /api/v2/question/answer for question id = " +
        question.get("id"),

      status: "offline" // string
    };

    Logger.recordEvent(params);
  } else {
    let params = {
      event: "MCQ Question", // string
      message:
        "Sending question to /api/v2/question/answer for question id = " +
        question.get("id"),
      status: "offline" // string
    };

    Logger.recordEvent(params);
  }

  return response;
}

export function* questionsGetDetail(
  questionId: number,
  sessionCode: string
): Generator<*, *, *> {
  const translations: Map<string, any> = yield select(getTranslations);
  const sessionDurationCount: Map<string, any> = yield select(
    getsessionDurationCount
  );
  try {
    const candidate: Map<string, any> = yield select(getCandidate);
    const section: Map<string, any> = yield select(getSection);
    const sessionsFilterData: Map<string, any> = yield select(
      getSessionsFilterData
    );
    const sessionDurationCount: Map<string, any> = yield select(
      getsessionDurationCount
    );
    //stop time and section time
    frontEndRunTime(
      sessionDurationCount.get("front_end_run_time"),
      sessionRunTimeWatcher,
      true
    );
    yield put({ type: "SECTIONS_REDUCE_DURATION_CANCEL" });
    // console.log(sessionDurationCount.get("front_end_run_time"));
    const duration_used =
      sessionDurationCount.get("duration_left") -
      sessionDurationCount.get("front_end_run_time");
    const response = yield call(
      questionsGetDetailApi,
      sessionsFilterData.get("invite_id"),
      candidate.get("id"),
      questionId,
      sessionCode,
      sessionsFilterData.get("token"),
      section.get("id"),
      duration_used
    );

    if (response.status >= 200 && response.status < 300) {
      // let params = {
      //     event: "Get Question Detail", // string
      //     message:`"success get question & token user :${sessionsFilterData.get("token")}`,
      //     status: "offline" // string
      //   };

      //   Logger.recordEvent(params);
      //continue time and section time
      frontEndRunTime(
        sessionDurationCount.get("front_end_run_time"),
        sessionRunTimeWatcher,
        false
      );
      //try to fix duration question

      yield put({
        type: "SECTIONS_REDUCE_DURATION_REQUEST",
        sessionCode: sessionCode
      });

      const question: Object = response.data.question;
      const isNext = response.data.next_question;
      if (isNext == false) {
        //handle all pages

        yield put({ type: "QUESTIONS_IS_NEXT_SUCCESS", is_next: isNext });
        var answered = [];

        question.map((value, index) =>
          value.question_answered_ids.map(val => answered.push(val))
        );
        //ditemukan hasilnya yield put then
        yield put({ type: "QUESTIONS_GET_DETAIL_SUCCESS", question });
        yield put({
          type: "QUESTIONS_ADD_FORM_ANSWERED_IDS_SUCCESS",
          answered_ids: answered
        });
        yield all([
          put({
            type: "SESSIONS_ADD_UI_MODAL_LOADER_DATA_SUCCESS",
            modal_loader_data: { isOpen: false }
          })
        ]);

        return fromJS(question);
      } else {
        var max_length = question.max_length;
        if (typeof question.freetext_answer != "undefined") {
          max_length =
            question.max_length -
            question.freetext_answer.split(" ").length +
            1;
        }

        yield all([
          put({ type: "QUESTIONS_GET_DETAIL_SUCCESS", question }),
          put({
            type: "QUESTIONS_ADD_FORM_ANSWERED_IDS_SUCCESS",
            answered_ids: question.question_answered_ids
          }),
          put({
            type: "QUESTIONS_ADD_FORM_ANSWERED_TEXT_SUCCESS",
            text_answer: question.freetext_answer
          }),
          put({
            type: "QUESTIONS_ADD_FORM_MAX_LENGTH_SUCCESS",
            max_length: max_length
          }),
          put({
            type: "SESSIONS_ADD_UI_MODAL_LOADER_DATA_SUCCESS",
            modal_loader_data: { isOpen: false }
          })
        ]);

        return fromJS(question);
      }
    } else {
      throw response;
    }
  } catch (error) {
    //continue duration section
    yield put({
      type: "SECTIONS_REDUCE_DURATION_REQUEST",
      sessionCode: sessionCode
    });
    frontEndRunTime(
      sessionDurationCount.get("front_end_run_time"),
      sessionRunTimeWatcher,
      false
    );
    if (typeof error.response == "undefined") {
      console.log(error);
    } else {
      if (error.response.data.message == "not authorized") {
        const sessionsFilterData: Map<string, any> = yield select(
          getSessionsFilterData
        );
        let params = {
          event: "Get Question Detail", // string
          message: `"fail get question & token user:${sessionsFilterData.get(
            "token"
          )}`,
          status: "offline" // string
        };

        Logger.recordEvent(params);
        Swal({
          type: "error",
          title: t(translations, "MULTIPLE_DEVICES_TITLE"),
          text: t(translations, "MULTIPLE_DEVICES_CONTENT")
        }).then(function() {
          window.location.reload(true);
        });
        console.log(error);
      } else {
        console.log(error.response.data.message);
      }
    }

    throw error;
  }
}

export function* questionsReduceDurationLeft({
  sessionCode
}: Object): Generator<*, *, *> {
  const question = yield select(getQuestion);
  const channel = yield call(countdown, question.get("question_duration_left"));
  const sessionsFilterData: Map<string, any> = yield select(
    getSessionsFilterData
  );
  try {
    while (true) {
      const seconds = yield take(channel);
      yield put({
        type: "QUESTIONS_REDUCE_DURATION_LEFT_SUCCESS",
        duration_left: seconds
      });
    }
  } finally {
    const _question: Map<string, any> = yield select(getQuestion);

    if (_question.get("question_duration_left") <= 0) {
      const questionId: number = yield call(questionsGetNextId);
      const translations: Map<string, any> = yield select(getTranslations);

      if (questionId) {
        yield put({
          type: "SESSIONS_ADD_UI_MODAL_LOADER_DATA_SUCCESS",
          modal_loader_data: {
            isOpen: true,
            title: t(translations, "INTERVIEW_DATA_TIMES"),
            text: t(translations, "INTERVIEW_DATA_LOADING")
          }
        });

        yield call(questionsGetDetail, questionId, sessionCode);
        yield put({ type: "QUESTIONS_CLEAR_FORM_DATA_SUCCESS" });
        if (
          sessionsFilterData.get("type") === "close test" &&
          sessionsFilterData.get("sub_type") !== "freetext"
        ) {
          yield put(push(`/code/${sessionCode}/mcq-questions/${questionId}`));
        } else {
          yield put(push(`/code/${sessionCode}/ftq-questions/${questionId}`));
        }

        yield put({
          type: "QUESTIONS_REDUCE_DURATION_LEFT_REQUEST",
          sessionCode: sessionCode
        });
      } else {
        yield call(
          sessionsFinish,
          sessionCode,
          t(translations, "INTERVIEW_DATA_THATS"),
          t(translations, "INTERVIEW_DATA_YOU_HAVE")
        );
      }
    }

    channel.close();
  }
}

export function* questionsAddAnswers({ id }: Object): Generator<*, *, *> {
  const question: Map<string, any> = yield select(getQuestion);
  const questionsFormData: Map<string, any> = yield select(
    getQuestionsFormData
  );
  const sessionsFilterData: Map<string, any> = yield select(
    getSessionsFilterData
  );

  if (
    question.get("question_duration_left") <= 0 &&
    sessionsFilterData.get("type") === "close test" &&
    sessionsFilterData.get("sub_type") !== "freetext"
  ) {
    return;
  }

  const answerIds: List<number> = questionsFormData.get("answered_ids");
  let _answerIds: ?List<number> = null;

  if (question.get("type_child") === "multiple_options_for_test") {
    const answerIndex: number = answerIds.indexOf(id);

    if (answerIndex >= 0) {
      _answerIds = answerIds.delete(answerIndex);
    } else {
      _answerIds = answerIds.push(id);
    }
  } else {
    _answerIds = List([id]);
  }
  yield put({
    type: "QUESTIONS_ADD_FORM_ANSWERED_IDS_SUCCESS",
    answered_ids: _answerIds
  });
}

export function* questionsAddAnswersOnAllPages({
  id,
  question
}: Object): Generator<*, *, *> {
  // const question: Map<string, any> = yield select(getQuestion);
  const questionsFormData: Map<string, any> = yield select(
    getQuestionsFormData
  );
  const sessionsFilterData: Map<string, any> = yield select(
    getSessionsFilterData
  );

  // console.log(question.toJS());
  // console.log(questionsFormData.toJS());

  if (
    question.get("question_duration_left") <= 0 &&
    sessionsFilterData.get("type") === "close test" &&
    sessionsFilterData.get("sub_type") !== "freetext"
  ) {
    return;
  }

  var answerIds: List<number> = questionsFormData.get("answered_ids");
  let _answerIds: ?List<number> = null;

  if (question.get("type_child") === "multiple_options_for_test") {
    const answerIndex: number = answerIds.indexOf(id);

    if (answerIndex >= 0) {
      _answerIds = answerIds.delete(answerIndex);
    } else {
      _answerIds = answerIds.push(id);
    }
  } else {
    //jadi caranya hapus seluruh id yang ada di answerIds kemudian insert nilai baru
    question
      .get("answers")
      .map(
        value => (answerIds = answerIds.filter(data => data != value.get("id")))
      );

    _answerIds = answerIds.push(id);
  }
  yield put({
    type: "QUESTIONS_ADD_FORM_ANSWERED_IDS_SUCCESS",
    answered_ids: _answerIds
  });
}

export function* questionsAddAnswersOnAllPages2(id, question): Object {
  // const question: Map<string, any> = yield select(getQuestion);
  const questionsFormData: Map<string, any> = yield select(
    getQuestionsFormData
  );
  const sessionsFilterData: Map<string, any> = yield select(
    getSessionsFilterData
  );

  // console.log(question.toJS());
  // console.log(questionsFormData.toJS());

  if (
    question.get("question_duration_left") <= 0 &&
    sessionsFilterData.get("type") === "close test" &&
    sessionsFilterData.get("sub_type") !== "freetext"
  ) {
    return;
  }

  var answerIds: List<number> = questionsFormData.get("answered_ids");
  let _answerIds: ?List<number> = null;

  if (question.get("type_child") === "multiple_options_for_test") {
    const answerIndex: number = answerIds.indexOf(id);

    if (answerIndex >= 0) {
      _answerIds = answerIds.delete(answerIndex);
    } else {
      _answerIds = answerIds.push(id);
    }
  } else {
    //jadi caranya hapus seluruh id yang ada di answerIds kemudian insert nilai baru
    question
      .get("answers")
      .map(
        value => (answerIds = answerIds.filter(data => data != value.get("id")))
      );

    _answerIds = answerIds.push(id);
  }
  yield put({
    type: "QUESTIONS_ADD_FORM_ANSWERED_IDS_SUCCESS",
    answered_ids: _answerIds
  });
}

export function* questionsAddTextAnswers({ text }: Object): Generator<*, *, *> {
  const question: Map<string, any> = yield select(getQuestion);
  const questionsFormData: Map<string, any> = yield select(
    getQuestionsFormData
  );
  const sessionsFilterData: Map<string, any> = yield select(
    getSessionsFilterData
  );

  if (
    question.get("question_duration_left") <= 0 &&
    sessionsFilterData.get("type") === "close test" &&
    sessionsFilterData.get("sub_type") === "freetext"
  ) {
    return;
  }

  const text_answer: String = text;
  yield put({
    type: "QUESTIONS_ADD_FORM_ANSWERED_TEXT_SUCCESS",
    text_answer: text_answer
  });
}

export function* questionsAddMaxLength({
  max_length
}: Object): Generator<*, *, *> {
  const question: Map<string, any> = yield select(getQuestion);
  const questionsFormData: Map<string, any> = yield select(
    getQuestionsFormData
  );
  const sessionsFilterData: Map<string, any> = yield select(
    getSessionsFilterData
  );
  if (
    question.get("question_duration_left") <= 0 &&
    sessionsFilterData.get("type") === "close test" &&
    sessionsFilterData.get("sub_type") === "freetext"
  ) {
    return;
  }
  yield put({
    type: "QUESTIONS_ADD_FORM_MAX_LENGTH_SUCCESS",
    max_length: max_length
  });
}

export function* questionsGetDetailOnStartedTest({
  sessionCode
}: Object): Generator<*, *, *> {
  const sessionsStateInformation: Map<string, any> = yield select(
    getSessionsStateInformation
  );
  const questionId: number = sessionsStateInformation
    .get("prevQuestStates")
    .last()
    .get("question_id");

  yield call(questionsGetDetail, questionId, sessionCode);

  yield put({
    type: "QUESTIONS_REDUCE_DURATION_LEFT_REQUEST",
    sessionCode: sessionCode
  });
}

export function* questionsGetDetailOnSection({
  id,
  sessionCode
}: Object): Generator<*, *, *> {
  //agar tidak dispam
  yield delay(200);
  try {
    yield call(questionsGetDetail, id, sessionCode);

    const section: Map<string, any> = yield select(getSection);
    if (section.get("sub_type") != "freetext") {
      yield put(
        push(
          `/code/${sessionCode}/sections/${section.get(
            "id"
          )}/mcq-questions/${id}`
        )
      );
    } else {
      yield put(
        push(
          `/code/${sessionCode}/sections/${section.get(
            "id"
          )}/ftq-questions/${id}`
        )
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* questionsReduceDurationWatcher({
  sessionCode
}: Object): Generator<*, *, *> {
  const backgroundSyncTask = yield fork(questionsReduceDurationLeft, {
    sessionCode
  });
  yield take("QUESTIONS_REDUCE_DURATION_CANCEL");

  yield cancel(backgroundSyncTask);
}
