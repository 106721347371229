// @flow
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import pluralize from 'pluralize'
import { Map } from 'immutable'

import { t } from '../../helpers/global'

type Props = {
  total: string | number,
  className: string,
  translations: Map<string, any>,
};

class SectionTotal extends React.Component<Props> {

  static defaultProps = {
    total: 7,
    className: '',
  }

  render() {
    const {
      total,
      translations,
      className
    } = this.props

    return (
      <Fragment>
        <h2 className={`heading1 ${className}`}>
          <span className="m-r-8">{total}</span>
          <span className="heading4 uppercase">
            {t(
              translations,
              "LABEL_PLURAL_SECTION",
              {
                ':pluralize_section': pluralize("Section", total)
              }
            )}
          </span>
        </h2>
      </Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations,
  }
}

export default connect(mapStateToProps, null)(SectionTotal)
