import Promise from "bluebird";

const AUTH_TOKEN_KEY = "08ASDAZ123#ASNDAJ=AD8?";

let authToken = {
  get() {
    let tokenString = localStorage.getItem(AUTH_TOKEN_KEY);

    const time_extend = JSON.parse(tokenString);

    return new Promise((res, rej) => {
      res(time_extend);
    });
  },

  set(time_extend) {
    let pass = time_extend != null;
    if (pass) {
      return new Promise((res, rej) => {
        res(localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(time_extend)));
      });
    }
  },

  remove() {
    return new Promise((res, rej) => {
      res(localStorage.removeItem(AUTH_TOKEN_KEY));
    });
  }
};
export default authToken;
