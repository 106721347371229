// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "react-router-redux";
import { Map } from "immutable";
import Header from "../../lib/elements/Header";
import CardBasicLayout from "../../lib/elements/CardBasicLayout";
import Button from "../../lib/elements/Button";
import Card from "../../lib/elements/Card";
import Content from "../../lib/elements/Content";
import Checkbox from "../../lib/elements/Checkbox";
import bindAll from "lodash/bindAll";
import { sendAstronautQnASessionEvent } from "../../actions/embedded-interview";
import { postQnaDataRating } from "../../actions/sessions";
import { t } from "../../helpers/global";

import {
  sectionsStartSessionRequest,
  sectionsStartSessionWelcomeVideoRequest
} from "../../actions/sections";
import { Field } from "redux-form";

type Props = {
  history: Object,
  translations: Map<string, any>,
  job: Map<string, any>,
  company: Map<string, any>,
  sectionsStartSessionRequest: Function,
  sectionsStartSessionWelcomeVideoRequest: Function,
  sessionCode: string,
  sessionsFilterData: Map<string, any>
};

type State = {
  isConsent: boolean
};

class PostQnaRating extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.state = {
      isConsent: false,
      rating: "",
      feedback: "",
      isHideName: 0
    };

    bindAll(this, [
      "handleRatingChange",
      "handleTextArea",
      "handleContinueClick",
      "handleFocus",
      "handleBlur",
      "handleCheckHideClick"
    ]);
  }

  handleFocus(e) {
    document.onkeypress = null;
  }

  handleBlur(e) {
    if (window.isKeyPress) {
      document.onkeypress = window.isKeyPress;
    }
  }

  handleRatingChange(e, params) {
    this.setState({ rating: params }, () => {
      this.setState({ isConsent: true });
    });
  }

  handleTextArea(e) {
    this.setState({ feedback: e.target.value });
  }

  handleContinueClick(e) {
    e.preventDefault();

    const {
      sessionCode,
      sessionsFilterData,
      candidate,
      sectionsStartSessionRequest,
      sectionsStartSessionWelcomeVideoRequest,
      sendAstronautQnASessionEvent,
      redirect,
      postQnaDataRating
    } = this.props;

    sendAstronautQnASessionEvent("GDPR Complied");
    postQnaDataRating(
      this.state,
      sessionsFilterData.get("invite_id"),
      candidate.get("id"),
      sessionCode,
      sessionsFilterData.get("token")
    );

    // if (sessionsFilterData.get("welcome_video_url") == "") {
    //   sectionsStartSessionRequest(sessionCode);
    // } else {
    //   sectionsStartSessionWelcomeVideoRequest(sessionCode);
    // }
  }

  handleCheckHideClick(e) {
    this.setState({ isHideName: !this.state.isHideName });
  }

  render() {
    const {
      job,
      company,
      candidate,
      sessionsFilterData,
      translations
    } = this.props;

    const { isConsent, isHideName } = this.state;

    return (
      <Fragment>
        <Header
          company={company.get("title")}
          job={job.get("title")}
          position={job.get("jobType")}
          location={job.get("locations")}
          type="primary"
          redirectCode={sessionsFilterData.get("ecourse_code")}
          learner={candidate}
          logoCourse={company.get("logoUrl")}
        />
        <div className="container">
          <Card theme="rating">
            <div className="card-body p-0">
              <div className="row">
                <div className="w-100">
                  {/*<h4>{t(translations, "LABEL_HOW_MUCH")}</h4>*/}
                  <h4>Seberapa puas kamu dengan kursus</h4>
                  <h4 className="bold">{job.get("title")}</h4>
                </div>
              </div>
              <div className="row m-l-0 m-r-0 m-t-40">
                <div className="rating-container flex-container flex-between header-desktop-hide">
                  <button
                    onClick={e => this.handleRatingChange(e, 10)}
                    className={`button-post-rating ${
                      this.state.rating === 10 ? "active" : ""
                    } `}
                  >
                    10
                  </button>
                  <button
                    onClick={e => this.handleRatingChange(e, 9)}
                    className={`button-post-rating ${
                      this.state.rating === 9 ? "active" : ""
                    } `}
                  >
                    9
                  </button>
                  <button
                    onClick={e => this.handleRatingChange(e, 8)}
                    className={`button-post-rating ${
                      this.state.rating === 8 ? "active" : ""
                    } `}
                  >
                    8
                  </button>
                  <button
                    onClick={e => this.handleRatingChange(e, 7)}
                    className={`button-post-rating ${
                      this.state.rating === 7 ? "active" : ""
                    } `}
                  >
                    7
                  </button>
                  <button
                    onClick={e => this.handleRatingChange(e, 6)}
                    className={`button-post-rating ${
                      this.state.rating === 6 ? "active" : ""
                    } `}
                  >
                    6
                  </button>
                  <button
                    onClick={e => this.handleRatingChange(e, 5)}
                    className={`button-post-rating ${
                      this.state.rating === 5 ? "active" : ""
                    } `}
                  >
                    5
                  </button>
                  <button
                    onClick={e => this.handleRatingChange(e, 4)}
                    className={`button-post-rating ${
                      this.state.rating === 4 ? "active" : ""
                    } `}
                  >
                    4
                  </button>
                  <button
                    onClick={e => this.handleRatingChange(e, 3)}
                    className={`button-post-rating ${
                      this.state.rating === 3 ? "active" : ""
                    } `}
                  >
                    3
                  </button>
                  <button
                    onClick={e => this.handleRatingChange(e, 2)}
                    className={`button-post-rating ${
                      this.state.rating === 2 ? "active" : ""
                    } `}
                  >
                    2
                  </button>
                  <button
                    onClick={e => this.handleRatingChange(e, 1)}
                    className={`button-post-rating ${
                      this.state.rating === 1 ? "active" : ""
                    } `}
                  >
                    1
                  </button>
                  <button
                    onClick={e => this.handleRatingChange(e, 0)}
                    className={`button-post-rating ${
                      this.state.rating === 0 ? "active" : ""
                    } `}
                  >
                    0
                  </button>
                </div>
                <div className="rating-container-mobile header-mobile-hide">
                  <button
                    onClick={e => this.handleRatingChange(e, 10)}
                    className={`button-post-rating-mobile ${
                      this.state.rating === 10 ? "active" : ""
                    } `}
                  >
                    10
                  </button>
                  <button
                    onClick={e => this.handleRatingChange(e, 9)}
                    className={`button-post-rating-mobile ${
                      this.state.rating === 9 ? "active" : ""
                    } `}
                  >
                    9
                  </button>
                  <button
                    onClick={e => this.handleRatingChange(e, 8)}
                    className={`button-post-rating-mobile ${
                      this.state.rating === 8 ? "active" : ""
                    } `}
                  >
                    8
                  </button>
                  <button
                    onClick={e => this.handleRatingChange(e, 7)}
                    className={`button-post-rating-mobile ${
                      this.state.rating === 7 ? "active" : ""
                    } `}
                  >
                    7
                  </button>
                  <button
                    onClick={e => this.handleRatingChange(e, 6)}
                    className={`button-post-rating-mobile ${
                      this.state.rating === 6 ? "active" : ""
                    } `}
                  >
                    6
                  </button>
                  <button
                    onClick={e => this.handleRatingChange(e, 5)}
                    className={`button-post-rating-mobile ${
                      this.state.rating === 5 ? "active" : ""
                    } `}
                  >
                    5
                  </button>
                  <button
                    onClick={e => this.handleRatingChange(e, 4)}
                    className={`button-post-rating-mobile ${
                      this.state.rating === 4 ? "active" : ""
                    } `}
                  >
                    4
                  </button>
                  <button
                    onClick={e => this.handleRatingChange(e, 3)}
                    className={`button-post-rating-mobile ${
                      this.state.rating === 3 ? "active" : ""
                    } `}
                  >
                    3
                  </button>
                  <button
                    onClick={e => this.handleRatingChange(e, 2)}
                    className={`button-post-rating-mobile ${
                      this.state.rating === 2 ? "active" : ""
                    } `}
                  >
                    2
                  </button>
                  <button
                    onClick={e => this.handleRatingChange(e, 1)}
                    className={`button-post-rating-mobile ${
                      this.state.rating === 1 ? "active" : ""
                    } `}
                  >
                    1
                  </button>
                  <button
                    onClick={e => this.handleRatingChange(e, 0)}
                    className={`button-post-rating-mobile ${
                      this.state.rating === 0 ? "active" : ""
                    } `}
                  >
                    0
                  </button>
                </div>
              </div>
              <div className="row m-t-8 flex-container flex-between header-desktop-hide">
                <div className="div-not">
                  <div className="margin-left-min">Sangat Tidak Puas</div>
                </div>
                <div className="div-like">
                  <div className="margin-right-min">Sangat Puas</div>
                </div>
              </div>
              <div className="row m-t-8 flex-container flex-between header-mobile-hide">
                <div className="div-not">
                  <div className="m-l-5">Sangat Tidak Puas</div>
                </div>
                <div className="div-like">
                  <div className="m-r-5">Sangat Puas</div>
                </div>
              </div>
              <div className="row row-textarea m-t-20">
                <textarea
                  onChange={this.handleTextArea}
                  value={this.state.feedback}
                  className="textarea-rating"
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                  placeholder="Hal apa yang bisa ditingkatkan dari kursus ini?"
                ></textarea>
              </div>
              <div className="row m-t-20">
                <Checkbox
                  onClick={this.handleCheckHideClick}
                  label={"Sembunyikan nama asli saya di halaman ulasan."}
                  className="color-999"
                  checked={isHideName}
                />
              </div>
              <div className="row justify-content-center">
                <Button
                  className="m-t-40 btn-next-rating"
                  onClick={this.handleContinueClick}
                  disabled={!isConsent}
                >
                  Kirim
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations,
    job: state.job,
    company: state.company,
    candidate: state.candidate,
    sessionCode: ownProps.match.params.sessionCode,
    sessionsFilterData: state.sessionsFilterData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sectionsStartSessionRequest: sessionCode =>
      dispatch(sectionsStartSessionRequest(sessionCode)),
    sectionsStartSessionWelcomeVideoRequest: sessionCode =>
      dispatch(sectionsStartSessionWelcomeVideoRequest(sessionCode)),
    sendAstronautQnASessionEvent: astronautEvent =>
      dispatch(sendAstronautQnASessionEvent(astronautEvent)),
    redirect: params => dispatch(push(params)),
    postQnaDataRating: (data, invite_id, candidate_id, sessionCode, token) =>
      dispatch(
        postQnaDataRating(data, invite_id, candidate_id, sessionCode, token)
      )
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PostQnaRating)
);
