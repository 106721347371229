import Promise from "bluebird";

export function interviewsPracticeRetrySuccess() {
  return {
    type: "INTERVIEWS_PRACTICE_RETRY_SUCCESS"
  };
}

export function interviewsStartPracticeRecordingSound(start) {
  return {
    type: "INTERVIEWS_START_PRACTICE_RECORDING_SOUND",
    start: start
  };
}

export function interviewsPracticeValidSuccess(valid) {
  return {
    type: "INTERVIEWS_PRACTICE_VALID_SUCCESS",
    valid: valid
  };
}

export function interviewsPracticeSoundStart(is_record) {
  return {
    type: "INTERVIEWS_PRACTICE_SOUND_START_SUCCESS",
    is_record: is_record
  };
}

export function interviewsPracticeSoundDuration(duration) {
  return {
    type: "INTERVIEWS_PRACTICE_SOUND_DURATION_SUCCESS",
    duration: duration
  };
}

export function interviewsReduceQuestionDurationCancel() {
  return {
    type: "INTERVIEWS_REDUCE_QUESTION_DURATION_CANCEL"
  };
}

export function interviewsStartSessionRequest(sessionCode) {
  return {
    type: "INTERVIEWS_START_SESSION_REQUEST",
    sessionCode
  };
}

export function interviewsStartWelcomeVideoRequest(sessionCode) {
  return {
    type: "INTERVIEWS_START_WELCOME_VIDEO_REQUEST",
    sessionCode
  };
}

export function interviewsCheckGDPRRequest(sessionCode) {
  return {
    type: "INTERVIEWS_CHECK_GDPR_REQUEST",
    sessionCode
  };
}

export function interviewsStartSessionPracticeRequest(sessionCode) {
  return {
    type: "INTERVIEWS_START_SESSION_PRACTICE_REQUEST",
    sessionCode
  };
}

export function interviewsStartRecordingRequest(sessionCode, haltStart) {
  return {
    type: "INTERVIEWS_START_RECORDING_REQUEST",
    sessionCode,
    haltStart
  };
}

export function interviewsRemoveCountdownAndStart(countdown_duration) {
  return {
    type: "INTERVIEWS_REMOVE_COUNTDOWN_AND_START",
    countdown_duration
  };
}

export function interviewsStartRecordingPracticeRequest(
  sessionCode,
  haltStart
) {
  return {
    type: "INTERVIEWS_START_RECORDING_PRACTICE_REQUEST",
    sessionCode,
    haltStart
  };
}

export function interviewsStartUploadRequest(sessionCode) {
  return {
    type: "INTERVIEWS_START_UPLOAD_REQUEST",
    sessionCode
  };
}

export function interviewsStartNextQuestionRequest(sessionCode) {
  return {
    type: "INTERVIEWS_START_NEXT_QUESTION_REQUEST",
    sessionCode
  };
}

export function interviewsAddFilterIsModalOpenSuccess(isModalOpen) {
  return {
    type: "INTERVIEWS_ADD_FILTER_IS_MODAL_OPEN_SUCCESS",
    is_modal_open: isModalOpen
  };
}

export function interviewsAddFilterIsModalOpen(isModalOpen) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      resolve(dispatch(interviewsAddFilterIsModalOpenSuccess(isModalOpen)));
    });
  };
}

export function interviewsAddFilterIsModalInterviewCustomSuccess(
  modal_loader_data
) {
  return {
    type: "INTERVIEWS_ADD_FILTER_IS_MODAL_INTERVIEW_CUSTOM_SUCCESS",
    modal_loader_data: modal_loader_data
  };
}

export function interviewsFinishByTrigger(sessionCode) {
  return {
    type: "INTERVIEWS_FINISH_BY_TRIGGER",
    sessionCode: sessionCode
  };
}

export function interviewsAddFilterIsLowSoundSuccess(isLowSound) {
  return {
    type: "INTERVIEWS_ADD_FILTER_IS_LOW_SOUND_SUCCESS",
    is_low_sound: isLowSound
  };
}

export function interviewsResetQuestionAttemptRequest(sessionCode) {
  return {
    type: "INTERVIEWS_RESET_QUESTION_ATTEMPT_REQUEST",
    sessionCode
  };
}

export function interviewsAddFilterIsNoSoundSuccess(isNoSound) {
  return {
    type: "INTERVIEWS_ADD_FILTER_IS_NO_SOUND_SUCCESS",
    is_no_sound: isNoSound
  };
}

export function interviewsAddFilterIsPracticeNoSoundSuccess(isPracticeNoSound) {
  return {
    type: "INTERVIEWS_ADD_FILTER_IS_PRACTICE_NO_SOUND_SUCCESS",
    is_practice_no_sound: isPracticeNoSound
  };
}

export function interviewsRetakeRequest(sessionCode) {
  return {
    type: "INTERVIEWS_RETAKE_REQUEST",
    sessionCode
  };
}
