import React, { Fragment } from "react";
import {
  Field,
  reduxForm,
  change,
  formValueSelector,
  stopAsyncValidation,
  touch
} from "redux-form";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import Header from "../../lib/elements/Header";
import bindAll from "lodash/bindAll";
import { withRouter } from "react-router";
import { ecourseLogin, redirectCourse, login } from "../../actions/ecourse";

import validate from "./validateLogin";

const normalizeEmail = (value, previousValue) => {
  return value.trim();
};

class LoginPage extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      renderErrorAccount: <div />
    };
    bindAll(this, [
      "handleSaveData",
      "renderError",
      "inputFocus",
      "inputBlur",
      "handleRedirect",
      "handleRedirectReset",
      "renderField",
      "handleSaveData"
    ]);
  }

  handleRedirect() {
    const { redirect, jobCode } = this.props;
    redirect(`/register/${jobCode}`);
  }

  handleRedirectReset() {
    const { redirect, jobCode } = this.props;
    redirect(`/reset/${jobCode}`);
  }

  handleSaveData(values) {
    const { login, jobCode, ecourseLogin } = this.props;
    login(values, jobCode).catch(error => {
      this.setState({
        renderErrorAccount: (
          <div className="form-group error">
            Email dan kata sandi yang Anda masukkan tidak cocok dengan data
            kami. Harap periksa kembali dan coba lagi.
          </div>
        )
      });
    });
    // ecourseLogin(values)
  }

  renderField({ input, placeholder, type, meta: { touched, error } }) {
    return (
      <input
        {...input}
        type={type}
        placeholder={placeholder}
        className={touched && error ? "error" : ""}
      />
    );
  }

  renderError({ meta: { touched, error } }) {
    // const {
    // 	translationjob
    // } = this.props
    // const translations : Map<string, any> = fromJS(translationjob.translation)
    return touched && error ? (
      <div>
        <span className="error normal" id="js-err-msg">
          {error}
        </span>
      </div>
    ) : (
      false
    );
  }

  inputFocus(e: object): void {
    // const {publicJobCustom}= this.props
    e.target.style.boxShadow = `0 0 10px ${"red"}`;
    e.target.style.border = `1px solid ${"red"}`;
    e.target.style.borderRadius = `5px`;
  }

  inputBlur(e: object): void {
    e.target.style.border = `1px solid #999999`;
    e.target.style.borderRadius = ``;
    e.target.style.boxShadow = ``;
  }

  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      ecourseJobDetail
    } = this.props;
    var ecourseDetail = ecourseJobDetail.toJS();
    let data_provider;
    let data_course;
    let provider = "";
    let course = "";
    let logoUrl = "";
    let jobId = "";
    let loginPage = <div></div>;

    if (ecourseJobDetail.size > 0) {
      data_provider = ecourseDetail.provider;
      data_course = ecourseDetail.course;
      provider = data_provider.title;
      course = data_course.title;
      logoUrl = data_provider.logoUrl;
      loginPage = (
        <Fragment>
          <Header
            provider={provider}
            course={course}
            position={"Pendaftaran"}
            location={"bandung"}
            logoCourse={logoUrl}
            type="register"
          />
          <div className="container max-width-500 m-t-50">
            <section id="section-registration" className="login">
              <div className="flex-container flex-between vertical-item--center">
                <p className="label-ask">Belum punya akun?</p>
                <div
                  onClick={this.handleRedirect}
                  className="a-action heading4"
                >
                  Daftar Baru
                </div>
              </div>
              <div className="div-separator-or m-t-30">
                <span className="span-separator-or">atau masuk kembali</span>
              </div>
              <div className="div-apply-now m-t-40">
                <p className={"text-center"} style={{ color: "#000000" }}>
                  {"LOGIN"}
                </p>

                <hr style={{ borderColor: "#000000" }} />
              </div>
              <form
                onSubmit={handleSubmit(this.handleSaveData)}
                id="form-registration"
                className="vertical-form"
              >
                {this.state.renderErrorAccount}
                {/* <div className="form-group error">
                Email dan kata sandi yang Anda masukkan tidak cocok dengan catatan kami. Harap periksa kembali dan coba lagi.
              </div> */}
                <div className="form-group">
                  <label>{"Alamat Email"}</label>
                  <Field
                    name="email"
                    type="text"
                    placeholder={"Alamat Email"}
                    component={this.renderField}
                    normalize={normalizeEmail}
                  />
                  <Field name="email" component={this.renderError} />
                </div>
                <div className="form-group">
                  <label>{"Kata Sandi"}</label>
                  <Field
                    name="password"
                    type="password"
                    placeholder={"Kata Sandi"}
                    component={this.renderField}
                  />
                  <Field name="password" component={this.renderError} />
                </div>
                {/*
                              <div className="form-group hidden">
                                  <div className="col-md-10 p-0"><label>What of experience do you expect to gain at <a href="https://astrnt.co" target="_blank"><b style={{color:customColor}}>Astronaut</b></a>?</label></div>
                                  <div className="col-md-2 text-right p-0"><label>{countText}<font className="normal">/1000</font></label></div>
                                  <textarea
                                      name="experience"
                                      onFocus={this.inputFocus}
                                      onBlur={this.inputBlur}
                                      value={formValues.experience}
                                      onChange={this.handleChangeTextArea}
                                      placeholder="What of experience do you expect to gain at Astronaut?"
                                      className="form-control h-100"
                                  />

                                  <Field
                                      name="experience"
                                      component="textarea"
                                      placeholder="What of experience do you expect to gain at Astronaut?"
                                      className="form-control h-100 hidden" />
                                  <Field name="experience" component={this.renderError}/>
                              </div> */}

                <center className="m-t-30">
                  {/* <p className="label-click-continue" dangerouslySetInnerHTML={{__html: t(translations, 'LABEL_CONTINUE_DESC')}}/> */}
                  <button
                    id="btn-submit"
                    type="submit"
                    className={`button button__primary button--medium button-submit-course uppercase is-on`}
                    disabled={false}
                  >
                    Masuk
                  </button>
                </center>
                <div className="text-right m-t-40">
                  <div
                    onClick={this.handleRedirectReset}
                    className="a-action heading4"
                  >
                    Lupa Kata Sandi?
                  </div>
                </div>
              </form>
            </section>
          </div>
        </Fragment>
      );
    }

    return loginPage;
  }
}

LoginPage = reduxForm({
  form: "LoginPage",
  enableReinitialize: true,
  validate
})(LoginPage);

const initialValues = {
  email: ""
};
const selector = formValueSelector("LoginPage");
const mapStateToProps = (state, ownProps) => {
  return {
    initialValues,
    formValues: selector(state, "email"),
    jobCode: ownProps.location.pathname.split("/")[2],
    ecourseJobDetail: state.ecourseJobDetail
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeFieldValue: (field, value) =>
      dispatch(change("LoginPage", field, value, true, true)),
    touchForm: field => dispatch(touch("LoginPage", field)),
    stopAsyncValidationForm: object =>
      dispatch(stopAsyncValidation("LoginPage", object)),
    ecourseLogin: data => dispatch(ecourseLogin(data)),
    redirect: link => dispatch(redirectCourse(link)),
    login: (values, jobCode) => dispatch(login(values, jobCode))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginPage)
);
