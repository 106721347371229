// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Map } from "immutable";
import bindAll from "lodash/bindAll";
import pluralize from "pluralize";
import Header from "../../lib/elements/Header";
import CardSingleContent from "../../lib/elements/CardSingleContent";
import CardWithLabel from "../../lib/elements/CardWithLabel";
import InstructionDesk from "../../lib/elements/InstructionDesk";
import Button from "../../lib/elements/Button";
import Time from "../../lib/elements/Time";
import Icon from "../../lib/elements/Icon";
import ModalDialog from "../../lib/elements/ModalDialog";
import imgInstruction1 from "../../images/instruction-mcq-1.svg";
import imgInstruction2 from "../../images/instruction-mcq-2.svg";
import imgInstruction3 from "../../images/instruction-mcq-3.svg";
import CardWelcomeVideo from "../../lib/elements/CardWelcomeVideo";
import ModalWelcomeVideo from "../partials/ModalWelcomeVideo";
import { globalGenerateUnitOfTimeHelper } from "../../helpers/global";
import { sendAstronautQnASessionEvent } from "../../actions/embedded-interview";
import CardTopicTitle from "../../lib/elements/CardTopicTitle";
import CardTopicDesc from "../../lib/elements/CardTopicDesc";
import CardTopicInstruksi from "../../lib/elements/CardTopicInstruksi";
import CardTopicAll from "../../lib/elements/CardTopicAll";

import LogoAstrntWhite from "../../images/logo-white.png";

import { t } from "../../helpers/global";

import {
  mcqsStartRequest,
  mcqsGDPRRequest,
  mcqsStartWelcomeVideoRequest
} from "../../actions/mcqs";

type Props = {
  job: Map<string, any>,
  company: Map<string, any>,
  candidate: Map<string, any>,
  mcqsStartRequest: Function,
  mcqsStartWelcomeVideoRequest: Function,
  mcqsGDPRRequest: Function,
  translations: Map<string, any>,
  sessionCode: string,
  sessionsFilterData: Map<string, any>,
  sessionsStateInformation: Map<string, any>
};

type State = {
  modal: boolean
};

class McqIndex extends React.Component<Props, State> {
  constructor(props: Object): void {
    super(props);

    this.state = {
      modal: false
    };

    bindAll(this, [
      "handleContinueClick",
      "handleModalToggle",
      "handleClickWelcomeVideo"
    ]);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleContinueClick(e: Object): void {
    e.preventDefault();

    const {
      sessionCode,
      mcqsStartRequest,
      mcqsStartWelcomeVideoRequest,
      mcqsGDPRRequest,
      sessionsFilterData,
      sendAstronautQnASessionEvent
    } = this.props;

    sendAstronautQnASessionEvent("Start MCQ Session");

    if (sessionsFilterData.get("gdpr_complied") == "0") {
      mcqsGDPRRequest(sessionCode);
    } else if (sessionsFilterData.get("welcome_video_url") == "") {
      mcqsStartRequest(sessionCode);
    } else {
      mcqsStartWelcomeVideoRequest(sessionCode);
    }
  }

  handleModalToggle(e: Object): void {
    e.preventDefault();

    this.setState({ modal: !this.state.modal });
  }
  handleClickWelcomeVideo() {
    this.ModalWelcomeVideo.handleOpenModal(false);
  }
  render() {
    const {
      job,
      company,
      candidate,
      sessionsFilterData,
      translations,
      sessionsStateInformation,
      sessionsUiData
    } = this.props;

    const { modal } = this.state;

    const duration: Map<string, any> = globalGenerateUnitOfTimeHelper(
      sessionsFilterData.get("duration_left")
    );
    const questionIdsSize: number = sessionsFilterData.get("question_ids").size;
    let modalWelcome;
    if (sessionsStateInformation.get("is_onboarding")) {
      modalWelcome = (
        <ModalWelcomeVideo onRef={ref => (this.ModalWelcomeVideo = ref)} />
      );
    } else {
      modalWelcome = "";
    }
    const video = sessionsFilterData.get("welcome_video_url");
    var renderVideo = "";
    if (video != "") {
      renderVideo = (
        <div
          className="card-click"
          onClick={this.handleClickWelcomeVideo}
          style={{ cursor: "pointer" }}
        >
          <CardWelcomeVideo className="m-t-16 test m-t-small" />
        </div>
      );
    }
    return (
      <Fragment>
        <Header
          company={company.get("title")}
          job={job.get("title")}
          position={job.get("jobType")}
          location={job.get("locations")}
          type="primary"
          logoCourse={company.get("logoUrl")}
        />
        <div className="container">
          <section>
            <h2 className="heading4 normal m-t-24 m-t-small">
              Selamat Datang,{" "}
              <b className="bold">{candidate.get("fullname")}</b>!
            </h2>
            {renderVideo}
            <CardSingleContent
              className="m-t-16 m-t-small"
              number={questionIdsSize}
              text={t(translations, "QUESTION_TYPE_MCQ_PLURAL_WITHOUT_N", {
                ":pluralize_question": pluralize("question", questionIdsSize)
              })}
            >
              <Icon
                type="icon"
                name="icon-test"
                size="extralarge"
                align="bottom"
                className="m-r-8"
              />
            </CardSingleContent>
            <CardWithLabel label="Durasi video Kursus">
              <Time data={duration} className="m-b-0 bold">
                <Icon
                  type="icon"
                  name="icon-timer"
                  size="extralarge"
                  align="middle"
                  className="m-r-8"
                />
              </Time>
            </CardWithLabel>
          </section>
          <section>
            <h2 className="heading4 uppercase normal m-t-32 m-t-small">
              <b>{t(translations, "HOW_IT_WORKS_HOW_LABEL")}</b>{" "}
              {t(translations, "HOW_IT_WORKS_WORKS_LABEL")}
            </h2>
            <div className="row no-gutters m-t-20 m-t-small">
              <InstructionDesk
                image={imgInstruction1}
                text={t(translations, "HOW_IT_WORKS_MCQ_TIMER")}
                className="col-sm-4 bg-white"
              />
              <InstructionDesk
                image={imgInstruction2}
                text={t(translations, "HOW_IT_WORKS_MCQ_TOOLS")}
                className="col-sm-4 bg-white"
              />
              <InstructionDesk
                image={imgInstruction3}
                text={t(translations, "HOW_IT_WORKS_MCQ_INSTRUCTION")}
                className="col-sm-4 bg-white"
              />
            </div>
          </section>
          <section>
            <center>
              <Button
                className="m-t-40 m-b-40 m-t-small m-b-small"
                onClick={this.handleModalToggle}
              >
                Mulai Kuis
              </Button>
            </center>
          </section>
        </div>
        <footer className="footer-astrnt horizontal-item--center">
          Powered by <img src={LogoAstrntWhite} /> All Rights Reserved.
        </footer>
        <ModalDialog
          isOpen={modal}
          toggle={this.handleModalToggle}
          title={t(translations, "INSTRUCTION_START_SESSION") + "?"}
          text={t(translations, "POPUP_CONTENT_START_SESSION_MCQ")}
        >
          <Button
            type="button"
            theme="secondary"
            size="small"
            className="m-r-20"
            onClick={this.handleModalToggle}
          >
            {t(translations, "LABEL_CANCEL")}
          </Button>
          <Button
            type="button"
            theme="primary"
            size="small"
            onClick={this.handleContinueClick}
            buttonLoading={sessionsUiData.get("loading")}
            disabled={sessionsUiData.get("disabled_button")}
          >
            {t(translations, "LABEL_LETS_GO")}
          </Button>
        </ModalDialog>
        {modalWelcome}
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations,
    job: state.job,
    company: state.company,
    candidate: state.candidate,
    sessionCode: ownProps.match.params.sessionCode,
    sessionsFilterData: state.sessionsFilterData,
    sessionsStateInformation: state.sessionsStateInformation,
    sessionsUiData: state.sessionsUiData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    mcqsStartRequest: sessionCode => dispatch(mcqsStartRequest(sessionCode)),
    mcqsStartWelcomeVideoRequest: sessionCode =>
      dispatch(mcqsStartWelcomeVideoRequest(sessionCode)),
    mcqsGDPRRequest: sessionCode => dispatch(mcqsGDPRRequest(sessionCode)),
    sendAstronautQnASessionEvent: astronautEvent =>
      dispatch(sendAstronautQnASessionEvent(astronautEvent))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(McqIndex)
);
