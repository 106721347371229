// @flow

import React from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import { t } from '../../helpers/global'

import blockedFirefox from '../../images/blocked-firefox.png'

type Props = {
  translations: Map<string, any>
};

class AllowDeviceFirefox extends React.Component<Props> {
  render() {
    const { translations } = this.props

    return(
      <div className="interview__header">
        <div className="interview__error">
          <div className="interview__error">
            <div className="m-t-40 m-b-15">{t(translations, 'ERROR_PAGE_WE_HAVE_PROBLEM')}</div>
            <div className="m-b-10">
              <p>{t(translations, 'ERROR_PAGE_WE_WANT_TO_PROCEED')} <br /> {t(translations, 'ERROR_PAGE_YOUR_CAMERA')}</p>
            </div>
            <div className="m-t-30 m-b-20">
              <img
                src={blockedFirefox}
                className="img-responsive"
                alt="" />
            </div>
            <div>
              <p>{t(translations, 'ERROR_PAGE_TO_FIX_FIREFOX')}</p>
              <p className="m-t-10">{t(translations, 'ERROR_PAGE_THEN_CLICK')} <b>x</b> {t(translations, 'ERROR_PAGE_NEXT_TO_CAMERA')} <br /> {t(translations, 'ERROR_PAGE_THEN_RELOAD')}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations
  }
}

export default connect(mapStateToProps, null)(AllowDeviceFirefox)
