import React, { Fragment } from "react";
import Header from "../../lib/elements/Header";
import Card from "../../lib/elements/Card";
import Button from "../../lib/elements/Button";
import ListAnswersNotInterested from "../../lib/elements/ListAnswersNotInterested";
import { connect } from "react-redux";
import {
  getCandidateUnsubscribeStatus,
  unsubscribeCandidate,
  resubscribeCandidate
} from "../../apis/global";

class NotInterested extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      hideForm: true,
      hideThankyouPage: true,
      data: "undefined",
      renderReady: false,
      submit: true,
      reason: undefined,
      hideAll: true,
      reapply: false,
      translations: {},
      type: undefined
    };
  }

  componentDidMount() {
    const { sessionCode, translations } = this.props;
    let apiResult = getCandidateUnsubscribeStatus(sessionCode);

    apiResult
      .then(response => {
        this.setState({
          data: response.data.message.candidate_status,
          renderReady: true,
          hideForm: false,
          submit: false,
          company: response.data.message.company_title,
          job: response.data.message.job_title,
          hideAll: false,
          type: response.data.message.type,
          translations: {
            done_header: translations.get("NOT_INTERESTED_DONE_HEADER"),
            done_content_1: translations.get("NOT_INTERESTED_DONE_CONTENT_1"),
            done_content_1_finished: translations.get(
              "NOT_INTERESTED_DONE_CONTENT_1_FINISHED"
            ),
            done_content_2_unfinished: translations.get(
              "NOT_INTERESTED_DONE_CONTENT_2_UNFINISHED"
            ),
            done_content_2_deadline_passed: translations.get(
              "NOT_INTERESTED_DONE_CONTENT_2_DEADLINE_PASSED"
            ),
            done_content_2_finished: translations.get(
              "NOT_INTERESTED_DONE_CONTENT_2_FINISHED"
            ),
            form_header: translations.get("NOT_INTERESTED_FORM_HEADER"),
            form_content_1: translations.get("NOT_INTERESTED_FORM_CONTENT_1"),
            form_content_2: translations.get("NOT_INTERESTED_FORM_CONTENT_2"),
            form_options_1: translations.get("NOT_INTERESTED_FORM_OPTION_1"),
            form_options_2: translations.get("NOT_INTERESTED_FORM_OPTION_2"),
            form_options_3: translations.get("NOT_INTERESTED_FORM_OPTION_3"),
            form_textarea: translations.get("NOT_INTERESTED_TEXT_AREA"),
            post_submit_header: translations.get(
              "NOT_INTERESTED_POST_SUBMIT_HEADER"
            ),
            post_submit_content_1: translations.get(
              "NOT_INTERESTED_POST_SUBMIT_CONTENT_1"
            ),
            post_submit_content_2: translations.get(
              "NOT_INTERESTED_POST_SUBMIT_CONTENT_2"
            ),
            post_submit_button: translations.get(
              "NOT_INTERESTED_POST_SUBMIT_BUTTON"
            ),
            reapply_header: translations.get("NOT_INTERESTED_REAPPLY_HEADER"),
            reapply_content_1: translations.get(
              "NOT_INTERESTED_REAPPLY_CONTENT_1"
            )
          },
          options: [
            {
              label: "Saya telah berhenti mengikuti kursus terkait",
              checked: true
            },
            {
              label: "Saya tidak lagi tertarik untuk menerima email ini",
              checked: false
            },
            {
              label: "Email yang saya terima terlalu sering",
              checked: false
            },
            {
              label: "Alasan lainnya",
              checked: false
            }
          ],
          reason: translations.get("NOT_INTERESTED_FORM_OPTION_1")
        });
      })
      .catch(error => {
        // window.location.href = "http://" + window.location.host + "/code/" + sessionCode
      });
  }

  submitData(event) {
    event.preventDefault();
    this.setState({ submit: true });

    const { sessionCode } = this.props;
    const message = {
      reason: this.state.reason,
      desc: document.getElementById("textareaForm").value
    };

    let apiResult = unsubscribeCandidate(
      sessionCode,
      this.state.data.candidate_id,
      this.state.data.invite_id,
      message
    );

    apiResult
      .then(response => {
        this.setState({
          hideForm: true,
          hideThankyouPage: false
        });
      })
      .catch(error => {
        this.setState({
          submit: false
        });
      });
  }

  handleAnswersClick(answerIndex) {
    let _options = this.state.options.map((oldOption, index) => {
      let newObject;
      if (index === answerIndex) {
        newObject = { label: oldOption.label, checked: true };
        this.setState({ reason: oldOption.label });
      } else {
        newObject = { label: oldOption.label, checked: false };
      }
      return newObject;
    });
    this.setState({
      options: _options
    });
  }

  resubscribeJob(e) {
    e.preventDefault();
    const { sessionCode } = this.props;

    let apiResult = resubscribeCandidate(
      sessionCode,
      this.state.data.invite_id,
      this.state.data.candidate_id
    );

    apiResult.then(response => {
      this.setState({
        reapply: true,
        hideThankyouPage: false
      });
    });
  }

  render() {
    const host =
      "http://" + window.location.host + "/code/" + this.props.sessionCode;

    const mapTranslations = {
      ":job_title": this.state.job,
      ":company_title": this.state.company,
      ":interview_code": this.props.sessionCode
    };

    let _translations = {};
    let finishPage;
    let _done_content_2_finished_split;
    let _done_content_2_unfinished_split;

    Object.keys(this.state.translations).forEach(key => {
      _translations[key] = this.state.translations[key].replace(
        /:company_title|:job_title|:interview_code/gi,
        match => {
          return mapTranslations[match];
        }
      );
    });

    if (_translations.done_content_2_finished) {
      _done_content_2_finished_split = _translations.done_content_2_finished.split(
        "|"
      );
    }

    if (_translations.done_content_2_unfinished) {
      _done_content_2_unfinished_split = _translations.done_content_2_unfinished.split(
        "|"
      );
    }

    switch (this.state.type) {
      case "finish-and-unsubscribe":
        finishPage = (
          <div>
            <div className="center">
              <span className="icon-kognisi-final center m-t-80 m-b-80"></span>
              <h2 className="heading2 bold text-left">
                {" "}
                Anda telah berhenti berlangganan{" "}
              </h2>
            </div>
            <div>
              <p className="heading5 normal line-height-l">
                Kini Anda tidak akan menerima email apapun tentang kursus ini.
              </p>
            </div>
            <div className="p-32 center">
              <Button
                onClick={this.resubscribeJob.bind(this)}
                theme="secondary"
                className="m-t-32 m-b-32 center"
              >
                Kembali Berlangganan
              </Button>
            </div>
          </div>
        );
        break;
      case "unfinish-and-unsubscribe":
        finishPage = (
          <div>
            <div className="center">
              <span className="icon-kognisi-final center m-t-80 m-b-80"></span>
              <h2 className="heading2 bold text-left">
                {" "}
                Anda telah berhenti berlangganan{" "}
              </h2>
            </div>
            <div>
              <p className="heading5 normal line-height-l">
                Kini Anda tidak akan menerima email apapun tentang kursus ini.
              </p>
            </div>
            <div className="p-32 center">
              <Button
                onClick={this.resubscribeJob.bind(this)}
                theme="secondary"
                className="m-t-32 m-b-32 center"
              >
                Kembali Berlangganan
              </Button>
            </div>
          </div>
        );
        break;
      default:
        finishPage = (
          <div>
            <div className="center">
              <span className="icon-kognisi-final center m-t-80 m-b-80"></span>
              <h2 className="heading2 bold text-left">
                {" "}
                Anda telah berhenti berlangganan{" "}
              </h2>
            </div>
            <div>
              <p className="heading5 normal line-height-l">
                Kini Anda tidak akan menerima email apapun tentang kursus ini.
              </p>
            </div>
            <div className="p-32 center">
              <Button
                onClick={this.resubscribeJob.bind(this)}
                theme="secondary"
                className="m-t-32 m-b-32 center"
              >
                Kembali Berlangganan
              </Button>
            </div>
          </div>
        );
        break;
    }

    const displayLandingPage = this.state.data.not_interested ? (
      finishPage
    ) : (
      <div hidden={this.state.hideForm}>
        <div className="center">
          <span className="icon-kognisi-final m-t-80 m-b-80"></span>
        </div>
        <h2 className="heading2 bold p-l-20 p-r-20">
          Berhenti dari kursus “{this.state.job}” di Kognisi Marketplace
        </h2>
        <div className="p-l-20 p-r-20">
          <p className="m-b-40 heading5 normal">
            {/*{" "}
            {_translations.form_content_1}{" "}*/}
            Kami akan berhenti mengirimkan informasi tentang kursus ini.
          </p>
        </div>
        <div className="m-t-20 p-l-20 p-r-20 p-b-40">
          <div className="container-grey-not-interested">
            <p className="m-b-16 heading5 normal">
              {/*{" "}
              {_translations.form_content_2}{" "}*/}
              Demi meningkatkan pelayanan, mohon berikan alasan Anda untuk
              berhenti berlangganan:
            </p>
            {this.state.options.map((option, index) => (
              <ListAnswersNotInterested
                key={index}
                theme="circle"
                title={""}
                type="checkbox"
                name="super"
                label={option.label}
                value={option.label}
                checked={option.checked}
                size="small"
                onClick={() => this.handleAnswersClick(index)}
              />
            ))}

            <p className="m-t-16 m-b-5"> {_translations.form_textarea} </p>
            <textarea
              className="w-100 max-w-100"
              cols="40"
              rows="6"
              id="textareaForm"
            >
              {" "}
            </textarea>
          </div>
          <div className="center m-t-32">
            <Button
              theme="primary"
              className="m-t-32 m-b-32 center"
              disabled={this.state.submit}
              onClick={this.submitData.bind(this)}
            >
              Kirim
            </Button>
          </div>
        </div>
      </div>
    );

    const displayThankyouPage = this.state.reapply ? (
      <div hidden={this.state.hideThankyouPage}>
        <div className="center">
          <span className="icon-kognisi-final center m-t-80 m-b-80"></span>
          <h2 className="heading2 bold text-left">
            {" "}
            Anda kembali berlangganan{" "}
          </h2>
        </div>
        <div className="m-b-40">
          <p className="heading5 normal line-height-l">
            Kami akan kembali mengirimkan informasi tentang kursus ini.
          </p>
        </div>
      </div>
    ) : (
      <div hidden={this.state.hideThankyouPage}>
        <div className="center">
          <span className="icon-kognisi-final center m-t-80 m-b-80"></span>
          <h2 className="heading2 bold text-left">
            {" "}
            Anda telah berhenti berlangganan{" "}
          </h2>
        </div>
        <div>
          <p className="heading5 normal line-height-l">
            Kini Anda tidak akan menerima email apapun tentang kursus ini.
          </p>
        </div>
        <div className="p-32 center">
          <Button
            onClick={this.resubscribeJob.bind(this)}
            theme="secondary"
            className="m-t-32 m-b-32 center"
          >
            Kembali Berlangganan
          </Button>
        </div>
      </div>
    );

    return (
      <Fragment>
        <div hidden={this.state.hideAll}>
          {/*<Header />*/}
          <div className="container container-not-interested">
            <Card theme={"form"} className={"m-t-60"}>
              <div hidden={this.state.reapply}>{displayLandingPage}</div>
              {displayThankyouPage}
            </Card>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    sessionCode: ownProps.location.pathname.split("/")[2],
    translations: state.translations
  };
};

export default connect(mapStateToProps)(NotInterested);
