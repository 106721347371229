// @flow

import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import Header from '../../lib/elements/Header'
import CardFooterLayout from '../../lib/elements/CardFooterLayout'
import imgSands from '../../images/sands.png'

import { t } from '../../helpers/global'

type Props = {
  translations: Map<string, any>
};

class TimeOutPage extends React.Component<Props> {
	render() {
    const { translations } = this.props

		return(
      <Fragment>
        <Header type="default" />
        <div className="container">
          <CardFooterLayout
            contentTitle={t(translations, "DEADLINE_PASSED_SESSION_NOT")}
            contentImage={imgSands}
            contentImageAlt={t(translations, "COMPLETED_SESSION_FINISHED")}
            contentText={t(translations, "TIMEOUT_PAGE_YOUR")} />
        </div>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations
  }
}

export default connect(mapStateToProps, null)(TimeOutPage)
