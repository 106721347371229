// @flow
import React from "react";
import Card from "./Card";
import Content from "./Content";
import ContentSoundCheck from "./ContentSoundCheck";
import Button from "../elements/Button";
import Loader from "../elements/Loader";
import MicrophoneTester from "../../lib/elements/MicrophoneTester";
import imgPengajar1 from "../../images/pengajar1.png";
import imgPengajar2 from "../../images/pengajar2.png";

type Props = {
  contentTitle: string,
  contentImage: string,
  contentImageAlt: string,
  contentText: string,
  className: string,
  buttonName: string,
  loaderName?: string,
  loaderClassName?: string,
  onClick: Function,
  isLoading: boolean
};

class CardTopicTitle extends React.Component<Props> {
  static defaultProps = {
    contentTitle: "",
    contentText: "",
    contentImage: "",
    contentImageAlt: "",
    className: "",
    buttonName: "",
    onClick: () => {},
    isLoading: false
  };

  render() {
    const { className, course, ecourseTeachers } = this.props;

    return (
      <Card theme="title" className={className}>
        <div className="card-body p-0">
          <h2 className="heading2 bold m-0 uppercase">{course.get("title")}</h2>
          <div className="container-teacher">
            {ecourseTeachers.map(t => {
              return (
                <div className="pengajar-container">
                  <img src={t.get("photo")} />
                  <div className="flex-grow pengajar-detail">
                    <h4>{t.get("name")}</h4>
                    <label>{t.get("title")}</label>
                    <p>{t.get("description")}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Card>
    );
  }
}

export default CardTopicTitle;
