// @flow

import React from 'react'
import QuestionBar from './QuestionBar'
import VolumeBar from './VolumeBar'

type Props = {
  label: string,
  type: string,
  questionText: string,
  className: string,
  refVolumePercentage: Object
};

class HeaderQuestionInterview extends React.Component<Props> {
  static defaultProps = {
    label: '',
    type: 'default',
    questionText: '',
    className: '',
    refVolumePercentage: {}
  }

  render() {
    const {
      label,
      type,
      questionText,
      refVolumePercentage
    } = this.props

    return (
      <div className="bg-dark">
        <div className="container">
          <div className="header-video">
            <QuestionBar type={type} label={label} questionText={questionText} />
            <VolumeBar refVolumePercentage={refVolumePercentage} />
          </div>
        </div>
      </div>
    )
  }
}

export default HeaderQuestionInterview
