// @flow

import { put, call, select, take, all, fork, cancel } from "redux-saga/effects";
import { delay } from "redux-saga";
import { push } from "react-router-redux";
import { Map, List } from "immutable";
import * as Logger from "astrnt-web-logger";
import { countdown } from "./global";
import { initInterview } from "./interviews";
import { sessionsFinish, sessionsShowFinishModal } from "./sessions";
import Swal from "sweetalert2";
import store_shopee from "../dependencies/forShopeeStorage";
import {
  questionsFinish,
  questionsAnswer,
  questionsGetNextId,
  questionsGetDetail,
  questionsAnswerOnShowAll,
  questionsAddAnswersOnAllPages2
} from "./questions";

import {
  sessionsStartApi,
  sessionsUpdateElapsedTimeApi,
  sessionsSetGDPRComplied
} from "../apis/sessions";

import { sessionRunTimeWatcher } from "../actions/sessions";

import { frontEndRunTime } from "../dependencies/frontEndRuntime";

import { sectionsStartApi, sectionsStopApi } from "../apis/sections";

import {
  getQuestion,
  getTranslations,
  getQuestionsFormData,
  getCandidate,
  getSection,
  getSections,
  getSessionsFilterData,
  getsessionDurationCount,
  getInterviewsFilterData,
  getinterviewPracticeSound
} from "../selectors";

import { t } from "../helpers/global";

export function* sectionsStartSessionWelcomeVideo({
  sessionCode
}: Object): Generator<*, *, *> {
  try {
    const sessionsFilterData: Map<string, any> = yield select(
      getSessionsFilterData
    );

    yield all([
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
        loading: true
      }),
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
        disabled_button: true
      })
    ]);

    yield call(
      sessionsSetGDPRComplied,
      sessionCode,
      sessionsFilterData.get("token")
    );

    yield all([
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
        loading: false
      }),
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
        disabled_button: false
      })
    ]);
    yield call(sectionsStartSession, { sessionCode });
    // yield all([put(push(`/code/${sessionCode}/section-welcome-video`))]);
  } catch (error) {
    yield all([
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
        loading: false
      }),
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
        disabled_button: false
      })
    ]);
    console.error(error);
  }
}

export function* sectionsStartSession({
  sessionCode
}: Object): Generator<*, *, *> {
  const translations: Map<string, any> = yield select(getTranslations);
  const sessionDurationCount: Map<string, any> = yield select(
    getsessionDurationCount
  );

  try {
    const sessionsFilterData: Map<string, any> = yield select(
      getSessionsFilterData
    );

    yield all([
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
        loading: true
      }),
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
        disabled_button: true
      })
    ]);

    yield call(
      sessionsSetGDPRComplied,
      sessionCode,
      sessionsFilterData.get("token")
    );
    // frontEndRunTime(
    //   sessionDurationCount.get("front_end_run_time"),
    //   sessionRunTimeWatcher,
    //   true
    // );
    const response: Object = yield call(
      sessionsStartApi,
      sessionCode,
      sessionsFilterData.get("token")
    );

    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data.message != "not authorized"
    ) {
      const section: Map<string, any> = yield select(getSection);

      // frontEndRunTime(
      //   sessionDurationCount.get("front_end_run_time"),
      //   sessionRunTimeWatcher,
      //   false
      // );

      // if (!sessionsFilterData.get("is_section_started")) {
      //   yield put({
      //     type: "SECTIONS_DECIDE_REDUCE_TIMER_REQUEST",
      //     sessionCode
      //   });
      // }

      yield all([
        put({
          type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
          loading: false
        }),
        put({
          type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
          disabled_button: false
        })
      ]);
      yield all([
        // put({
        //   type: "SESSIONS_ADD_FILTER_IS_SECTION_STARTED_SUCCESS",
        //   is_section_started: true
        // }),
        put(
          push(`/code/${sessionCode}/sections/${section.get("id")}/instruction`)
        )
      ]);
    } else {
      throw response;
    }
  } catch (error) {
    frontEndRunTime(
      sessionDurationCount.get("front_end_run_time"),
      sessionRunTimeWatcher,
      false
    );

    yield all([
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
        loading: false
      }),
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
        disabled_button: false
      })
    ]);
    console.error(error);

    if (typeof error.data == "undefined") {
      console.log(error);
    } else {
      if (error.data.message == "not authorized") {
        Swal({
          type: "error",
          title: t(translations, "MULTIPLE_DEVICES_TITLE"),
          text: t(translations, "MULTIPLE_DEVICES_CONTENT")
        }).then(function() {
          window.location.reload(true);
        });
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
}

export function* sectionsGDPR({ sessionCode }: Object): Generator<*, *, *> {
  try {
    const sessionsFilterData: Map<string, any> = yield select(
      getSessionsFilterData
    );

    const response: Object = yield call(
      getSessionsFilterData,
      sessionCode,
      sessionsFilterData.get("token"),
      sessionsFilterData.get("gdpr_complied"),
      sessionsFilterData.get("gdpr_text")
    );
    yield all([put(push(`/code/${sessionCode}/check-gdpr-sections`))]);
  } catch (error) {
    console.error(error);
  }
}

export function* sectionsStart(sessionCode: string): Generator<*, *, *> {
  try {
    const section: Map<string, any> = yield select(getSection);
    const candidate: Map<string, any> = yield select(getCandidate);
    const sessionsFilterData: Map<string, any> = yield select(
      getSessionsFilterData
    );
    const sessionDurationCount: Map<string, any> = yield select(
      getsessionDurationCount
    );

    frontEndRunTime(
      sessionDurationCount.get("front_end_run_time"),
      sessionRunTimeWatcher,
      true
    );
    const duration_used =
      sessionDurationCount.get("duration_left") -
      sessionDurationCount.get("front_end_run_time");
    yield call(
      sectionsStartApi,
      candidate.get("id"),
      section.get("id"),
      sessionCode,
      sessionsFilterData.get("token"),
      duration_used
    );

    frontEndRunTime(
      sessionDurationCount.get("front_end_run_time"),
      sessionRunTimeWatcher,
      false
    );
  } catch (error) {
    yield all([
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
        loading: false
      }),
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
        disabled_button: false
      })
    ]);
    console.log(error);
  }
}

export function* sectionsStop(sessionCode: string): Generator<*, *, *> {
  try {
    const section: Map<string, any> = yield select(getSection);
    const candidate: Map<string, any> = yield select(getCandidate);
    const sessionsFilterData: Map<string, any> = yield select(
      getSessionsFilterData
    );

    const sectionNumber: number = section.get("index") + 1;

    let params = {
      event: "Section Info", // string
      message: "Section Stop on section " + sectionNumber,
      status: "offline" // string
    };

    Logger.recordEvent(params);

    yield call(
      sectionsStopApi,
      candidate.get("id"),
      section.get("id"),
      sessionCode,
      sessionsFilterData.get("token")
    );
  } catch (error) {
    console.log(error);
  }
}

export function* sectionsGetNextId(): Generator<*, *, *> {
  const section: Map<string, any> = yield select(getSection);
  const sections: List<Map<string, any>> = yield select(getSections);
  let _section: Map<string, any> = sections.get(section.get("index") + 1);

  if (!_section) {
    return _section;
  }

  // return if section has unanswered_question_ids
  if (_section.get("unanswered_question_ids").size > 0) {
    return _section;
  }

  let loop = true;
  while (loop) {
    _section = sections.get(_section.get("index") + 1);

    if (_section) {
      if (_section.get("unanswered_question_ids").size > 0) {
        loop = false;
      }
    } else {
      loop = false;
    }
  }

  return _section;
}

export function* sectionsGetDetail(
  sessionCode: string,
  headerTitle: string
): Generator<*, *, *> {
  const section: Map<string, any> = yield call(sectionsGetNextId);
  const translations: Map<string, any> = yield select(getTranslations);
  //disini update sections
  const topics: List<Map<string, any>> = yield select(getSections);
  const prevSection: Map<string, any> = yield select(getSection);
  const sessionsFilterData: Map<string, any> = yield select(
    getSessionsFilterData
  );

  let mergedTopics: List<Map<string, any>> = topics.map(topic => {
    if (topic.get("id") === prevSection.get("id")) {
      return topic.merge(prevSection);
    } else {
      return topic;
    }
  });

  if (section) {
    yield put({
      type: "SECTIONS_GET_DETAIL_SUCCESS",
      section: section
    });

    yield put({ type: "SECTIONS_FETCH_DATA_SUCCESS", sections: mergedTopics });

    yield call(
      sessionsShowFinishModal,
      headerTitle,
      t(translations, "INTERVIEW_DATA_LOADING_NEXT_SECTION")
    );

    yield put(
      push(`/code/${sessionCode}/sections/${section.get("id")}/instruction`)
    );

    let params = {
      event: "Question Finish", // string
      message: "Section Id on sectionGetDetail function" + section.get("id"),
      status: "offline" // string
    };

    Logger.recordEvent(params);

    yield put({
      type: "SECTIONS_DECIDE_REDUCE_TIMER_REQUEST",
      sessionCode
    });
  } else {
    // check all answered questions
    let unansweredQuestions = [];
    mergedTopics.map((section, index) => {
      if (section.get("unanswered_question_ids").size > 0) {
        unansweredQuestions.push(section.get("unanswered_question_ids").toJS());
      }
    });

    if (unansweredQuestions.length > 0) {
      yield put({
        type: "SECTIONS_FETCH_DATA_SUCCESS",
        sections: mergedTopics
      });

      yield put(push(`/code/${sessionCode}`));
    } else {
      // check if the session already finish
      if (sessionsFilterData.get("is_finish")) {
        console.log("back to home");
        yield put(push(`/code/${sessionCode}`));
      } else {
        yield call(
          sessionsFinish,
          sessionCode,
          headerTitle,
          t(translations, "INTERVIEW_DATA_YOU_HAVE")
        );
      }
    }
  }

  yield all([
    put({
      type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
      loading: false
    }),
    put({
      type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
      disabled_button: false
    })
  ]);

  return section;
}

export function* sectionsDecideReduceTimer({
  sessionCode
}: Object): Generator<*, *, *> {
  const section: Map<string, any> = yield select(getSection);

  if (section.get("preparation")) {
    yield put({
      type: "SECTIONS_REDUCE_PREPARATION_REQUEST",
      sessionCode
    });
  } else if (section.get("duration")) {
    yield put({
      type: "SECTIONS_REDUCE_DURATION_REQUEST",
      sessionCode
    });
  }
}

export function* sectionsStartNextInterviewQuestion({
  sessionCode
}: Object): Generator<*, *, *> {
  try {
    const questionId: number = yield call(questionsGetNextId);
    const section: Map<string, any> = yield select(getSection);

    if (questionId && section.get("duration")) {
      yield call(questionsGetDetail, questionId, sessionCode);

      yield put({
        type: "INTERVIEWS_ADD_FILTER_IS_PRACTICE_SUCCESS",
        is_practice: false
      });

      yield call(initInterview);

      yield put(
        push(
          `/code/${sessionCode}/sections/${section.get(
            "id"
          )}/interview-questions/${questionId}/instruction`
        )
      );
      yield all([
        put({
          type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
          loading: false
        }),
        put({
          type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
          disabled_button: false
        })
      ]);
    } else {
      const translations: Map<string, any> = yield select(getTranslations);
      const sessionsFilterData: Map<string, any> = yield select(
        getSessionsFilterData
      );

      yield call(
        sessionsUpdateElapsedTimeApi,
        section.get("id"),
        "section",
        sessionCode,
        sessionsFilterData.get("token")
      );

      yield call(sectionsStop, sessionCode);

      yield put({ type: "SECTIONS_REDUCE_DURATION_CANCEL" });

      yield call(
        sectionsGetDetail,
        sessionCode,
        t(translations, "INTERVIEW_DATA_THATS")
      );
      yield all([
        put({
          type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
          loading: false
        }),
        put({
          type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
          disabled_button: false
        })
      ]);
    }
  } catch (error) {
    console.error(error);
    yield all([
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
        loading: false
      }),
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
        disabled_button: false
      })
    ]);
  }
}

export function* sectionsStartNextMcqSession({
  sessionCode
}: Object): Generator<*, *, *> {
  try {
    const section: Map<string, any> = yield select(getSection);
    const translations: Map<string, any> = yield select(getTranslations);
    const sessionsFilterData: Map<string, any> = yield select(
      getSessionsFilterData
    );

    yield call(
      sessionsUpdateElapsedTimeApi,
      section.get("id"),
      "section",
      sessionCode,
      sessionsFilterData.get("token")
    );
    let params = {
      event: "Question Finish", // string
      message: "Section Id after elapsed Time function" + section.get("id"),
      status: "offline" // string
    };

    Logger.recordEvent(params);
    // Make questionId blank, because backend need to decide this invitation from section or not
    yield call(questionsFinish, "", sessionCode);

    yield call(sectionsStop, sessionCode);

    yield put({ type: "SECTIONS_REDUCE_DURATION_CANCEL" });

    yield call(
      sectionsGetDetail,
      sessionCode,
      t(translations, "INTERVIEW_DATA_THATS")
    );
  } catch (error) {
    yield all([
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
        loading: false
      }),
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
        disabled_button: false
      })
    ]);
    console.log(error);
  }
}

export function* sectionsStartNextFtqSession({
  sessionCode
}: Object): Generator<*, *, *> {
  try {
    const section: Map<string, any> = yield select(getSection);
    const translations: Map<string, any> = yield select(getTranslations);
    const sessionsFilterData: Map<string, any> = yield select(
      getSessionsFilterData
    );

    yield call(
      sessionsUpdateElapsedTimeApi,
      section.get("id"),
      "section",
      sessionCode,
      sessionsFilterData.get("token")
    );

    // Make questionId blank, because backend need to decide this invitation from section or not
    yield call(questionsFinish, "", sessionCode);

    yield call(sectionsStop, sessionCode);

    yield put({ type: "SECTIONS_REDUCE_DURATION_CANCEL" });

    yield call(
      sectionsGetDetail,
      sessionCode,
      t(translations, "INTERVIEW_DATA_THATS")
    );
  } catch (error) {
    console.log(error);
  }
}

export function* sectionsStartAndSkipPreparation(
  sessionCode: string
): Generator<*, *, *> {
  try {
    const section: Map<string, any> = yield select(getSection);
    const sessionsFilterData: Map<string, any> = yield select(
      getSessionsFilterData
    );

    if (section.get("preparation")) {
      yield call(
        sessionsUpdateElapsedTimeApi,
        section.get("id"),
        "preparation",
        sessionCode,
        sessionsFilterData.get("token")
      );

      yield put({ type: "SECTIONS_REDUCE_PREPARATION_CANCEL" });
    }

    yield call(sectionsStart, sessionCode);
  } catch (error) {
    console.log(error);
    yield all([
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
        loading: false
      }),
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
        disabled_button: false
      })
    ]);
  }
}

export function* sectionsStartInterview({
  sessionCode
}: Object): Generator<*, *, *> {
  try {
    yield all([
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
        loading: true
      }),
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
        disabled_button: true
      })
    ]);

    yield call(sectionsStartAndSkipPreparation, sessionCode);

    yield put({
      type: "SECTIONS_START_NEXT_INTERVIEW_QUESTION_REQUEST",
      sessionCode
    });
  } catch (error) {
    yield all([
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
        loading: false
      }),
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
        disabled_button: false
      })
    ]);
    console.log(error);
  }
}

export function* sectionsStartMcq({ sessionCode }: Object): Generator<*, *, *> {
  try {
    yield call(sectionsStartAndSkipPreparation, sessionCode);
    const questionId: number = yield call(questionsGetNextId);
    const section: Map<string, any> = yield select(getSection);
    yield all([
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
        loading: true
      }),
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
        disabled_button: true
      })
    ]);
    yield call(questionsGetDetail, questionId, sessionCode);

    yield all([
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
        loading: false
      }),
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
        disabled_button: false
      })
    ]);

    yield put(
      push(
        `/code/${sessionCode}/sections/${section.get(
          "id"
        )}/mcq-questions/${questionId}`
      )
    );
  } catch (error) {
    yield all([
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
        loading: false
      }),
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
        disabled_button: false
      })
    ]);
    console.log(error);
  }
}

export function* sectionsStartFtq({ sessionCode }: Object): Generator<*, *, *> {
  try {
    yield call(sectionsStartAndSkipPreparation, sessionCode);
    const questionId: number = yield call(questionsGetNextId);
    const section: Map<string, any> = yield select(getSection);

    yield call(questionsGetDetail, questionId, sessionCode);

    yield put(
      push(
        `/code/${sessionCode}/sections/${section.get(
          "id"
        )}/ftq-questions/${questionId}`
      )
    );
  } catch (error) {
    console.log(error);
  }
}

export function* sectionsAnswerQuestionOnShowAll({
  id,
  sessionCode,
  question
}: Object): Generator<*, *, *> {
  // yield put({
  //   type: "QUESTIONS_ADD_ANSWERS_REQUEST_ON_ALL_PAGES",
  //   id: id,
  //   question: question
  // });
  yield call(questionsAddAnswersOnAllPages2, id, question);

  const section: Map<string, any> = yield select(getSection);
  const questionsFormData: Map<string, any> = yield select(
    getQuestionsFormData
  );

  let unansweredQuestionIds: List<number>;

  if (questionsFormData.get("answered_ids").size) {
    unansweredQuestionIds = section
      .get("unanswered_question_ids")
      .filter(questionId => questionId !== question.get("id"));
  } else {
    unansweredQuestionIds = section
      .get("unanswered_question_ids")
      .push(question.get("id"));
  }

  yield put({
    type: "SECTIONS_REDUCE_UNANSWERED_QUESTION_IDS_SUCCESS",
    unanswered_question_ids: unansweredQuestionIds
  });

  // Need to make sure answered_ids is updated
  // yield delay(100);
  try {
    const response: Object = yield call(
      questionsAnswerOnShowAll,
      sessionCode,
      "section",
      question,
      id
    );

    if (response.status >= 200 && response.status < 300) {
    } else {
      throw response;
    }
  } catch (error) {
    console.log("error");
  }
}

export function* sectionsAnswerQuestion({
  id,
  sessionCode
}: Object): Generator<*, *, *> {
  try {
    yield put({
      type: "QUESTIONS_ADD_ANSWERS_REQUEST",
      id: id
    });

    // Need to make sure answered_ids is updated
    yield delay(100);

    const question: Map<string, any> = yield select(getQuestion);
    const section: Map<string, any> = yield select(getSection);
    const questionsFormData: Map<string, any> = yield select(
      getQuestionsFormData
    );

    let unansweredQuestionIds: List<number>;

    if (questionsFormData.get("answered_ids").size) {
      unansweredQuestionIds = section
        .get("unanswered_question_ids")
        .filter(questionId => questionId !== question.get("id"));
    } else {
      unansweredQuestionIds = section
        .get("unanswered_question_ids")
        .push(question.get("id"));
    }

    yield put({
      type: "SECTIONS_REDUCE_UNANSWERED_QUESTION_IDS_SUCCESS",
      unanswered_question_ids: unansweredQuestionIds
    });
  } catch (error) {
    console.log(error);
  }
}

export function* sectionsAnswerQuestionSend({
  sessionCode,
  resolve,
  reject
}: Object): Generator<*, *, *> {
  yield delay(200);
  try {
    //agar tidak dispam

    const response: Object = yield call(
      questionsAnswer,
      sessionCode,
      "section"
    );

    if (response.status >= 200 && response.status < 300) {
      resolve();
    } else {
      throw response;
    }
  } catch (error) {
    yield all([
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_SUCCESS",
        loading: false
      }),
      put({
        type: "SESSIONS_ADD_UI_BUTTON_DATA_DISABLED",
        disabled_button: false
      })
    ]);
    reject();
    console.log(error);
  }
}

export function* sectionsAnswerTextQuestion({
  text,
  sessionCode,
  question_id,
  resolve,
  reject
}: Object): Generator<*, *, *> {
  yield put({
    type: "QUESTIONS_ADD_ANSWERS_TEXT_REQUEST",
    text: text
  });
  // Need to make sure answered_ids is updated
  yield delay(100);
  const response: Object = yield call(questionsAnswer, sessionCode, "section");

  if (response.status >= 200 && response.status < 300) {
    const question: Map<string, any> = yield select(getQuestion);
    const section: Map<string, any> = yield select(getSection);
    const questionsFormData: Map<string, any> = yield select(
      getQuestionsFormData
    );
    let unansweredQuestionIds: List<number>;

    if (text.length > 0) {
      unansweredQuestionIds = section
        .get("unanswered_question_ids")
        .filter(questionId => questionId !== parseInt(question_id));

      yield put({
        type: "SECTIONS_REDUCE_UNANSWERED_QUESTION_IDS_SUCCESS",
        unanswered_question_ids: unansweredQuestionIds
      });
    } else {
      let unansweredQuestions = section.get("unanswered_question_ids");
      unansweredQuestions = unansweredQuestions.indexOf(question.get("id"));
      if (unansweredQuestions == -1) {
        unansweredQuestionIds = section
          .get("unanswered_question_ids")
          .push(question.get("id"));

        yield put({
          type: "SECTIONS_REDUCE_UNANSWERED_QUESTION_IDS_SUCCESS",
          unanswered_question_ids: unansweredQuestionIds
        });
      }
    }
    resolve();
  } else {
    reject();
    throw response;
  }
}

export function* sectionsReducePreparation(
  sessionCode: string
): Generator<*, *, *> {
  const section: Map<string, any> = yield select(getSection);
  const interviewsFilterData: Map<string, any> = yield select(
    getInterviewsFilterData
  );
  const channel = yield call(countdown, section.get("preparation"));
  const interviewPracticeSound: Map<string, any> = yield select(
    getinterviewPracticeSound
  );
  try {
    while (true) {
      const seconds = yield take(channel);

      yield put({
        type: "SECTIONS_REDUCE_PREPARATION_SUCCESS",
        preparation: seconds
      });
    }
  } finally {
    yield put({
      type: "SECTIONS_REDUCE_DURATION_REQUEST",
      sessionCode
    });

    channel.close();
    if (
      !interviewsFilterData.get("is_check_sound") ||
      interviewPracticeSound.get("valid")
    ) {
      if (
        section.get("type") === "test" &&
        section.get("sub_type") !== "freetext"
      ) {
        console.log("masuk");
        yield put({
          type: "SECTIONS_START_MCQ_REQUEST",
          sessionCode
        });
      } else if (
        section.get("type") === "test" &&
        section.get("sub_type") === "freetext"
      ) {
        console.log("masuk");
        yield put({
          type: "SECTIONS_START_FTQ_REQUEST",
          sessionCode
        });
      } else {
        console.log("masuk");
        yield put({
          type: "SECTIONS_START_INTERVIEW_REQUEST",
          sessionCode
        });
      }
    }
  }
}

export function* sectionsReduceDuration(
  sessionCode: string
): Generator<*, *, *> {
  const section: Map<string, any> = yield select(getSection);

  const channel = yield call(countdown, section.get("duration"));

  try {
    while (true) {
      const seconds = yield take(channel);
      yield put({
        type: "SECTIONS_REDUCE_DURATION_SUCCESS",
        duration: seconds
      });
      //for shopee definetly mcq if shope 1
      const _section: Map<string, any> = yield select(getSection);
      if (section.get("is_shopee") == 1) {
        if (_section.get("duration") <= 599 && store_shopee.get() != "open") {
          const currentPathArr: Array<string> = window.location.pathname.split(
            "/"
          );
          const lastIndexOfPathArr: string =
            currentPathArr[currentPathArr.length - 1];

          if (
            _section.get("type") === "interview" &&
            lastIndexOfPathArr !== "instruction"
          ) {
            yield put({
              type: "INTERVIEWS_ADD_FILTER_QUESTION_TAKES_COUNT_SUCCESS",
              question_takes_count: 0
            });
          } else {
            const translations: Map<string, any> = yield select(
              getTranslations
            );

            yield put({
              type: "SECTIONS_START_NEXT_MCQ_SESSION_REQUEST",
              sessionCode
            });
            //kurang call next section
          }
          store_shopee.set("close");
          channel.close();
        }
      }
    }
  } finally {
    const _section: Map<string, any> = yield select(getSection);

    if (_section.get("duration") <= 0) {
      const currentPathArr: Array<string> = window.location.pathname.split("/");
      const lastIndexOfPathArr: string =
        currentPathArr[currentPathArr.length - 1];

      if (
        _section.get("type") === "interview" &&
        lastIndexOfPathArr !== "instruction"
      ) {
        yield put({
          type: "INTERVIEWS_ADD_FILTER_QUESTION_TAKES_COUNT_SUCCESS",
          question_takes_count: 0
        });
      } else {
        const translations: Map<string, any> = yield select(getTranslations);

        yield call(
          sectionsGetDetail,
          sessionCode,
          t(translations, "INTERVIEW_DATA_TIMES")
        );
      }
    }

    channel.close();
  }
}

export function* sectionsReducePreparationWatcher({
  sessionCode
}: Object): Generator<*, *, *> {
  const backgroundSyncTask = yield fork(sectionsReducePreparation, sessionCode);

  yield take("SECTIONS_REDUCE_PREPARATION_CANCEL");

  yield cancel(backgroundSyncTask);
}

export function* sectionsReduceDurationWatcher({
  sessionCode
}: Object): Generator<*, *, *> {
  const backgroundSyncTask = yield fork(sectionsReduceDuration, sessionCode);

  yield take("SECTIONS_REDUCE_DURATION_CANCEL");

  yield cancel(backgroundSyncTask);
}
