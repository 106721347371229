// @flow

import { put, call, select, all } from "redux-saga/effects";
import { push } from "react-router-redux";
import { Map, toJS } from "immutable";

export function* sendAstronautQnASessionEvent({
  astronautEvent
}: Object): Generator<*, *, *> {
  try {
    window.parent.postMessage(astronautEvent, "*");
  } catch (error) {
    console.log(error);
  }
}
