// @flow

import _axios from "../../dependencies/_axios";
import React, { Fragment } from "react";
import bindAll from "lodash/bindAll";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Map } from "immutable";
import {
  getProcessingProgessVideoData,
  setVideoProcessingProgressQuestionIdsInfo
} from "../../actions/sessions";
import CardProcessingProgress from "../../lib/elements/CardProcessingProgress";
import CardThankYouForm from "../../lib/elements/CardThankYouForm";
import CardThankYouFormFinish from "../../lib/elements/CardThankYouFormFinish";
import Header from "../../lib/elements/Header";
import * as Logger from "astrnt-web-logger";
import { t, globalDisconnectSocket } from "../../helpers/global";
import { sendAstronautQnASessionEvent } from "../../actions/embedded-interview";
import io from "socket.io-client";

type Props = {
  job: Map<string, any>,
  company: Map<string, any>,
  sessionsFilterData: Map<string, any>,
  sessionCode: string
};

class SessionVideoProcessingProgress extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.state = { errorCounter: false };
    bindAll(this, ["connectSocket", "handleSocket", "handleSubmitClick"]);
  }

  componentDidMount() {
    const {
      getProcessingProgessVideoData,
      sendAstronautQnASessionEvent,
      sessionsFilterData,
      sessionCode
    } = this.props;

    let params = {
      event: "Video Processing Progress Page", // string
      message: "Entered Video Processing Progress Page",
      status: "offline" // string
    };

    getProcessingProgessVideoData(sessionCode, sessionsFilterData.get("token"));

    this.connectSocket();

    var $this = this;
    setTimeout(() => {
      $this.setState({ errorCounter: true });
    }, 60000);

    sendAstronautQnASessionEvent("Video Processing Progress");

    Logger.recordEvent(params);

    Logger.sendSavedEvents();
  }

  connectSocket() {
    const { sessionCode } = this.props;
    if (process.env.REACT_APP_API_HOST === "https://app.astrnt.co") {
      io("https://nodejs-beta.astrnt.co:3001").on(
        `channel:video-${sessionCode}-live-processing-progress`,
        message => {
          this.handleSocket(message);
        }
      );
    } else if (process.env.REACT_APP_API_HOST === "https://beta.astrnt.co") {
      io("https://nodejs-beta.astrnt.co:3001").on(
        `channel:video-${sessionCode}-beta-processing-progress`,
        message => {
          this.handleSocket(message);
        }
      );
    } else {
      io("https://nodejs-beta.astrnt.co:3001").on(
        `channel:video-${sessionCode}-beta-processing-progress`,
        message => {
          this.handleSocket(message);
        }
      );
    }
  }

  componentWillUnmount() {
    globalDisconnectSocket();
  }

  handleSocket(message) {
    const {
      videoProcessingProgressInfo,
      setVideoProcessingProgressQuestionIdsInfo
    } = this.props;

    console.log(message);
    const _question_id = parseInt(message.question_id);

    const question_ids = videoProcessingProgressInfo.get("question_ids").toJS();

    if (_question_id != null) {
      if (!question_ids.includes(_question_id)) {
        question_ids.push(_question_id);
        console.log(question_ids);
        setVideoProcessingProgressQuestionIdsInfo(question_ids);
      }
    }
  }

  handleSubmitClick() {
    const {
      redirect,
      sessionCode,
      sessionsFilterData,
      sendAstronautQnASessionEvent,
      videoProcessingProgressInfo
    } = this.props;

    const token = sessionsFilterData.get("token");
    var videos = [];

    // Get Video
    _axios({
      url: "/api/v2/get/finished/processed/video",
      method: "post",
      headers: {
        token
      },
      data: {
        interview_code: sessionCode
      }
    })
      .then(response => {
        if (response.data.status == "success") {
          videos = response.data.videos;
        } else {
          videos = [];
        }
      })
      .catch(error => {
        console.log(error);
      });

    //Hit interview finish
    _axios({
      url: "/api/v2/interview/finish",
      method: "post",
      headers: {
        token
      },
      data: {
        interview_code: sessionCode
      }
    })
      .then(response => {
        const videos_url = JSON.stringify(videos);
        sendAstronautQnASessionEvent(
          "astrnt_interview_video_result_url:" + videos_url
        );

        redirect(`/code/${sessionCode}/finish`);
      })
      .catch(response => {
        redirect(`/code/${sessionCode}/finish`);
      });
  }

  render() {
    const {
      job,
      company,
      sessionsFilterData,
      translations,
      candidate,
      videoProcessingProgressInfo
    } = this.props;

    const questions = videoProcessingProgressInfo.get("questions") || [];
    var question_answered_ids =
      videoProcessingProgressInfo.get("question_ids") || [];

    return [
      <Fragment>
        <Header
          company={company.get("title")}
          job={job.get("title")}
          position={job.get("jobType")}
          location={job.get("locations")}
          type="secondary"
        />
        <div className="container">
          <CardProcessingProgress
            companyTitle={company.get("title")}
            jobTitle={job.get("title")}
            questions={questions}
            question_answered_ids={question_answered_ids}
            errorCounter={this.state.errorCounter}
            handleSubmitClick={this.handleSubmitClick}
          />
        </div>
      </Fragment>
    ];
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    job: state.job,
    company: state.company,
    sessionsFilterData: state.sessionsFilterData,
    translations: state.translations,
    candidate: state.candidate,
    sessionCode: ownProps.match.params.sessionCode,
    videoProcessingProgressInfo: state.videoProcessingProgressInfo
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendAstronautQnASessionEvent: astronautEvent =>
      dispatch(sendAstronautQnASessionEvent(astronautEvent)),
    getProcessingProgessVideoData: (sessionCode, token) =>
      dispatch(getProcessingProgessVideoData(sessionCode, token)),
    setVideoProcessingProgressQuestionIdsInfo: question_ids =>
      dispatch(setVideoProcessingProgressQuestionIdsInfo(question_ids)),
    redirect: params => dispatch(push(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SessionVideoProcessingProgress);
