// @flow

import React from "react";
import Card from "./Card";

type Props = {
  label: string,
  children?: any
};

class CardWithLabel extends React.Component<Props> {
  static defaultProps = {
    label: "",
    children: null
  };

  render() {
    const { children, label } = this.props;

    return (
      <Card className="card m-t-24 m-t-small">
        <div>
          <h3 className="heading4 bold m-0">{label}</h3>
        </div>
        {children}
      </Card>
    );
  }
}

export default CardWithLabel;
