// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Map } from "immutable";
import Header from "../../lib/elements/Header";
import CardBasicLayout from "../../lib/elements/CardBasicLayout";
import Button from "../../lib/elements/Button";
import Card from "../../lib/elements/Card";
import Content from "../../lib/elements/Content";
import Checkbox from "../../lib/elements/Checkbox";
import bindAll from "lodash/bindAll";
import { sendAstronautQnASessionEvent } from "../../actions/embedded-interview";

import { t } from "../../helpers/global";

import {
  sectionsStartSessionRequest,
  sectionsStartSessionWelcomeVideoRequest
} from "../../actions/sections";

type Props = {
  history: Object,
  translations: Map<string, any>,
  job: Map<string, any>,
  company: Map<string, any>,
  sectionsStartSessionRequest: Function,
  sectionsStartSessionWelcomeVideoRequest: Function,
  sessionCode: string,
  sessionsFilterData: Map<string, any>
};

type State = {
  isConsent: boolean
};

class SectionGDPRPage extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.state = {
      isConsent: false
    };

    bindAll(this, ["handleConsentClick", "handleContinueClick"]);
  }

  handleConsentClick(e) {
    this.setState({ isConsent: !this.state.isConsent });
  }

  handleContinueClick(e) {
    e.preventDefault();

    const {
      sessionCode,
      sessionsFilterData,
      sectionsStartSessionRequest,
      sectionsStartSessionWelcomeVideoRequest,
      sendAstronautQnASessionEvent
    } = this.props;

    sendAstronautQnASessionEvent("GDPR Complied");

    if (sessionsFilterData.get("welcome_video_url") == "") {
      sectionsStartSessionRequest(sessionCode);
    } else {
      sectionsStartSessionWelcomeVideoRequest(sessionCode);
    }
  }

  render() {
    const {
      job,
      company,
      candidate,
      sessionsFilterData,
      translations
    } = this.props;

    const { isConsent } = this.state;

    return (
      <Fragment>
        <Header
          company={company.get("title")}
          job={job.get("title")}
          position={job.get("jobType")}
          location={job.get("locations")}
          type="primary"
        />
        <div className="container">
          <h4 className="m-t-20 m-b-10">
            <b>{t(translations, "GDPR_TITLE")}</b>
          </h4>
          <Card theme="basic">
            <div className="card-body p-0">
              <div className="row">
                <div className="">
                  <h6
                    align="left"
                    dangerouslySetInnerHTML={{
                      __html: sessionsFilterData.get("gdpr_text")
                    }}
                  />
                </div>
              </div>
              <Checkbox
                onClick={this.handleConsentClick}
                checked={isConsent}
                label={sessionsFilterData.get("gdpr_aggrement_text")}
                className="m-t-20"
              />
            </div>
          </Card>
        </div>
        <div className="row justify-content-center">
          <Button
            className="m-t-40"
            onClick={this.handleContinueClick}
            disabled={!isConsent}
          >
            {t(translations, "LABEL_CONTINUE")}
          </Button>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations,
    job: state.job,
    company: state.company,
    candidate: state.candidate,
    sessionCode: ownProps.match.params.sessionCode,
    sessionsFilterData: state.sessionsFilterData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sectionsStartSessionRequest: sessionCode =>
      dispatch(sectionsStartSessionRequest(sessionCode)),
    sectionsStartSessionWelcomeVideoRequest: sessionCode =>
      dispatch(sectionsStartSessionWelcomeVideoRequest(sessionCode)),
    sendAstronautQnASessionEvent: astronautEvent =>
      dispatch(sendAstronautQnASessionEvent(astronautEvent))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SectionGDPRPage)
);
