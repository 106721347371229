// @flow

import React from 'react'
import classnames from 'classnames'

const dotsType = {
  default: 'dots--grey',
  active: 'dots--red'
}

type Props = {
  type: string,
  className: string,
};

class Dots extends React.Component<Props> {
  static defaultProps = {
    type: 'default',
    className: '',
  }

  render() {
    const {
      className,
      type
    } = this.props

    const classProps: string = classnames(
      'dots',
      dotsType[type],
      className
    )
    return (
      <span className={classProps}></span>
    )
  }
}

export default Dots
