// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import bindAll from "lodash/bindAll";
import { Map } from "immutable";

import OptionNote from "../../lib/elements/OptionNote";
import ListAnswers from "../../lib/elements/ListAnswers";
import Button from "../../lib/elements/Button";
import * as Logger from "astrnt-web-logger";
import {
  sectionsAnswerQuestionRequest,
  sectionsAnswerQuestionOnShowAllRequest
} from "../../actions/sections";
import { mcqsAnswerQuestionRequest } from "../../actions/mcqs";
import { questionsAddAnswersRequest } from "../../actions/questions";
import { sendAstronautQnASessionEvent } from "../../actions/embedded-interview";

import { t } from "../../helpers/global";

type Props = {
  translations: Map<string, any>,
  candidate: Map<string, any>,
  interviewsAddUiTestDurationClass: Function,
  sectionsAnswerQuestionRequest: Function,
  mcqsAnswerQuestionRequest: Function,
  sessionsFilterData: Map<string, any>,
  sessionCode: string,
  question: Map<string, any>,
  questionsFormData: Map<string, any>,
  questionsAddAnswersRequest: Function,
  questionsTestAnswer: Function,
  questionsTestAnswerFinish: Function
};

class McqBody extends React.Component<Props> {
  constructor(props: Object): void {
    super(props);

    bindAll(this, [
      "alphabetAtIndex",
      "renderNonSectionSubmitButton",
      "handleAnswerClick",
      "handleSubmitClick"
    ]);
  }

  alphabetAtIndex(index: number): string {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    return alphabet.charAt(index);
  }

  handleAnswerClick(id: number): void {
    const {
      sectionsAnswerQuestionRequest,
      questionsAddAnswersRequest,
      sessionCode,
      sessionsFilterData,
      question,
      not_all_pages,
      sectionsAnswerQuestionOnShowAllRequest,
      first_question
    } = this.props;

    if (sessionsFilterData.get("type") === "close test") {
      if (!sessionsFilterData.get("duration_left")) {
        var eventLocation = "MCQ";
      } else {
        var eventLocation = "MCQ Continue";
      }
    } else if (
      sessionsFilterData.get("type") === "close section without interview"
    ) {
      if (!sessionsFilterData.get("duration_left")) {
        var eventLocation = "Section MCQ";
      } else {
        var eventLocation = "Section MCQ Continue";
      }
    } else if (sessionsFilterData.get("type") === "close section") {
      if (!sessionsFilterData.get("duration_left")) {
        var eventLocation = "Section MCQ";
      } else {
        var eventLocation = "Section MCQ Continue";
      }
    }

    var questionPage = question.toJS();
    var json_session = sessionsFilterData.toJS();
    var question_position;
    json_session.question_ids.map((value, key) =>
      value ===
      parseInt(this.props.history.location.pathname.split("/").slice(-2)[1])
        ? (question_position = key + 1)
        : null
    );
    // console.log(question.toJS());
    let params = {
      event: eventLocation, // string
      message:
        "Answered on mcq page " +
        question_position +
        " " +
        questionPage.title +
        " on id " +
        question.get("id"),
      status: "offline" // string
    };

    Logger.recordEvent(params);

    if (sessionsFilterData.get("type") === "close test") {
      questionsAddAnswersRequest(id);
    } else if (not_all_pages == false) {
      sectionsAnswerQuestionOnShowAllRequest(id, sessionCode, first_question);
    } else {
      sectionsAnswerQuestionRequest(id, sessionCode);
    }
  }

  handleSubmitClick(e: Object): void {
    e.preventDefault();

    const {
      sessionCode,
      mcqsAnswerQuestionRequest,
      sessionsFilterData,
      question,
      sendAstronautQnASessionEvent
    } = this.props;

    sendAstronautQnASessionEvent("Multiple Choice Question Answered");

    var questionPage = question.toJS();
    var json_session = sessionsFilterData.toJS();
    var question_position;
    json_session.question_ids.map((value, key) =>
      value ===
      parseInt(this.props.history.location.pathname.split("/").slice(-2)[1])
        ? (question_position = key + 1)
        : null
    );

    mcqsAnswerQuestionRequest(sessionCode);

    if (sessionsFilterData.get("type") === "close test") {
      if (!sessionsFilterData.get("duration_left")) {
        var eventLocation = "MCQ";
      } else {
        var eventLocation = "MCQ Continue";
      }
    } else if (
      sessionsFilterData.get("type") === "close section without interview"
    ) {
      if (!sessionsFilterData.get("duration_left")) {
        var eventLocation = "Section MCQ";
      } else {
        var eventLocation = "Section MCQ Continue";
      }
    } else if (sessionsFilterData.get("type") === "close section") {
      if (!sessionsFilterData.get("duration_left")) {
        var eventLocation = "Section MCQ";
      } else {
        var eventLocation = "Section MCQ Continue";
      }
    }

    let params = {
      event: eventLocation, // string
      message: "Submited for mcq question page " + question_position,
      status: "offline" // string
    };

    Logger.recordEvent(params);
  }

  renderNonSectionSubmitButton() {
    const { questionsFormData, sessionsFilterData, translations } = this.props;

    let nonSectionSubmitButton: Object;

    if (sessionsFilterData.get("type") === "close test") {
      nonSectionSubmitButton = (
        <center>
          <Button
            theme="primary"
            disabled={!questionsFormData.get("answered_ids").size}
            className="m-b-40"
            onClick={this.handleSubmitClick}
          >
            {t(translations, "LABEL_SUBMIT")}
          </Button>
        </center>
      );
    }

    return nonSectionSubmitButton;
  }

  render(): Object {
    const {
      question,
      questionsFormData,
      questionsOptionMcq,
      not_all_pages,
      first_question
    } = this.props;
    // not_all_pages then its shopee quickspeed
    // console.log(question.get("type_child"));
    let body;
    if (not_all_pages == false) {
      body = (
        <Fragment>
          {first_question.get("type_child") ===
          "manual_options_for_test" ? null : (
            <OptionNote
              plural={
                first_question.get("type_child") === "multiple_options_for_test"
              }
            />
          )}

          <div className="p-l-24 p-r-24 p-b-40">
            {first_question.get("answers").map((answer, index) => (
              <ListAnswers
                key={index}
                theme="circle"
                title={this.alphabetAtIndex(index)}
                image={answer.get("image_url")}
                type="checkbox"
                name="super"
                label={answer.get("label")}
                value={answer.get("id")}
                checked={questionsFormData
                  .get("answered_ids")
                  .includes(answer.get("id"))}
                onClick={() => this.handleAnswerClick(answer.get("id"))}
              />
            ))}
          </div>
          {this.renderNonSectionSubmitButton()}
        </Fragment>
      );
    } else {
      body = (
        <Fragment>
          <OptionNote
            plural={question.get("type_child") === "multiple_options_for_test"}
          />
          <div className="p-l-24 p-r-24 p-b-40">
            {question.get("answers").map((answer, index) => (
              <ListAnswers
                key={index}
                theme="circle"
                title={this.alphabetAtIndex(index)}
                image={answer.get("image_url")}
                type="checkbox"
                name="super"
                label={answer.get("label")}
                value={answer.get("id")}
                checked={questionsFormData
                  .get("answered_ids")
                  .includes(answer.get("id"))}
                onClick={() => this.handleAnswerClick(answer.get("id"))}
              />
            ))}
          </div>
          {this.renderNonSectionSubmitButton()}
        </Fragment>
      );
    }
    try {
      return <Fragment>{body}</Fragment>;
    } catch (error) {
      if (not_all_pages == false) {
        let params = {
          event: "Section MCQ", // string
          message: "Answered on mcq page on id " + first_question.get("id"),
          status: "offline" // string
        };
      } else {
        let params = {
          event: "Section MCQ", // string
          message: "Answered on mcq page on id " + question.get("id"),
          status: "offline" // string
        };
      }
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations,
    candidate: state.candidate,
    sessionsFilterData: state.sessionsFilterData,
    sessionCode: ownProps.match.params.sessionCode,
    question: state.question,
    questionsFormData: state.questionsFormData,
    questionId: ownProps.match.params.questionId,
    questionsOptionMcq: state.questionsOptionMcq
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sectionsAnswerQuestionRequest: (id, sessionCode) =>
      dispatch(sectionsAnswerQuestionRequest(id, sessionCode)),
    sectionsAnswerQuestionOnShowAllRequest: (id, sessionCode, question) =>
      dispatch(
        sectionsAnswerQuestionOnShowAllRequest(id, sessionCode, question)
      ),
    mcqsAnswerQuestionRequest: sessionCode =>
      dispatch(mcqsAnswerQuestionRequest(sessionCode)),
    questionsAddAnswersRequest: id => dispatch(questionsAddAnswersRequest(id)),
    sendAstronautQnASessionEvent: astronautEvent =>
      dispatch(sendAstronautQnASessionEvent(astronautEvent))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(McqBody)
);
