import React, { Fragment } from "react";

import { withRouter } from "react-router";
import { push } from "react-router-redux";

class LoginHome extends React.Component<Props> {
  render() {
    const { children } = this.props;
    return <Fragment>{children}</Fragment>;
  }
}

export default withRouter(LoginHome);
