// @flow

import React from "react";
import bindAll from "lodash/bindAll";
import classnames from "classnames";
import ReactImageMagnify from "react-image-magnify";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Popup from "reactjs-popup";

type Props = {
  className: string,
  classQuestion: string,
  imageUrl: string,
  alt: string
};

type State = {
  isOpen: boolean
};

class ImageWithModal extends React.Component<Props, State> {
  constructor(props: Object) {
    super(props);

    bindAll(this, ["handleOpenModal"]);

    this.state = {
      photoIndex: 0,
      isOpen: false
    };
  }

  static defaultProps = {
    className: "",
    classQuestion: "",
    imageUrl: "",
    alt: ""
  };

  handleOpenModal() {
    this.setState({ isOpen: true });
  }

  render() {
    const { className, imageUrl, alt, classQuestion } = this.props;

    const { isOpen } = this.state;

    const classProps = classnames(
      "img-responsive",
      "img-ftq",
      className,
      classQuestion
    );

    const imageFTQ = imageUrl;

    const userAgent = navigator.userAgent || navigator.vendor;

    let imageFTQContainer = (
      <a className="img-box" onClick={this.handleOpenModal}>
        <img src={imageFTQ} alt={alt} className={classProps} />
        <div className="img-ftq-hover">
          <div>
            <i className="icon-zoom-hover" />
          </div>
        </div>
      </a>
    );

    if (
      /android/i.test(userAgent) ||
      (/ipad|iphone|ipod/i.test(userAgent) && !window.MSStream)
    ) {
      imageFTQContainer = (
        <a onClick={this.handleOpenModal}>
          <img src={imageFTQ} alt={alt} className={classProps} />
        </a>
      );
    }

    return (
      <div className="img-container-ftq">
        {imageFTQContainer}
        <Popup
          open={this.state.isOpen}
          closeOnDocumentClick
          modal
          overlayStyle={{ background: "rgba(0, 0, 0, 0.95)" }}
          contentStyle={{
            width: "auto",
            padding: "0",
            background: "transparent",
            border: "0"
          }}
          onClose={() => this.setState({ isOpen: false })}
        >
          <div className="modal-img-ftq">
            <a
              className="close"
              onClick={() => this.setState({ isOpen: false })}
            >
              &times;
            </a>
            <img src={imageFTQ} alt={alt} />
          </div>
        </Popup>
      </div>
    );
  }
}

export default ImageWithModal;
