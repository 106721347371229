import { Map } from 'immutable'

export function translations(state = Map({}), action) {
  switch (action.type) {
    case 'TRANSLATIONS_FETCH_DATA_SUCCESS':
      return Map(action.translations)
    default:
      return state
  }
}
