import React, { Fragment } from "react";
import {
  Field,
  reduxForm,
  change,
  formValueSelector,
  stopAsyncValidation,
  touch
} from "redux-form";
import { withRouter } from "react-router";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import Header from "../../lib/elements/Header";
import bindAll from "lodash/bindAll";
import imgSuccessBlack from "../../images/icon-success-black.png";

const normalizeEmail = (value, previousValue) => {
  return value.trim();
};

class PassChangeSuccessPage extends React.Component<Props> {
  constructor(props) {
    super(props);

    bindAll(this, [
      "handleSaveData",
      "renderError",
      "inputFocus",
      "inputBlur",
      "handleRedirect"
    ]);
  }

  handleSaveData(values) {
    return;
  }

  handleRedirect() {
    const { redirect, jobCode } = this.props;
    // redirect(`/code/${`rmnwiDhF`}/not-interested`)
    redirect(`/login/${jobCode}`);
  }

  renderError({ meta: { touched, error } }) {
    // const {
    // 	translationjob
    // } = this.props
    // const translations : Map<string, any> = fromJS(translationjob.translation)
    return touched && error ? (
      <div>
        <span className="error normal" id="js-err-msg">
          {"Something wrong! Please contact Administrator!"}
        </span>
      </div>
    ) : (
      false
    );
  }

  inputFocus(e: object): void {
    // const {publicJobCustom}= this.props
    e.target.style.boxShadow = `0 0 10px ${"red"}`;
    e.target.style.border = `1px solid ${"red"}`;
    e.target.style.borderRadius = `5px`;
  }

  inputBlur(e: object): void {
    e.target.style.border = `1px solid #999999`;
    e.target.style.borderRadius = ``;
    e.target.style.boxShadow = ``;
  }

  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      ecourseJobDetail
    } = this.props;

    var ecourseDetail = ecourseJobDetail.toJS();
    let data_provider;
    let data_course;
    let provider = "";
    let course = "";
    let logoUrl = "";
    let jobId = "";
    let passSuccessPage = <div />;

    if (ecourseJobDetail.size > 0) {
      data_provider = ecourseDetail.provider;
      data_course = ecourseDetail.course;
      provider = data_provider.title;
      course = data_course.title;
      logoUrl = data_provider.logoUrl;

      passSuccessPage = (
        <Fragment>
          <Header
            provider={provider}
            course={course}
            position={"Pendaftaran"}
            location={"bandung"}
            logoCourse={logoUrl}
            type="register"
          />
          <div className="container max-width-500 m-t-50">
            <div className="wrapper-request-ecourse">
              <div>
                <img src={imgSuccessBlack} />
              </div>
              <div>
                <label>Kata Sandi berhasil diubah</label>
              </div>
              <p>
                Kamu bisa menggunakan kata sandi baru untuk mengakses kursusnya.
              </p>
              <center className="m-t-30">
                <button
                  id="btn-submit"
                  type="submit"
                  className={`button button__primary button--medium`}
                  onClick={this.handleRedirect}
                >
                  Kembali ke Halaman Login
                </button>
              </center>
            </div>
          </div>
        </Fragment>
      );
    }

    return passSuccessPage;
  }
}

PassChangeSuccessPage = reduxForm({
  form: "PassChangeSuccessPage",
  enableReinitialize: true
})(PassChangeSuccessPage);

const initialValues = {
  password: ""
};
const selector = formValueSelector("PassChangeSuccessPage");
const mapStateToProps = (state, ownProps) => {
  return {
    initialValues,
    formValues: selector(state, "email"),
    jobCode: ownProps.location.pathname.split("/")[2],
    ecourseJobDetail: state.ecourseJobDetail
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeFieldValue: (field, value) =>
      dispatch(change("PassChangeSuccessPage", field, value, true, true)),
    touchForm: field => dispatch(touch("PassChangeSuccessPage", field)),
    stopAsyncValidationForm: object =>
      dispatch(stopAsyncValidation("PassChangeSuccessPage", object)),
    redirect: link => dispatch(push(link))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PassChangeSuccessPage);
