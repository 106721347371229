import React from "react";
import CircularProgressbar from "react-circular-progressbar";

class CircleCounter extends React.Component {
  render() {
    const {
      percentage,
      textForPercentage,
      classForPercentage,
      classForTransparent,
      handleCountdownDurationClick
    } = this.props;

    return (
      <div style={{ width: "59px", height: "59px" }}>
        <a onClick={handleCountdownDurationClick}>
          <CircularProgressbar
            className={`circular-counter ${classForPercentage} ${classForTransparent}`}
            percentage={percentage}
            background="#FFFFFF"
            strokeWidth="6"
            backgroundPadding="0"
            text={textForPercentage}
          />
        </a>
      </div>
    );
  }
}

export default CircleCounter;
